import axios from 'axios';

const URL = process.env.REACT_APP_URL;

export const saveCourseDetailsApi = async (data) => {
  try {
    console.log(data , "saveCourseDetailsApi")
    return await axios.post(`${URL}/api/course/addCourseDetails` , data  );
  } catch (err) {
    console.log('Error occurs while saving course Details', err);
    throw err; 
  }
};


export const fetchCourseApi = async () => {
  try {
    return await axios.get(`${URL}/api/course/fetchCourses`);
  } catch (err) {
    console.log('Error occurs while fetchCourseApi', err);
    throw err; 
  }
};

export const CreateCourseApi = async (courseInfo) => {
  try {
    return await axios.post(`${URL}/api/course/createCourse` , courseInfo);
  } catch (err) {
    console.log('Error occurs while fetchCourseApi', err);
    throw err; 
  }
};


export const createCourseByIdApi = async (id) => {
  try {
    return await axios.post(`${URL}/api/course/createCourseById` ,{ id:id});
  } catch (err) {
    console.log('Error occurs while createCourseByIdApi', err);
    throw err; 
  }
};


export const fetchCourseDetailsApi = async (id) => {
  console.log(id, "fetchCourseDetailsApi");

  try {
    return await axios.get(`${URL}/api/course/fetchCoursesWithDetails`, {
      params: { id }
    });
  } catch (err) {
    console.log('Error occurs while fetchCourseDetailsApi', err);
    throw err; 
  }
};

export const postDiscussionApi = async (data) => {
  try {
    return await axios.post(`${URL}/api/course/addMessage`, data);
  } catch (err) {
    console.log('Error occurs while posting', err);
    throw err; 
  }
};

export const fetchDiscussionApi = async (courseId) => {
  try {
    return await axios.get(`${URL}/api/course/${courseId}`);
  } catch (err) {
    console.log('Error occurs while fetching', err);
    throw err; 
  }
};

export const fetchRegistrationsApi = async (courseid) => {
  try {
    const response = await axios.get(`${URL}/api/event/fetchEventRegistration?courseid=${courseid}`);
    return response;
  } catch (err) {
    console.log('Error occurs while fetching registrations', err);
    throw err;
  }
};

export const saveBasicSettingApi = async (data) => {
  try {
    console.log(data , "saveBasicSettingApi")
    return await axios.post(`${URL}/api/course/saveOrUpdateCourseBasicSetting` , data  );
  } catch (err) {
    console.log('Error occurs while saving course Details', err);
    throw err; 
  }
};

export const courseAppearceSettingApi = async (data) => {
  try {
    console.log(data , "courseAppearceSettingApi")
    return await axios.post(`${URL}/api/course/saveOrUpdateCourseAppearanceSetting` , data  );
  } catch (err) {
    console.log('Error occurs while saving course Details', err);
    throw err; 
  }
};
