import React, { useState, useEffect } from 'react';
import {
  MainContainer,
  Heading,
  LinearGradientContainer,
  ImageContainer,
  ContactMainContainer,
  ContactImg,
  ContactContainer,
  Header,
  Desc,
  ContactForm,
  Text,
  TextFieldStyle,
  SubmitButton,
  TextFieldMsg,
  TextFieldMsgStyle,
  TextFieldMainCont,
  ContactDescMain,
  ContactIconImage,
  ContactLine,
  ContactMainHead,
  TextFieldStyleA,
  TextFieldMain,
} from '../../styles/Landing/ContactStyles';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { palette } from '../../styles/palette';

import { TitleB, TitleDash, TitleHeadComCont } from '../../styles/Common/CommonStyles';

import AOS from 'aos';
import 'aos/dist/aos.css';

import ContactIcon from '../../images/ContactIcon.png';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Contact = () => {
  
  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // const [Name, setName] = useState(''); // useState to store First Name
  // const [email, setEmail] = useState(''); // useState to store Email
  // const [mobile, setMobile] = useState(''); // useState to store Mobile Number
  // const [message, setMessage] = useState(''); // useState to store Age

  const handleBlur = (e) => {
    if (e.target.value === '' || null || undefined)
      setErrorState({ ...errorState, [e.target.name]: true });
  };

  const handleChange = (event) => {
    if (event.target.value === '' || null || undefined) {
      setErrorState({
        ...errorState,
        [event.target.name]: true,
      });
    } else {
      setErrorState({
        ...errorState,
        [event.target.name]: false,
      });
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  };

  // const userSchema = yup.object().shape({
  //   // name can not be an empty string so we will use the required function
  //   Name: yup.string().required(),

  //   // email can not be an empty string so we will use the required function
  //   email: yup.string().email().required(),
  //   // password can not be an empty string so we will use the required function. Also, we have used the `min` function to set the minimum length of the password. Yup passwords by default handle the conditions of at least one upper case, at least one lower case, and at least one special character in the password
  //   subject: yup.number(),

  //   message: yup.string(),
  // });

  // const handleValidation = async () => {
  //   let dataObject = {
  //     Name: Name,
  //     email: email,
  //     subject: subject,
  //     message: message,
  //   };
  //   const isValid = await userSchema.isValid(dataObject);

  //   if (isValid) {
  //     alert('Form is Valid');
  //   } else {
  //     alert('Form is Invalid');
  //   }
  // };

  const handleSubmit = async () => {
    if (state.name === '' || errorState.name === true)
      alert('Please enter Name');
    else if (state.email === '' || errorState.email === true)
      alert('Please enter email');
    else if (state.subject === '' || errorState.subject === true)
      alert('Please enter subject');
    else if (state.message === '' || errorState.message === true)
      alert('Please enter message');
    else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: state.name,
          email: state.email,
          subject: state.subject,
          message: state.message,
        }),
      };

      try {
        const response = await fetch(
          'https://leapot-lms-backend.onrender.com/api/addContactUs',
          requestOptions,
        );
        if (response.ok) {
          toast.info('Your details have been forwarded to the concerned team.');
          setErrorState({
            name: false,
            email: false,
            subject: false,
            message: false,
          });
          setState({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
        } else {
          throw new Error('Failed to submit the form');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to submit the form. Please try again later.');
      }
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  
  const { t } = useTranslation();
  return (
    <>
      <TitleHeadComCont>
        <TitleB
          // data-aos="fade-left"
          // data-aos-easing="ease-in-out"
          // data-aos-duration="500"
          // data-aos-delay="150"
        >
          {t("landing.contactLabel")}
        </TitleB>
        <TitleDash
          // data-aos="fade-left"
          // data-aos-easing="ease-in-out"
          // data-aos-duration="500"
          // data-aos-delay="150"
        />
      </TitleHeadComCont>
      <MainContainer>
        <ContactDescMain>
          <ContactIconImage
            src={ContactIcon}
            data-aos="zoom-in"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-delay="50"
          />
          <Header
            // data-aos="zoom-in"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="700"
            // data-aos-delay="50"
          >
            {t("landing.ContactUsLabel")}
          </Header>
          <Desc
            // data-aos="zoom-in"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="700"
            // data-aos-delay="50"
          >
           {t("landing.text")}
          </Desc>
        </ContactDescMain>

        <ContactForm
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
          data-aos-delay="50"
        >
          <TextFieldMain>
            <TextField
              required
              type="text"
              name="name"
              id="name"
              variant="outlined"
              placeholder= {t("landing.nameLabel")}
              sx={TextFieldStyleA}
              error={errorState.name}
              value={state.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              required
              type="text"
              name="email"
              id="email"
              variant="outlined"
              placeholder={t("landing.emailLabel")}

              sx={TextFieldStyle}
              error={errorState.email}
              value={state.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              required
              type="number"
              name="subject"
              id="subject"
              variant="outlined"
              placeholder={t("landing.phoneNumberLabel")}
              sx={TextFieldStyle}
              error={errorState.subject}
              value={state.subject}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </TextFieldMain>
          <TextFieldMsg>
            <TextField
              required
              multiline
              rows={4}
              name="message"
              variant="outlined"
              placeholder={t("landing.message")}
              error={errorState.message}
              value={state.message}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={TextFieldMsgStyle}
            />
          </TextFieldMsg>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit}
            style={SubmitButton}
            sx={{
              '& .MuiButtonBase-root': {
                backgroundColor: palette.secondary,
              },
            }}
          >
            {t("landing.submitLabel")}
          </Button>
        </ContactForm>
      </MainContainer>
      {/* <Contactus/> */}
    </>
  );
};

export default Contact;
