// import React, { useMemo } from "react";
// import { Grid, IconButton, Tooltip } from "@mui/material";
// import {
//   CreateContent,
//   Heading,
// } from "../../styles/Courses/CourseBuilderStyles";
// import { useNavigate } from "react-router-dom";
// import { PageContainer } from "../../styles/Common/CommonStyles";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { palette } from "../../styles/palette";
// import TableUtilityLib from "../Utilities/TableUtilityLib";

// const LearnersList = ( { dashData }) => {
//   const navigate = useNavigate();

//   const columns = useMemo(() => [

//     {
//       name: 'Name',
//       selector: (row) => row.name,
//       sortable: true,
//     },
//     {
//       name: 'Student ID',
//       selector: (row) => row.studentid,
//       sortable: true,
//     },
//     {name: 'Contact',
//     selector: (row) => row.contact,
//     sortable: true,},
//     {
//       name: 'Email',
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: 'Enroll Date',
//       selector: (row) => row.enroll,
//       sortable: true,
//     },
    
//   ], []);
//     const data = [
//     { name: "Diptesh", studentid: "101", contact: "9632589632", email:"dip@gmail.com",enroll:"5/5/2024" },
//     { name: "Gaurav ", studentid: "102", contact: "8547963256", email:"gaurav@gmail.com",enroll:"7/4/2024" },
//     { name: "Ganesh", studentid: "103", contact: "7721076039", email:"ganesh@gmail.com",enroll:"8/6/2024" },
//     ];

//   return (
//     <PageContainer>
//       {/* <HeaderContainer></HeaderContainer> */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <CreateContent>
//             <Tooltip title="Go Back">
//               <IconButton
//                 size="small"
//                 onClick={() => navigate("/CourseBuilder")}
//                 sx={{ color: `${palette.THEME_THREE}` }}
//               >
//                 <ArrowBackIosIcon />
//               </IconButton>
//             </Tooltip>
//             <Heading> List of Learners enrolled for the Course (Total learners: {data.length})</Heading>
//           </CreateContent>
//         </Grid>
//       </Grid>
//       <Grid item xs={12} sx={{ px: 4 }}>
//           {/* Apply padding to the Grid */}
//           <TableUtilityLib columns={columns} data={data} />
//         </Grid>

//     </PageContainer>
//   );
// };

// export default LearnersList;

//code 2

// LearnersList.js
// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { Grid, IconButton, Tooltip } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { PageContainer } from '../../styles/Common/CommonStyles';
// import { palette } from '../../styles/palette';
// import TableUtilityLib from '../Utilities/TableUtilityLib';
// import { fetchLearnersByCourse } from '../../features/courses/LearnersRegistrationSlice';

// const LearnersList = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const location = useLocation();

//   const searchParams = new URLSearchParams(location.search);
//   const courseId = searchParams.get('courseId');

//   const { data: registrations, status, error } = useSelector((state) => state.registrations);

//   useEffect(() => {
//     if (courseId) {
//       dispatch(fetchLearnersByCourse(courseId));
//     }
//   }, [courseId, dispatch]);

//   if (status === 'loading') {
//     return <div>Loading...</div>;
//   }

//   if (status === 'failed') {
//     return <div>Error: {error}</div>;
//   }

//   const columns = [
//     { Header: 'Learner Name', accessor: 'learnerName' },
//     { Header: 'Email', accessor: 'email' },
//     { Header: 'Registration Date', accessor: 'registrationDate' },
//     // Add other columns as needed
//   ];

//   return (
//     <PageContainer>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Tooltip title="Go Back">
//             <IconButton
//               size="small"
//               onClick={() => navigate('/CourseBuilder')}
//               sx={{ color: `${palette.THEME_THREE}` }}
//             >
//               <ArrowBackIosIcon />
//             </IconButton>
//           </Tooltip>
//           <h2>List of Learners enrolled for the Course (Total learners: {registrations.length})</h2>
//         </Grid>
//       </Grid>
//       <Grid item xs={12} sx={{ px: 4 }}>
//         <TableUtilityLib columns={columns} data={registrations} />
//       </Grid>
//     </PageContainer>
//   );
// };

// export default LearnersList;


import React, { useEffect , useMemo} from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  CreateContent,
  Heading,
} from "../../styles/Courses/CourseBuilderStyles";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "../../styles/Common/CommonStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../styles/palette";
import TableUtilityLib from "../Utilities/TableUtilityLib";
import { useDispatch, useSelector } from 'react-redux';
import { fetchLearnersByCourse } from '../../features/courses/LearnersRegistrationSlice';

const LearnersList = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const { learners, status } = useSelector((state) => state.learners);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchLearnersByCourse(courseId));
    }
  }, [courseId, dispatch]);

  console.log("learnerlist", learners);
  const columns = useMemo(
    () => [
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: 'Name',
        selector: (row) => `${row.userid.firstname} ${row.userid.lastname}`,
        sortable: true,
      },
      {
        name: 'Registration Date',
        selector: (row) => new Date(row.registrationDate).toLocaleDateString('en-GB'), // Format date as dd/mm/yyyy
        sortable: true,
      },
      {
        name: 'Status',
        selector: (row) => row.registrationStatus ? "Registered" : "Not Registered",
        sortable: true,

      },
    ],
    []
  );

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CreateContent>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={() => navigate("/CourseBuilder")}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
            <Heading>
              List of Learners enrolled for the Course (Total learners: {learners.length})
            </Heading>
          </CreateContent>
        </Grid>
        <Grid item xs={12} sx={{ px: 4 }}>
          {status === 'loading' ? (
            <div>Loading...</div>
          ) : status === 'failed' ? (
            <div>Error fetching data</div>
          ) : (
            <TableUtilityLib columns={columns} data={learners} />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LearnersList;
