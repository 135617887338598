import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CertificateTabs from './CertificateTabs';
import TextField from '@mui/material/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Tooltip } from '@mui/material';
import { palette } from '../../styles/palette';

import {
  fetchAllCertificateApi,
  deleteCertificate,
} from '../../features/certificate/CertificateSlice';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../../styles/Common/CommonStyles';

import { SearchEmail, WholePage } from '../../styles/Certificate/CertificateStyle';

import {
  Image,
  VerticalLine,
  ActionButton,
  CertificateInfo,
  CertificateWrapper,
} from '../../styles/Certificate/SeparateCertificateStyles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const SeparateCertificate = () => {
  const [certificateToDelete, setCertificateToDelete] = useState('notDelete');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allcertificate } = useSelector((state) => state.certificate) || {};

  const filteredTemplates =
    allcertificate?.certificates?.filter((certificate) =>
      certificate?.certificateName?.toLowerCase()
        .includes(searchQuery.toLowerCase()),
    ) || [];

  const handleDeleteDialogOpen = (certificate) => {
    setCertificateToDelete(certificate);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setCertificateToDelete(null);
  };

  const handleDelete = () => {
    if (!certificateToDelete) return;
    dispatch(deleteCertificate(certificateToDelete._id))
      .then(() => {
        setDeleteDialogOpen(false);
        setCertificateToDelete('delete');
      })
      .catch((error) => {
        console.error('Error deleting certificate:', error);
        setDeleteDialogOpen(false);
        setCertificateToDelete(null);
      });
  };

  const handleEdit = (certificateId) => {
    navigate(`/Certificate/EditTemplate/${certificateId}`);
    console.log('Edit certificate');
  };

  useEffect(() => {
    dispatch(fetchAllCertificateApi());
  }, [certificateToDelete]);
  const { t } = useTranslation();
  return (
    <>
      <CertificateTabs />
      <WholePage>
      <div style={{width:'95%', margin:'auto'}}>
        <Text>{t("certificate.seperateDisplay")}</Text>
        <SearchEmail>
          <TextField
            label={t("certificate.searchText")}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchEmail>
     
      {filteredTemplates.map((certificate) => (
        <CertificateWrapper key={certificate._id}>
          <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSMVuOT0ojnhtTxVM9IiHj0lE529sOTX4jJw&s" />
          <CertificateInfo>
            <p>
              <strong>{t("certificate.certificateName")}</strong> {certificate.certificateName}
            </p>
          </CertificateInfo>
          <VerticalLine />
          <ActionButton>
            <Tooltip title={t("certificate.edit")}>
              <IconButton onClick={() => handleEdit(certificate._id)}>
                <EditIcon style={{ color: palette.primary }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("certificate.deleteLabel")}>
              <IconButton onClick={() => handleDeleteDialogOpen(certificate)}>
                <DeleteIcon style={{ color: palette.primary }} />
              </IconButton>
            </Tooltip>
          </ActionButton>
        </CertificateWrapper>
      ))}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>{t("certificate.ConfirmDeleteLabel")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("certificate.deleteTemQue")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>{t("certificate.cancelLabel")}</Button>
          <Button onClick={handleDelete} color="error">
            {t("certificate.deleteLabel")}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      </WholePage>
    </>
  );
};

export default SeparateCertificate;
