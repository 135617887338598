import React from "react";
import {
  PageContainer,
  HeaderContainer,
  Heading,
  Description,
  Content,
  ModuleContainerStyle,
} from "../styles/Common/CommonStyles";
import {
  Card,
  Container,
  Grid,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import assets_card from "../images/assets_card.jpg";
import analytics_card from "../images/analytics_card.jpg";
import website_builder_card from "../images/website_builder_card.jpg";
import communities from "../images/communities_card.jpg";
import manage_bundles_card from "../images/bundles_card.jpg";
import users_card from "../images/users_card.jpg";
import dashboard_card from "../images/dashboard_card.jpg";
import customization_Card from "../images/customization_Card.jpg";
import quiz_card from "../images/exam.jpg";
import events_card from "../images/events_calendar.jpg";
import attendance_card from "../images/attendance.jpg";
import admin_card from "../images/admin.jpg";
import automation_Card from "../images/automation.jpg";

const Modules = () => {
  return (
    <PageContainer>
      <Container>
        {/* <Container style={ModuleContainerStyle} > */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#acddde',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Website Builder
                  </Typography>
                  nav
                  <Typography color="text.secondary">
                    The website builder enables you to swiftly generate your
                    site pages by utilizing pre-designed section templates.
                    Effortlessly create campus pages, custom pages, and course
                    pages on the fly, without the need for coding experience. If
                    desired, you can still customize the code to enhance the
                    look and feel of your site pages.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#caf1de',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    App Builder
                  </Typography>
                  <Typography color="text.secondary">
                    Transform your educational campus into a mobile application,
                    providing accessibility to your users on both mobile phones
                    and tablets. Our current support extends to Android and iOS
                    devices.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#e1f8dc',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Manage Products
                  </Typography>
                  <Typography color="text.secondary">
                    Empowers you to craft skill-specific learning paths for your
                    students, neatly packaged into a single bundle. Create a
                    package of multiple courses for effortless availability,
                    purchase, and enrollment.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#fee8d2',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Users
                  </Typography>
                  <Typography color="text.secondary">
                    Effortlessly manage user administration tasks with intuitive
                    functions, encompassing user creation, information updates,
                    enrollments, course progress tracking, comprehensive course
                    completion data, spanning lessons, chapters, quizzes, and
                    exams. purchase history etc.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#ffefe2',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Communities
                  </Typography>
                  <Typography color="text.secondary">
                    Facilitates the creation of vibrant learning communities for
                    users.Create course or event-specific spaces for
                    discussions, sharing information, and fostering engagement.
                    Cultivate relationships, promote growth, and enhance your
                    business.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#fcdce5',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Assets
                  </Typography>
                  <Typography color="text.secondary">
                    The Assets Library consolidates all your digital learning
                    resources in one place, enabling effortless reuse of images,
                    videos, audio files, etc. Upload once during course creation
                    and reuse whenever you want in multiple courses.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#ffe7c7',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Quizzes and Exams
                  </Typography>
                  <Typography color="text.secondary">
                    Create quizzes and exams to assess user knowledge and skills
                    within your course. Construct surveys for valuable feedback
                    on courses and instructors. Streamlined functionalities make
                    quiz and exam management efficient.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#f7d8ba',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Analytics
                  </Typography>
                  <Typography color="text.secondary">
                    Generate detailed reports on learner interactions,time
                    spent, course progress, exams, quizzes, enrollments,
                    registrations and purchases. Easily download the reports for
                    comprehensive insights into learner engagement.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#faf0dc',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Events Calendar
                  </Typography>
                  <Typography color="text.secondary">
                    Display your past and upcoming events, classes, and courses.
                    Users can easily access course information, register, and
                    make payments. The interactive feature allows you to create
                    discount coupons that users can apply during registrations
                    and payments. Customize the calendar according to your needs
                    and branding guidelines, and seamlessly integrate it with
                    your in-house software for streamlined registration and
                    payments.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#9edbf7',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Attendance Check-in App
                  </Typography>
                  <Typography color="text.secondary">
                    Say bye to the manual attendance system used in in-person
                    training rooms. Check-in app enables you to record
                    attendance effortlessly using mobile phones and iPads.
                    Tailored for in-person events, it offers options for marking
                    attendance in multi-day and multi-session courses. Users can
                    conveniently check-in using QR codes, and the app generates
                    instant attendance reports.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#acddde',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Administration
                  </Typography>
                  <Typography color="text.secondary">
                    Immerse yourself in the ease of hassle-free campus
                    administration with confidence through our dedicated
                    administration functionalities. Each step is meticulously
                    designed for your success, covering course administration,
                    user management, site and mobile app oversight, quiz and
                    exam administration, enrollment tracking, progress
                    monitoring, and more.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#caf1de',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Automation
                  </Typography>
                  <Typography color="text.secondary">
                    Empower tutors and instructors to save time by utilizing
                    highly efficient automated tools for evaluating your
                    learning journey. Implement process automation for course
                    creation, user enrollments, reminders, welcome
                    notifications, expirations, and the automatic issuance of
                    certificates upon course completion. Utilize automated
                    reminders, completion emails, and other features to
                    streamline processes, saving time and reducing manual labor.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                height: '320px',
                background: '#e1f8dc',
                borderRadius: '10px',
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Customizations
                  </Typography>
                  <Typography color="text.secondary">
                    Facilitates integration with internal organizational systems
                    for LMS communication. Retrieve data for analysis and
                    reporting. Enable users to single sign-on and access the
                    education portal through the HRM system.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default Modules;
