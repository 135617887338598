import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "./palette";

export const MainContainer = styled.div`
  ${tw`flex justify-center items-center`}
`;

export const LangSelect = styled.div`
  position: relative;
  width: 100%;
  background-color:red;
`;

export const SelectButton = styled.div`
  ${tw`p-2 text-base border-none bg-[#0684FB] text-white cursor-pointer outline-none transition-colors duration-200 ease-in-out`}
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 110%;
  background-color: white;
  border: none;
  border-radius:4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const Option = styled.div`
  ${tw`p-2 cursor-pointer text-lg transition-colors duration-200 ease-in-out`}

  &:hover {
    background-color: ${palette.primary};
    color: white;
    // border-bottom-radius:4px;

  }
`;

