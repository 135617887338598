import React, { useState, useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import {
  HeaderContainer,
  CreateContent,
  Heading,
} from "../../../styles/Courses/CourseBuilderStyles";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../../styles/Common/CommonStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
import coursesList from "../../../store/CoursesData.json";

const LandingPageDetails = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <HeaderContainer>
        <CreateContent>
          <Tooltip title="Go Back">
            <IconButton
              size="small"
              onClick={() => navigate(-1)}
              sx={{ color: `${palette.THEME_THREE}` }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <Heading> Landing Page Details </Heading>
        </CreateContent>
      </HeaderContainer>
    </PageContainer>
  );
};

export default LandingPageDetails;
