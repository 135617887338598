import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  HomeContainer,
  LogoImg,
  NavLink,
  NavMenu,
  Descr,
  LearnMorebtn,
  ContainerDesc,
  MainLandingContainer,
  ProductContainer,
  ProductHead,
  ProductHeadB,
  ProductDesc,
  GridCardContainer,
  ProductContSub,
  ProductImg,
  ProductContSubMain,
  GridCardContainerSub,
  MainLandingContainerSub,
  MailCont,
  MailContMain,
  SendBtn,
  MainDescImg,
  MainDescCont,
  NavSecond,
  SideMenu,
  NavMenuB,
  NavMenuBLink,
  StyledSVG,
  CardsHeading,
  HeadingCont,
  CardsImg,
  ProductHeading,
  ProductPara,
  CardContentCont,
  CardsStyle,
  TitleProduct,
  GridContainer,
  ScrollTopCont,
  DescCont,
  TitleSub,
  ProductContHeadMain,
} from '../../styles/Landing/LandingStyles';

import{
  TitleB,
  TitleDash,
} from '../../styles/Common/CommonStyles'
// import { FaBars, FaGithub, FaLinkedin, FaTimes } from 'react-icons/fa';

import Box from '@mui/material/Grid';

import { Grid, Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import ScrollToTop from '../ScrollToTop';

import Contact from './Contact';

import ServicesLanding from './ServicesLanding';
// import { makeStyles } from '@mui/styles';

import CarouselDefault from './CarouselDefault';
// import pr from '../../images/LmsIcon.png';
// import pr1 from '../../images/event-planner.png';
// import pr2 from '../../images/calendar.png';
// import pr3 from '../../images/ExamG.png';
// import { palette } from '../../styles/palette';
import { useTranslation } from 'react-i18next';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Item = ({ children }) => (
  <div style={{ border: '1px solid #ccc', padding: '10px' }}>{children}</div>
);

const Landing = ({ userLoggedIn, scrollToTop, classes, isVisible }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <CarouselDefault interval={5000} />
      <HomeContainer>
        <ProductHeading>
          <TitleB
            // data-aos="fade-left"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="500"
            // data-aos-delay="150"
          >
            {t("landing.landingProductLabel")}
          </TitleB>
          <TitleDash
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="200"
          />
        </ProductHeading>
        <MainLandingContainerSub>
          <ProductContSub>
            <ProductHeadB
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="200"
            >
              {t("landing.landingText")}

            </ProductHeadB>
            <ProductDesc
              data-aos="fade-left"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              {t("landing.learningProductsText")}

            </ProductDesc>
          </ProductContSub>
          <ProductContHeadMain>
            <ProductContainer>
              <ProductContSubMain>
                <GridCardContainerSub>
                  <GridContainer>
                    <Grid
                      container
                      spacing={2}
                      data-aos="zoom-in"
                      data-aos-easing="ease-in-out"
                      data-aos-duration="700"
                      data-aos-delay="300"
                    >
                      <Grid item xs={12} md={12} lg={6}>
                        <CardContentCont>
                          <DescCont>
                            <TitleSub>{t("landing.leapotLmsLabel")}</TitleSub>
                            <ProductPara>
                              {t("landing.leapotLmsText")}
                            </ProductPara>
                          </DescCont>
                        </CardContentCont>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <CardContentCont>
                          {/* <CardsImg src={pr1} /> */}
                          <DescCont>
                            <TitleSub>{t("landing.leapotEventHubLabel")}</TitleSub>
                            <ProductPara>
                              {t("landing.leapotEventHubText")}

                            </ProductPara>
                          </DescCont>
                        </CardContentCont>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <CardContentCont>
                          {/* <CardsImg src={pr2} /> */}
                          <DescCont>
                            <TitleSub>{t("landing.leapotEventNavigatorLabel")}</TitleSub>
                            <ProductPara>
                              {t("landing.leapotEventNavigatorText")}
                            </ProductPara>
                          </DescCont>
                        </CardContentCont>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <CardContentCont>
                          {/* <CardsImg src={pr3} /> */}
                          <DescCont>
                            <TitleSub>{t("landing.leapotAI-ExamGuardLabel")}</TitleSub>
                            <ProductPara>
                              {t("landing.leapotAI-ExamGuardText")}
                            </ProductPara>
                          </DescCont>
                        </CardContentCont>
                      </Grid>
                    </Grid>
                  </GridContainer>
                </GridCardContainerSub>
              </ProductContSubMain>
            </ProductContainer>
          </ProductContHeadMain>
        </MainLandingContainerSub>
        <ServicesLanding />
        <Contact />
        {/* <ScrollToTop
          isVisible={isVisible}
          scrollToTop={scrollToTop}
          classes={classes}
        /> */}
      </HomeContainer>
    </>
  );
};

export default Landing;
