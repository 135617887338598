import axios from 'axios';

const URL = process.env.REACT_APP_URL;

export const createNotificationTemplateApi = async (templateData) => {
    try {
      return await axios.post(`${URL}/api/notification/postnotification`, templateData);
    } catch (err) {
      console.log('Error occurs while creating template', err);
      throw err;
    }
  };

  export const fetchNotificationTemplateApi = async () => {
    try {
      return await axios.get(`${URL}/api/notification/getnotifications`);
    } catch (err) {
      console.log('Error occurs while fetching template', err);
      throw err; 
    }
  };

  export const updateNotificationTemplateApi = async (templateData) => {
    try {
      return await axios.put(`${URL}/api/notification/editnotification/${templateData.id}`, templateData);
    } catch (err) {
      console.log('Error occurs while updating', err);
      throw err; 
    }
  };

  export const fetchSingleTemplateApi = async (TemplateId) => {
    try {
      return await axios.get(`${URL}/api/notification/getSingleNotification/${TemplateId}`);
    } catch (err) {
      console.log('Error occurs while fetching Template', err);
      throw err; 
    }
  };

  export const deleteNotificationApi = async (notificationId) => {
    try {
      return await axios.put(`${URL}/api/notification/deleteNotification/${notificationId}`);
    } catch (err) {
      console.log('Error occurs while deleting template', err);
      throw err; 
    }
  };

  export const sendNotificationApi = async (Data) => {
    try {
      return await axios.post(`${URL}/api/notification/sendnotifications`, Data);
    } catch (err) {
      console.log('Error occurs while sending notification', err);
      throw err; 
    }
  };

  export const fetchSentNotificationAPi = async () => {
    try {
      return await axios.get(`${URL}/api/notification/fetchnotifications`);
    } catch (err) {
      console.log('Error occurs while fetching data', err);
      throw err; 
    }
  };

  export const fetchSingleSentNotificationApi = async (notificationId) => {
    console.log("api",notificationId)
    try {
      return await axios.get(`${URL}/api/notification/singlefetchnotifications/${notificationId}`);
    } catch (err) {
      console.log('Error occurs while fetching Template', err);
      throw err; 
    }
  };

  export const createNotificationSettingsApi = async (Data) => {
    try {
      return await axios.post(`${URL}/api/notification/notificationsettings`, Data);
    } catch (err) {
      console.log('Error occurs while creating settings', err);
      throw err; 
    }
  };

  export const fetchNotificationSettingsApi = async () => {
    try {
      return await axios.get(`${URL}/api/notification/fetchAllSettings`);
    } catch (err) {
      console.log('Error occurs while fetching data', err);
      throw err; 
    }
  };

  export const toggleNotificationSettingApi = async (settingId, role, isEnabled) => {
    try {
      const response = await axios.put(
        `${URL}/api/notification/notificationsettings/${settingId}/toggle`,
        {
          role, 
          isEnabled, 
        }
      );
      return response.data; 
    } catch (err) {
      console.error('Error occurred while toggling notification setting:', err);
      throw err; 
    }
  };
  

  export const searchNotificationApi = async (searchQuery) => {
    try {
      return await axios.get(`${URL}/api/notification/search?searchQuery=${searchQuery}`);
    } catch (err) {
      console.log('Error occurs while fetching data', err);
      throw err; 
    }
  };