import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';
import { Text } from '../Common/CommonStyles';

export const NotificationContainer = styled.div`
  ${tw`flex m-auto w-11/12 justify-between items-center mb-4 rounded-lg`}
  background-color: ${palette.white};
  border: 1px solid ${palette.gray};
  &:hover {
    box-shadow:
      rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
`;

export const SettingHeading = styled.h3`
  ${tw`text-base md:text-xl text-stone-700`}
`;

export const SettingDiv = styled.div`
  ${tw`flex text-slate-700 rounded items-center justify-around pl-12 mb-4`}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;

export const SingleSetting = styled.div`
${tw`flex flex-row gap-4`}
`;

export const DateText = styled(Text)`
${tw`text-xs`}
`;

export const NotificationContent = styled.div`
  ${tw`flex grow items-center`}
`;

export const NotificationSender = styled.div`
  ${tw`flex items-center font-bold mr-3.5`}
`;

export const NotificationActions = styled.div`
  ${tw`flex items-center`}
`;

export const ActionIcon = styled.div`
  margin-right: 8px; /* Adjust the margin between icons as needed */
`;

export const Back = styled.div`
  ${tw``}
`;

export const FullHeight = styled.div`
  ${tw``}
`;

export const NotificationHead = styled.div`
  ${tw`flex items-center justify-between`}
`;

export const NotificationDetailed = styled.div`
  ${tw`w-10/12 m-auto px-8 py-4 mb-2`}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;

export const ActionButton = styled.button`
  ${tw`px-4 py-2 border-none mr-2 cursor-pointer`}
  background-color: ${palette.white};
  border: 1px solid ${palette.gray};
  color: ${palette.secondary};

  &:hover {
    background-color: ${palette.light_gray};
  }
`;
