import React, { useState } from "react";
import { TextField, Tooltip, IconButton, Grid, Typography, Button, Select,MenuItem } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { palette } from "../../../styles/palette";
import { useNavigate } from 'react-router-dom';
import QuestionUtility from '../CourseModule/QuestionUtility';
import SurveyPreview from '../CourseModule/SurveyPreview';
import VisibilityIcon from '@mui/icons-material/Visibility';


const InsertSurvey = ({ onClose }) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);


  const initialValues = {
    questionType: 'oneAnswer',
    questionText: '',
    choices: [],
    isOptional: false,
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleAddQuestionClick = () => {
    setQuestions([...questions, initialValues]);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleOpenSelect = () => {
    setSelectOpen(true);
  };

  const handleCloseSelect = () => {
    setSelectOpen(false);
  };

  const handleSelectOption = (option) => {
    if (option === "currentLesson") {
      // Handle opening current lesson tab
    // const newTab = window.open('', '_blank');
    // newTab.document.write('<html><head><title>Survey Preview</title></head><body>');
    // newTab.document.write('<div id="root">hello</div>');
    // newTab.document.write('</body></html>');
    // newTab.document.close();
    // ReactDOM.render(<SurveyPreview questions={questions} />, newTab.document.getElementById('root'));
    const currentLessonURL = "/SurveyPreview.js";
    window.open(currentLessonURL, "_blank");     } 
    else if (option === "enrolledCourse") {
      // Handle opening enrolled course tab
      window.open("URL_TO_ENROLLED_COURSE", "_blank");
    }
    setSelectOpen(false);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Survey
            </Typography>
          </Grid>
        </Grid>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }}>Discard Changes</Button>
          <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }}>Save</Button>
          <IconButton
            style={{ borderRadius: 4 }}
            onClick={handleOpenSelect}
          >
            <VisibilityIcon />
          </IconButton>
          <Select
            open={selectOpen}
            onClose={handleCloseSelect}
            style={{ marginLeft: "10px", borderRadius: 4 }}
            value=""
          >
            <MenuItem onClick={() => handleSelectOption("currentLesson")}>Current Lesson</MenuItem>
            <MenuItem onClick={() => handleSelectOption("enrolledCourse")}>Enrolled Course</MenuItem>
          </Select>
        </div>
      </div>

      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"

          style={{ width: "100%", marginBottom: "24px" }}
        />
        {questions.map((question, index) => (
          <div key={index}>
            <QuestionUtility initialValues={question} />
            <IconButton color="error" onClick={() => handleRemoveQuestion(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ borderRadius: 4 }}
            onClick={handleAddQuestionClick}
          >
            Add Question
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: 4, marginLeft: '10px' }}
            onClick={handlePreviewOpen}
          >
            Preview
          </Button> */}
        </Grid>
      </div>
      {/* <PreviewDialog open={previewOpen} onClose={handlePreviewClose} questions={questions} /> */}

    </div>
  );
};


export default InsertSurvey;

