import { Modal , Box , Typography, Grid } from "@mui/material"
import CommonButton from "../Utilities/CommonButton";

const SuccessPopup  = ({ isOpen , onClose}) => {

  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height:300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
          <Grid display="flex" justifyContent="center" alignItems="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              User Added Successfully
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" alignItems="center">
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >

            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" alignItems="center">
            <CommonButton label="Close" onClick={onClose} />
          </Grid>

        </Box>
      </Modal>
    );
}
export default SuccessPopup;