import styled from 'styled-components';
import tw from 'twin.macro';
import { Grid } from '@mui/material';

export const CreateForm = styled.div`
${tw`flex flex-col ml-6 mr-6 gap-0 md:ml-14`}
`;

export const BackButtonWrapper = styled.div`
  ${tw`flex items-center justify-start ml-4 mb-4`} 
`;

export const ResponsiveGrid = styled.div`
${tw `flex flex-col lg:flex-row`}
`;

export const ResponsiveGridItem = styled(Grid)`
${tw `mx-0.5`}
`;