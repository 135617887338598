import { useState } from 'react';
import Chart from 'react-apexcharts';
import DisplayTableLib from '../Utilities/TableUtilityLib';
import CommonButton from '../Utilities/CommonButton';
import { ImageTag } from '../../styles/Calendar/CalendarStyle';
import TopEvents from '../../store/TopEvents.json';
import studentDetails from '../../store/DashboardStudentDetails.json';
import { useTranslation } from 'react-i18next';

const { Grid, Typography, Card, CardContent } = require('@mui/material');
const { PageContainer } = require('../../styles/Dashboard/DashboardStyles');
const {
  ProfileHead,
  ProfilePic,
} = require('../../styles/Landing/NavbarElements');
const { Link } = require('react-router-dom');

const InstructorDashboard = () => {
  const studentDetailsColumns = [
    {
      name: 'Student Name',
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: 'Enrolled Course',
      selector: (row) => row.ecourse,
      sortable: true,
    },
    {
      name: 'Contact No',
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: 'Email ID',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Gender',
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: 'Starting Date',
      selector: (row) => row.starting,
      sortable: true,
    },
    {
      name: 'Ending Date',
      selector: (row) => row.ending,
      sortable: true,
    },
  ];
  const topCoursesColumns = [
    {
      name: 'Event Name',
      selector: (row) => row.courseName,
      sortable: true,
    },
    {
      name: 'Enrolled Students',
      selector: (row) => row.enrollStudents,
      sortable: true,
    },
  ];

  const [queryState, setQueryState] = useState({
    options: {
      labels: ['Resolved', 'Pending'],
    },
    series: [68, 32],
  });

  const [activeState, setActiveState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    series: [
      {
        name: 'Active Users',
        data: [120, 155, 180, 200, 100, 150, 200, 160, 150, 140, 250, 230],
      },
    ],
  });
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Grid container sx={{ border: '0px solid red', mt: 2 }}>
        {/*First Card Grid */}
        
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {t("dashboard.totalLearners")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                70
              </Typography>
              <Link>{t("dashboard.seeAllLearners")}</Link>
            </CardContent>
          </Card>
        </Grid>
        {/* Second Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {t("dashboard.activeLearner")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                12
              </Typography>
              <Link>{t("dashboard.viewAllLearners")}</Link>
            </CardContent>
          </Card>
        </Grid>
        {/* Third Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {/* NO. OF COURSES */}
                    {t("dashboard.nubOfCources")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                10
              </Typography>
              {/* <Link>See Details</Link> */}
              <Link>{t("dashboard.seeDetails")}</Link>
            </CardContent>
          </Card>
        </Grid>
        {/* Fourth Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {/* SITE VIEW */}
                    {t("dashboard.siteView")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                100
              </Typography>
              {/* <Link>View Details</Link> */}
              <Link>{t("dashboard.viewDetails")}</Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* To do List and Accouncement Grid */}
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mb: 1, ml: 2, mr: 1, mt: 1, minHeight: 440 }}>
            <CardContent>
              <Grid container style={{ border: '0px solid red' }}>
                <Grid item xs={10}>
                  {/* <Typography variant="h6">Notification</Typography> */}
                  <Typography variant="h6">{t("dashboard.Notification")}</Typography>
                </Grid>

                <Grid item xs={2}>
                  {/* <Link style={{ cursor: 'pointer' }}>View All</Link> */}
                  <Link style={{ cursor: 'pointer' }}>{t("dashboard.viewAll")}</Link>
                </Grid>
              </Grid>
              <hr />

              <Grid container>
                <ul style={{ listStyleType: 'disc' }}>
                  <li style={{ marginBottom: '7px', cursor: 'pointer' }}>
                    Complete the Chapter Material for the MERN Stack Course dead
                    line that course is beyond the limit of 30days
                  </li>
                  <li style={{ marginBottom: '7px', cursor: 'pointer' }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </li>
                  <li style={{ marginBottom: '7px', cursor: 'pointer' }}>
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries
                  </li>
                </ul>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid green' }}>
          <Card
            sx={{
              mr: 2,
              ml: 1,
              mb: 1,
              mt: 1,
              border: '0px solid green',
            }}
          >
            <CardContent>
              <Grid xs={12}>
                {/* <Typography variant="h6">Upcoming Courses</Typography> */}
                <Typography variant="h6">{t("dashboard.upComingCources")}</Typography>
              </Grid>
              <hr />
              <Grid display="flex" justifyContent="center" alignItems="center">
                <ImageTag
                  style={{ border: '0px solid red' }}
                  src="https://i.pinimg.com/736x/73/a4/5e/73a45ea3b5548b23b44148ec3fd0a72c.jpg"
                ></ImageTag>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Active users and Student Queries Grid */}
      <Grid container>
        <Grid item xs={7}>
          <Card sx={{ mr: 1, ml: 2, mt: 2, mb: 1 }}>
            <CardContent>
              <Typography variant="h6"> {t("dashboard.activeUsers")}</Typography>
              <Chart
                options={activeState.options}
                series={activeState.series}
                type="bar"
                // width='500'
                height="305"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card sx={{ mr: 2, mb: 2, mt: 2, ml: 1 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h6">{t("dashboard.studentQueries")}</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              {/* Place the Percentage Circular graph */}
              <Chart
                options={queryState.options}
                series={queryState.series}
                type="donut"
                width="455"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8} style={{ border: '0px solid red' }}>
          <Card sx={{ ml: 2, mr: 1, mb: 1 }}>
            <CardContent>
              <DisplayTableLib
                title={t("dashboard.enrollStudent")}
                columns={studentDetailsColumns}
                data={studentDetails}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ border: '0px solid red' }}>
          <Card sx={{ mr: 2, ml: 1 }}>
            <CardContent>
              <DisplayTableLib
                title={t("dashboard.Top Events")}
                columns={topCoursesColumns}
                data={TopEvents}
              ></DisplayTableLib>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
export default InstructorDashboard;
