import styled from "styled-components";
import tw from "twin.macro";

export const Container1 = styled.div`
  ${tw` flex`}
  /* top:90px; */
  z-index: 2;
`;

export const RouteStyles = {
  paddingLeft: "200px",
};

export const RouteStylesMini = {
  paddingLeft: "200px",
};
