import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';

export const HistoryPage = styled.div`
  ${tw`w-11/12 m-auto`}
`;

export const UserTable = styled.div`
  ${tw`m-auto`}
`;

export const Text = styled.p`
  ${tw`text-sm sm:text-base tracking-normal sm:tracking-wider leading-relaxed text-justify md:text-left`}
`;

export const AddButton = styled.button`
  ${tw`ml-2`}
  ${tw`rounded text-lg md:text-base py-2 px-4  border-none transition-all duration-300 cursor-pointer`}
  color: ${palette.white};
  background-color: ${palette.tertiary};
  &:hover {
    background-color: ${palette.buttonhover};
  }
`;
export const Header = styled.div``;
