import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';
import { HeadingH1 } from '../Common/CommonStyles';

export const MainContainer = styled.div`
  ${tw`w-full`}
  background: ${palette.light_gray}
`;

export const Container = styled.div`
  ${tw`w-full bg-cover bg-no-repeat  top-0 right-0 flex justify-center items-center`}
  background : ${(props) => `url(${props.bgImage})`};
  min-height: 500px;
  height: 500px;
  background-size: cover;
  top: 90px;
`;

export const LinearGradientContainer = styled.div`
  ${tw`absolute`}
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(73, 125, 208, 0.95) 0%,
    rgba(7, 100, 189, 0) 100%
  );
  left: 0px;
  top: 90px;
  width: 100%;
  min-height: 500px;
  height: 500px;
`;

export const Content = styled.div`
  ${tw`w-1/2 p-3 self-center`}
  // background:${palette.white};
  background : linear-gradient( 90deg, rgb(169 219 242 / 95%) 0%, rgba(7, 100, 189, 0) 100% );
  margin-left: 100px;
  border-radius: 25px;
  z-index: 1;
`;

export const Heading = styled(HeadingH1)`
  ${tw`py-1 text-center font-extrabold`}
`;

// export const Container2 = styled.div`
//   ${tw`w-full`}
//   background: ${palette.light_gray}
// `;

export const Container2 = styled.div`
  ${tw`w-2/3 m-auto mt-32 justify-center items-center`}
`;

export const ContentHeading = styled.h1`
  ${tw` font-semibold 2xl:text-4xl xl:text-4xl lg:text-2xl md:text-2xl  sm:text-xl leading-4 `}
  color: ${palette.black};
`;

export const Paragraph = styled.p`
  ${tw`font-thin 2xl:text-2xl xl:text-3xl xl:leading-10 xl:mt-14
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg sm:leading-6  tracking-wide`}
  color: ${palette.black};
  line-height: 40px;
`;

export const ContainerShadow = styled.div`
  ${tw`w-full h-1 shadow-2xl shadow-black shadow-slate-600`}
  background: ${palette.light_gray}
`;
