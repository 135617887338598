import React, { useState, useEffect } from 'react';
import ImageSlider from './ImageSlider';
import CalendarCompSub from './CalendarCompSub';
import TagsCard from './TagsCard';
import HorizontalCard from './HorizontalCard';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  UpperContainer,
  DisplayText,
  DisplayTextCalendar,
  DisplayTextAnn,
} from '../../styles/Calendar/CalendarStyle';
import CalendarTabs from './CalendarTabs';
import { Grid } from '@mui/material';
import { fetchAnnouncement } from '../../features/calendar/CalendarSlice';
const CalendarMain = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { announcement } = useSelector((state) => state.calendar);
  useEffect(() => {
    dispatch(fetchAnnouncement());
  }, [dispatch]);
  return (
    <>
      <CalendarTabs />
      <PageContainer>
        <UpperContainer>
          <DisplayText>{t('calender.exploreText')}</DisplayText>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CalendarCompSub />
              <DisplayTextCalendar>
                {t('calender.highlightText')}
              </DisplayTextCalendar>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ImageSlider />
              <DisplayTextAnn>
                {t('calender.announcementDetails')}
              </DisplayTextAnn>
            </Grid>
          </Grid>
        </UpperContainer>
        <Grid item xs={12}>
          <TagsCard />
        </Grid>
        <Grid container spacing={3}>
          <HorizontalCard />
        </Grid>
      </PageContainer>
    </>
  );
};

export default CalendarMain;
