import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LearnersCalendarMain from './LearnersCalendarMain';
import { PageContainer } from '../../styles/Common/CommonStyles';
import Announcements from './Announcements';
import AnnounceDetails from './AnnounceDetails';
import EventDetails from './EventDetails';

const LearnersCalendar = () => {
  return (
    <PageContainer>
      <Routes>
        <Route path="/*" element={<LearnersCalendarMain />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route
          path="/announcedetails/:announcementNo"
          element={<AnnounceDetails />}
        />
        <Route path="/eventdetails/:eventname" element={<EventDetails />} />
      </Routes>
    </PageContainer>
  );
};

export default LearnersCalendar;
