import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loader">

      <img src={`${process.env.PUBLIC_URL}/logo-loader.gif`} alt="Loading..." />
      
    </div>
  );
};

export default Loader;
