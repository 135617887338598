import React, { useState } from "react";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import {
  AddDiv,
  Description,
  CloseIconStyle,
  TextFieldStyle,
  TypographyStyle,
  SubmitButtonStyle,
} from "../../styles/Profile/ProfileStyle";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "../Utilities/Modal";
import CommonButton from "../Utilities/CommonButton";

const AdditionalField = (props) => {
  const [addFieldState, setAddFeildState] = useState({
    fieldName: "",
    // dataType: "",
    // mandatoryField: false,
  });
  const [addFieldErrorState, setAddFeildErrorState] = useState({
    fieldName: false,
    // dataType: false,
    // mandatoryField: false,
  });

  const handleChange = (event) => {
    if (event.target.value !== "" || null || undefined) {
      setAddFeildState({
        ...addFieldState,
        [event.target.name]: event.target.value,
      });
      setAddFeildErrorState({
        ...addFieldErrorState,
        [event.target.name]: true,
      });
    }
  };

  const handleClose = () => {
    console.log("in handle close function handle close");
    props.onClose();
  };

  const modalWidth = "550px";
  const modalHeight = "max-content";

  const handleSubmitButton = () => {
    if (!addFieldErrorState.fieldName) alert("Please enter Field Name");
    // else if (!addFieldErrorState.dataType) alert("Please enter data type");
    // else if (!addFieldErrorState.mandatoryField)
    // alert("Please enter if the field is mandatory");
    else {
      console.log("In handle submit ", addFieldState);
      props.AddFields(addFieldState);
      props.onClose();
    }
  };

  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props.isOpen}
      modalWidth={modalWidth}
      modalHeight={modalHeight}
    >
      <AddDiv>
        <Description>Add Additional Fields</Description>
        <Tooltip title="Close">
          <CloseIcon onClick={handleClose} sx={CloseIconStyle} />
        </Tooltip>
      </AddDiv>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography style={TypographyStyle}> Field Name </Typography>
          <TextField
            required
            type="text"
            name="fieldName"
            id="fieldName"
            variant="outlined"
            sx={TextFieldStyle}
            value={addFieldState.fieldName}
            onChange={handleChange}
          ></TextField>
        </Grid>

        {/*------------- Data Type field-------------  */}
        {/* <Grid item xs={12} >
          <FormControl sx={TextFieldStyle}>
            <Typography style={TypographyStyle}> Data Type </Typography>
            <Select
              labelId="dataType"
              id="dataType"
              value={addFieldState.dataType}
              // label="Data Type"
              onChange={handleChange}
              name="dataType"
            >
              <MenuItem value={100030001}>String</MenuItem>
              <MenuItem value={100030002}>Number</MenuItem>
              <MenuItem value={100030003}>Alpha Numeric</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        {/*------------- Required Type field-------------  */}
        {/* <Grid item xs={12} >
          <FormControl sx={TextFieldStyle}>
            <Typography style={TypographyStyle}> Required </Typography>
            <Select
              labelId="mandatoryField"
              id="mandatoryField"
              value={addFieldState.mandatoryField}
              // label="Data Type"
              onChange={handleChange}
              name="mandatoryField"
            >
              <MenuItem value={100040001}>Mandatory</MenuItem>
              <MenuItem value={100040002}>Optional</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        {/* <Grid xs={12} style={SubmitButtonStyle}>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmitButton}
          >
            Submit
          </Button>
        </Grid> */}

        <SubmitButtonStyle>
          <CommonButton onClick={handleSubmitButton} label="Submit" />
        </SubmitButtonStyle>
      </Grid>
    </Modal>
  );
};

export default AdditionalField;
