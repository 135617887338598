import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { TextField, MenuItem, Box, Tooltip } from '@mui/material';
import NotificationTabs from './NotificationTabs';
import CommonButton from '../Utilities/CommonButton';
import { createNotificationTemplate } from '../../features/notification/NotificationSlice';
import { fetchRole } from '../../features/userModule/userModuleSlice';

import {
  CertificateForm,
  JoditEditorDiv,
  ButtonDiv,
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CreateNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const { roles } = useSelector((state) => state.users) || {};

  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
  }, [dispatch, roles.data]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmit = () => {
    const errors = {};

    if (selectedRole.trim() === '') {
      errors.selectedRole = 'Select Role.';
    }

    if (templateName.trim() === '') {
      errors.templateName = 'Select Template.';
    }

    if (subject.trim() === '') {
      errors.subject = 'Subject is required.';
    }

    if (content.trim() === '') {
      alert('Enter Notification body');
      return;
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const templateData = {
      role: selectedRole,
      notificationType: templateName,
      subject: subject,
      notificationBody: content,
    };

    dispatch(createNotificationTemplate(templateData))
      .then((response) => {
        setTemplateName('');
        setSubject('');
        setContent('');
        toast.success('Notification template created successfully!');
        navigate('/Notification/listnotification');
      })
      .catch((error) => {
        toast.success('Notification template created successfully. Please try again.');
      });
  };

  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
    }),
    [],
  );

  const { t } = useTranslation();
  return (
    <>
      <NotificationTabs />
      <WholePage style={{ marginTop: '35px' }}>
        <CertificateForm>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            <TextField
              label="Role"
              select
              value={selectedRole}
              onChange={handleRoleChange}
              error={Boolean(fieldErrors.selectedRole)}
              helperText={fieldErrors.selectedRole}
              fullWidth
            >
              <MenuItem value="" disabled>
                {t('notification.selectRole')}
              </MenuItem>
              <MenuItem value="Select All">
                {' '}
                {t('notification.selectLabel')}
              </MenuItem>
              {Array.isArray(roles.data) &&
                roles.data.map((role) => (
                  <MenuItem key={role._id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              label={t('notification.typeNotificationLabel')}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              fullWidth
              placeholder="Ex. Campus Welcome Email"
              error={Boolean(fieldErrors.templateName)}
              helperText={fieldErrors.templateName}
              InputLabelProps={{shrink : true}}
            />
          </Box>
          <TextField
            label={t('notification.subjectLabel')}
            variant="outlined"
            margin="normal"
            value={subject}
            error={Boolean(fieldErrors.subject)}
            helperText={fieldErrors.subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <JoditEditorDiv>
            <JoditEditor
              value={content}
              onChange={(newContent) => setContent(newContent)}
              config={config}
            />
          </JoditEditorDiv>

          <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Tooltip title='Create New Notification'>
          <span>
              {' '}
              <CommonButton
                 onClick={handleSubmit}
                 label={t('notification.createLabel')}
              />
            </span>
          </Tooltip>
          </div>
        </CertificateForm>
      </WholePage>
    </>
  );
};

export default CreateNotification;
