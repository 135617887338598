import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const BackButton = ({ backgroundColor }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back one step in the history stack
  };

  return (
    <IconButton
      color="primary"
      style={{
        backgroundColor,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={handleBack}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

export default BackButton;
