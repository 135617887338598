import React from 'react';

const ScormPlayer = ({ scormUrl }) => {
  return (
    <iframe
      src={scormUrl}
      width="100%"
      height="500px"
      title="SCORM Content"
      allowFullScreen
    ></iframe>
  );
};

export default ScormPlayer;