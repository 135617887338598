import styled from "styled-components";
import { palette } from "../palette";

export const ContentHeading = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin: 10px;
`;

export const IconStyle = {
  padding: "8px",
  background: `${palette.white}`,
  borderRadius: "8px",
  marginRight: "8px",
};

export const GridStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: "8px",
  cursor: "pointer",
  marginBottom: "5px",
  marginTop: "5px",
  marginLeft: "20px",
  boxShadow: "0 0px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19)",
  
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justifycontent: center;
  margin-bottom: 25px;
`;

export const CText = styled.p`
  margin: 0px;
  margin-left: 10px;
  color: ${palette.gray};
`;
