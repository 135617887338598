import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createNotificationTemplateApi,
  fetchNotificationTemplateApi,
  fetchSingleTemplateApi,
  updateNotificationTemplateApi,
  deleteNotificationApi,
  sendNotificationApi,
  fetchSentNotificationAPi,
  fetchSingleSentNotificationApi,
  createNotificationSettingsApi,
  fetchNotificationSettingsApi,
  toggleNotificationSettingApi,
} from '../../api/NotificationApi';

export const createNotificationTemplate = createAsyncThunk(
  'notification/createNotificationTemplate',
  async (templateData, { rejectWithValue }) => {
    try {
      const response = await createNotificationTemplateApi(templateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchNotificationTemplate = createAsyncThunk(
  'notification/fetchNotificationTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchNotificationTemplateApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSingleTemplate = createAsyncThunk(
  'notification/fetchSingleTemplate',
  async (TemplateId, { rejectWithValue }) => {
    try {
      const response = await fetchSingleTemplateApi(TemplateId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateNotification = createAsyncThunk(
  'notification/updateNotification',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateNotificationTemplateApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteNotification = createAsyncThunk(
  'notification/deleteNotification',
  async (notificationId, { rejectWithValue }) => {
    try {
      await deleteNotificationApi(notificationId);
      return notificationId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendNotification = createAsyncThunk(
  'notification/sendNotification',
  async (Data, { rejectWithValue }) => {
    try {
      const response = await sendNotificationApi(Data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSentNotification = createAsyncThunk(
  'notification/fetchSentNotification',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchSentNotificationAPi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSingleSentNotification = createAsyncThunk(
  'notification/fetchSingleSentNotification',
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await fetchSingleSentNotificationApi(notificationId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createNotificationSettings = createAsyncThunk(
  'notifications/createNotificationSettings',
  async (Data, { rejectWithValue }) => {
    try {
      const response = await createNotificationSettingsApi(Data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchNotificationSettings = createAsyncThunk(
  'notification/fetchNotificationSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchNotificationSettingsApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const toggleNotificationSetting = createAsyncThunk(
  'notification/toggleNotificationSetting',
  async ({ settingId, role, isEnabled }, { rejectWithValue }) => {
    try {
      const response = await toggleNotificationSettingApi(
        settingId,
        role,
        isEnabled,
      );

      return response.data;
      // dispatch(fetchNotificationSettings);
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

const initialState = {
  data: [],
  template: [],
  singleTemplate: [],
  singleSent: [],
  sent: [],
  settings: [],
  toggle: [],
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle createNotificationTemplate
      .addCase(createNotificationTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNotificationTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.template.push(action.payload);
        state.error = null;
      })
      .addCase(createNotificationTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle fetchNotificationTemplate
      .addCase(fetchNotificationTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.template = action.payload;
        state.error = null;
      })
      .addCase(fetchNotificationTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle fetchSingleTemplate
      .addCase(fetchSingleTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTemplate = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle updateNotification
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.map((template) =>
          template._id === action.payload._id ? action.payload : template,
        );
        state.error = null;
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle deleteNotification
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.template = state.template.filter(
          (notification) => notification._id !== action.payload,
        );
        state.error = null;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle sendNotification
      .addCase(sendNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.sent.push(action.payload);
        state.error = null;
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle fetchSentNotification
      .addCase(fetchSentNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSentNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.sent = action.payload;
        state.error = null;
      })
      .addCase(fetchSentNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle fetchSingleSentNotification
      .addCase(fetchSingleSentNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleSentNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.singleSent = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleSentNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle createNotificationSettings
      .addCase(createNotificationSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNotificationSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings.push(action.payload);
        state.error = null;
      })
      .addCase(createNotificationSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle fetchNotificationSettings
      .addCase(fetchNotificationSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
        state.error = null;
      })
      .addCase(fetchNotificationSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addCase(toggleNotificationSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(toggleNotificationSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.toggle = action.payload;
        state.error = null;
      })
      .addCase(toggleNotificationSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.toggle = null;
      }) 

  },
});

export default notificationSlice.reducer;
