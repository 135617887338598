import React from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../Utilities/BackButton';
import {
  Back,
  NotificationDetailed,
  ActionButton,
  FullHeight,
  NotificationHead,
  DateText,
} from '../../styles/Notification/NotificationStyles';
import { Text, HeadingH2 } from '../../styles/Common/CommonStyles';

import { Archive, Delete, Star } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const DetailedNotification = () => {
  const notifications = [
    {
      id: 1,
      subject: 'Important Notification',
      message:
        "Archived notification 1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      sender: 'Admin',
      timestamp: Date.now(),
    },
    {
      id: 2,
      subject: 'Another notification',
      message: 'This is another notification message.',
      sender: 'John Doe',
      timestamp: Date.now() - 1000 * 60 * 5,
    },
    {
      id: 3,
      subject: 'Yet another notification',
      message: 'This is yet another notification message.',
      sender: 'Jane Doe',
      timestamp: Date.now() - 1000 * 60 * 10,
    },
  ];

  const { id } = useParams();

  const notification = notifications.find(
    (notification) => notification.id === parseInt(id),
  );

  if (!notification) {
    const { t } = useTranslation();
    // return <p>Notification not found</p>;
    return <p>{t("notFoundNotification")}</p>;
  }

  const handleArchive = () => {
    console.log(`Notification ${id} archived`);
  };

  const handleDelete = () => {
    console.log(`Notification ${id} deleted`);
  };

  const handleMarkImportant = () => {
    console.log(`Notification ${id} marked as important`);
  };

  return (
    <>
      <Back>
        <BackButton />
      </Back>
      <FullHeight>
        <NotificationDetailed>
          <NotificationHead>
            <div>
              <HeadingH2>{notification.subject}</HeadingH2>
              <DateText>
                {new Date(notification.timestamp).toLocaleTimeString()}
              </DateText>
            </div>
            <div>
              <ActionButton onClick={handleArchive}>
                <Archive />
              </ActionButton>
              <ActionButton onClick={handleDelete}>
                <Delete />
              </ActionButton>
              <ActionButton onClick={handleMarkImportant}>
                <Star />
              </ActionButton>
            </div>
          </NotificationHead>
          <Text>{notification.message}</Text>
        </NotificationDetailed>
      </FullHeight>
    </>
  );
};

export default DetailedNotification;
