import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Typography,
  Tooltip,
  Divider,
  Box,
  Stack,
  Chip,
  Grid,
  Container,
  Button,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { fetchedAllEvents } from '../../features/calendar/CalendarSlice';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {
  CardContent,
  Day,
  Month,
  CardUpperCont,
  CardLowerCont,
  Time,
  Type,
} from '../../styles/Calendar/CalendarStyle';
import { Date as DateFormat } from '../../styles/Calendar/CalendarStyle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const HorizontalCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const events = useSelector((state) =>
    state.calendar.data.filter((event) => !event.isDeleted),
  );
  const { users, loading } = useSelector((state) => state.auth);
  const { eventTags } = useSelector((state) => state.calendar);
  const [tagClicked, setTagClicked] = useState(false);

  useEffect(() => {
    dispatch(fetchedAllEvents());
  }, [dispatch]);

  const handleRegister = (eventname) => {
    console.log('handleRegister');
    if (users.user.userid?.role === 'SuperAdmin') {
      navigate(`/EventsNavigator/eventdetails/${eventname}`);
    } else {
      navigate(`/LearnersEventsNavigator/eventdetails/${eventname}`);
    }
  };

  // Filter events based on the current date and above, to display cards
  const currentDate = moment().startOf('day'); // Get current date without time
  const filteredEvents = events.filter((event) =>
    moment(event.SDate).startOf('day').isSameOrAfter(currentDate),
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${month}/${day}`;
  };
  const getColorByDelivery = (deliveryType) => {
    switch (deliveryType) {
      case 'In-Person':
        return '#35be57'; // Example color for 'In-Person'
      case 'Live Online':
        return '#f47d89'; // Example color for 'Live Online'
      case 'Hybrid':
        return '#ed93cd'; // Example color for 'Hybrid'
      case 'Self-paced Online':
        return '#f47d89'; // Example color for 'Self-paced Online'
      case 'Interactive Webinar':
        return '#68c5df'; // Example color for 'Interactive Webinar'
      case 'Bootcamp':
        return '#947fe4'; // Example color for 'Bootcamp'
      case 'Video-based Course':
        return '#e1956cb0'; // Example color for 'Video-based Course'
      default:
        return '#a1d9b9'; // Default color
    }
  };
  const handleClickTag = () => {
    setTagClicked(!tagClicked);
  };
  const { t } = useTranslation();

  return (
    <>
      <Container fluid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: '30px',
            marginTop: '40px',
            marginLeft: 0,
            padding: '5px',
          }}
        >
          {eventTags.map((event, index) => {
            // Filtering events based on currentDate and event date
            const filteredEventsForTag = filteredEvents.filter(
              (filteredEvent) =>
                moment(filteredEvent.SDate).isSame(event.SDate, 'day'),
            );
            return (
              <React.Fragment key={index}>
                {filteredEventsForTag.map((filteredEvent, innerIndex) => (
                  <Grid item xs={12} lg={6} key={innerIndex}>
                    <Card style={{ height: '150px', border: '1px solid #333' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Box
                          sx={{
                            backgroundColor: getColorByDelivery(event.Delivery),
                            width: '22%',
                            height: '150px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Day style={{ color: '#fff', fontSize: '17px' }}>
                            {new Date(event.SDate)
                              .toLocaleDateString('en-US', { weekday: 'short' })
                              .substring(0, 3)}
                          </Day>
                          <DateFormat
                            style={{
                              color: '#fff',
                              fontWeight: 600,
                              fontSize: '28px',
                            }}
                          >
                            {formatDate(event.SDate)}
                          </DateFormat>

                          <Time style={{ color: '#fff', fontSize: '17px' }}>
                            {event.STime}
                          </Time>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: '16px',
                          }}
                        >
                          <CardUpperCont>
                            <Stack direction="row" spacing={1}>
                              <LocationOnOutlinedIcon
                                sx={{
                                  color: getColorByDelivery(event.Delivery),
                                  width: '17px',
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '15px',
                                  fontWeight: 'bold',
                                  color: getColorByDelivery(event.Delivery),
                                }}
                              >
                                {event.Delivery}
                              </Typography>
                            </Stack>
                          </CardUpperCont>
                          <CardContent>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                              {event.EventName}
                            </Typography>
                            <Typography variant="body2">
                              {event.EventDesp ? (
                                <>
                                  {event.EventDesp.split(' ')
                                    .slice(0, 7)
                                    .join(' ')}
                                  {event.EventDesp.split(' ').length > 7
                                    ? '...'
                                    : ''}
                                </>
                              ) : (
                                t('calender.noDiscriptionText')
                              )}
                            </Typography>
                          </CardContent>
                          <CardLowerCont>
                            <Button
                              variant="text"
                              sx={{ fontSize: '12px' }}
                              endIcon={
                                <ArrowForwardIosIcon
                                  sx={{
                                    width: '13px',
                                    marginTop: '-2px',
                                    marginLeft: '-8px',
                                  }}
                                />
                              }
                              onClick={() => handleRegister(event.EventName)}
                            >
                              {users.user.userid?.role === 'SuperAdmin'
                                ? 'Enroll Learners'
                                : 'Register'}
                            </Button>
                          </CardLowerCont>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </React.Fragment>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export default HorizontalCard;
