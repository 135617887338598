import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  MainContainer,
  TopSection,
  Bars,
  Section,
  Icon,
  MenuTitle,
  ImageContainerSidebar,
  ImageOpen,
  ImageClosed,
  LogoProfileContainer,
} from '../../styles/Sidebar/SidebarStyle';

import {
  NavbarContainer,
  Image,
  ImageContainer,
  NavMenu,
  NavBtn,
  NavBtnLink,
  ProfileHead,
  Initial,
  ProfileContainer,
  faCustomStyle,
  Container1,
  ProfileLink,
  ProfilePic,
  SettingIcon,
} from '../../styles/Landing/NavbarElements';

import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import WebIcon from '@mui/icons-material/Web';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ForumIcon from '@mui/icons-material/Forum';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCardIcon from '@mui/icons-material/AddCard';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { logOutApi } from '../../features/authentication/AuthenticationSlice';
const SideBar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [displayProfile, setDisplayProfile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { users, loading, error } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.users);

  console.log(users);
  const routes = [
    {
      path: '/Dashboard',
      name: 'Dashboard',
      icon: <DashboardCustomizeIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor', 'Learner'], // Accessible to SuperAdmin, Instructor, and Learner
    },
    {
      path:
        users.user.userid?.role === 'SuperAdmin'
          ? '/CourseBuilder'
          : '/CourseBuilder',
      name:
        users.user.userid?.role === 'SuperAdmin' ? 'Course Builder' : 'Courses',
      icon: <MenuBookIcon fontSize="small" />,
      access: ['SuperAdmin', 'Learner'],
    },
    // {
    //   path: "/ManageBundles",
    //   name: "Manage Bundles",
    //   icon: <LibraryBooksIcon fontSize="small" />,
    // },
    // {
    //   path: "/WebsiteBuilder",
    //   name: "Website Builder",
    //   icon: <WebIcon fontSize="small" />,
    // },
    // {
    //   path: "/AppBuilder",
    //   name: "App Builder",
    //   icon: <AppSettingsAltIcon fontSize="small" />,
    // },
    {
      path: '/Users',
      name: 'Users',
      icon: <SupervisorAccountIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor'],
      // subRoutes: [
      //   { path: '/Users/Admin', name: 'Admin' },
      //   { path: '/Users/Instructors', name: 'Instructors' },
      //   { path: '/Users/Learners', name: 'Learners' }
      // ]
    },
    {
      path:
        users.user.userid?.role === 'SuperAdmin'
          ? '/EventsNavigator'
          : '/LearnersEventsNavigator',
      name: 'Events Navigator',
      icon: <CalendarMonthIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor'], // Accessible only to SuperAdmin
    },
    // {
    //   path: '/Calendar',
    //   name: 'Events Navigator',
    //   icon: <CalendarMonthIcon fontSize="small" />,
    //   access: 'All',
    // },
    {
      path:
        users.user.userid?.role === 'SuperAdmin'
          ? '/Certificate'
          : '/LearnerCertificate',
      name: 'Certificates',
      icon: <CardMembershipIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor'], // Accessible only to SuperAdmin
    },
    {
      path:
        users.user.userid?.role === 'SuperAdmin'
          ? '/Notification'
          : '/LearnerNotification',
      name: 'Notifications',
      icon: <NotificationsIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor', 'Learner'], // Accessible to SuperAdmin and Instructor
    },

    // {
    //   path: "/Community",
    //   name: "Communities",
    //   icon: <ForumIcon fontSize="small" />,
    // },
    // {
    //   path: "/Analytics",
    //   name: "Analytics",
    //   icon: <SummarizeIcon fontSize="small" />,
    // },
    // {
    //   path: "/Assets",
    //   name: "Assets",
    //   icon: <LocalLibraryIcon fontSize="small" />,
    // },
    {
      path: '/Modules',
      name: 'Modules',
      icon: <AddCardIcon fontSize="small" />,
      access: ['SuperAdmin', 'Instructor', 'Learner'], // Accessible to SuperAdmin and Instructor
    },
    {
      path: '/LearnerCourses',
      name: 'Courses',
      icon: <MenuBookIcon fontSize="small" />,
      access: ['Learner'], // Accessible only to SuperAdmin
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5,
      },
    },
  };

  // useEffect(() => {
  //   console.log('Nav bar use effect', userProfile);
  // }, [userProfile]);

  // useEffect(() => {
  //   // Check if the current page is CertificateDetails
  //   // and window width is greater than 768px
  //   if (location.pathname === '/Certificate/CertificateDetail' || location.pathname === '/Certificate/EditSettings/:id' && window.innerWidth > 768) {
  //     setIsOpen(false);
  //   } else {
  //     setIsOpen(true);
  //   }
  // }, [location]);

  useEffect(() => {
    // Check if the current page is either CertificateDetails or EditSettings (with a specific ID)
    // and the window width is greater than 768px
    const currentPath = location.pathname;
    const isCourseModulesPath = currentPath.startsWith('/CourseModules/');
    const isLearnerCoursePreviewPath = currentPath.startsWith('/LearnerCoursePreview/');
    const isCoursePreviewPath = currentPath.startsWith('/CoursePreview/');

    if (
      (location.pathname === '/Certificate/CertificateDetail' ||
        location.pathname.startsWith('/Certificate/EditSettings/') ||  isCourseModulesPath || isLearnerCoursePreviewPath || isCoursePreviewPath ) &&
      window.innerWidth > 768
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [location]);

  // useEffect(() => {
  // Check if the current page is CertificateDetails
  // and window width is greater than 768px
  //   if (location.pathname === '/CourseModules' && window.innerWidth > 900) {
  //     setIsOpen(false);
  //   } else {
  //     setIsOpen(true);
  //   }
  // }, [location]);

  useEffect(() => {
    const handleResize = () => {
      // Update isOpen based on screen width
      setIsOpen(window.innerWidth > 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('userDetails');

        setDisplayProfile(!displayProfile);
        dispatch(logOutApi())
          .unwrap()
          .then(() => {
            navigate('/');
          })
          .catch((err) => {
            console.error('Logout failed:', err);
          });
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <MainContainer userLoggedIn={users?.user?.userid?.email_verified}>
      <motion.div
        animate={{
          width: isOpen ? '' : '58px',
          transition: {
            duration: 0.5,
            type: 'spring',
            damping: 10,
          },
        }}
        className={`sidebar`}
      >
        <TopSection>
          <LogoProfileContainer>
            <ImageContainerSidebar>
              {/* <Bars>
              <FaBars onClick={toggle} />
            </Bars> */}
              {isOpen ? (
                <ImageOpen
                  src={require('../../images/NoBackground.png')}
                  onClick={toggle}
                />
              ) : (
                <ImageClosed
                  src={require('../../images/SmallBlueBackground.png')}
                  onClick={toggle}
                />
              )}
            </ImageContainerSidebar>
          </LogoProfileContainer>
          {/* <MenuTitle> Menu </MenuTitle> */}
        </TopSection>

        <Section>
          {routes.map((route, index) => {
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                />
              );
            }

            if (
              route.access === 'Learner' ||
              (Array.isArray(route.access) &&
                route.access.includes(users?.user?.userid?.role))
            ) {
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <Icon>{route.icon}</Icon>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            } else {
              return null; // Render nothing if the user doesn't have access
            }
          })}
        </Section>
      </motion.div>

      <ProfileContainer>
        {displayProfile && (
          <Container1>
            <ProfileLink
              to="/MyProfile"
              onClick={() => setDisplayProfile(!displayProfile)}
            >
              My Profile
            </ProfileLink>
            <ProfileLink onClick={logout}>Logout</ProfileLink>
          </Container1>
        )}

        <ProfileHead onClick={() => setDisplayProfile(!displayProfile)}>
          {users?.user?.userid?.hasOwnProperty('picture') &&
          users?.user?.userid?.picture !== '' ? (
            <ProfilePic src={users?.user?.userid?.picture} />
          ) : (
            <ProfilePic src={require('../../images/user.png')} />
          )}
          {isOpen && <Initial>{users?.user?.userid?.username}</Initial>}
          {!displayProfile && (
            <IconButton style={{ color: 'white' }}>
              {isOpen && (
                <SettingsIcon
                  onClick={() => setDisplayProfile(!displayProfile)}
                />
              )}
            </IconButton>
          )}
          {displayProfile && (
            <IconButton style={{ color: 'white' }}>
              {!isOpen && (
                <SettingsIcon
                  style={{ transform: 'rotate(90deg)' }}
                  onClick={() => setDisplayProfile(!displayProfile)}
                />
              )}
            </IconButton>
          )}
        </ProfileHead>
      </ProfileContainer>

      {/* <main>{children}</main> */}
    </MainContainer>
  );
};

export default SideBar;
