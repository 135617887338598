import React from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography, Box, Stack, Tooltip, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { palette } from "../../../styles/palette";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SelectCourseCard = () => {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    // <Box sx={{ flexGrow: 1, padding: 2, minHeight: '76vh', position: 'relative' }}>
    <div style={{position: 'relative'}}>
      <Tooltip title="Go Back">
        <IconButton
          size="small"
          onClick={() => navigate(-1)}
          sx={{ 
            color: `${palette.THEME_THREE}`,
            position: 'absolute',
            top: '8px',
            left: '8px',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Tooltip>
      <Grid container spacing={3} justifyContent="center" margin='15px'>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: '#fff',
              boxShadow: 3,
              '&:hover': {
                boxShadow: 6,
              },
              margin: 'auto',
              textAlign: 'center',
              padding: "20px"
                       }}
          >
            <CardActionArea onClick={() => handleCardClick('/CreateCourse')}>
              <CardContent>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <AddIcon sx={{ fontSize: 40, color: `${palette.primary}`}} />
                  <Typography gutterBottom variant="h6" component="div">
                    Blank Course
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create a new course from scratch.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: '#fff',
              boxShadow: 3,
              '&:hover': {
                boxShadow: 6,
              },
              margin: 'auto',
              textAlign: 'center',
              padding: "20px"
            }}
          >
            <CardActionArea onClick={() => handleCardClick('/copyCourse')}>
              <CardContent>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <FileCopyIcon sx={{ fontSize: 40, color: `${palette.primary}`}} />
                  <Typography gutterBottom variant="h6" component="div">
                    Copy Existing Course
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Duplicate course to create new version.
</Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={5}>
          <Card
            sx={{
              backgroundColor: '#fff',
              boxShadow: 3,
              '&:hover': {
                boxShadow: 6,
              },
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <CardActionArea onClick={() => handleCardClick('/templates')}>
              <CardContent>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <BackupTableIcon sx={{ fontSize: 40, color: `${palette.primary}`}} />
                  <Typography gutterBottom variant="h6" component="div">
                    Sample Templates
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Use predefined templates to create courses.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: '#fff',
              boxShadow: 3,
              '&:hover': {
                boxShadow: 6,
              },
              margin: 'auto',
              textAlign: 'center',
              padding: "20px"
            }}
          >
            <CardActionArea onClick={() => handleCardClick('/ScromImport')}>
              <CardContent>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <FolderZipIcon sx={{ fontSize: 40, color: `${palette.primary}`}} />
                  <Typography gutterBottom variant="h6" component="div">
                    Import SCORM file
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Import course using SCORM files.
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectCourseCard;
