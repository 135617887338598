import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllEvents } from '../../api/CalendarApi';
import {
  getEventsByStartDate,
  fetchEventDetailsByName,
  fetchManualEnrolledusersDetails,
  addAnnouncement,
  fetchEventsByTag,
  cancelAnnouncement,
  enrollUsersforEvent,
} from '../../api/CalendarApi';

import {
  getAllTags,
  fetchAnnouncements,
  fetchUserInfo,
  fetchUserStats,
  fetchEnrolledUsers,
} from '../../api/CalendarApi';

export const fetchedAllEvents = createAsyncThunk(
  'calendar/fetchAllEvents',
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await getAllEvents();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchEventsByStartDate = createAsyncThunk(
  'calendar/fetchEventsByStartDate',
  async (startDate, { rejectWithValue }) => {
    try {
      const response = await getEventsByStartDate(startDate);
      return response.event;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAllTags = createAsyncThunk(
  'calendar/fetchAllTags',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllTags();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchEventsByTagAsync = createAsyncThunk(
  'calendar/fetchEventsByTag',
  async (tag, { rejectWithValue }) => {
    try {
      console.log('tags in calender ', tag);
      // const response = await fetchEventsByTag(tag);
      return tag;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAnnouncement = createAsyncThunk(
  'calendar/fetchannouncementDetails',
  async (annid, { rejectWithValue }) => {
    try {
      const response = await fetchAnnouncements();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const cancelAnnouncements = createAsyncThunk(
  'calendar/cancelannouncement',
  async (id, { rejectWithValue }) => {
    try {
      const response = await cancelAnnouncement(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const fetchUsersInfo = createAsyncThunk(
  '/calendar/fetchenrolledusersDetails',
  async (eventname, { rejectWithValue }) => {
    try {
      const response = await fetchUserInfo(eventname);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchUsersStats = createAsyncThunk(
  '/calendar/fetchuserstats',
  async (eventname, { rejectWithValue }) => {
    try {
      const response = await fetchUserStats(eventname);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchEventDetails = createAsyncThunk(
  '/calendar/fetchEventDetails',
  async (eventname, { rejectWithValue }) => {
    try {
      const response = await fetchEventDetailsByName(eventname);
      console.log('fetch event details', response);
      return response.data.eventdetails; // Modify this according to your response structure
    } catch (error) {
      return rejectWithValue(error.message.data);
    }
  },
);

export const fetchManualEnrolledusers = createAsyncThunk(
  '/calendar/fetchusersdetails',
  async (eventname, { rejectWithValue }) => {
    try {
      const response = await fetchManualEnrolledusersDetails(eventname);
      console.log('fetch event details', response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message.data);
    }
  },
);

export const addAnnouncementdetails = createAsyncThunk(
  'calendar/addannouncements',
  async (data, { rejectWithValue }) => {
    try {
      const response = await addAnnouncement(data);
      console.log('post annoncements', response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message.data);
    }
  },
);

export const enrollUsers = createAsyncThunk(
  '/calendar/manualenrollusers',
  async ({ eventName, emails }, { rejectWithValue }) => {
    try {
      const response = await enrollUsersforEvent(eventName, emails);
      console.log('enroll users', response);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message.data);
    }
  },
);
export const fetchEnrolledUsersData = createAsyncThunk(
  'calendar/enrolledUsersforEvent',
  async (eventName, { rejectWithValue }) => {
    try {
      const response = await fetchEnrolledUsers(eventName);
      console.log('enroll users for event', response);

      return response;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

const initialState = {
  data: [],
  tags: [],
  eventTags: [],
  events: [],
  info: [],
  stats: [],
  eventDetails: [],
  enrolluser: [],
  userdata: [],
  announcement: [],
  userinfo: [],
  archived: [],
  loading: false,
  error: null,
};
const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchedAllEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchedAllEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.eventTags = action.payload;
        state.error = null;
      })
      .addCase(fetchedAllEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      })
      .addCase(fetchEventsByStartDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventsByStartDate.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
        state.error = null;
      })
      .addCase(fetchEventsByStartDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.events = null;
      })

      .addCase(fetchAnnouncement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.announcement = action.payload.filter((ann) => ann.active);
        state.archived = action.payload.filter((ann) => !ann.active);

        state.error = null;
      })
      .addCase(fetchAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.announcement = [];
        state.archived = [];
      })

      .addCase(cancelAnnouncements.fulfilled, (state, action) => {
        const index = state.announcement.findIndex(
          (ann) => ann._id === action.payload._id,
        );
        if (index !== -1) {
          const [canceledAnnouncement] = state.announcement.splice(index, 1);
          canceledAnnouncement.active = false;
          state.archived.push(canceledAnnouncement);
        }
      })

      .addCase(fetchAllTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = action.payload;
        state.error = null;
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.tags = [];
      })

      .addCase(fetchEventsByTagAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventsByTagAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        console.log('Fetched events by tag:', action.payload); // Check if the fetched events are correct
        // state.eventTags = action.payload.events;
        console.log('action', action.payload);
        // const { tag } = action.payload;
        // console.log(tag)
        if (action.payload == 'All') {
          state.eventTags = state?.data;
        } else if (action.payload) {
          // console.log( "inside in fetchEventsByTagAsync functions",data)
          state.eventTags = state?.data?.filter(
            (ele) => ele.tags.filter((t) => t === action.payload).length > 0,
          );
        } else {
          state.eventTags = state?.data;
        }
        // state.eventTags = action.payload;
      })
      .addCase(fetchEventsByTagAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.eventTags = null;
      })
      .addCase(fetchUsersStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload; // Set stats to the userstats array
        state.error = null;
      })
      .addCase(fetchUsersStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.stats = null;
      })

      .addCase(fetchEventDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.eventDetails = action.payload;
        state.error = null;
      })
      .addCase(fetchEventDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.eventDetails = null;
      })

      // .addCase(fetchManualEnrolledusers.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchManualEnrolledusers.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.enrolluser = action.payload;
      //   state.error = null;
      // })
      // .addCase(fetchManualEnrolledusers.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      //   state.enrolluser = null;
      // })

      .addCase(enrollUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(enrollUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.enrolluser = action.payload;
        state.error = null;
      })
      .addCase(enrollUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.enrolluser = null;
      })

      .addCase(fetchEnrolledUsersData.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log('Fetching enrolled users data...'); // Debugging log
      })
      .addCase(fetchEnrolledUsersData.fulfilled, (state, action) => {
        state.loading = false;
        state.userdata = action.payload;
        state.error = null;
        console.log('Fetched enrolled users data:', action.payload);
      })
      .addCase(fetchEnrolledUsersData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.userdata = [];
        console.log('Error fetching enrolled users data:', action.payload); // Debugging log
      })
      .addCase(fetchUsersInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.userinfo = action.payload;
        state.error = null;
      })
      .addCase(fetchUsersInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.userinfo = null;
      })

      .addCase(addAnnouncementdetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(addAnnouncementdetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.announcement = action.payload;
        state.announcement.push(action.payload.newAnnouncement);
        state.error = null;
      })
      .addCase(addAnnouncementdetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload
          : 'Failed to add announcement';
        state.announcement = null;
      });
  },
});

export default calendarSlice.reducer;
