import React, { useState, useEffect } from 'react';
import EventsTabs from './EventsTabs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  UserInfoCont,
  NotdonePayment,
  CompletedPayment,
  ApprovedPayment,
  UserTable,
} from '../../styles/Calendar/CalendarStyle';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { EventTitle, Title } from '../../styles/Calendar/CalendarStyle';
import BackButton from '../Utilities/BackButton';

import TableUtilityLib from '../Utilities/TableUtilityLib';
import {
  fetchUsersInfo,
  fetchUsersStats,
  fetchEnrolledUsersData,
} from '../../features/calendar/CalendarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const UserInfo = () => {
  const dispatch = useDispatch();
  const { eventname } = useParams();
  const [tableData, setTableData] = useState([]);
  const { stats, userinfo, userdata, loading, error, eventDetails } =
    useSelector((state) => state.calendar) || {};
  // const { eventName } = useParams();

  // const { userinfo, userdata, loading, error } = useSelector(
  //   (state) => state.calendar,
  // );

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const response = await dispatch(fetchUsersInfo(eventname)).unwrap();
  //       setTableData(response);
  //       console.log('helowwwwww', response.data);
  //     } catch (error) {
  //       console.error('Error fetching announcements', error);
  //     }
  //   };
  //   fetchUserDetails();
  // }, [dispatch, eventname]);

  // useEffect(() => {
  //   dispatch(fetchUsersStats(eventname));
  // }, [dispatch, eventname]);
  const handleBack = () => {
    navigate('/EventsNavigator');
  };

  useEffect(() => {
    if (eventname) {
      console.log('Fetching data for event:', eventname); // Debugging log

      dispatch(fetchEnrolledUsersData(eventname));
    }
  }, [dispatch, eventname]);
  useEffect(() => {
    console.log('User data:', userdata); // Debugging log
  }, [userdata]);


  // Function to format date as MM-DD-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };
  const { t } = useTranslation();
  const columns = [
    {
      name: t('calender.firstName'),
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: t('calender.lastName'),
      selector: (row) => row.lastname,
      sortable: true,
    },
    { name: t('calender.Email'), selector: (row) => row.email, sortable: true },
    {
      name: t('calender.joiningDate'),
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
  ];

  return (
    <>
      <EventsTabs />

      {stats.length > 0 ? (
        stats.map((stat) => (
          <Grid container spacing={4} sx={{ padding: 6 }} key={stat._id}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  display: 'flex',
                  width: 265,
                  height: 110,
                  backgroundColor: '#f3f4f8',
                }}
              >
                <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                  <img
                    src={require('../../images/student.jpg')}
                    style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                  />
                </CardContent>
                <CardContent sx={{ padding: 1 }}>
                  <Typography sx={{ color: '#0684fb' }} variant="h5">
                    {stat.totalRegistrations}
                  </Typography>
                  <Typography variant="body1">Total Registrations</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  display: 'flex',
                  width: 265,
                  height: 110,
                  backgroundColor: '#f3f4f8',
                }}
              >
                <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                  <img
                    src={require('../../images/registered.png')}
                    style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                  />
                </CardContent>
                <CardContent sx={{ padding: 1 }}>
                  <Typography sx={{ color: '#0684fb' }} variant="h5">
                    {stat.activeRegistrations}
                  </Typography>
                  <Typography variant="body1">Active Registrations</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  display: 'flex',
                  width: 265,
                  height: 110,
                  backgroundColor: '#f3f4f8',
                }}
              >
                <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                  <img
                    src={require('../../images/payment.png')}
                    style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                  />
                </CardContent>
                <CardContent sx={{ padding: 1 }}>
                  <Typography sx={{ color: '#0684fb' }} variant="h5">
                    {stat.cancelRegistrations}
                  </Typography>
                  <Typography variant="body1">
                    <Typography variant="body1">
                      Cancel Registrations
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  display: 'flex',
                  width: 265,
                  height: 110,
                  backgroundColor: '#f3f4f8',
                }}
              >
                <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                  <img
                    src={require('../../images/paymentpending.png')}
                    style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                  />
                </CardContent>
                <CardContent sx={{ padding: 1 }}>
                  <Typography sx={{ color: '#0684fb' }} variant="h5">
                    {stat.paymentPending}
                  </Typography>
                  <Typography variant="body1">Payments Pending</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))
      ) : (
        // Display default cards with 0 values
        <Grid container spacing={4} sx={{ padding: 6 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: 'flex',
                width: 265,
                height: 110,
                backgroundColor: '#f3f4f8',
              }}
            >
              <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                <img
                  src={require('../../images/student.jpg')}
                  style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                />
              </CardContent>
              <CardContent sx={{ padding: 1 }}>
                <Typography sx={{ color: '#0684fb' }} variant="h5">
                  06
                </Typography>
                <Typography variant="body1">
                  {t('calender.totalRegistrationLabel')}
                </Typography>

                <Typography variant="body1">
                  {t('calender.totalRegistrationLabel')}
                </Typography>

              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: 'flex',
                width: 265,
                height: 110,
                backgroundColor: '#f3f4f8',
              }}
            >
              <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                <img
                  src={require('../../images/registered.png')}
                  style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                />
              </CardContent>
              <CardContent sx={{ padding: 1 }}>
                <Typography sx={{ color: '#0684fb' }} variant="h5">
                  04
                </Typography>
                <Typography variant="body1">
                  {t('calender.activeRegistrationLabel')}
                </Typography>

                <Typography variant="body1">
                  {t('calender.activeRegistrationLabel')}
                </Typography>

              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: 'flex',
                width: 265,
                height: 110,
                backgroundColor: '#f3f4f8',
              }}
            >
              <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                <img
                  src={require('../../images/payment.png')}
                  style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                />
              </CardContent>
              <CardContent sx={{ padding: 1 }}>
                <Typography sx={{ color: '#0684fb' }} variant="h5">
                  02
                </Typography>
                <Typography variant="body1">
                  <Typography variant="body1">
                    {t('calender.cancelRegistrationLabel')}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: 'flex',
                width: 265,
                height: 110,
                backgroundColor: '#f3f4f8',
              }}
            >
              <CardContent sx={{ flex: '0 0 100px', padding: 1 }}>
                <img
                  src={require('../../images/paymentpending.png')}
                  style={{ width: '60%', marginTop: 10, marginLeft: 10 }}
                />
              </CardContent>
              <CardContent sx={{ padding: 1 }}>
                <Typography sx={{ color: '#0684fb' }} variant="h5">
                  01
                </Typography>
                <Typography variant="body1">
                  {t('calender.paindingPaymentLabel')}
                </Typography>

                <Typography variant="body1">
                  {t('calender.paindingPaymentLabel')}
                </Typography>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <UserTable style={{ padding: '16px' }}>
        <TableUtilityLib
          title={`${t('calender.enrolleventdescriptionLabel')} ${eventDetails?.EventName}:`}
          columns={columns}
          data={userdata}
          selectableRows={true}
          selectableRowsHighlight={true}
        />
      </UserTable>
    </>
  );
};

export default UserInfo;
