import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import { palette } from '../../styles/palette';
import { useTranslation } from 'react-i18next';

import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';

const CalendarTabs = () => {
  const location = useLocation();
  const defaultTabValue = [
    '/EventsNavigator',
    '/EventsNavigator/Announcements',
  ].includes(location.pathname)
    ? location.pathname
    : '/EventsNavigator';
  const { t } = useTranslation();

  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label={t('calender.calendarLabel')}
        value="/EventsNavigator"
        component={Link}
        to="/EventsNavigator"
      />

      <StyledTab
        label={t('calender.announcementsLabel')}
        value="/EventsNavigator/Announcements"
        component={Link}
        to="/EventsNavigator/Announcements"
      />
    </StyledTabs>
  );
};

export default CalendarTabs;
