import axios from 'axios';
// const URL = 'https://leapot-lms-backend.onrender.com/api';
// const URL = 'http://localhost:8000/api';

//Users

export const updateUserApi = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/userProfile`,
      data,
    );
    console.log('update api', response.data);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};
