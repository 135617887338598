// pdfSlice.js

import { createSlice } from '@reduxjs/toolkit';

const pdfSlice = createSlice({
  name: 'pdf',
  initialState: {
    pdfUrl: '',
    title: '',
  },
  reducers: {
    setPdf: (state, action) => {
      state.pdfUrl = action.payload.pdfUrl;
      state.title = action.payload.title;
    },
  },
});

export const { setPdf } = pdfSlice.actions;
export default pdfSlice.reducer;
