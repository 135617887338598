import axios from 'axios';

// const URL = 'https://leapot-lms-backend.onrender.com/api';
// const URL = 'http://localhost:8000/api';

const URL = process.env.REACT_APP_URL;

export const getAllEvents = async () => {
  try {
    return await axios.get(`${URL}/api/calendar/fetchAllEvents`);
  } catch (error) {
    console.log('Error occurs while fetching all events details ', error);
    throw error;
  }
};

export const getEventsByStartDate = async (startDate) => {
  try {
    const response = await axios.get(
      `${URL}/api/calendar/fetchEventByStartDate/${startDate}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching events by start date:', error);
    throw error;
  }
};

export const getAllTags = async () => {
  try {
    return await axios.get(`${URL}/api/calendar/fetchTags`);
  } catch (err) {
    console.log('Error occurs while fetching tags', err);
    throw err;
  }
};

export const fetchEventsByTag = async (tag) => {
  try {
    const response = await axios.get(
      `${URL}/api/calendar/fetchEventsbyTags/${tag}`,
    );
    return response.data;
  } catch (error) {
    console.log('Error occurs while fetching tags', err);
    throw error;
  }
};

export const fetchAnnouncements = async () => {
  try {
    return await axios.get(`${URL}/api/calendar/fetchannouncementDetails`);
  } catch (err) {
    console.log('Error occurs while fetching announcements', err);
    throw err;
  }
};
export const cancelAnnouncement = async (id) => {
  try {
    return await axios.put(`${URL}/api/calendar/cancelannouncement/${id}`);
  } catch (err) {
    console.log('Error occurs while canceling announcement', err);
    throw err;
  }
};

export const fetchAnnouncementInfo = async (annNo) => {
  try {
    const response = await axios.get(
      `${URL}/api/calendar/fetchannouncementinfo/${annNo}`,
    );
    return response.data;
  } catch (err) {
    console.log('Error occurs while fetching announcements details', err);
    throw err;
  }
};

export const fetchUserInfo = async (eventname) => {
  try {
    return await axios.get(
      `${URL}/api/calendar/fetchenrolledusersDetails/${eventname}`,
    );
  } catch (err) {
    console.log('Error occurs while fetching users details', err);
    throw err;
  }
};

export const fetchUserStats = async (eventname) => {
  try {
    return await axios.get(`${URL}/api/calendar/fetchuserstats/${eventname}`);
  } catch (err) {
    console.log('Error occurs while fetching user stats', err);
    throw err;
  }
};

export const fetchEventDetailsByName = async (eventname) => {
  try {
    return await axios.get(
      `${URL}/api/calendar/fetchEventDetails/${eventname}`,
    );
  } catch (error) {
    console.error('Error fetching event details:', error.message);
    throw error;
  }
};

export const fetchManualEnrolledusersDetails = async (eventname) => {
  try {
    return await axios.get(
      `${URL}/api/calendar/fetchusersdetails/${eventname}`,
    );
  } catch (error) {
    console.error(
      'Error fetching manuaaly enrolled users details:',
      error.message,
    );
    throw error;
  }
};

export const addAnnouncement = async (data) => {
  try {
    return await axios.post(`${URL}/api/calendar/addannouncements`, data);
  } catch (error) {
    console.log('Error posting announcements', error);
    throw error;
  }
};

export const enrollUsersforEvent = async (eventName, emails) => {
  try {
    const encodedEventName = encodeURIComponent(eventName); // Encode the event name

    const response = await axios.post(
      `${URL}/api/calendar/manualenrollusers/${encodedEventName}`,
      {
        emails,
      },
    );
    return response.data;
  } catch (error) {
    console.log('Error enrolling users', error);
    throw error;
  }
};

export const fetchEnrolledUsers = async (eventName) => {
  try {
    const response = await axios.get(
      `${URL}/api/calendar/enrolledUsersforEvent/${eventName}`,
    );
    return response.data;
  } catch (error) {
    console.log('Error fetching enrolled users', error);
    throw error;
  }
};
