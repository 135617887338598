import React, { useState }  from 'react';
import axios from 'axios';
import ScormPlayer from '../CreateCourse/ScromPlayer';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';


const ProgressModal = ({ open, progress, completed, onClose }) => (
  <Modal open={open} onClose={completed ? onClose : () => { }}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" component="h2">
        Uploading files, please wait...
      </Typography>
      <Box sx={{ width: '100%', mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography sx={{ mt: 2 }}>{`${Math.round(progress)}%`}</Typography>
    </Box>
  </Modal>
);

const UploadScorm = ({ onClose }) => {
  const [files, setFiles] = useState([]);
  const [uploadUrls, setUploadUrls] = useState({});
  const [scormUrl, setScormUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [scormFolderPath, setScormFolderPath] = useState('');
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  const onDrop = async (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
    for (const file of newFiles) {
      await requestUploadUrl(file.webkitRelativePath, file.type);
    }
  };

  const requestUploadUrl = async (filePath, fileType) => {
    try {
      const response = await axios.post('http://localhost:8000/api/uploadscrom', {
        fileName: filePath,
        fileType: fileType,
      });
      const uploadUrl = response.data.url;

      setUploadUrls(prevUrls => ({ ...prevUrls, [filePath]: response.data.url }));

      setScormFolderPath(response.data.path); // Save the folder path

    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async (file, url) => {
    try {
      const data = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(prevProgress => prevProgress + (percentCompleted / files.length));
        },
      });
      console.log('File uploaded successfully', data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
    setProgress(0);
    setCompleted(false);

    for (const file of files) {
      const url = uploadUrls[file.webkitRelativePath];
      if (url) {
        await uploadToS3(file, url);
      }
    }
    const mainScormFile = files.find(file => file.name === 'story.html');
    if (mainScormFile) {
      const baseUrl = new URL(uploadUrls[mainScormFile.webkitRelativePath]).origin;
      const scormUrl = `${baseUrl}/${scormFolderPath}${mainScormFile.webkitRelativePath}`;
      setScormUrl(scormUrl);

    }

    setCompleted(true);
    setLoading(false);

  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        {/* Grid container aligned to the left */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Scrom
            </Typography>
          </Grid>
        </Grid>

        {/* Buttons div aligned to the right */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            style={{
              color: '#0684FB',
              borderColor: '#ccc',
              marginRight: '10px',
              width: '180px',
            }}
          >
            Discard Changes
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#0684FB',
              color: 'white',
              borderColor: '#ccc',
            }}
          >
            Save
          </Button>
        </div>
      </div>
      <div style={{ marginRight: '10px', marginLeft: '10px' }}>
        <TextField
          variant="outlined"
          label="Title"
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          {/* <TextField
            variant="outlined"
            label="Upload Scorm Folder"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ borderRadius: 4 }}
                  >
                    Upload Folder
                    <input
                      type="file"
                      webkitdirectory="true"
                      directory="true"
                      multiple
                      style={{ display: 'none' }}
                    />
                  </Button>
                </InputAdornment>
              ),
            }}
            style={{ width: '100%', marginBottom: '10px' }}
          /> */}

          <input
            type="file"
            webkitdirectory="true"
            directory="true"
            multiple
            onChange={onDrop}
            style={{
              padding: '10px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginRight: '10px',
            }}
          />
          <Button
            variant="contained"
            onClick={handleSendMessage}
            disabled={files.length === 0 || loading}
          >
            Upload
          </Button>
        </Grid>
        <ProgressModal
        open={loading}
        progress={progress}
        completed={completed}
        onClose={() => setLoading(false)}
      />
        {scormUrl && 
        <>
         <Typography variant="h6" style={{marginTop:'5px', marginBottom:'5px'}}>Uploaded Scorm</Typography>
        <ScormPlayer scormUrl={scormUrl} />
        </>
        }
      </div>
    </div>
  );
};

export default UploadScorm;
