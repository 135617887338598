import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  StyledTabs,
  StyledTab,
} from "../../styles/Certificate/CertificateStyle"

const NotificationTabs = () => {
  const location = useLocation();
  return (
    <StyledTabs>
      <StyledTab
        label="User"
        value="/Users/admin"
        component={Link}
        to="/Users/admin"
      />
      <StyledTab
        label="Instructor"
        value="/Users/Instructors"
        component={Link}
        to="/Users/Instructors"
      />
      <StyledTab
        label="Learner"
        value="/Users/Learners"
        component={Link}
        to="/Users/Learners"
      />
    </StyledTabs>
  );
};

export default NotificationTabs;
