import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Switch,
  FormControlLabel,
  CircularProgress,
  Tooltip
} from '@mui/material';
import NotificationTabs from './NotificationTabs';
import CommonButton from '../Utilities/CommonButton';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchNotificationSettings,
  toggleNotificationSetting,
} from '../../features/notification/NotificationSlice';

import { Page } from '../../styles/Notification/NotificationListStyles';

import{
  HeadingH3
} from '../../styles/Common/CommonStyles';

import {
  WholePage,
  ButtonDiv,
} from '../../styles/Certificate/CertificateStyle';

import {
  SettingHeading,
  SettingDiv,
  SingleSetting,
} from '../../styles/Notification/NotificationStyles';
import { useTranslation } from 'react-i18next';

const NotificationSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, settings } = useSelector((state) => state.notification);

  useEffect(() => {
    if (!settings || settings.length === 0) {
    dispatch(fetchNotificationSettings());
    }
  }, [dispatch]);

  const adminSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Admin'),
  );
  const instructorSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Instructor'),
  );
  const learnerSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Learner'),
  );
  const courseReviewerSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Course Reviewer'),
  );
  const courseDeveloperSettings = settings?.filter((s) =>
    s.roles.some((r) => r.role === 'Course Developer'),
  );

  const handleToggle = async (setting, role) => {
    const roleObject = setting.roles.find((r) => r.role === role);

    if (roleObject) {
      const newIsEnabled = !roleObject.isEnabled;

      try {
        await dispatch(
          toggleNotificationSetting({
            settingId: setting._id,
            role,
            isEnabled: newIsEnabled,
          }),
        );
        dispatch(fetchNotificationSettings());
      } catch (error) {
        console.error('Error toggling notification setting:', error);
      }
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <NotificationTabs />
      <WholePage style={{ marginTop: '35px' }}>
        {/* <Tooltip title='Create New Setting'>
        <ButtonDiv>
          <span>
          <CommonButton
            onClick={() => navigate('/Notification/createsettings')}
            label={t('notification.certificateCreateLabel')}
          />
          </span>
        </ButtonDiv>
        </Tooltip> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '26px',
            marginRight: '42px',
          }}
        >
          <Tooltip title="Create New Setting" placement="bottom">
            <span>
              {' '}
              {/* Span added to fix issues with non-button elements */}
              <CommonButton
                onClick={() => navigate('/Notification/createsettings')}
                label={t('notification.certificateCreateLabel')}
              />
            </span>
          </Tooltip>
        </div>

        <Page>
          {adminSettings?.length > 0 && <h3>{t('notification.adminLabel')}</h3>}

          <SettingDiv>
            <Grid container spacing={2}>
              {adminSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            setting.roles.find((r) => r.role === 'Admin')
                              ?.isEnabled
                          }
                          color="primary"
                          onChange={() => handleToggle(setting, 'Admin')}
                        />
                      }
                    />
                    <div>
                      <SettingHeading>{setting.settingsName}</SettingHeading>
                      <p>{setting.description}</p>
                    </div>
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>

          {instructorSettings?.length > 0 && (
            <h3>{t('notification.instructorLabel')}</h3>
          )}

          <SettingDiv>
            <Grid container spacing={2}>
              {instructorSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    <FormControlLabel
                      control={
                        <Switch
                          // checked={setting.isEnabled}
                          // color="primary"
                          checked={
                            setting.roles.find((r) => r.role === 'Instructor')
                              ?.isEnabled
                          }
                          color="primary"
                          onChange={() => handleToggle(setting, 'Instructor')}
                        />
                      }
                    />
                    <div>
                      <SettingHeading>{setting.settingsName}</SettingHeading>
                      <p>{setting.description}</p>
                    </div>
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>

          {learnerSettings?.length > 0 && <h3>{t('notification.Learner')}</h3>}

          <SettingDiv>
            <Grid container spacing={2}>
              {learnerSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            setting.roles.find((r) => r.role === 'Learner')
                              ?.isEnabled
                          }
                          color="primary"
                          onChange={() => handleToggle(setting, 'Learner')}
                        />
                      }
                    />
                    <div>
                      <SettingHeading>{setting.settingsName}</SettingHeading>
                      <p>{setting.description}</p>
                    </div>
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>

          {courseReviewerSettings?.length > 0 && (
            <h3>{t('notification.courseReviewLabel')}</h3>
          )}
          <SettingDiv>
            <Grid container spacing={2}>
              {courseReviewerSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            setting.roles.find(
                              (r) => r.role === 'Course Reviewer',
                            )?.isEnabled
                          }
                          color="primary"
                          onChange={() =>
                            handleToggle(setting, 'Course Reviewer')
                          }
                        />
                      }
                    />
                    <div>
                      <SettingHeading>{setting.settingsName}</SettingHeading>
                      <p>{setting.description}</p>
                    </div>
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>
          {courseDeveloperSettings?.length > 0 && (
            <h3>{t('notification.courseDeveloper')}</h3>
          )}
          <SettingDiv>
            <Grid container spacing={2}>
              {courseDeveloperSettings?.map((setting, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <SingleSetting>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            setting.roles.find(
                              (r) => r.role === 'Course Developer',
                            )?.isEnabled
                          }
                          color="primary"
                          onChange={() =>
                            handleToggle(setting, 'Course Developer')
                          }
                        />
                      }
                    />
                    <div>
                      <SettingHeading>{setting.settingsName}</SettingHeading>
                      <p>{setting.description}</p>
                    </div>
                  </SingleSetting>
                </Grid>
              ))}
            </Grid>
          </SettingDiv>
        </Page>
      </WholePage>
    </>
  );
};

export default NotificationSettings;
