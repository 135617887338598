import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@mui/material/TextField';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CommonButton from '../Utilities/CommonButton';
import UserData from '../../store/UsersData.json';
import { UserTable } from '../../styles/Users/UsersStyles';
import BackButton from '../Utilities/BackButton';

import { EventTitle, Title } from '../../styles/Calendar/CalendarStyle';
import {
  WholePage,
  TableButtonDiv,
  SeparateTableButton,
  SearchEmail,
} from '../../styles/Certificate/CertificateStyle';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  fetchUser,
  fetchRole,
} from '../../features/userModule/userModuleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '@mui/material/styles/createTransitions';
import PParse from 'papaparse';
import SuccessPopup from '../Utilities/SuccessPopup';
import { useTranslation } from 'react-i18next';
import { enrollUsers } from '../../features/calendar/CalendarSlice';
import { useParams } from 'react-router-dom';

const EnrolledUsers = () => {
  const { enrolluser, eventDetails } = useSelector((state) => state.calendar);
  // const { eventName } = useParams();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { users, status } = useSelector((state) => state.users);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { roles } = useSelector((state) => state.users) || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);


  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
  }, [dispatch, roles.data]);

  const handleBack = () => {
    navigate('/EventsNavigator');
  };

  const filteredData = Array.from(users).filter((user) => {
    const searchTerm = searchQuery.toLowerCase();
    const lowerCaseFirstName = user.firstname
      ? user.firstname.toLowerCase()
      : '';
    const lowerCaseLastName = user.lastname ? user.lastname.toLowerCase() : '';
    const lowerCaseEmail = user.email ? user.email.toLowerCase() : '';
    const lowerCaseRole = user.role ? user.role.toLowerCase() : '';

    return (
      (lowerCaseFirstName.includes(searchTerm) ||
        lowerCaseLastName.includes(searchTerm) ||
        lowerCaseEmail.includes(searchTerm) ||
        lowerCaseRole.includes(searchTerm)) &&
      user.role === 'Learner'
    );
  });
  const { t } = useTranslation();

  const handleEnrollClick = async () => {
    if (selectedUsers.length === 0) {
      alert('Please select at least one user to enroll.');
      return;
    }

    try {
      const emails = selectedUsers.map((user) => user.email);
      await dispatch(
        enrollUsers({ eventName: eventDetails.EventName, emails }),
      );

      setIsModalOpen(true); // Show success popup
    } catch (error) {
      console.error('Error enrolling users:', error.message);
      // Handle error
    }
  };
  const handleRowSelected = ({ selectedRows }) => {
    console.log('Selected rows:', selectedRows);

    setSelectedUsers(selectedRows);
  };
  const columns = [
    {
      name: t('calender.firstName'),
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: t('calender.lastName'),
      selector: (row) => row.lastname,
      sortable: true,
    },
    { name: t('calender.Email'), selector: (row) => row.email, sortable: true },
    { name: t('calender.Role'), selector: (row) => row.role, sortable: true },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <EventTitle>
        <BackButton onClick={handleBack} />
        <Title>{eventDetails?.EventName}</Title>
      </EventTitle>
      <WholePage>
        <br />
        <SearchEmail onSubmit={handleFormSubmit}>
          <TextField
            label={t('calender.searchLabel')}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: '300px', marginBottom: '3px', marginLeft: '48px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <TableButtonDiv>
            <Tooltip title="Enroll User for the Event">
              <SeparateTableButton>
                <CommonButton
                  label={t('calender.enrollText')}
                  onClick={handleEnrollClick}
                />
              </SeparateTableButton>
            </Tooltip>
          </TableButtonDiv>
        </SearchEmail>

        <UserTable>
          <TableUtilityLib
            columns={columns}
            data={filteredData}
            // onRowClicked={handleRowClick}
            selectableRows={true} // Disable checkboxes
            selectableRowsHighlight={true} // Disable checkbox highlight
            onSelectedRowsChange={handleRowSelected}
          />

          <SuccessPopup
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </UserTable>
        <SuccessPopup isOpen={isModalOpen} onClose={handleCloseModal} />
      </WholePage>
    </>
  );
};

export default EnrolledUsers;
