// import React, { useState } from 'react';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Chip } from '@mui/material';
// import CoursesData from "../../../store/CoursesData.json";
// import { Autocomplete } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import {addModule} from '../../../features/courses/addCourseDetailsSlice';

// const AddModuleDialog = ({ open, onClose }) => {
//     const [moduleName, setModuleName] = useState({
//         id: "",
//         title : "",
//         lessons:[],

//     });
//   const dispatch = useDispatch();
//   const { modules } = useSelector((state) => state?.addCourseDetails);
    
//     const [selectedOption, setSelectedOption] = useState('');
//     const [selectedModules, setSelectedModules] = useState([]);

   

    
//   const handleModuleNameChange = (e) => {
//     const newTitle = e.target.value;

//     // Update the title property of the moduleName object
//     setModuleName({
//       ...moduleName,
//       id : generateRandomNumber(),
//       title: newTitle
//     });


//   };

//   const generateRandomNumber = () => {
//     const min = 100000; // Minimum 6-digit number
//     const max = 999999; // Maximum 6-digit number
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   };

//     // const [courseModule, setCourseModule] = useState({
//     //     module: []
//     //   });
//     const handleSave =async () => {
//         // Send API request to save the new module
//         // Close the dialog
//         // setCourseModule((prevCourseModule) => ({
//         //     module: [...prevCourseModule.module, moduleName],
//         //   }));
//         console.log("hiii");

//         console.log(moduleName);
//       await  dispatch(addModule(moduleName))
//         onClose();
//     };

//     const handleOptionChange = (event) => {
//         setSelectedOption(event.target.value);
//     };

//     const handleModuleSelect = (event, value) => {
//         setSelectedModules(value);
//     };

//     const handleRemoveModule = (module) => {
//         setSelectedModules((prevSelectedModules) => prevSelectedModules.filter((item) => item !== module));
//     };

//     // Extract only the titles from CoursesData
//     const moduleTitles = CoursesData.map((module) => module.title);

//     return (
//         <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
//             <DialogTitle>Module Title</DialogTitle>
//             <DialogContent>
//                 <TextField
//                     autoFocus
//                     margin="dense"
//                     id="moduleName"
//                     label="Module Name"
//                     type="text"
//                     fullWidth
//                     value={moduleName.title}
//                     onChange={handleModuleNameChange} 
//                 />
//                 <FormControl component="fieldset" style={{ marginTop: '16px' }}>
//                     <RadioGroup
//                         aria-label="moduleOption"
//                         name="moduleOption"
//                         value={selectedOption}
//                         onChange={handleOptionChange}
//                     >
//                         <FormControlLabel
//                             value="copy"
//                             control={<Radio />}
//                             label="Copy Module"
//                         />
//                     </RadioGroup>
//                 </FormControl>
                
//                 {selectedOption === 'copy' && (
//                     <FormControl fullWidth style={{ marginTop: '16px' }}>
//                         <Autocomplete
//                             multiple
//                             options={moduleTitles}
//                             value={selectedModules}
//                             onChange={handleModuleSelect}
//                             renderInput={(params) => (
//                                 <TextField {...params} label="Select Module" variant="outlined" />
//                             )}
//                         />
//                     </FormControl>
//                 )}
//                 <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
//                     {selectedModules.map((module) => (
//                         <Chip
//                             key={module}
//                             label={module}
//                             onDelete={() => handleRemoveModule(module)}
//                             style={{ marginRight: '8px', marginBottom: '8px' }}
//                         />
//                     ))}
//                 </div>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={onClose}>Cancel</Button>
//                 <Button onClick={handleSave}>Submit</Button>
//             </DialogActions>
//         </Dialog>
//     );
// };

// export default AddModuleDialog;

import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, TextField, Button, FormControl, RadioGroup, FormControlLabel, Radio, Chip, Autocomplete
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addModule, fetchCourseInSlice, fetchCourseDetailsForCopyInSlice } from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from "jodit-react";
import { useParams } from 'react-router-dom';

const AddModuleDialog = ({ onClose }) => {
  const [selectedOption, setSelectedOption] = useState('create');
  const [isCopy, setIsCopy] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);
  const [moduleName, setModuleName] = useState({
    id: "",
    title: "",
    description: "",
    number: "",
    EstTime: "",
    lessons: []
  });

  const dispatch = useDispatch();
  const { courseDetailsForCopy, coursesList } = useSelector((state) => state.addCourseDetails);
  const { id, courseStructure } = useParams();

  useEffect(() => {
    dispatch(fetchCourseInSlice());
  }, [dispatch]);

  const handleModuleNameChange = (e) => {
    const { name, value } = e.target;
    setModuleName({
      ...moduleName,
      id: generateRandomNumber(),
      courseStructure: courseStructure,
      [name]: value
    });
  };

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSave = async () => {
    console.log(moduleName , "moduleName");
    console.log(selectedModules , "copy model");

    if(selectedOption == 'copy'){
      await dispatch(addModule(selectedModules));

    }else {

      await dispatch(addModule(moduleName));
    }
    onClose();
  };

  const handleOptionChange = (event) => {
   
    setSelectedOption(event.target.value);
  };

  const handleCourseSelect = (event, value) => {
    

    setSelectedCourse(value);
    if (value) {
      dispatch(fetchCourseDetailsForCopyInSlice(value.id));
    }
  };

  const handleModuleSelect = (value) => {
    console.log(value ,"handleModuleSelect" )

    const modifiedValue = value.map(moduleObj => ({
      ...moduleObj,
      module: {
        ...moduleObj.module,
        id: generateRandomNumber(),// Replace generateNewId with your id generation logic
        title: "Copied " + moduleObj.module.title
      }
    }));
  
    console.log(modifiedValue, "Modified handleModuleSelect");
    setSelectedModules(modifiedValue);
  };

  const handleRemoveModule = (module) => {
    setSelectedModules((prevSelectedModules) => prevSelectedModules?.filter((item) => item !== module));
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "14px", maxHeight: '80vh', overflow: 'auto' }}>
      <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
        <RadioGroup
          aria-label="moduleOption"
          name="moduleOption"
          value={selectedOption}
          onChange={handleOptionChange}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value="create"
            control={<Radio />}
            label="Create Module"
          />
          <FormControlLabel
            value="copy"
            control={<Radio />}
            label="Copy Module"
          />
        </RadioGroup>
      </FormControl>
      <div style={{ borderBottom: '1px solid grey', marginBottom: '16px' }}></div>
      
      {selectedOption === 'create' && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: "8px", marginTop: "22px" }}>
              Module Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Name"
              variant="outlined"
              fullWidth
              name="title"
              value={moduleName.title}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Enter Description"
              variant="outlined"
              rows="5"
              multiline
              fullWidth
              name="description"
              value={moduleName.description}
              onChange={handleModuleNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Module Number
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Number"
              variant="outlined"
              fullWidth
              name="number"
              type="number"
              value={moduleName.number}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Estimated Time</Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Estimated Time"
              variant="outlined"
              fullWidth
              name="EstTime"
              value={moduleName.EstTime}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Learning Objectives</Typography>
          </Grid>
          <Grid item xs={12}>
            <JoditReact
              value={moduleName.objectives}
              config={{
                style: {},
              }}
            />
          </Grid>
        </Grid>
      )}

      {selectedOption === 'copy' && (
        <>
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <Autocomplete
              options={coursesList.map(course => ({ title: course.generalInformation.title, id: course._id }))}
              value={selectedCourse}
              onChange={handleCourseSelect}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label="Select Course" variant="outlined" />
              )}
            />
          </FormControl>
          {selectedCourse && (
            <>
             <FormControl fullWidth style={{ marginTop: '16px' }}>
  <Autocomplete
    multiple
    options={courseDetailsForCopy?.modules?.map(module => ({ module }))}
    value={selectedModules.module}
    onChange={(event, value) => handleModuleSelect(value)}
    getOptionLabel={(option) => option?.module?.title}
    renderInput={(params) => (
      <TextField {...params} label="Select Module" variant="outlined" />
    )}
  />
</FormControl>
              <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
                {selectedModules?.module?.map((module) => (
                  <Chip
                    key={module?.title}
                    label={module?.title}
                    onDelete={() => handleRemoveModule(module)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}

      <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} style={{ marginRight: '10px' }}>Cancel</Button>
        <Button variant="contained" onClick={handleSave} style={{ backgroundColor: "#0684FB", color: "white" }}>Submit</Button>
      </div>
    </div>
  );
};

export default AddModuleDialog;
