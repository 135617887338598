import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../Utilities/BackButton';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { HeadingH2, Text } from '../../styles/Common/CommonStyles';
import {
  Head,
  Details,
  ImgSection,
  Info,
  Profile,
  SubDetailDiv2,
  ImgContainer,
} from '../../styles/Users/UserDetailStyles';

import CommonButton from '../Utilities/CommonButton';
import {
  MainHeading,
  ButtonDiv,
  DetailsForm,
} from '../../styles/Certificate/CertificateStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  fetchUserDetails,
  updateUser,
  passwordReset,
} from '../../features/userModule/userModuleSlice';
import { toast } from 'react-toastify';

const UserDetails = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { users, userdetails, status, password } = useSelector(
    (state) => state.users,
  );
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [pass, setPass] = useState('');
  // const [file, setFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [token, setTokan] = useState({});
  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    password: '',
    phoneNo: '',
    picture: '',
    referredBy: '',
  });

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (userdetails) {
      console.log('User Details:', userdetails);
      setData({
        ...userdetails, // Ensure all fields from userdetails are populated
        picture: userdetails.picture || '',
        referredBy: userdetails.learnerDetails?.referredBy || '',
      });

      if (userdetails.picture) {
        setPreviewImage(userdetails.picture);
      }
    }
  }, [userdetails]);

  const handleTfChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setImage(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      requestUploadUrl(selectedFile);
    }
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/uploadUserImages',
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      setUploadUrl(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      await axios.put(uploadUrl, image, {
        headers: {
          'Content-Type': image.type,
        },
      });
      console.log('File uploaded successfully');

      const baseUrl = uploadUrl.split('?')[0];

      // Update local state with the updated picture URL
      setData((prevData) => ({
        ...prevData,
        picture: baseUrl,
      }));

      // Dispatch update with the updated data including picture URL
      await dispatch(
        updateUser({ id: userId, userdetails: { ...data, picture: baseUrl } }),
      )
        .unwrap()
        .then(() => {
          toast.success('User details saved successfully');
          navigate('/Users');
        })
        .catch((error) => console.error('Failed to save user details:', error));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleInfoChange = async () => {
    if (image) {
      await uploadToS3();
    }

    // Directly use `data` here, as it should have the updated picture URL
    await dispatch(
      updateUser({
        id: userId,
        userdetails: { ...data, picture: uploadUrl?.split('?')[0] },
      }),
    )
      .unwrap()
      .then(() => {
        toast.success('User details saved successfully');

        navigate('/Users');
      })
      .catch((error) => console.error('Failed to save user details:', error));
  };

  const handleDelete = () => {
    dispatch(deleteUser(userId))
      .unwrap()
      .then(() => {
        toast.success('User deleted successfully');

        navigate('/Users');
      })
      .catch((error) => {
        console.error('Failed to delete user:', error);
      });
  };

  const handleChangePassword = () => {
    dispatch(passwordReset(userdetails.email))
      .unwrap()
      .then(() => {
        toast.success('Password reset email sent successfully');
      })
      .catch((error) => {
        console.error('Failed to send password reset email:', error);
      });
  };

  return (
    <>
      <Details>
        <Head>
          <BackButton />
          <HeadingH2>User Details</HeadingH2>
        </Head>
        <Profile>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <ImgContainer
                style={{
                  border: '1px solid #ccc',
                  width: '60%',
                  paddingTop: '50%',
                  position: 'relative',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                {previewImage ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${previewImage ? previewImage : image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isHovered && (
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                        }}
                      >
                        <Button
                          onClick={() =>
                            document
                              .getElementById('contained-button-file')
                              .click()
                          }
                          variant="contained"
                          color="primary"
                        >
                          Change Image
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Button
                      onClick={() =>
                        document.getElementById('contained-button-file').click()
                      }
                      variant="contained"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </div>
                )}
              </ImgContainer>
            </Grid>
          </Grid>

          <Info>
            <TextField
              label="FirstName"
              variant="outlined"
              margin="normal"
              name="firstname"
              value={data.firstname}
              onChange={handleTfChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="LastName"
              variant="outlined"
              margin="normal"
              name="lastname"
              value={data.lastname}
              onChange={handleTfChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled
              label="Email"
              variant="outlined"
              margin="normal"
              name="email"
              value={data.email}
              onChange={handleTfChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Role"
              variant="outlined"
              margin="normal"
              name="role"
              value={data.role}
              onChange={handleTfChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Contact Info"
              variant="outlined"
              margin="normal"
              name="phoneNo"
              value={data.phoneNo}
              onChange={handleTfChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            {data.role === 'Learner' && (
              <TextField
                label="Referred By"
                variant="outlined"
                margin="normal"
                name="referredBy"
                value={data.referredBy}
                onChange={handleTfChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            <ButtonDiv>
              <CommonButton label="Save" onClick={handleInfoChange} />
            </ButtonDiv>
          </Info>
        </Profile>

        <MainHeading style={{ marginLeft: '30px', marginTop: '20px' }}>
          Change Password
        </MainHeading>
        <Profile>
          <Typography>
            Do you want to change the password, click on the send mail button to
            recieve the email.
          </Typography>
          <ButtonDiv>
            <CommonButton label="Send Mail" onClick={handleChangePassword} />
          </ButtonDiv>
        </Profile>
        <MainHeading style={{ marginLeft: '30px', marginTop: '20px' }}>
          Delete User
        </MainHeading>
        <Profile>
          <Typography>You agree to delete the User Profile</Typography>
          <ButtonDiv>
            <CommonButton label="Delete" onClick={handleDelete} />
          </ButtonDiv>
        </Profile>
      </Details>
    </>
  );
};

export default UserDetails;
