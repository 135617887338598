import React, { useEffect } from 'react';
import { EventTitle, Title } from '../../styles/Calendar/CalendarStyle';
import { useNavigate } from 'react-router-dom';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import { UserTable, HistoryPage, Header } from '../../styles/Users/UsersStyles';
import BackButton from '../Utilities/BackButton';
import CommonButton from '../Utilities/CommonButton';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserHistory } from '../../features/userModule/userModuleSlice';

const HistoryofImports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { history } = useSelector((state) => state.users) || {};

  const handleBack = () => {
    navigate('/users');
  };
  useEffect(() => {
    dispatch(fetchUserHistory());
  }, [dispatch]);

  const data = history ? [...history].reverse() : [];

  const extractFileName = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    return pathname.substring(pathname.lastIndexOf('/') + 1);
  };

  const columns = [
    { name: 'Record No.', selector: (row) => row.SrNo, sortable: true },
    {
      name: 'Successful Records',
      selector: (row) => row.SuccessfulRecords,
      sortable: true,
    },
    {
      name: 'Failed Records',
      selector: (row) => row.FailedRecords,
      sortable: true,
    },
    {
      name: 'Total Records',
      selector: (row) => row.TotalRecords,
      sortable: true,
    },

    {
      name: 'Time of Action',
      selector: (row) =>
        format(new Date(row.TimeofAction), 'MM/dd/yyyy, hh:mm a'),
      // selector: (row) => row.TimeofAction,

      sortable: true,
    },
    {
      name: 'SuccessFiles',
      cell: (row) => (
        <a href={row.SuccessFilePath} download>
          Download
        </a>
      ),
      // cell: (row) => {
      //   const fileName = extractFileName(row.SuccessFilePath);
      //   return (
      //     <a href={row.SuccessFilePath} download={fileName}>
      //       {fileName}
      //     </a>
      //   );
    },
    {
      name: 'FailedFiles',
      cell: (row) => (
        <a href={row.FailureFilePath} download>
          Download
        </a>
      ),
      // cell: (row) => {
      //   const fileName = extractFileName(row.FailureFilePath);
      //   return (
      //     <a href={row.FailureFilePath} download={fileName}>
      //       {fileName}
      //     </a>
      //   );
    },
  ];

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // Set the download attribute
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <HistoryPage>
      <EventTitle style={{ marginTop: '20px', marginBottom: '37px' }}>
        <Header style={{ marginLeft: '-59px' }}>
          <BackButton
            onClick={handleBack}
            style={{ marginTop: '10px', marginLeft: '30px' }}
          />
        </Header>
        <Title>History of Imports</Title>
      </EventTitle>

      <UserTable>
        <TableUtilityLib columns={columns} data={data} />
      </UserTable>
    </HistoryPage>
  );
};

export default HistoryofImports;
