import { hot } from "react-hot-loader/root";
// import { Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import Landing from "./pages/Landing";
// import About from "./pages/About";
// import Services from "./pages/Services";
// import Contact from "./pages/Contact";
// import Footer from "./components/Footer";
// import Login from "./pages/Login";
// import Signup from "./pages/Signup";
// import Dashboard from "./components/Dashboard";
import LandingHome from "./components/Landing/LandingHome";
import { useEffect } from "react";
import { loginUser } from "./api/AuthenticationApi";
import { useNavigate } from "react-router-dom";

function App() {
  return (
    <div style={{ height: "100%" }}>
      <LandingHome />
      {/* <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Dashboard" element={<Dashboard />} />
      </Routes>
      <Footer /> */}
    </div>
  );
}

export default hot(App);
