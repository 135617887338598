import React from 'react';
import LearnerTabs from './LearnerTabs';
import TextField from '@mui/material/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import CommonButton from '../Utilities/CommonButton';
import Chip from '@mui/material/Chip';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CertificateData from '../../store/CertificateData.json';

import { useNavigate } from 'react-router-dom';
import {
  WholePage,
  SearchEmail,
  TableDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';

const LearnerEligibleCertificate = () => {
  const columns = [
    { name: 'Course Name', selector: (row) => row.cname, sortable: true },
    { name: 'Event Name', selector: (row) => row.ename, sortable: true },
    // { name: 'Status', selector: (row) => row.status, sortable: true   },
    { name: 'Status', selector: (row) => (<Chip
      // key={index}
      label={row.status}
      color={row.status === 'Paid' ? 'primary' : 'success'}
      variant="outlined"
      style={{ marginRight: '5px', marginBottom: '5px' }}
    />)  },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Tooltip title="View">
            <CommonButton label="View" />
          </Tooltip>
          &nbsp;
          <Tooltip title="Enroll">
            <CommonButton label="Enroll" />
          </Tooltip>
        </>
      ),
    },
  ];
  const navigate = useNavigate();
  const handleViewCourse = () => {
    navigate('/LandingPageDetails');
  };

  const { t } = useTranslation();
  return (
    <>
      <LearnerTabs />
      <br />
      <WholePage>
        <SearchEmail>
          <TextField
            id="outlined-basic"
            label={t("certificate.searchText")}
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchEmail>
        <TableDiv>
          <TableUtilityLib
            columns={columns}
            data={CertificateData}
          />
        </TableDiv>
      </WholePage>
    </>
  );
};

export default LearnerEligibleCertificate;
