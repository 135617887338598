import React, { useState, useEffect } from 'react';
import LearnerNotificationTabs from '../Notifications/LearnerNotificationTabs';
import { Delete, Archive, CheckCircle, Star } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';

import { WholePage } from '../../styles/Certificate/CertificateStyle';

import {
  NotificationContainer,
  NotificationContent,
  NotificationSender,
  NotificationActions,
  ActionIcon,
  ActionButton,
} from '../../styles/Notification/NotificationStyles';
import { Text } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';


const Notification = ({
  id,
  message,
  sender,
  timestamp,
  onArchive,
  onDelete,
  onMarkAsRead,
  isOpen,
  isImportantProp,
  handleClick,
}) => {
  const [isRead, setIsRead] = useState(false);
  const [isImportant, setIsImportant] = useState(false);
  const navigate = useNavigate();

  const handleArchive = () => {
    onArchive();
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleMarkAsRead = () => {
    setIsRead(true);
    onMarkAsRead(id);
  };

  const handleToggleImportant = (event) => {
    event.stopPropagation();
    setIsImportant(!isImportant);
  };

  const handleClickEvent = (event, message) => {
    event.preventDefault();
    navigate(`/LearnerNotification/DetailedArchivedNotification/${id}`, {
      state: { message },
    });
  };

  const { t } = useTranslation();
  return (
    <NotificationContainer
      className={`${isRead ? 'read' : ''} ${isImportant ? 'important' : ''}`}
      onClick={(event) => handleClickEvent(event, message)}
    >
      <NotificationContent>
        <NotificationSender>
          <Tooltip
            title={isImportant ? 'Remove from Important' : 'Mark as Important'}
            arrow
          >
            <ActionIcon>
              <Star
                onClick={handleToggleImportant}
                style={{
                  cursor: 'pointer',
                  color: isImportant ? 'gold' : 'grey',
                  marginTop: '4px',
                }}
              />
            </ActionIcon>
          </Tooltip>
          <Text>{sender}</Text>
        </NotificationSender>
        <Text>{message}</Text> &nbsp;&nbsp;
        <Text>{t("notification.ReceivedAt")} {new Date(timestamp).toLocaleTimeString()}</Text>
      </NotificationContent>
      <NotificationActions>
        <Tooltip title={t("notification.archievedLabel")} arrow>
          <ActionButton>
            <Archive onClick={handleArchive} style={{ cursor: 'pointer' }} />
          </ActionButton>
        </Tooltip>
        <Tooltip title={t("notification.deleteLabel")} arrow>
          <ActionButton>
            <Delete onClick={handleDelete} style={{ cursor: 'pointer' }} />
          </ActionButton>
        </Tooltip>
      </NotificationActions>
    </NotificationContainer>
  );
};

const ArchivedNotification = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [openedNotification, setOpenedNotification] = useState(null);
  const [showImportantOnly, setShowImportantOnly] = useState(false);
  const [chipClicked, setChipClicked] = useState(false);

  const notifications = [
    {
      id: 1,
      subject: 'Notification 1',
      message: 'Archived notification 1 ',
      sender: 'Admin',
      timestamp: Date.now() - 1000 * 60 * 15, // 15 minutes ago
      isRead: true,
      isImportant: true,
    },
    {
      id: 2,
      subject: 'Notification 2',
      message: 'Archived notification 2',
      sender: 'John Doe',
      timestamp: Date.now() - 1000 * 60 * 30, // 30 minutes ago
      isRead: false,
      isImportant: false,
    },
    {
      id: 3,
      subject: 'Notification 3',
      message: 'Archived notification 3',
      sender: 'Jane Doe',
      timestamp: Date.now() - 1000 * 60 * 45, // 45 minutes ago
      isRead: true,
      isImportant: true,
    },
  ];

  const handleNotificationArchive = () => {
    console.log('Notification archived');
  };

  const handleNotificationDelete = () => {
    console.log('Notification deleted');
  };

  const handleNotificationMarkAsRead = (id) => {
    console.log(`Notification ${id} marked as read`);
  };

  const toggleNotification = (id) => {
    if (openedNotification === id) {
      setOpenedNotification(null);
    } else {
      setOpenedNotification(id);
    }
  };

  // Function to filter notifications based on search term
  const handleSearch = (searchTerm) => {
    let filtered = notifications.filter(
      (notification) =>
        notification.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
        notification.sender.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    if (showImportantOnly) {
      filtered = filtered.filter((notification) => notification.isImportant);
    }

    setFilteredNotifications(filtered);
  };

  // Update filtered notifications when search term or showImportantOnly changes
  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, showImportantOnly]);

  const handleClick = (event, message) => {
    event.preventDefault();
    // Navigation logic
  };

  const { t } = useTranslation();
  return (
    <>
      <LearnerNotificationTabs />
      <WholePage>
        <br />
        <div style={{ display: 'flex' }}>
          <TextField
            label={t("notification.searchNotificationLabel")}
            size="small"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '300px', marginBottom: '16px', marginLeft: '44px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Chip
            label={t("notification.important")}
            color="primary"
            style={{ marginLeft: '25px', marginTop: '5px' }}
            variant={chipClicked ? 'filled' : 'outlined'}
            onClick={() => {
              setShowImportantOnly(!showImportantOnly);
              setChipClicked(!chipClicked);
            }}
          />
        </div>

        {filteredNotifications.map((notification) => (
          <Notification
            key={notification.id}
            {...notification}
            onArchive={handleNotificationArchive}
            onDelete={handleNotificationDelete}
            onMarkAsRead={handleNotificationMarkAsRead}
            isOpen={openedNotification === notification.id}
            toggleOpen={toggleNotification}
            isImportantProp={notification.isImportant}
            handleClick={(event) => handleClick(event, notification.message)}
          />
        ))}
      </WholePage>
    </>
  );
};

export default ArchivedNotification;
