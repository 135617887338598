import styled from 'styled-components';

export const CertificateWrapper = styled.div`
  display: flex;
  // width: 92%;
  // margin: auto;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  justify-content: space-between;
`;

export const CertificateInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 20px; /* Adjust based on your layout */
`;

export const ActionButton = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid #ccc;
  height: 80px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
`;
