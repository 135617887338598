import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SliderImg from '../../images/SliderImg.png';
import {
  SliderContainer,
  SlideWrapper,
  Slide,
  ContentContainer,
  Image,
  CarousalTextHead,
  CarousalTextHeadSub,
  CarousalTextDesc,
  ContentSub,
  MainHeadCont,
  Desc,
  Title,
  MainDesc,
  HeaderImage,
} from '../../styles/Landing/CarousalStyle';

import TypewriterComponent from 'typewriter-effect';
import { useTranslation } from 'react-i18next';

// import Typical from 'react-typical';

const slidesData = [
  {
    id: 1,
    title: 'Leapot Technologies',
    titleSub: 'Efficiency Empowers Excellence.',
    description:
      'At Leapot Technologies, we specialize in revolutionizing education and training with our comprehensive suite of services. From our state-of-the-art Learning Management System to our expertly crafted Digital Content Development Solutions, we empower educational institutions, businesses, and organizations globally to thrive in the digital age. With a relentless dedication to innovation and a proven history of delivering excellence, we also offer top-tier Software Product Development and Staffing Services to ensure our clients stay ahead of the curve.. ',
    image: SliderImg,
    color: '#389dfc',
  },
  {
    id: 2,
    title: 'Leapot Technologies',
    titleSub: 'Efficiency Empowers Excellence.',
    description:
      'At Leapot Technologies, we specialize in revolutionizing education and training with our comprehensive suite of services. From our state-of-the-art Learning Management System to our expertly crafted Digital Content Development Solutions, we empower educational institutions, businesses, and organizations globally to thrive in the digital age. With a relentless dedication to innovation and a proven history of delivering excellence, we also offer top-tier Software Product Development and Staffing Services to ensure our clients stay ahead of the curve.. ',
    image: SliderImg,
    color: '#6ab6fd',
  },
  {
    id: 3,
    title: 'Leapot Technologies',
    titleSub: 'Efficiency Empowers Excellence.',
    description:
      'At Leapot Technologies, we specialize in revolutionizing education and training with our comprehensive suite of services. From our state-of-the-art Learning Management System to our expertly crafted Digital Content Development Solutions, we empower educational institutions, businesses, and organizations globally to thrive in the digital age. With a relentless dedication to innovation and a proven history of delivering excellence, we also offer top-tier Software Product Development and Staffing Services to ensure our clients stay ahead of the curve.. ',
    image: SliderImg,
    color: '#389dfc',
  },
];

const CarouselDefault = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slidesData.length;
  const { t } = useTranslation();

  return (
    <SliderContainer>
      <MainHeadCont>
        <MainDesc>
          <Title>
            <TypewriterComponent
              options={{
                autoStart: true,
                loop: true,
                delay: 40,
                strings: ['Efficiency Empowers Excellence'],
              }}
            />
          </Title>
          <Desc>
            {t("landing.carouselDefaultText")}
          </Desc>
        </MainDesc>
        <HeaderImage src={SliderImg} />
      </MainHeadCont>

      <svg viewBox="0 0 500 50">
        <path
          d="M 0 25 C 150 75 300 0 500 40 L 500 0 L 0 0"
          fill="#0684fb"
        ></path>
      </svg>
    </SliderContainer>
  );
};

export default CarouselDefault;

