import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { saveBasicSettingApi , courseAppearceSettingApi } from '../../api/CoursesApi';

export const basicSetting = createAsyncThunk(
    'basicSetting',
    async (data, { rejectWithValue }) => {
      console.log('hii from redux basicSetting', data);
      const result = await saveBasicSettingApi(data);
      return result;
    }
  );
  export const courseAppearceSetting = createAsyncThunk(
    'courseAppearceSetting',
    async (data, { rejectWithValue }) => {
      console.log('hii from redux courseAppearceSetting', data);
      const result = await courseAppearceSettingApi(data);
      return result;
    }
  );


const CourseUtilitySlice = createSlice({
  name: 'CourseUtility',
  initialState: {
    basicSetting: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(basicSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(basicSetting.fulfilled, (state, action) => {
        state.basicSetting = action.payload;
        state.loading = false;
      })
      .addCase(basicSetting.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default CourseUtilitySlice.reducer;

