import React from 'react';
import { Grid, Button } from '@mui/material';

const PreviewTopicDownload = (props) => {
  const files = props.item.files || []; // Provide a default value of an empty array if files is undefined

  const getFileTypeIcon = (type) => {
    // You can add more file types and corresponding icons as needed
    switch (type) {
      case 'pdf':
        return '1'; // Replace with an actual icon if needed
      case 'pptx':
        return '2'; // Replace with an actual icon if needed
      case 'txt':
        return '3'; // Replace with an actual icon if needed
      case 'video':
        return '4'; // Replace with an actual icon if needed
      case 'audio':
        return '5'; // Replace with an actual icon if needed
      default:
        return 'none'; // Replace with an actual icon if needed
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          margin: '20px 20px 10px 0', // Adjust margin as needed
          padding: '10px', // Adjust padding as needed
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add box shadow
          overflow: 'auto', // Enable scrolling if content overflows
          textAlign: 'justify', // Justify text content
        }}
      >
        <h3>{props.item.title}</h3>
      </Grid>
      <div style={{ padding: "10px" }}>
        {files.map((file, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ marginRight: '10px' }}>{getFileTypeIcon(file.type)}</span>
            <span style={{ flexGrow: 1 }}>{file.name}</span>
            <Button
              variant="outlined"
              color="primary"
              href={file.link}
              download
            >
              Download
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviewTopicDownload;
