


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveCourseDetailsApi, fetchCourseDetailsApi, fetchCourseApi , CreateCourseApi ,createCourseByIdApi } from '../../api/CoursesApi';

export const updateModule = createAsyncThunk(
  'updateModule',
  async (data, { rejectWithValue }) => {
    console.log('Updating module in redux', data);
    return data;
  }
);

export const addModule = createAsyncThunk(
  'addModule',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux addModule', data);
    return data;
  }
);

export const fetchCourseInSlice = createAsyncThunk(
  'fetchCourseInSlice',
  async (data, { rejectWithValue }) => {
    const result = await fetchCourseApi();
    console.log(result.data, 'fetchCourseDetailsInSlice');
    return result.data.data;
  }
);

export const fetchCourseById = createAsyncThunk(
  'fetchCourseById',
  async (data, { rejectWithValue }) => {
    const result = await createCourseByIdApi(data);

    console.log(data , "fetchCourseById")
    return result.data.data;;
  }
);

export const createCourseInSlice = createAsyncThunk(
  'createCourseInSlice',
  async (data, { rejectWithValue }) => {
    const result = await CreateCourseApi(data);
    console.log(result.data, 'createCourseInSlice');
    return result.data.data;
  }
);

export const fetchCourseDetailsInSlice = createAsyncThunk(
  'fetchCourseDetailsInSlice',
  async (data, { rejectWithValue }) => {
    console.log(data, fetchCourseDetailsInSlice);
    const result = await fetchCourseDetailsApi(data);
    console.log(result.data.data, 'fetchCourseDetailsInSlice');
    return result.data.data;
  }
);

export const fetchCourseDetailsForCopyInSlice = createAsyncThunk(
  'fetchCourseDetailsForCopyInSlice',
  async (data, { rejectWithValue }) => {
    console.log(data, fetchCourseDetailsInSlice);
    const result = await fetchCourseDetailsApi(data);
    console.log(result.data.data, 'fetchCourseDetailsInSlice');
    return result.data.data;
  }
);

export const saveDetails = createAsyncThunk(
  'saveDetails',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux saveDetails', data);
    const result = await saveCourseDetailsApi(data);
    console.log(result, 'saveDetails result');
    return result;
  }
);

export const deleteModule = createAsyncThunk(
  'deleteModule',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux addModule', data);
    return data;
  }
);

export const addLesson = createAsyncThunk(
  'addLesson',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux addLesson', data);
    return data;
  }
);
export const updateLesson = createAsyncThunk(
  'updateLesson',
  async (data, { rejectWithValue }) => {
    console.log('Updating lesson in redux', data);
    return data;
  }
);

export const deleteLesson = createAsyncThunk(
  'deleteLesson',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux deleteLesson', data);
    return data;
  }
);

export const addItem = createAsyncThunk(
  'addItem',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux addItem', data);
    return data;
  }
);

export const deleteTopic = createAsyncThunk(
  'deleteTopic',
  async (data, { rejectWithValue }) => {
    console.log('hii from redux deleteTopic', data);
    return data;
  }
);

export const addCourseDetails = createSlice({
  name: 'addCourseDetails',
  initialState: {
    courseDetails: {
      modules: [],
      lessons: []
    },
    courseDetailsForCopy: {
      modules: [],
      lessons: []
    },
    coursesList: [],
    loading: false,
    error: null,
    searchData: [],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder

    .addCase(createCourseInSlice.pending, (state) => {
      state.loading = true;
    })
    .addCase(createCourseInSlice.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload, 'createCourseInSlice in slice');
      state.coursesList.push(action.payload);
    })
    .addCase(createCourseInSlice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    })

      // add module reducers
      .addCase(addModule.pending, (state) => {
        state.loading = true;
      })
      .addCase(addModule.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload , "dhfsifbdfjh")
        let newModules;

        if (Array.isArray(action.payload)) {
          // If payload is an array, map over it to extract modules if present
          newModules = action.payload.map(item => item.module ? item.module : item);
        } else {
          // If payload is a single object, use it directly
          newModules = action.payload.module ? [action.payload.module] : [action.payload];
        }
      
        if (state.courseDetails?.modules) {
          // If modules array exists, concatenate it with the newModules array
          state.courseDetails.modules = [...state.courseDetails.modules, ...newModules];
        } else {
          // If modules array does not exist, initialize it with the newModules array
          state.courseDetails.modules = newModules;
        }
      })
      .addCase(addModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      //fetch courses reducers
      .addCase(fetchCourseInSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCourseInSlice.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, 'fetchCourseInSlice in slice');
        state.coursesList = action.payload;
      })
      .addCase(fetchCourseInSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })


      //fetch course by id reducers
      .addCase(fetchCourseById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, 'fetchCourseInSlice in slice');
        
        const duplicateData = state.coursesList.find(course => course._id == action.payload);
        console.log(duplicateData , "duplicateData")
        if (duplicateData) {
            state.coursesList.push({...duplicateData});
        }
    })
      .addCase(fetchCourseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // fetch course details reducers
      .addCase(fetchCourseDetailsInSlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCourseDetailsInSlice.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, "fetchCourseDetailsInSlice");

        if (action.payload) {
          state.courseDetails.modules = action.payload.modules || [];
          state.courseDetails.lessons = action.payload.lessons || [];
        }
      })
      .addCase(fetchCourseDetailsInSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })


        // fetch course details for copy reducers
        .addCase(fetchCourseDetailsForCopyInSlice.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchCourseDetailsForCopyInSlice.fulfilled, (state, action) => {
          state.loading = false;
          console.log(action.payload, "fetchCourseDetailsForCopyInSlice");
  
          if (action.payload) {
            state.courseDetailsForCopy.modules = action.payload.modules || [];
            state.courseDetailsForCopy.lessons = action.payload.lessons || [];
          }
        })
        .addCase(fetchCourseDetailsForCopyInSlice.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload.message;
        })
      
        // update module reducers
        .addCase(updateModule.pending, (state) => {
          state.loading = true;
        })
        .addCase(updateModule.fulfilled, (state, action) => {
          state.loading = false;
          const updatedModule = action.payload;
          const index = state.courseDetails.modules.findIndex(
            (module) => module.id === updatedModule.id
          );
          if (index !== -1) {
            state.courseDetails.modules[index] = updatedModule;
          } else {
            console.error('Module not found for update');
          }
        })
        .addCase(updateModule.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload.message;
        })

      // delete module reducers
      .addCase(deleteModule.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        state.loading = false;
        const deletedModuleId = action.payload;
        state.courseDetails.modules = state.courseDetails.modules.filter(module => module.id !== deletedModuleId);
      })
      .addCase(deleteModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // add lesson reducers
      .addCase(addLesson.pending, (state) => {
        state.loading = true;
      })
//       .addCase(addLesson.fulfilled, (state, action) => {
//         state.loading = false;

//         const { moduleId ,courseStructure } = action.payload;
// if(courseStructure === 'CMLT') {

//         const moduleIndex = state.courseDetails.modules.findIndex(
//           (module) => module.id === moduleId
//         );
//         console.log(moduleIndex, 'moduleIndex');
//         if (moduleIndex !== -1) {
//           state.courseDetails.modules[moduleIndex].lessons.push(action.payload);
//         } else {
//           console.log('module not found');
//         }
//       } else {
//         state.courseDetails?.lessons?.push(action.payload);
//       }
//       })

.addCase(addLesson.fulfilled, (state, action) => {
  state.loading = false;

  let newLessons;
  let moduleId, courseStructure;

  // Determine if the payload is an array or a single object and handle accordingly
  if (Array.isArray(action.payload)) {
    newLessons = action.payload;
    // Assuming moduleId and courseStructure are the same for all objects in the array
    moduleId = action.payload[0].moduleId;
    courseStructure = action.payload[0].courseStructure;
  } else {
    newLessons = [action.payload];
    moduleId = action.payload.moduleId;
    courseStructure = action.payload.courseStructure;
  }

  if (courseStructure === 'CMLT') {
    const moduleIndex = state.courseDetails.modules.findIndex(
      (module) => module.id === moduleId
    );

    console.log(moduleIndex, 'moduleIndex');

    if (moduleIndex !== -1) {
      if (!state.courseDetails.modules[moduleIndex].lessons) {
        state.courseDetails.modules[moduleIndex].lessons = [];
      }

      state.courseDetails.modules[moduleIndex].lessons.push(...newLessons);
    } else {
      console.log('module not found');
    }
  } else {
    if (!state.courseDetails.lessons) {
      state.courseDetails.lessons = [];
    }

    state.courseDetails.lessons.push(...newLessons);
  }
})


      .addCase(addLesson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      //update lesson reducers
      .addCase(updateLesson.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLesson.fulfilled, (state, action) => {
        state.loading = false;
        const updatedLesson = action.payload;
        const moduleIndex = state.courseDetails.modules.findIndex(
          (module) => module.id === updatedLesson.moduleId
        );
        if (moduleIndex !== -1) {
          const lessonIndex = state.courseDetails.modules[moduleIndex].lessons.findIndex(
            (lesson) => lesson.id === updatedLesson.id
          );
          if (lessonIndex !== -1) {
            state.courseDetails.modules[moduleIndex].lessons[lessonIndex] = updatedLesson;
          } else {
            console.error('Lesson not found for update');
          }
        } else {
          console.error('Module not found for update');
        }
      })
      .addCase(updateLesson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // delete lesson reducers
      .addCase(deleteLesson.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLesson.fulfilled, (state, action) => {
        state.loading = false;
        const { moduleId, lessonId } = action.payload;
        const moduleIndex = state.courseDetails.modules.findIndex(module => module.id === moduleId);
        if (moduleIndex !== -1) {
          const lessonIndex = state.courseDetails.modules[moduleIndex].lessons.findIndex(lesson => lesson.id === lessonId);
          if (lessonIndex !== -1) {
            state.courseDetails.modules[moduleIndex].lessons.splice(lessonIndex, 1);
          } else {
            console.log('Lesson not found');
          }
        } else {
          console.log('Module not found');
        }
      })
      .addCase(deleteLesson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // add item reducers
      .addCase(addItem.pending, (state) => {
        state.loading = true;
      })
      // .addCase(addItem.fulfilled, (state, action) => {
      //   state.loading = false;
      //   const { lessonId, moduleId, id ,courseStructure} = action.payload;
      //   if(courseStructure==="CMLT") {
      //   const moduleIndex = state.courseDetails.modules.findIndex(module => module.id === moduleId);
      //   if (moduleIndex !== -1) {
      //     const lessonIndex = state.courseDetails.modules[moduleIndex].lessons.findIndex(lesson => lesson.id === lessonId);
      //     if (lessonIndex !== -1) {
      //       const itemIndex = state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items.findIndex(item => item.id === id);
      //       if (itemIndex !== -1) {
      //         state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items[itemIndex] = action.payload;
      //       } else {
      //         state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items.push(action.payload);
      //         console.log("Item not found add new item");
      //       }
      //     } else {
      //       console.error("Lesson not found");
      //     }
      //   }
      // } else {
      //   const lessonIndex = state.courseDetails.lessons.findIndex(lesson => lesson.id === lessonId);
      //   if (lessonIndex !== -1) {
      //     const itemIndex = state.courseDetails.lessons[lessonIndex].items.findIndex(item => item.id === id);
      //      if (itemIndex !== -1) {
      //         state.courseDetails.lessons[lessonIndex].items[itemIndex] = action.payload;
      //       } else {
      //         state.courseDetails.lessons[lessonIndex].items.push(action.payload);
      //         console.log("Item not found added new item");
      //       }
          
      //   }
      // } } )
      .addCase(addItem.fulfilled, (state, action) => {
        state.loading = false;
      
        let newItems;
      
        // Determine if the payload is an array or a single object and handle accordingly
        if (Array.isArray(action.payload)) {
          newItems = action.payload;
        } else {
          newItems = [action.payload];
        }
      
        newItems.forEach(item => {
          if (item.courseStructure === "CMLT") {
            const moduleIndex = state.courseDetails.modules.findIndex(module => module.id === item.moduleId);
      
            if (moduleIndex !== -1) {
              const lessonIndex = state.courseDetails.modules[moduleIndex].lessons.findIndex(lesson => lesson.id === item.lessonId);
      
              if (lessonIndex !== -1) {
                const itemIndex = state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items.findIndex(existingItem => existingItem.id === item.id);
      
                if (itemIndex !== -1) {
                  state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items[itemIndex] = item;
                } else {
                  state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items.push(item);
                  console.log("Item not found, added new item");
                }
              } else {
                console.error("Lesson not found");
              }
            }
          } else {
            const lessonIndex = state.courseDetails.lessons.findIndex(lesson => lesson.id === item.lessonId);
      
            if (lessonIndex !== -1) {
              const itemIndex = state.courseDetails.lessons[lessonIndex].items.findIndex(existingItem => existingItem.id === item.id);
      
              if (itemIndex !== -1) {
                state.courseDetails.lessons[lessonIndex].items[itemIndex] = item;
              } else {
                state.courseDetails.lessons[lessonIndex].items.push(item);
                console.log("Item not found, added new item");
              }
            }
          }
        });
      })
      .addCase(addItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // delete topic reducers
      .addCase(deleteTopic.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTopic.fulfilled, (state, action) => {
        state.loading = false;
        const { moduleId, lessonId, id } = action.payload;
        const moduleIndex = state.courseDetails.modules.findIndex(module => module.id === moduleId);
        if (moduleIndex !== -1) {
          const lessonIndex = state.courseDetails.modules[moduleIndex].lessons.findIndex(lesson => lesson.id === lessonId);
          if (lessonIndex !== -1) {
            const topicIndex = state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items.findIndex(topic => topic.id === id);
            if (topicIndex !== -1) {
              state.courseDetails.modules[moduleIndex].lessons[lessonIndex].items.splice(topicIndex, 1);
            } else {
              console.error("Topic not found");
            }
          } else {
            console.error("Lesson not found");
          }
        } else {
          console.error("Module not found");
        }
      })
      .addCase(deleteTopic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

  },
});

export default addCourseDetails.reducer;

