import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CommonButton from '../Utilities/CommonButton';
import { UserTable, HistoryPage } from '../../styles/Users/UsersStyles';
import {
  WholePage,
  TableButtonDiv,
  SeparateTableButton,
  SearchEmail,
} from '../../styles/Certificate/CertificateStyle';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  MenuItem,
  Menu,
  Tooltip,
  Backdrop,
  CircularProgress,
  IconButton,
  Modal,
  Box,
} from '@mui/material';
import ExcelTable from './ExcelTable';
import {
  fetchUser,
  createUser,
  createUserByCsv,
  fetchRole,
} from '../../features/userModule/userModuleSlice';
import { useDispatch, useSelector } from 'react-redux';
import CSVReader from 'react-csv-reader';
import { create } from '@mui/material/styles/createTransitions';
import PParse from 'papaparse';
import SuccessPopup from '../Utilities/SuccessPopup';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

const Admins = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState('SuperAdmin');
  const [selectedRole, setSelectedRole] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddingUser, setIsAddingUser] = useState(false); // State for controlling the backdrop visibility
  const [isLoading, setIsLoading] = useState(false); // State to manage loading indicator

  const [token, setTokan] = useState([]);

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, addusers } = useSelector((state) => state.users);
  const { roles } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
  }, [dispatch, roles.data]);

  const rolesToSave =
    selectedRole === 'Select All'
      ? [{ role: 'Select All', isEnabled: true }]
      : [{ role: selectedRole, isEnabled: false }];

  console.log(roles.data);

  const handleRowClick = (row) => {
    navigate(`/users/UserDetails/${row._id}`);
  };

  const handleOpenDialog = () => {
    if (selectedValue === 'SuperAdmin') {
      data.role = 'SuperAdmin';
    } else if (selectedValue === 'Instructor') {
      data.role = 'Instructor';
    } else if (selectedValue === 'Learner') {
      data.role = 'Learner';
    } else if (selectedValue === 'Course Reviewer') {
      data.role = 'Course Reviewer';
    } else if (selectedValue === 'Course Builder') {
      data.role = 'Course Builder';
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setData({
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      password: 'password', // Resetting to default password
    });
    setOpenDialog(false);
  };

  //Download CSV file
  const downloadCSV = () => {
    // Define headers
    const headers = ['firstname', 'lastname', 'email', 'role'];

    // Prepare CSV content
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(',')]
        .concat(
          users
            .map((user) => headers.map((header) => user[header]))
            .map((row) => row.join(',')),
        )
        .join('\n');

    // Create and download CSV file
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    var timestamp = new Date().getTime();
    let fileName = 'user_' + timestamp + '.csv';
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const logfilesdata = () => {
    navigate(`/users/HistoryofImports`);
  };
  const filteredData = Array.isArray(users)
    ? users.filter((user) => {
        if (selectedValue === 'SuperAdmin') {
          return (
            user.role === 'SuperAdmin' &&
            (user?.firstname
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase()) ||
              user?.lastname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
              // user?.username?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
          );
        } else if (selectedValue === 'Instructor') {
          return (
            user.role === 'Instructor' &&
            (user?.firstname
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase()) ||
              user?.lastname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
              // user?.username?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
          );
        } else if (selectedValue === 'Learner') {
          return (
            user.role === 'Learner' &&
            (user?.firstname
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase()) ||
              user?.lastname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
              user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
          );
        } else if (selectedValue === 'Course Reviewer') {
          return (
            user.role === 'Course Reviewer' &&
            (user?.firstname
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase()) ||
              user?.lastname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
              user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
          );
        } else if (selectedValue === 'Course Builder') {
          return (
            user.role === 'Course Builder' &&
            (user?.firstname
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase()) ||
              user?.lastname
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase()) ||
              user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
              user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()))
          );
        }
      })
    : [];

  const commonColumns = [
    { name: 'Firstname', selector: (row) => row.firstname, sortable: true },
    { name: 'LastName', selector: (row) => row.lastname, sortable: true },
    { name: 'Email', selector: (row) => row.email, sortable: true },
  ];
  // Additional columns for Learner role
  const learnerColumns = [
    // {
    //   name: 'Amount Spent',
    //   selector: (row) => row.learnerDetails.amountSpent,
    //   sortable: true,
    // },
    {
      name: 'Last Sign In',
      // selector: (row) => row.learnerDetails.lastSignIn,
      selector: (row) => {
        const lastSignIn = row.learnerDetails.lastSignIn;
        return lastSignIn
          ? format(new Date(lastSignIn), 'MM/dd/yyyy, hh:mm a')
          : '';
      },
      sortable: true,
    },
    {
      name: 'Referred By',
      selector: (row) => row.learnerDetails.referredBy,
      sortable: true,
    },
    {
      name: 'Sign In Count',
      selector: (row) => row.learnerDetails.signInCount,
      sortable: true,
    },
    {
      name: 'Active Enrollments',
      selector: (row) => row.learnerDetails.activeEnrollments,
      sortable: true,
    },
  ];

  // Combine columns based on selected role
  const columns =
    selectedValue === 'Learner'
      ? [...commonColumns, ...learnerColumns]
      : commonColumns;

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleSelectValue = (e) => {
    const getValue = e.target.value;
    console.log(getValue);
    setSelectedValue(getValue);
  };

  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    password: 'password',
    referredBy: '',
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (name === 'email') {
      setIsValid(validateEmail(value));
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddEvent = async () => {
    setOpenDialog(false);
    setIsAddingUser(true);
    data.role = selectedValue;
    console.log('Data to be sent:', data);
    if (!data.firstname || !data.lastname || !data.email || !data.role) {
      toast.warning('Please fill in all required fields.');
      setIsAddingUser(false);
      return;
    }
    try {
      const createdUser = await dispatch(createUser(data));
      console.log(createdUser);
      // alert(`Failed to create user: ${createdUser.message}`);

      if (createdUser && !createdUser.message) {
        setData({
          firstname: '',
          lastname: '',
          email: '',
          role: '',
          password: 'wxyzabc',
          referredBy: '',
        });

        toast.success('User added successfully!');
        dispatch(fetchUser());
      } else if (createdUser.message) {
        toast.error(`${createdUser.message}`);
      } else {
        console.error('Failed to create user (check API response)');
        toast.error('Failed to create user. Please try again.');
      }
    } catch (error) {
      console.error('Failed to create user:', error);
      toast.error(`Failed to create user: ${error.message}`);
    } finally {
      setIsAddingUser(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChildValueChange = (value) => {
    dispatch(fetchUser()); // Refresh the user data after CSV import
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <WholePage>
        <br />
        <SearchEmail
          style={{
            marginLeft: '55px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onSubmit={handleFormSubmit}
        >
          <Select
            size="small"
            variant="outlined"
            style={{ width: '300px', marginBottom: '3px', marginLeft: '1px' }}
            onChange={handleSelectValue}
            value={selectedValue}
          >
            {Array.isArray(roles.data) &&
              roles.data.map((role) => (
                <MenuItem key={role._id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
          </Select>

          <TextField
            label="Search"
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: '300px', marginBottom: '3px', marginLeft: '44px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <TableButtonDiv>
            <Tooltip title="Click here to add new user">
              <SeparateTableButton>
                {/* <CommonButton label="Create" onClick={handleOpenDialog} /> */}
                <CommonButton
                  label="Create"
                  onClick={handleOpenDialog}
                  disabled={isLoading}
                  style={{
                    backgroundColor: isLoading ? '#CCCCCC' : null,
                    cursor: isLoading ? 'not-allowed' : 'pointer',
                  }}
                />
              </SeparateTableButton>
            </Tooltip>
            <ExcelTable onChildValueChange={handleChildValueChange} />

            <SeparateTableButton>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logfilesdata}>History of Imports</MenuItem>
                <MenuItem onClick={downloadCSV}>Export Users</MenuItem>
              </Menu>
            </SeparateTableButton>
          </TableButtonDiv>
        </SearchEmail>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add {selectedValue}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                label="FirstName"
                variant="outlined"
                fullWidth
                margin="normal"
                name="firstname"
                value={data.firstname}
                onChange={handleValueChange}
              />
              <TextField
                label="LastName"
                variant="outlined"
                fullWidth
                margin="normal"
                name="lastname"
                value={data.lastname}
                onChange={handleValueChange}
              />

              <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                value={data.email}
                onChange={handleValueChange}
                style={{ borderColor: isValid ? 'green' : 'red' }}
              />
              {isValid ? null : (
                <span style={{ color: 'red' }}>
                  Please enter a valid email address
                </span>
              )}
              <TextField
                label="Role"
                variant="outlined"
                margin="normal"
                style={{ display: 'none' }}
                fullWidth
                name="role"
                value={data.role}
                onChange={handleValueChange}
              />

              <TextField
                label="Password"
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                style={{ display: 'none' }}
                value={data.password}
                onChange={handleValueChange}
              />
              {selectedValue === 'Learner' && (
                <TextField
                  label="Referred By"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="referredBy"
                  value={data.referredBy}
                  onChange={handleValueChange}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton label="Add" onClick={handleAddEvent} />
            <CommonButton label="Cancel" onClick={handleCloseDialog} />
          </DialogActions>
        </Dialog>
        <HistoryPage>
          <UserTable>
            <TableUtilityLib
              columns={columns}
              data={filteredData}
              onRowClicked={(row) => handleRowClick(row)}
            />
          </UserTable>
        </HistoryPage>
        {/* <Backdrop
          sx={{ color: '#eee', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isAddingUser}
        >
          <CircularProgress
            style={{
              height: '20px',
              width: '20px',
              marginRight: '15px',
              color: '#fff',
            }}
          />
          <div>Please wait till the user is getting added in database....</div>
        </Backdrop> */}
        <Modal
          open={isAddingUser}
          aria-labelledby="adding-user-modal-title"
          aria-describedby="adding-user-modal-description"
        >
          <Box sx={modalStyle}>
            <CircularProgress
              style={{ height: '20px', width: '20px', marginRight: '15px' }}
            />
            <Typography>
              Please wait few moments till the user is added...
            </Typography>
          </Box>
        </Modal>
      </WholePage>
    </>
  );
};

export default Admins;
