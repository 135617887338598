import React from 'react';
import {
  LinearGradientContainer,
  ServiceContainer,
  Container,
  Container2,
  Content2,
  Description,
  Heading,
  Content,
  Content3,
  Content3Img,
  Content3Desc,
  Content4,
  Content4SubA,
  Content4SubB,
  Content4Img,
  TitleSub,
  TitleCont,
  Content3Img2,
  Content3Img2B,
  ServiceHeadMain,
  DescriptionMain,
  ContMain,
  ImgContOne,
  ImgContTwo,
} from '../../styles/Landing/ServicesStyle';

import {
  TitleB,
  TitleDash,
  TitleHeadComCont,
} from '../../styles/Common/CommonStyles';

import services_bg from '../../images/services_bg.jpg';
import SD2 from '../../images/SDLanding.jpg';
import DS2 from '../../images/DS.jpg';
import SS from '../../images/aboutImage.jpg';
import EX from '../../images/ExamGuard.jpg';
import { useTranslation } from 'react-i18next';
// import LanguageSelector from '../LanguageSelector';

// import ScrollToTop from '../ScrollToTop';

// import languageSelector from '../languageSelector';

const Services = (scrollToTop, classes, isVisible) => {
  // const servicesData = [
  //   {
  //     title: 'Learning Management System',
  //     description:
  //       "Our flagship product, the Leapot LMS, is designed to revolutionize the way you manage and deliver education and training. It adapts to the unique learning needs of each individual, ensuring a more effective and personalized learning experience. Self-Paced Training Content: We understand that not all learners follow the same pace. That's why we offer self-paced training content that allows learners to progress at their own speed while maintaining high engagement and knowledge retention. ",
  //   },
  //   {
  //     title: 'Virtual Instructor-Led Training (VILT) Content',
  //     description:
  //       'In an increasingly digital world, VILT has become a powerful tool for remote learning and collaboration. Our VILT content is designed to deliver interactive and engaging training experiences, bridging the gap between in-person and virtual classrooms. ',
  //   },
  //   {
  //     title: 'Comprehensive Talent Solutions',
  //     description:
  //       "In addition to our cutting-edge software solutions, we offer comprehensive talent solutions to help you build and deliver exceptional educational and training programs. Our pool of experts includes: Instructional Designers: Our instructional designers bring years of experience to the table, crafting engaging and effective learning materials that align with your goals and objectives. Graphic Designers: Visual appeal is crucial for capturing learners' attention. Our graphic designers create stunning visuals and multimedia content to enhance the learning experience. Software Developers: Behind every great software solution is a team of skilled developers. We provide top-notch software development services.",
  //   },
  // ];

  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <ServiceContainer>
        <Container2>
          <Content2>
            <TitleHeadComCont>
              <TitleB
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="150"
              >
                {t("services.mainheading")}
              </TitleB>
              <TitleDash
              // data-aos="fade-left"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="500"
              // data-aos-delay="200"
              />
            </TitleHeadComCont>
            <TitleSub>{t('services.heading')}</TitleSub>
            <DescriptionMain>{t('services.description')}</DescriptionMain>
          </Content2>
          <ContMain>
            <Content3>
              <Content3Desc>
                <TitleCont>{t('services.subheading1')}</TitleCont>
                <Description>{t('services.desc1')}</Description>
              </Content3Desc>
              <ImgContOne>
                <Content3Img src={SD2} />
              </ImgContOne>
            </Content3>
            <Content3>
              <Content3Img2 src={DS2} />
              <Content3Desc>
                <TitleCont>{t('services.subheading2')}</TitleCont>
                <Description>{t('services.desc2')}</Description>
              </Content3Desc>
              <ImgContTwo>
                <Content3Img2B src={DS2} />
              </ImgContTwo>
            </Content3>
            <Content3>
              <Content3Desc>
                <TitleCont>{t('services.subheading3')}</TitleCont>
                <Description>{t('services.desc3')}</Description>
              </Content3Desc>
              <ImgContOne>
                <Content3Img src={SS} />
              </ImgContOne>
            </Content3>
            <Content3>
              <Content3Img2 src={DS2} />
              <Content3Desc>
                <TitleCont>{t('services.subheading4')}</TitleCont>
                <Description>{t('services.desc4')}</Description>
              </Content3Desc>
              <ImgContTwo>
                <Content3Img2B src={DS2} />
              </ImgContTwo>
            </Content3>
          </ContMain>
        </Container2>
        {/* <ScrollToTop
        isVisible={isVisible}
        scrollToTop={scrollToTop}
        classes={classes}
      /> */}
      </ServiceContainer>
    </div>
  );
};

export default Services;
