import React, { useEffect, useState } from 'react';
import ImageSlider from './ImageSlider';
import CalendarCompSub from './CalendarCompSub';
import TagsCard from './TagsCard';
import HorizontalCard from './HorizontalCard';
import SearchBar from '../Utilities/SearchBar';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { Box, Typography } from '@mui/material';

import {
  UpperContainer,
  DisplayText,
  DisplayTextCalendar,
  DisplayTextAnn,
} from '../../styles/Calendar/CalendarStyle';
import LearnersCalendarTabs from './LearnersCalendarTabs';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LearnersCalendarMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <LearnersCalendarTabs />
      <PageContainer>
        <UpperContainer>
          <DisplayText>
            {t("calender.exploreText")}
          </DisplayText>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CalendarCompSub />
              <DisplayTextCalendar>
                {t("calender.highlightText")}
              </DisplayTextCalendar>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ImageSlider />
              <DisplayTextAnn>
                {t("calender.announcementDetails")}
              </DisplayTextAnn>
            </Grid>
          </Grid>
        </UpperContainer>
        <Grid item xs={12}>
          <TagsCard />
        </Grid>
        <Grid container spacing={3}>
          <HorizontalCard />
        </Grid>
      </PageContainer>
    </>
  );
};

export default LearnersCalendarMain;
