import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationTabs from './NotificationTabs';
import TextField from '@mui/material/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { fetchSentNotification } from '../../features/notification/NotificationSlice';
import{
  HeadingH2,
  Text
} from '../../styles/Common/CommonStyles';
import { Page } from '../../styles/Notification/NotificationListStyles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';

const DisplayNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [notificationToView, setNotificationToView] = useState(null);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const { sent = [], loading } =
  useSelector((state) => state.notification) || {};

  useEffect(() => {
    if (!sent || sent.length === 0) {
      dispatch(fetchSentNotification());
    }
  }, [dispatch, sent]);

  const columns = [
    {
      name: 'Recipient Name',
      selector: (row) => {
        if (Array.isArray(row.user_recipients)) {
          return row.user_recipients.join(', ');
        } else {
          return row.user_recipients || 'N/A';
        }
      },
      sortable: true,
    },
    { name: 'Subject', selector: (row) => row.email_Subject, sortable: true },
    {
      name: 'Date',
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
    },
  ];

  const handleSearch = (term) => {
    setSearchTerm(term);
    const filtered = sent?.filter((notification) =>
      notification.email_Subject.toLowerCase().includes(term.toLowerCase()),
    );

    setFilteredNotifications(filtered);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  const { t } = useTranslation();

  const handleRowClick = (notification) => {
    setNotificationToView(notification);
    setViewDialogOpen(true);
  };

  const handleResendNotification = (notificationId) => {
      navigate(`/Notification/sendnotification/${notificationId}`);
  };
  

  const handleCloseModal = () => {
    setViewDialogOpen(false);
    setNotificationToView(null);
  };

  return (
    <>
      <NotificationTabs />
      <Page style={{ marginTop: '35px' }}>
        <TextField
          label={t('notification.searchText')}
          size="small"
          variant="outlined"
          value={searchTerm}
          style={{ marginTop: '15px', marginBottom: '15px' }}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {loading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableUtilityLib
            columns={columns}
            data={
              filteredNotifications.length > 0 ? filteredNotifications : sent
            }
            onRowClicked={handleRowClick}
          />
        )}
      </Page>
      <Dialog open={viewDialogOpen} onClose={handleCloseModal}>
        <DialogContent>
          {notificationToView && (
            <>
              <HeadingH2>{notificationToView.email_Type}</HeadingH2>
              <div>
                <Text>{notificationToView.notificationType}</Text>
              </div>
              <Text>
                <strong>Subject:</strong> {notificationToView.email_Subject}
              </Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: notificationToView.email_Body,
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResendNotification(notificationToView._id)}
          >
            Resend
          </Button>

          <Button onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisplayNotification;
