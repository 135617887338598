import React, { useState } from 'react';
import { TextField, Button, Grid, Box, Typography, Container } from '@mui/material';
import { LoginButton} from "../../styles/Landing/LoginStyles";
import { palette } from "../../styles/palette";


const SignupNew = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
});

  const [errorState, setErrorState] = useState({
    firstName: false,
    lastName: false,
    email: false,
});
  const handleChange = (e) => {
    const { firstName, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [firstName]: value,
    }));
  };

  const handleSignup = async () => {
    console.log("this is signup form")
    if (state.firstName === '' || errorState.firstName === true) {
      alert('Please enter first Name');
    } else if (state.lastName === '' || errorState.lastName === true) {
      alert('Please enter lastName');
    } else if (state.email === '' || errorState.email === true) {
      alert('Please enter email');
    } 
    else {
        console.log("this is else block")
      try {
        const response = await fetch(
          // 'https://leapot-lms-backend.onrender.com/api/addContactUs',
          'https://localhost:8000/signup',

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email 
              }),
          },
          alert('request for account creation is forwarded to the Admin, kindly check your mail box after somr time to verify your email id and create')
        );

        
        if (response.ok) {
          toast.info('Your details have been forwarded to the concerned team.');
          setErrorState({
            firstName: false,
            lastName: false,
            email: false
          });
          setState({
            firstName: '',
            lastName: '',
            email: '',
          });
        } else {
          toast.error('Failed to submit the form. Please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to submit the form. Please try again later.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSignup} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                value={state.firstName}
                onChange={(e)=> setState({...state, firstName: e.target.value})}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                value={state.lastName}
                onChange={(e)=> setState({...state, lastName: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={state.email}
                onChange={(e)=> setState({...state, email: e.target.value})}
              />
            </Grid>
          </Grid>
          <Box
              
              sx={{ display: 'flex', justifyContent: 'center', mt: 3,'& .MuiButtonBase-root': {
                 backgroundColor: `${palette.tertiary}}`,
               }, }}>
            <Button
              onclick = {handleSignup}
              type="submit"
              variant="contained"
              sx={{ mb: 2 }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SignupNew;
