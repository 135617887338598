import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  StyledTabs,
  StyledTab,
} from '../../../styles/Certificate/CertificateStyle';

const CourseSettingTabs = () => {
  const location = useLocation();
  const defaultTabValue = [
    "/CourseSettings",
    "/CoursePlayerAppearenceSetting",
    "/CourseProgressCompletionSetting",
  ].includes(location.pathname)
    ? location.pathname
    : "/CourseSettings";

  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label="Basic"
        value="/CourseSettings"
        component={Link}
        to="/CourseSettings"
      />
      <StyledTab
        label="Player Appearance"
        value="/CoursePlayerAppearenceSetting"
        component={Link}
        to="/CoursePlayerAppearenceSetting"
      />
      {/* <StyledTab
        label="Progress & Completion"
        value="/CourseProgressCompletionSetting"
        component={Link}
        to="/CourseProgressCompletionSetting"
      /> */}
    </StyledTabs>
  );
};


// const CourseSettingTabs = ({ onChange }) => {
//   const handleTabChange = (event, newValue) => {
//     onChange(newValue);
//   };

//   return (
//     <StyledTabs onChange={handleTabChange}>
//       <StyledTab
//         label="Basic"
//         value="/CourseSettings"
//       />
//       <StyledTab
//         label="Player Appearance"
//         value="/CoursePlayerAppearenceSetting"
//       />
//       <StyledTab
//         label="Progress & Completion"
//         value="/CourseProgressCompletionSetting"
//       />
//     </StyledTabs>
//   );
// };

export default CourseSettingTabs;
