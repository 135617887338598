// import styled from "styled-components";
// import tw from "twin.macro";
// import { palette } from "../../styles/palette";

// export const ServiceContainer = styled.div`
// ${tw`mt-[120px] flex justify-center items-center m-24`}
// `;

// export const LinearGradientContainer = styled.div`
//   z-index: 1;
//   background: linear-gradient(
//     90deg,
//     rgba(73, 125, 208, 0.95) 0%,
//     rgba(7, 100, 189, 0) 100%
//   );
//   position: absolute;
//   left: 0px;
//   top: 90px;
//   width: 100%;
//   min-height: 500px;
//   height: 500px;
// `;

// export const Container = styled.div`
//   ${tw`relative w-full bg-cover bg-no-repeat  top-0 right-0 flex justify-center items-center`}
//   background : ${(props) => `url(${props.bgImage})`};
//   min-height: 500px;
//   height: 500px;
//   background-size: cover;
//   top: 90px;
// `;

// export const Content = styled.div`
//   ${tw`w-1/2 p-3 self-center`}
//   // background:${palette.white};
//   background : linear-gradient( 90deg, rgb(169 219 242 / 95%) 0%, rgba(7, 100, 189, 0) 100% );
//   margin-left: 100px;
//   border-radius: 25px;
//   z-index: 1;
// `;

// export const Heading = styled.h1`
//   ${tw`font-black 2xl:text-7xl xl:text-5xl lg:text-4xl md:text-4xl sm:text-3xl py-1 text-center leading-5`}
//   color: ${palette.black};
// `;

// export const Container2 = styled.div`
//   ${tw`relative flex flex-col justify-center items-center `}
// `;

// export const Content2 = styled.div`
//   ${tw`flex justify-center items-center flex-col mb-20 mt-[-25px]`}
//   width: 100%;
// `;

// export const ContMain = styled.div`
//   ${tw`mt-[-50px]`}
// `;

// export const Content4SubA = styled.div`
//   ${tw`my-8 p-5 flex justify-center items-center flex-col  pb-20 shadow-2xl`}
//   width: 35%;
//   // border: 1px solid gray;
//   border-Radius:20px
// `;

// export const Content4SubB = styled.div`
//   ${tw`my-8 p-5 m-3 flex justify-center items-center flex-col shadow-2xl `}
//   width: 70%;
//   // border: 1px solid gray;
//   border-Radius:20px
// `;

// export const Content3 = styled.div`
//   ${tw`flex justify-center items-center flex-col mb-24
//   lg:flex-row
//   `}
//   width: 100%;
//   // border: 1px solid gray;
// `;

// export const Content3Img = styled.img`
//   ${tw`w-[100%] h-auto rounded-xl
//   lg:w-[100%] lg:h-auto
//   `}
//   // border: 2px solid #0684fb;
// `;

// export const ImgContOne = styled.div`
// ${tw`rounded-xl ml-0 flex justify-center items-center w-[100%] h-auto
// lg:w-[40%] lg:ml-5
// `}
// border: 2px solid #0684fb;
// `

// export const ImgContTwo = styled.div`
// ${tw`rounded-xl ml-0 flex justify-center items-center w-[100%] h-auto
// lg:hidden lg:ml-5
// `}
// border: 2px solid #0684fb;
// `

// export const Content3Img2B = styled.img`
//   ${tw`w-[100%] h-auto rounded-xl
//   lg:hidden
//   `}
//   // border: 2px solid #0684fb;
// `;

// export const Content3Img2 = styled.img`
//   ${tw`hidden w-[40vw] h-[42vh] rounded-xl mr-20
//   lg:block
//   `}
//   border: 2px solid #0684fb
// `;

// export const Content4Img = styled.img`
//   ${tw`w-[50%] h-auto `}
// `;

// export const Content3Desc = styled.div`
//   ${tw`w-[80vw]
//   lg:w-[45vw]
//   `}
// `;

// export const Content4 = styled.div`
//   ${tw`flex justify-center items-center`}
//   width:70%

// `;

// export const TitleCont = styled.h1`
//   ${tw` font-bold text-lg 2xl:text-3xl xl:text-xl lg:text-xl md:text-xl  sm:text-lg leading-4 `}
//   color: ${palette.primary};
// `;

// export const Title = styled.h1`
//   ${tw` font-bold text-lg 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-xl sm:text-lg leading-4 `}
//   color: ${palette.primary};
// `;

// export const TitleSub = styled.h1`
//   ${tw` font-medium mt-5 text-lg 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl  sm:text-lg leading-5 `}
// `;

// export const DescriptionMain = styled.p`
//   ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
//   lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
//   color: ${palette.black};
// `;

// export const Description = styled.p`
//   ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify
//   lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg sm:leading-6  tracking-wide`}
//   color: ${palette.black};
//   line-height: 40px;
// `;

// export const TitleDash = styled.hr`
// ${tw`h-[3px] w-[100%] rounded-lg mb-5`}
// background:${palette.primary}
// `

// export const ServiceHeadMain = styled.div`
// ${tw`flex justify-center items-center flex-col`}
// `

import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../../styles/palette';

export const ServiceContainer = styled.div`
  ${tw`mt-[120px] flex justify-center items-center m-24`}
`;

export const LinearGradientContainer = styled.div`
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(73, 125, 208, 0.95) 0%,
    rgba(7, 100, 189, 0) 100%
  );
  position: absolute;
  left: 0px;
  top: 90px;
  width: 100%;
  min-height: 500px;
  height: 500px;
`;

export const Container = styled.div`
  ${tw`relative w-full bg-cover bg-no-repeat  top-0 right-0 flex justify-center items-center`}
  background : ${(props) => `url(${props.bgImage})`};
  min-height: 500px;
  height: 500px;
  background-size: cover;
  top: 90px;
`;

export const Content = styled.div`
  ${tw`w-1/2 p-3 self-center`}
  // background:${palette.white};
  background : linear-gradient( 90deg, rgb(169 219 242 / 95%) 0%, rgba(7, 100, 189, 0) 100% );
  margin-left: 100px;
  border-radius: 25px;
  z-index: 1;
`;

export const Heading = styled.h1`
  ${tw`font-black 2xl:text-7xl xl:text-5xl lg:text-4xl md:text-4xl sm:text-3xl py-1 text-center leading-5`}
  color: ${palette.black};
`;

export const Container2 = styled.div`
  ${tw`relative flex flex-col justify-center items-center `}
`;

export const Content2 = styled.div`
  ${tw`flex justify-center items-center flex-col mb-20 mt-[-25px]`}
  width: 100%;
`;

export const ContMain = styled.div`
  ${tw`mt-[-50px]`}
`;

export const Content4SubA = styled.div`
  ${tw`my-8 p-5 flex justify-center items-center flex-col  pb-20 shadow-2xl`}
  width: 35%;
  // border: 1px solid gray;
  border-radius: 20px;
`;

export const Content4SubB = styled.div`
  ${tw`my-8 p-5 m-3 flex justify-center items-center flex-col shadow-2xl `}
  width: 70%;
  // border: 1px solid gray;
  border-radius: 20px;
`;

export const Content3 = styled.div`
  ${tw`flex justify-center items-center flex-col mb-24
  lg:flex-row
  `}
  width: 100%;
  // border: 1px solid gray;
`;

export const Content3Img = styled.img`
  ${tw`w-[100%] h-auto rounded-xl 
  lg:w-[100%] lg:h-[40vh] lg:aspect-[3/2] lg:object-contain
  `}// border: 2px solid #0684fb;
`;

export const ImgContOne = styled.div`
  ${tw`rounded-xl ml-0 flex justify-center items-center w-[100%] h-auto
lg:w-[40%] lg:ml-5
`}
  border: 2px solid #0684fb;
`;

export const ImgContTwo = styled.div`
  ${tw`rounded-xl ml-0 flex justify-center items-center w-[100%] h-auto
lg:hidden lg:ml-5
`}
  border: 2px solid #0684fb;
`;

export const Content3Img2B = styled.img`
  ${tw`w-[100%] h-auto rounded-xl
  lg:hidden
  `}// border: 2px solid #0684fb;
`;

export const Content3Img2 = styled.img`
  ${tw`hidden w-[35vw] h-[42vh] lg:aspect-[3/2] lg:object-contain rounded-xl mr-5
  lg:block
  `}
  border: 2px solid #0684fb
`;

export const Content4Img = styled.img`
  ${tw`w-[50%] h-auto `}
`;

export const Content3Desc = styled.div`
  ${tw`w-[80vw]
  lg:w-[45vw]
  `}
`;

export const Content4 = styled.div`
  ${tw`flex justify-center items-center`}
  width:70%
`;

export const TitleCont = styled.h1`
  ${tw` font-medium text-lg 2xl:text-3xl xl:text-xl lg:text-xl md:text-xl  sm:text-lg leading-4 `}
  color: ${palette.primary};
`;

export const Title = styled.h1`
  ${tw` font-bold text-lg 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-xl sm:text-lg leading-4 `}
  color: ${palette.primary};
`;

export const TitleSub = styled.h1`
  ${tw` font-medium mt-5 text-lg 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-xl  sm:text-lg leading-5 `}
`;

export const DescriptionMain = styled.p`
  ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify leading-8  w-[80vw]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
  color: ${palette.black};
`;

export const Description = styled.p`
  ${tw`font-thin 2xl:text-xl xl:text-xl xl:leading-8 text-justify
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg sm:leading-6  tracking-wide`}
  color: ${palette.black};
  line-height: 40px;
`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[100%] rounded-lg mb-5`}
  background:${palette.primary}
`;

export const ServiceHeadMain = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;
