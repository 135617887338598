
import React, { useState } from 'react';
import VideoUpload from '../ChapterItems/VideoUpload';
import AudioUpload from '../ChapterItems/AudioUpload';
import InsertText from '../ChapterItems/InsertText';
import PdfUpload from "../ChapterItems/PdfUpload";
import InsertLiveLesson from "../ChapterItems/InsertLiveLesson";
import UploadPresentation from "../ChapterItems/UploadPresentation";
import UploadDownload from "../ChapterItems/UploadDownload";
import UploadMultimedia from "../ChapterItems/UploadMultimedia";
import InsertQuiz from "../ChapterItems/InsertQuiz";
import UploadAssignment from "../ChapterItems/UploadAssignment";
import UploadExam from "../ChapterItems/UploadExam";
import InsertSurvey from "../ChapterItems/InsertSurvey";
import UploadCodingTest from "../ChapterItems/UploadCodingTest";
import UploadScorm from "../ChapterItems/UploadScorm";

import AddOrCopyTopic from './AddOrCopyTopic'


const LessonTopics = ({ data, onClose ,lessonId , selectedItem , moduleId}) => {
  const [state, setState] = useState(data);
  const [isComponentOpen, setIsComponentOpen] = useState(false);
console.log(data , lessonId , moduleId , "data")
  const handleCloseComponent = () => {
    setIsComponentOpen(false);
    onClose();
  };

  return (
    <>
      {data === 'Video' && <VideoUpload onClose={onClose}  lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId}    />}
      {data === 'Audio' && <AudioUpload onClose={onClose}  lessonId ={lessonId}  selectedItem = {selectedItem} moduleId ={moduleId}  />}
      {data === 'Text' && <InsertText onClose={onClose}  lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId}  />}
      {data === 'PDF' && <PdfUpload onClose={onClose}   lessonId ={lessonId} selectedItem = {selectedItem} moduleId ={moduleId}  />}
      {data === 'Download' && <UploadDownload onClose={onClose}  lessonId ={lessonId} selectedItem = {selectedItem} moduleId ={moduleId}   />}
      {data === 'Presentation' && <UploadPresentation onClose={onClose}  lessonId ={lessonId} selectedItem = {selectedItem} moduleId ={moduleId}   />}
      {data === 'Multimedia' && <UploadMultimedia onClose={onClose}   lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId}  />}
      {data === 'Live' && <InsertLiveLesson onClose={onClose}   lessonId ={lessonId} selectedItem = {selectedItem} moduleId ={moduleId}  />}
      {data === 'Scorm' && <UploadScorm onClose={onClose}  lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId}  />}
      {data === 'Quiz' && <InsertQuiz onClose={onClose}  lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId}  />}
      {data === 'Assignment' && <UploadAssignment onClose={onClose}  lessonId ={lessonId}  selectedItem = {selectedItem} moduleId ={moduleId}  />}
      {data === 'Exam' && <UploadExam onClose={onClose}   lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId} />}
      {data === 'Survey' && <InsertSurvey onClose={onClose}   lessonId ={lessonId} selectedItem = {selectedItem}  moduleId ={moduleId} />}
    </>
  );
};

export default LessonTopics;
