import React, { connect } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import NavbarMain from './NavbarMain';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';
import Login from './Login';
import Signup from './Signup';
import Landing from './Landing';
import Dashboard from '../Dashboard/Dashboard';
import { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import CourseBuilder from '../Courses/CourseBuilder';
import MyProfile from '../Profile/MyProfile';
import { Container1 } from '../../styles/Landing/LandingHomeStyles';
import CreateCourse from '../Courses/CreateCourse/CreateCourse';
import ScrollToTop from '../Utilities/ScrollToTop';

import EventsNavigator from '../Calendar/Calendar';

// import CalendarNew from "../Calendar/CalendarNew";


import Certificate from '../Certificates/Certificate';
import LearnerCertificate from '../Certificates/LearnerCertificate';
import Notification from '../Notifications/Notifications';
import LearnerNotification from '../Notifications/LearnerNotification';

import LearnersEventsNavigator from '../Calendar/LearnersCalendar';


import Modules from '../Modules';
import CourseDetails from '../Courses/CourseDetails';
import CourseModules from '../Courses/CourseModule/CourseModules';
import CourseSettings from '../Courses/CourseSettings/CourseSettings';
import LandingPageDetails from '../Courses/LandingPage/LandingPageDesign';
import LearnersList from '../Courses/LearnersList';
import CourseDiscussion from '../Courses/CourseDiscussion/CourseDiscussion';
import CoursePreview from '../Courses/CoursePreview/CoursePreview';
import SelectCourseCard from '../Courses/CreateCourse/SelectCourseCard';
import CopyExistingCourse from '../Courses/CreateCourse/CopyExistingCourse';
import ScromImport from '../Courses/CreateCourse/ScromImport';
import LearnerCoursePreview from '../Courses/LearnerCourseBuilder/LearnerCoursePreview';
import Payment from '../Payment/Payment';
import { PageContainer } from '../../styles/Dashboard/DashboardStyles';
import { useNavigate } from 'react-router-dom';
import LearnerCourses from '../Courses/LearnerCourses';
import LearnerCourseDetails from '../Courses/LearnerCourseDetails';
import CoursePlayerAppearenceSetting from '../Courses/CourseSettings/CoursePlayerAppearanceSetting';
import CourseProgressCompletionSetting from '../Courses/CourseSettings/CourseProgressCompletionSetting';
import EventDetails from '../Calendar/EventDetails';
import EnrolledUsers from '../Calendar/EnrolledUsers';
import UserInfo from '../Calendar/UserInfo';
import Announcements from '../Calendar/Announcements';
import AddAnnouncements from '../Calendar/AddAnnouncements';
import Users from '../Users/Users';
import Aboutus from './Aboutus';
import { useDispatch, useSelector } from 'react-redux';
import Contactus from './Contactus';
import ResetPassword from '../Users/ResetPassword';
import ForgotPassword from '../Users/ForgotPassword';
import VerificationSuccess from '../Users/VerificationSuccess';
import DisplayScrom from '../Courses/CourseModule/DisplayScrom';
import Signup_new from './Signup_new';
import Loader from '../Utilities/Loader'; 

//Being called from App.js for mapping all the routes
function LandingHome({ userProfile }) {
  const navigate = useNavigate();
  const { users, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log(' we are here', userProfile);
    if (localStorage.getItem('userDetails')) {
      navigate('/Dashboard');
    }
  }, []);

  const [collapsed, setCollapsed] = useState(false);

  const handleSidebar = () => {
    console.log('we are in handle side bar ', collapsed);
    setCollapsed(!collapsed);
  };
  if (loading) {
    return < Loader/>;
  }
  return (
    <>
      {/* To scroll to top of the page  when route changes */}
      <ScrollToTop />

      {/* Checks for the profile, if the user is logged in then the page displayed is landing else the other pages are displayed */}
      {!users?.user ? (
        <>
          <NavbarMain />
          <Routes>
            {/* Navbar paths before logging In*/}
            <Route path="/" element={<Landing />} />
            {/* <Route path="/Home" element={<Home />} /> */}
            {/* <Route path="/About" element={<About />} /> */}
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Services" element={<Services />} />
            {/* <Route path="/Contactus" element={<Contactus />} /> */}
            <Route path="/ContactUs" element={<Contactus />} />
            <Route path="/Login" element={<Login />} />
            {/* <Route path="/Signup" element={<Signup />} /> */}
            <Route path="/Payment" element={<Payment />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/Verification" element={<VerificationSuccess />} />
            <Route path="/Signup" element={<Signup_new/>} />
          </Routes>
        </>
      ) : (
        <>
          <Container1>
            {/* <Sidebar handleSidebar={handleSidebar} /> */}
            {location.pathname !== '/CoursePreview' && (
              <Sidebar handleSidebar={handleSidebar} />
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <Navbar collapsed={collapsed} />
              <Routes>
                {/* paths available for all users after logging in */}
                <Route path="/" element={<LandingHome />} />
                <Route path="/MyProfile" element={<MyProfile />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/CourseDetails" element={<CourseDetails />} />
                {/* <Route path="/CourseModules" element={<CourseModules />} /> */}

                {
                  // user?.user?.userid?.username
                  users?.user?.userid?.role === 'SuperAdmin' ||
                  users?.user?.userid?.role === 'Instructor' ? (
                    <>
                      <Route
                        path="/EventsNavigator/*"
                        element={<EventsNavigator />}
                      />

                      <Route
                        path="/CourseBuilder"
                        element={<CourseBuilder />}
                      />
                      <Route path="/Modules" element={<Modules />} />
                      <Route
                        path="/CourseSettings/:courseId"
                        element={<CourseSettings />}
                      />
                      <Route
                        path="/Notification/*"
                        element={<Notification />}
                      />
                      <Route path="/Certificate/*" element={<Certificate />} />

                      <Route path="/Users/*" element={<Users />} />

                      <Route
                        path="/EventsNavigator"
                        element={<EventsNavigator />}
                      />
                      <Route
                        path="/CourseDetails"
                        element={<CourseDetails />}
                      />
                      <Route
                        path="/CourseSettings"
                        element={<CourseSettings />}
                      />
                      <Route
                        path="/CoursePlayerAppearenceSetting"
                        element={<CoursePlayerAppearenceSetting />}
                      />
                      <Route
                        path="/CourseProgressCompletionSetting"
                        element={<CourseProgressCompletionSetting />}
                      />
                      <Route path="/CreateCourse" element={<CreateCourse />} />
                      <Route path="/SelectCourseCard" element={<SelectCourseCard />} />
                      <Route path="/ScromImport" element={<ScromImport />} />

                      {/* <Route path="/Event1" element={<Event1 />} /> */}
                      <Route
                        path="/CourseDiscussion/:courseId"
                        element={<CourseDiscussion />}
                      />
                      
                      <Route
                        path="/LandingPageDetails"
                        element={<LandingPageDetails />}
                      />
                      <Route path="/LearnersList/:courseId" element={<LearnersList />} />
                      <Route
                        path="/CoursePreview/:id/:courseStructure"
                        element={<CoursePreview />}
                      />
                      <Route
                        path="/CourseModules/:id/:courseStructure"
                        element={<CourseModules />}
                      />
                      <Route
                        path="/DisplayScrom/:id"
                        element={<DisplayScrom />}
                      />
                       <Route
                        path="/copyCourse"
                        element={<CopyExistingCourse />}
                      />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/LearnerNotification/*"
                        element={<LearnerNotification />}
                      />
                      <Route
                        path="/LearnerCertificate/*"
                        element={<LearnerCertificate />}
                      />
                      <Route
                        path="/LearnersEventsNavigator/*"
                        element={<LearnersEventsNavigator />}
                      />
                      {/* Temp Added Learner Courses Route*/}
                      <Route
                        path="/LearnerCourses"
                        element={<LearnerCourses />}
                      />
                      {/* Temp Added  Learner Course Details Route */}
                      <Route
                        path="/LearnerCourseDetails"
                        element={<LearnerCourseDetails />}
                      />
                      <Route
                        path="/CourseBuilder"
                        element={<CourseBuilder />}
                      />
                      <Route
                        path="/LearnerCoursePreview/:id/:courseStructure"
                        element={<LearnerCoursePreview />}
                      />
                    </>
                  )
                  // : (
                  //   userProfile?.userRole === "Learner" && (
                  //     <>
                  //       <Route
                  //         path="/LearnerCertificate"
                  //         element={<LearnerCertificate />}
                  //       />
                  //       <Route
                  //         path="/LearnerCourses"
                  //         element={<LearnerCourses />}
                  //       />
                  //     </>
                  //   )
                  // )
                }
              </Routes>
              <Footer />
            </div>
          </Container1>
        </>
      )}
    </>
  );
}

export default LandingHome;
