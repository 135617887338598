import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import CertificateTabs from './CertificateTabs';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CommonButton from '../Utilities/CommonButton';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import {
  fetchBlacklistedUsersApi,
  blacklistUser,
} from '../../features/certificate/CertificateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../styles/Common/CommonStyles';
import { Tooltip } from '@mui/material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from '@mui/material';

import {
  SearchEmail,
  ButtonDiv,
  WholePage,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CertificateBlacklisted = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [status, setStatus] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const { blacklist, loading } =
    useSelector((state) => state.certificate) || {};

  useEffect(() => {
    if (!blacklist || blacklist.length === 0) {
      dispatch(fetchBlacklistedUsersApi());
    }
  }, [dispatch, blacklist.data]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFieldErrors({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFieldErrors({});
  };

  const handleFormSubmit = async () => {
    const errors = {};

    if (email.trim() === '') {
      errors.email = 'Email is required.';
    }

    if (reason.trim() === '') {
      errors.reason = 'Reason is required.';
    }

    if (status.trim() === '') {
      errors.status = 'Status is required.';
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const blacklistData = {
      email: email,
      reason: reason,
      status: status,
    };

    dispatch(blacklistUser(blacklistData))

      .then((response) =>{
        setEmail("");
        setReason("");
        setStatus("");
        toast.success(response?.message || "User disqualified successfully");

        dispatch(fetchBlacklistedUsersApi());
        setOpenDialog(false);
      })
      .catch((error) => {

        toast.error(response?.message || "An error occurred while blacklisting the user.");

      });
  };

  const columns = [
    { name: 'Date', selector: (row) => row.createdAt, sortable: true },
    { name: 'Email', selector: (row) => row.email, sortable: true },
    { name: 'Reason', selector: (row) => row.reason, sortable: true },
    { name: 'Status', selector: (row) => row.status, sortable: true },
  ];

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const filteredData = blacklist?.data?.filter((row) =>
    row.email.toLowerCase().includes(filterValue.toLowerCase()),
  );
  const { t } = useTranslation();
  return (
    <>
      <CertificateTabs />
      <div style={{ width: '95%', margin: 'auto' }}>
        <WholePage>
          <Text>{t('certificate.blacklistLabel')}</Text>
          <SearchEmail>
            <TextField
              id="outlined-basic"
              label={t('certificate.searchText')}
              variant="outlined"
              size="small"
              value={filterValue}
              onChange={handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Disqulify User">
              <ButtonDiv>
                <CommonButton
                  label={t('certificate.certificateAdd')}
                  onClick={handleOpenDialog}
                />
              </ButtonDiv>
            </Tooltip>
          </SearchEmail>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{t('certificate.blacklistLabel')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <TextField
                  label={t('certificate.cerificateEmail')}
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={Boolean(fieldErrors.email)}
                  helperText={fieldErrors.email}
                />
                <TextField
                  label={t('certificate.reasonLabel')}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  error={Boolean(fieldErrors.reason)}
                  helperText={fieldErrors.reason}
                />
                <TextField
                  label={t('certificate.statusLabel')}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  error={Boolean(fieldErrors.status)}
                  helperText={fieldErrors.status}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CommonButton
                label={t('certificate.saveLabel')}
                onClick={handleFormSubmit}
              />
              <CommonButton
                label={t('certificate.cancelLabel')}
                onClick={handleCloseDialog}
              />
            </DialogActions>
          </Dialog>

          {loading ? (
            <CircularProgress />
          ) : (
            <TableUtilityLib columns={columns} data={filteredData} />
          )}
        </WholePage>
      </div>
    </>
  );
};
export default CertificateBlacklisted;
