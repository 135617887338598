import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import CertificateTabs from "./CertificateTabs";
import TableUtilityLib from '../Utilities/TableUtilityLib';
import Tooltip from '@mui/material/Tooltip';
import TextField from "@mui/material/TextField";
import { CircularProgress} from '@mui/material';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonButton from '../Utilities/CommonButton';
import Button from '@mui/material/Button';
import {fetchAllSettingsApi, deleteSetting} from '../../features/certificate/CertificateSlice';
import { useDispatch , useSelector} from "react-redux";
import { Text } from '../../styles/Common/CommonStyles';
import { palette } from '../../styles/palette';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import{
    WholePage,
    SearchEmail,
    ButtonDiv
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CertificateList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [certificateTemplates, setCertificateTemplates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const { allsettings, loading } = useSelector((state) => state.certificate) || {};

  useEffect(() => {
      dispatch(fetchAllSettingsApi());
  }, [dispatch]);

  const handleEdit = (settingId) => {
    navigate(`/Certificate/EditSettings/${settingId}`)
  }

  const handleDeleteDialogOpen = (settingId) => {
    setTemplateToDelete({ _id: settingId }); 
    setDeleteDialogOpen(true);
  };
  

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handleDeleteTemplate = () => {
    if (!templateToDelete || !templateToDelete._id) {
      toast.warning("Template to delete is not set correctly.");
      setDeleteDialogOpen(false);
      return;
    }
  
    dispatch(deleteSetting(templateToDelete._id))
      .then(() => {
        const updatedTemplates = certificateTemplates.filter(
          (template) => template._id !== templateToDelete._id,
        );
        setCertificateTemplates(updatedTemplates);
        setDeleteDialogOpen(false);
        setTemplateToDelete(null);
      })
      .catch((error) => {
        toast.error('Error deleting template:', error.message);
        setDeleteDialogOpen(false);
        setTemplateToDelete(null);
      });
  };
  

  useEffect(() => {
    const filterData = () => {
      const validSettings = allsettings?.data?.filter(
        (item) => !item.isDeleted 
      );

      const filtered = validSettings?.filter(
        (item) =>
          item.eventId?.EventName?.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setFilteredData(filtered);
    };

    filterData();
  }, [allsettings, searchQuery]); 

  

    const columns = [
        { name: 'Event Name', selector: (row) => row.eventId?.EventName, sortable: true },
        { name: 'Template', selector: (row) => row.certificateId?.certificateName, sortable: true },
        { name: 'Serial Number', selector: (row) => row.serialNumberType.type, sortable: true },
        { name: 'Issue Type', selector: (row) => row.issueType, sortable: true },
        { name: 'Type', selector: (row) => row.certificateType.type, sortable: true },
        {
          name: 'Action',
          cell: (row) => (
            <>
             <Tooltip title="Edit Settings">
              <div onClick={() => handleEdit(row._id)}>
                <EditIcon fontSize="small" style={{ marginRight: 14, color: palette.primary }} />
              </div>
              </Tooltip>
              <Tooltip title="Delete Settings">
              <div onClick={() => handleDeleteDialogOpen(row._id)}>
                <DeleteIcon fontSize="small" style={{ marginRight: 14, color: palette.primary }} />
              </div>
              </Tooltip>
            </>
          ),
        },

      ];
      
      const { t } = useTranslation();

  return (
    <>
     <CertificateTabs />
     <div style={{width:'95%', margin:'auto'}}>
     <WholePage>
      <Text>
        {t("certificate.eventDisplayText")}
      </Text>
     <SearchEmail>
     <TextField
        label={t("certificate.searchText")}
        size="small"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Tooltip title='Create New Settings'>
      <ButtonDiv>
          <CommonButton
            onClick={() => navigate('/Certificate/CertificateDetail')}
            label={t("certificate.certificateCreateLabel")}
          />
        </ButtonDiv>
        </Tooltip>
      </SearchEmail>
      {loading ? (
            <CircularProgress />
          ) : (
     <TableUtilityLib
            columns={columns}
            data={filteredData}
          />
        )}
        </WholePage>
        <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
          <DialogTitle>{t("certificate.ConfirmDeleteLabel")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("certificate.deleteTemQue")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>{t("certificate.cancelLabel")}</Button>
            <Button onClick={handleDeleteTemplate} color="error">
            {t("certificate.deleteLabel")}
            </Button>
          </DialogActions>
        </Dialog>
        </div>
    </>
  )
}

export default CertificateList
