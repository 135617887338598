import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, MenuItem, Grid } from '@mui/material';
import CommonButton from '../Utilities/CommonButton';
import BackButton from '../Utilities/BackButton';
import { Text } from '../../styles/Common/CommonStyles';

import {
  fetchEvent,
  addCertificateSettings,
  fetchAllCertificateApi,
} from '../../features/certificate/CertificateSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  MainHeading,
  OptionText,
  SubDiv,
  ButtonDiv,
  StrongText,
  DetailsForm,
  EventDropdown,
  MainDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function CertificateDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [prefix, setPrefix] = useState('');
  const [nextNumber, setNextNumber] = useState('');
  const [incrementalDetails, setIncrementalDetails] = useState('');
  const [selectedCertificateId, setSelectedCertificateId] = useState('');
  const [selectedEventId, setSelectedEventId] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { events, allcertificate } =
    useSelector((state) => state.certificate) || {};
  const certificates = allcertificate?.certificates || [];
  const selectedCertificate = certificates.find(
    (certificate) => certificate._id === selectedCertificateId,
  );

  useEffect(() => {
    dispatch(fetchAllCertificateApi());
  }, []);

  const [formData, setFormData] = useState({
    eventId: '',
    certificateId: '',
    serialNumberType: {
      type: '',
      prefix: '',
      nextNumber: '',
    },
    issueType: '',
    certificateType: {
      type: '',
      quizeType: '',
      passingPercentage: '',
      minimumPassingPercentage: '',
      completionPercentage: '',
    },
  });

  useEffect(() => {
    dispatch(fetchEvent());
  }, []);

  useEffect(() => {
    updateIncrementalDetails();
  }, [prefix, nextNumber]);

  const handleEventChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setSelectedEventId(value);
  };

  const handleCertificateChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setSelectedCertificateId(value);
  };

  const handleQuizTypeChange = (event) => {
    setFormData({
      ...formData,
      certificateType: {
        ...formData.certificateType,
        quizeType: event.target.value,
      },
    });
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    if (name === 'certificateOptionIssue') {
      setFormData({
        ...formData,
        issueType: value,
      });
    } else {
      let resetFields = {};
      if (name === 'certificateOptionSerial') {
        resetFields = {
          serialNumberType: {
            ...formData.serialNumberType,
            type: value,
            prefix: '',
            nextNumber: '',
          },
        };
      } else if (name === 'certificateOptionType') {
        resetFields = { certificateType: { type: value } };
      }
      setFormData({ ...formData, [name]: value, ...resetFields });
    }
  };

  const updateIncrementalDetails = () => {
    const details = `${prefix}${nextNumber}`;
    setIncrementalDetails(details);
  };

  const handleSaveButtonClick = async (event) => {
    event.preventDefault();

    if (!formData.eventId) {
      alert('Please select an event.');
      return;
    }
    if (!formData.certificateId) {
      alert('Please select a certificate.');
      return;
    }

    try {
      await dispatch(addCertificateSettings(formData)).unwrap();
      toast.success('Certificate settings saved successfully!');
      navigate('/Certificate/CertificateList');
    } catch (error) {
      toast.error(`Failed to save certificate settings: ${error.message}`);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      {/* <WholePage> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <BackButton />
        </div>
        <Text>{t('certificate.createSettingText')}</Text>
      </div>
      <form>
        <EventDropdown
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '99%',
            margin: 'auto',
          }}
        >
          <div style={{ width: '40%' }}>
            <TextField
              select
              label={t('certificate.selectEventLabel')}
              variant="outlined"
              margin="normal"
              fullWidth
              name="eventId"
              value={formData.eventId}
              onChange={handleEventChange}
            >
              {Array.from(events).map((event) => (
                <MenuItem key={event._id} value={event._id}>
                  {event.EventName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <ButtonDiv>
            <CommonButton
              onClick={handleSaveButtonClick}
              label={t('certificate.saveLabel')}
            />
          </ButtonDiv>
        </EventDropdown>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            xl={9}
            style={{
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 64px)',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            <MainDiv>
              <MainHeading>{t('certificate.templateLabel')}</MainHeading>
              <SubDiv>
                <EventDropdown>
                  <TextField
                    select
                    label={t('certificate.selectCertificateLabel')}
                    variant="outlined"
                    margin="normal"
                    sx={{ width: 400 }}
                    name="certificateId"
                    value={formData.certificateId}
                    onChange={handleCertificateChange}
                  >
                    {certificates.map((certificate) => (
                      <MenuItem key={certificate._id} value={certificate._id}>
                        {certificate.certificateName}
                      </MenuItem>
                    ))}
                  </TextField>
                </EventDropdown>
              </SubDiv>
              <MainHeading>{t('certificate.serialNumberLabel')}</MainHeading>
              <SubDiv>
                <OptionText>
                  {' '}
                  <input
                    type="radio"
                    value="Random"
                    name="certificateOptionSerial"
                    checked={formData.certificateOptionSerial === 'Random'}
                    onChange={handleOptionChange}
                  />
                  <StrongText>{t('certificate.randomLabel')}</StrongText>
                  {t('certificate.alphaNumLabel')}
                </OptionText>
                <OptionText>
                  <input
                    type="radio"
                    value="Incremental"
                    name="certificateOptionSerial"
                    checked={formData.certificateOptionSerial === 'Incremental'}
                    onChange={handleOptionChange}
                  />
                  <StrongText>{t('certificate.incrementalLabel')}</StrongText>
                  {t('certificate.IncrementalValueLabel')}
                </OptionText>
                {formData.certificateOptionSerial === 'Incremental' && (
                  <DetailsForm>
                    <TextField
                      id="outlined-basic"
                      label={t('prifixLabel')}
                      variant="outlined"
                      fullWidth
                      size="medium"
                      name="prefix"
                      value={formData.serialNumberType.prefix}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          serialNumberType: {
                            ...formData.serialNumberType,
                            prefix: event.target.value,
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label={t('certificate.NextNumbLabel')}
                      variant="outlined"
                      fullWidth
                      size="medium"
                      name="nextNumber"
                      value={formData.serialNumberType.nextNumber}
                      // onChange={handleEventChange}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          serialNumberType: {
                            ...formData.serialNumberType,
                            nextNumber: event.target.value,
                          },
                        })
                      }
                    />
                    <br />
                  </DetailsForm>
                )}
                {incrementalDetails && (
                  <OptionText>
                    {t('certificate.serialNubText')} <strong>{incrementalDetails}</strong>
                  </OptionText>
                )}
              </SubDiv>
              <MainHeading>{t('certificate.certificateIssueLabel')}</MainHeading>
              <SubDiv>
                <OptionText>
                  <input
                    type="radio"
                    value="Manual"
                    name="certificateOptionIssue"
                    checked={formData.issueType === 'Manual'}
                    onChange={handleOptionChange}
                  />
                  <StrongText>{t('certificate.manualLabel')}</StrongText>
                  {t('certificate.adminIssuedLabel')}
                </OptionText>
                <OptionText>
                  <input
                    type="radio"
                    value="Automatic"
                    name="certificateOptionIssue"
                    checked={formData.issueType === 'Automatic'}
                    onChange={handleOptionChange}
                  />
                  <StrongText>{t('certificate.automaticLabel')}</StrongText>
                  {t('certificate.complitionIssueText')}
                </OptionText>
              </SubDiv>

              <MainHeading>{t('certificate.certificateTypeLabel')}</MainHeading>
              <SubDiv>
                <OptionText>
                  <input
                    type="radio"
                    value="Completion"
                    name="certificateOptionType"
                    checked={formData.certificateOptionType === 'Completion'}
                    onChange={handleOptionChange}
                  />
                  <StrongText>{t('certificate.completionLabel')}</StrongText>{' '}
                  {t('certificate.complitionText')}
                </OptionText>
                {formData.certificateOptionType === 'Completion' && (
                  <DetailsForm>
                    <TextField
                      id="outlined-basic"
                      label={t('certificate.percentageLebal')}
                      variant="outlined"
                      fullWidth
                      size="medium"
                      type="number"
                      name="completionPercentage"
                      value={formData.certificateType.completionPercentage}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          certificateType: {
                            ...formData.certificateType,
                            completionPercentage: event.target.value,
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label={t('certificate.minPercentageLabel')}
                      variant="outlined"
                      fullWidth
                      size="medium"
                      type="number"
                      name="minimumPassingPercentage"
                      value={formData.certificateType.minimumPassingPercentage}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          certificateType: {
                            ...formData.certificateType,
                            minimumPassingPercentage: event.target.value,
                          },
                        })
                      }
                    />
                  </DetailsForm>
                )}
                <OptionText>
                  <input
                    type="radio"
                    value="KnowledgeBased"
                    name="certificateOptionType"
                    checked={
                      formData.certificateOptionType === 'KnowledgeBased'
                    }
                    onChange={handleOptionChange}
                  />
                  <StrongText>{t('certificate.knowledgeBasedLabel')}</StrongText>{' '}
                  {t('certificate.generateQuizText')}
                </OptionText>
                {formData.certificateOptionType === 'KnowledgeBased' && (
                  <DetailsForm>
                    <TextField
                      select
                      label={t('certificate.quizTypeLabel')}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.certificateType.quizeType}
                      onChange={handleQuizTypeChange}
                    >
                      <MenuItem value="">{t('certificate.selectQuizLabel')}</MenuItem>
                      <MenuItem value="Landscape">ABC</MenuItem>
                      <MenuItem value="Portrait">XYZ</MenuItem>
                    </TextField>
                    <TextField
                      id="outlined-basic"
                      label={t('certificate.passingPercentageLabel')}
                      variant="outlined"
                      fullWidth
                      size="medium"
                      type="number"
                      name="passingPercentage"
                      value={formData.certificateType.passingPercentage}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          certificateType: {
                            ...formData.certificateType,
                            passingPercentage: event.target.value,
                          },
                        })
                      }
                    />
                  </DetailsForm>
                )}
                <br />
              </SubDiv>
            </MainDiv>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            xl={3}
            style={{
              borderLeft: '2px solid #ccc',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                height: 'calc(100vh - 64px)',
                overflow: 'hidden',
                marginLeft: '25px',
              }}
            >
              <div>{t('certificate.previewLabel')}</div>
              {selectedCertificate && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedCertificate.certificateBody,
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                  }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default CertificateDetail;
