import React, { useState, useEffect } from 'react';
import { createCourseInSlice } from '../../../features/courses/addCourseDetailsSlice';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Switch,
  IconButton,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import {
  CreateContent,
  ContainerStyle,
  ButtonCont,
} from '../../../styles/Courses/CourseBuilderStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageContainer } from '../../../styles/Common/CommonStyles';
import CommonButton from '../../Utilities/CommonButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditReact from 'jodit-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useDispatch, useSelector } from 'react-redux';

import { ScormUploader } from './ScromImport';

const CreateCourse = ({ isImport, scormUrl, setScormUrl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const [courseDets, setCourseDets] = useState(location.state);
  const [courseDets, setCourseDets] = useState(
    location.state ?? {
      generalInformation: {
        title: '',
        description: '',
        courseCode: '',
        courseDuration: '',
        courseLevel: '',
        courseCategory: '',
        prerequisites: '',
        language: '',
        instructorName: [''],
        bannerImage: '',
        courseStructure: '',
        scormUrl: '',
        isPublished: false,
      },
      isPaidCourse: false,
      pricingDetails: {
        actualAmount: 0,
        discount: 0,
        discountedAmount: 0,
        taxes: 0,
        totalAmount: 0,
      },
      contentManagement: {
        learningObjectives: '',
        estimatedTimeToComplete: '',
      },
      accessAndEnrollment: {
        enrollmentType: '',
        startDate: '',
        endDate: '',
        startTime: '' || null,
        endTime: '' || null,
        maximumEnrollment: 0,
        accessPermissions: '',
        certificateAvailability: false,
        certificateRequirements: '',
      },
      progressTracking: {
        progressIndicators: false,
        enforceSequentialLearningPath: false,
        assessmentMethods: false,
        gradingPolicy: false,
        completionCriteria: false,
      },
    },
  );
  const [checked, setChecked] = useState(courseDets.paid);
  const [productPrice, setProductPrice] = useState(courseDets.productPrice);
  const [discount, setDiscount] = useState(courseDets.discount);
  const [finalPrice, setFinalPrice] = useState(courseDets.finalPrice);
  // const [image, setImage] = useState(null);
  const [isPaidCourse, setIsPaidCourse] = useState(false);
  const [actualAmount, setActualAmount] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState('');
  const [taxes, setTaxes] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    // handleSaveCourse();
    setCurrentStep(1);
  };

  const handleSubmit = () => {
    console.log('Submitted');
  };

  const handlePriceSwitchChange = () => {
    setCourseDets((prevState) => ({
      ...prevState,
      isPaidCourse: !prevState.isPaidCourse,
    }));
  };

  const handleSwitchChange = () => {
    // setChecked(!checked);

    const { name, checked } = event.target;
    setCourseDets((prevCourseDets) => ({
      ...prevCourseDets,
      progressTracking: {
        ...prevCourseDets.progressTracking,
        [name]: checked,
      },
    }));
  };

  useEffect(() => {
    const actualAmountValue = parseFloat(actualAmount) || 0;
    const discountValue = parseFloat(discountAmount) || 0;
    const taxesValue = parseFloat(taxes) || 0;
    const calculatedDiscountedAmount =
      actualAmountValue - (actualAmountValue * discountValue) / 100;
    const calculatedTotalAmount =
      calculatedDiscountedAmount +
      (calculatedDiscountedAmount * taxesValue) / 100;

    setDiscountedAmount(calculatedDiscountedAmount.toFixed(2));
    setTotalAmount(calculatedTotalAmount.toFixed(2));
  }, [actualAmount, discountAmount, taxes]);

  const handleSaveCourse = async () => {
    console.log('handle saveee');
    const result = await dispatch(createCourseInSlice(courseDets));
    console.log(result);
    navigate('/CourseBuilder');
  };

  useEffect(() => {
    if (isImport) {
      setCourseDets((prevState) => ({
        ...prevState,
        generalInformation: {
          ...prevState.generalInformation,
          courseStructure: 'SCORM',
        },
      }));
    }
  }, []);

  useEffect(() => {
    if (isImport) {
      setCourseDets((prevState) => ({
        ...prevState,
        generalInformation: {
          ...prevState.generalInformation,
          scormUrl: scormUrl,
        },
      }));
    }
  }, [scormUrl]);

  const config = {
    placeholder: 'Start typing....',
    height: 300,
    readonly: false,
    observer: false,
    shouldWork: true,
  };

  useEffect(() => {
    console.log('courseDets ', courseDets);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCourseDets((prevState) => ({
          ...prevState,
          generalInformation: {
            ...prevState.generalInformation,
            bannerImage: reader.result,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const image = courseDets.generalInformation.bannerImage;

  const [certificate, setCertificate] = useState('');
  const [isExam, setIsExam] = useState('');
  const [materials, setMaterials] = useState('');

  const handleCertificateChange = (event) => {
    setCertificate(event.target.value);
  };

  const handleIsExamChange = (event) => {
    setIsExam(event.target.value);
  };
  const handleMaterialChange = (event) => {
    setMaterials(event.target.value);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const [category, key] = name.split('.');

    setCourseDets((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [key]: value,
      },
    }));
  };

  const handleTimeChange = (time, field) => {
    handleSelectChange({ target: { name: field, value: time } });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [category, field] = name.split('.');

    setCourseDets((prevState) => {
      const updatedPricingDetails = {
        ...prevState.pricingDetails,
        [field]: value,
      };

      // Calculate discounted amount
      if (field === 'actualAmount' || field === 'discount') {
        const actualAmount =
          parseFloat(updatedPricingDetails.actualAmount) || 0;
        const discount = parseFloat(updatedPricingDetails.discount) || 0;
        const discountedAmount = actualAmount - actualAmount * (discount / 100);
        updatedPricingDetails.discountedAmount = discountedAmount.toFixed(2);
      }

      // Calculate total amount
      if (
        field === 'actualAmount' ||
        field === 'discount' ||
        field === 'taxes'
      ) {
        const actualAmount =
          parseFloat(updatedPricingDetails.actualAmount) || 0;
        const discount = parseFloat(updatedPricingDetails.discount) || 0;
        const taxes = parseFloat(updatedPricingDetails.taxes) || 0;
        const discountedAmount = actualAmount - actualAmount * (discount / 100);
        const totalAmount = discountedAmount + discountedAmount * (taxes / 100);
        updatedPricingDetails.totalAmount = totalAmount.toFixed(2);
      }

      return {
        ...prevState,
        pricingDetails: updatedPricingDetails,
      };
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PageContainer>
        <CreateContent style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={() => navigate(-1)}
                sx={{ color: `${palette.THEME_THREE}`, marginleft: '33px' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
            <h3>Fill out the form below to create a new course.</h3>
          </div>

          <ButtonCont>
            {isImport ? (
              currentStep === 0 ? (
                <CommonButton onClick={handleNext} label="Next" />
              ) : (
                <CommonButton onClick={handleSaveCourse} label="Save" />
              )
            ) : (
              <CommonButton onClick={handleSaveCourse} label="Save" />
            )}
          </ButtonCont>
        </CreateContent>
        {currentStep === 0 ? (
          <Container fluid sx={ContainerStyle}>
            <Grid container sx={{ mb: 2 }}>
              <h3>General Information</h3>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {/* Title and Description Grid */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Title</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Course Title"
                      variant="outlined"
                      fullWidth
                      name="generalInformation.title"
                      value={courseDets.generalInformation.title}
                      onChange={handleSelectChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Description</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Description"
                      variant="outlined"
                      rows="5"
                      multiline
                      fullWidth
                      name="generalInformation.description"
                      value={courseDets.generalInformation.description}
                      onChange={handleSelectChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      border: '1px solid #ccc',
                      width: '60%',
                      paddingTop: '50%',
                      position: 'relative',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    {image ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundImage: `url(${image})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {isHovered && (
                          <div
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              color: '#fff',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                            }}
                          >
                            <CommonButton
                              onClick={() =>
                                document
                                  .getElementById('contained-button-file')
                                  .click()
                              }
                              label="Change Image"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <CommonButton
                          onClick={() =>
                            document
                              .getElementById('contained-button-file')
                              .click()
                          }
                          label="Upload Image"
                        />
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography>Course Code</Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Course Code"
                  variant="outlined"
                  fullWidth
                  name="generalInformation.courseCode"
                  value={courseDets.generalInformation.courseCode}
                  onChange={handleSelectChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Course Duration</Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Course Duration"
                  variant="outlined"
                  fullWidth
                  name="generalInformation.courseDuration"
                  value={courseDets.generalInformation.courseDuration}
                  onChange={handleSelectChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Course Level</Typography>

                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="courseLevel"
                    name="generalInformation.courseLevel"
                    value={courseDets.generalInformation.courseLevel}
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Course Level'
                    }
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Course Level
                    </MenuItem>
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advanced</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography>Course Category </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="course-category"
                    name="generalInformation.courseCategory"
                    value={courseDets.generalInformation.courseCategory}
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Course Category'
                    }
                    required
                  >
                     <MenuItem value="" disabled>
                      Select Course Category
                    </MenuItem>
                    <MenuItem value="Beginner">Science</MenuItem>
                    <MenuItem value="Intermediate">Management</MenuItem>
                    <MenuItem value="Advanced">Arts</MenuItem>
                    {/* <MenuItem value="Add">Add Course Category</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography>Language* </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="language"
                    name="generalInformation.language"
                    value={courseDets.generalInformation.language}
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Language'
                    }
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Language
                    </MenuItem>
                    <MenuItem value="English">English</MenuItem>
                    <MenuItem value="Spanish">Spanish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sx={{ mb: 2 }}>
                <Typography>Instructor Name</Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Instructor Name"
                  variant="outlined"
                  fullWidth
                  name="generalInformation.instructorName"
                  value={courseDets.generalInformation.instructorName}
                  onChange={handleSelectChange}
                  required
                />
              </Grid>
              {!isImport && (
                <>
                  <Grid item xs={6}>
                    <Typography>Course Structure </Typography>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        id="structure"
                        onChange={handleSelectChange}
                        name="generalInformation.courseStructure"
                        value={courseDets.generalInformation.courseStructure}
                        required
                      >
                        <MenuItem value="CMLT">CMLT </MenuItem>
                        <MenuItem value="CLT">CLT</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Grid container>
                  <h3>Pricing Detail</h3>
                </Grid>
                <Switch
                  checked={courseDets.isPaidCourse}
                  onChange={handlePriceSwitchChange}
                />
                <Typography display="inline"> This is a paid course</Typography>

                {courseDets.isPaidCourse && (
                  <Grid container spacing={2} sx={{ mb: 2, mt: 4 }}>
                    <Grid item xs={6}>
                      <Typography>Actual Amount *</Typography>
                      <TextField
                        name="pricingDetails.actualAmount"
                        placeholder="Enter Actual Amount"
                        variant="outlined"
                        fullWidth
                        value={courseDets.pricingDetails.actualAmount}
                        onChange={handleInputChange}
                        required
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Discount (%) *</Typography>
                      <TextField
                        name="pricingDetails.discount"
                        placeholder="Enter Discount Percentage"
                        variant="outlined"
                        fullWidth
                        value={courseDets.pricingDetails.discount}
                        onChange={handleInputChange}
                        required
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Discounted Amount</Typography>
                      <TextField
                        name="pricingDetails.discountedAmount"
                        placeholder="Discounted Amount"
                        variant="outlined"
                        fullWidth
                        value={courseDets.pricingDetails.discountedAmount}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Taxes (%) *</Typography>
                      <TextField
                        name="pricingDetails.taxes"
                        placeholder="Enter Taxes Percentage"
                        variant="outlined"
                        fullWidth
                        value={courseDets.pricingDetails.taxes}
                        onChange={handleInputChange}
                        required
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Total Amount</Typography>
                      <TextField
                        name="pricingDetails.totalAmount"
                        placeholder="Total Amount"
                        variant="outlined"
                        fullWidth
                        value={courseDets.pricingDetails.totalAmount}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid>
              <h3>Content Management</h3>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Grid>
                  <Typography>Course Prerequisite </Typography>
                  <JoditReact
                    value={courseDets.generalInformation.prerequisite}
                    // onChange={handleSelectChange}
                  />
                </Grid>
                <br />
                <Typography>Learning Objectives</Typography>

                <JoditReact
                  value={courseDets.contentManagement.learningObjectives}
                  // onChange={handleSelectChange}
                  config={{
                    style: {},
                  }}
                />
              </Grid>

              {/* <Grid item xs={6}>
                <Typography>Estimated Time </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="contentManagement.estimatedTimeToComplete"
                  value={courseDets.contentManagement.estimatedTimeToComplete}
                  onChange={handleSelectChange}
                  required
                />
              </Grid> */}
            </Grid>
            <Grid container sx={{ mb: 2 }}>
              <h3>Access and Enrollment</h3>
              <h3>Access and Enrollment</h3>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography>Enrollment Type </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="enrollType"
                    name="accessAndEnrollment.enrollmentType"
                    value={courseDets.accessAndEnrollment.enrollmentType}
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Enrollment Type'
                    }
                    required
                  >
                    <MenuItem value="" disabled>
                    Enrollment Type
                    </MenuItem>
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Restricted">Restricted</MenuItem>
                    <MenuItem value="Invitation">By Invitation</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography>Maximum Enrollment</Typography>
                <TextField
                  id="allotment-number"
                  placeholder="Enter Allotment Number"
                  variant="outlined"
                  type="number"
                  fullWidth
                  name="accessAndEnrollment.maxEnrollment"
                  value={courseDets.accessAndEnrollment.maxEnrollment}
                  onChange={handleSelectChange}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>Start Date </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Course Title"
                  variant="outlined"
                  type="date"
                  fullWidth
                  name="accessAndEnrollment.startDate"
                  value={courseDets.accessAndEnrollment.startDate}
                  onChange={handleSelectChange}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>Start Time </Typography>
                <TimePicker
                  value={courseDets.accessAndEnrollment.startTime}
                  onChange={(time) =>
                    handleTimeChange(time, 'accessAndEnrollment.startTime')
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      error={false}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography>End Date</Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Course Title"
                  variant="outlined"
                  type="date"
                  fullWidth
                  name="accessAndEnrollment.endDate"
                  value={courseDets.accessAndEnrollment.endDate}
                  onChange={handleSelectChange}
                  required
                />
              </Grid>

              <Grid item xs={3}>
                <Typography>End Time</Typography>
                <TimePicker
                  value={courseDets.accessAndEnrollment.endTime}
                  onChange={(time) =>
                    handleTimeChange(time, 'accessAndEnrollment.endTime')
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      error={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <Typography>Access Permission </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="Permission"
                    name="accessAndEnrollment.accessPermissions"
                    value={courseDets.accessAndEnrollment.accessPermissions}
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    renderValue={(selected) =>
                      selected ? selected : 'Select Access Permission'
                    }
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Access Permission
                    </MenuItem>
                    <MenuItem value="Public">Public</MenuItem>
                    <MenuItem value="Private">Private</MenuItem>
                    <MenuItem value="Institution-specific">
                      Institution-specific
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography>Certificate</Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="Certificate"
                    name="accessAndEnrollment.certificateAvailability"
                    value={
                      courseDets.accessAndEnrollment.certificateAvailability
                    }
                    onChange={handleSelectChange}
                    displayEmpty // Shows the placeholder when no option is selected
                    // renderValue={(selected) =>
                    //   selected ? selected : 'Is Certificate Available'
                    // }
                    required
                  >
                    <MenuItem value="true">Available</MenuItem>
                    <MenuItem value="false">Not Available</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {!isImport && (
                <>
                  <Grid container>
                    <h3>Progress Tracking</h3>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.progressTracking.progressIndicators}
                        onChange={handleSwitchChange}
                        name="progressIndicators"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Progress Indicators</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.progressTracking.LearningPath}
                        onChange={handleSwitchChange}
                        name="LearningPath"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Enforce sequential learning path</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.progressTracking.assessmentMethods}
                        onChange={handleSwitchChange}
                        name="assessmentMethods"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Assessment Methods</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.progressTracking.gradingPolicy}
                        onChange={handleSwitchChange}
                        name="gradingPolicy"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Grading Policy</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.completionCriteria}
                        onChange={handleSwitchChange}
                        name="completionCriteria"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Completion Criteria</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.discussionForums}
                        onChange={handleSwitchChange}
                        name="discussionForums"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Discussion Forums</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1} alignItems="center">
                    <Grid item>
                      <Switch
                        checked={courseDets.announcements}
                        onChange={handleSwitchChange}
                        name="announcements"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>Announcements</Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        ) : (
          <ScormUploader scormUrl={scormUrl} setScormUrl={setScormUrl} />
        )}
      </PageContainer>
    </LocalizationProvider>
  );
};

export default CreateCourse;
