import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../../styles/palette';

export const MainContainer = styled.div`
  ${tw`flex justify-around flex-col items-center m-5 mt-10
  md:flex-row md:m-10   
  lg:m-20 lg:mt-10
  xl:m-40 xl:mt-10
  `}
  border-radius:20px;
  background: ${palette.tertiary};
`;

export const LinearGradientContainer = styled.div`
  ${tw`absolute`}
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(73, 125, 208, 0.95) 0%,
    rgba(7, 100, 189, 0) 100%
  );
  left: 0px;
  top: 30px;
  width: 100%;
  min-height: 500px;
  height: 500px;
`;

export const ImageContainer = styled.div`
  ${tw`relative w-full bg-cover top-0 right-0 flex justify-center items-center `}
  min-height: 500px;
  height: 100%;
  background-size: cover;
  top: 90px;
`;

export const ContactMainContainer = styled.div`
  ${tw`flex justify-center items-center flex-col  h-auto w-[100%]
  sm:flex-col 
  md:flex-col 
  lg:flex-row
  `}
  min-height: 700px;
  height: 100%;
  background-size: cover;
  margin-top: 1px;
`;

export const ContactImg = styled.img`
  ${tw`hidden flex justify-center items-center w-[50%] h-[50%] ml-5
    xl:w-[40%] xl:h-[40%] xl:block xl:ml-40
    lg:block
    `}// width: 350px;
  // min-height: 200px;
  // height: 360px;
  // padding-left: 180px;
  // background-size: cover;
  // margin-top: 0px;
  // margin-bottom: 240px;
`;

export const ContactContainer = styled.div`
  ${tw`flex flex-col w-[400px] h-[400px] items-center m-auto my-10 justify-center items-center
  sm:w-[450px] sm:h-[450px]
  md:w-[500px] md:h-[500px]
  xl:w-[600px] xl:h-[600px]
  `}
  /* top: 120px; */
  // margin-bottom: 10px;
  margin-top: 46px;
  background: linear-gradient(360deg, #389dfc, #025099);
  border-radius: 100%;
  margin-top: 0px;
`;

export const Header = styled.h1`
  ${tw` flex justify-center text-4xl font-bold leading-8 tracking-wide w-[100%]`}
  color:${palette.white}
`;

export const Desc = styled.p`
  ${tw` flex justify-center 2xl:text-2xl xl:text-lg lg:text-base mt-10 `}
  Color:${palette.white}
`;

export const ContactForm = styled.div`
  ${tw`2xl:text-2xl flex flex-col w-[200px]  h-[350px] p-16 mb-20 mt-20 mx-2 
  sm:w-[300px] sm:h-[350px] sm:m-10
  md:w-[320px] md:h-[350px]
  lg:w-[450px] lg:h-[400px]
  xl:w-[600px] xl:h-[400px]
  `}
  background : ${palette.white};
  box-shadow: 5px 5px 30px 0px black;

  //  background: linear-gradient(90deg, #389dfc, #025099);
  border-radius: 20px;
  //   margin-top: 0px;
`;

export const Text = styled.p`
  ${tw`2xl:text-2xl lg:text-sm w-full font-bold mt-3 mb-1`}
  color:${palette.white}
`;

export const TextFieldStyle = {
  '& .MuiInputBase-root ': {
    fontSize: '1rem',
    background: `${palette.white}`,
    height: '50px',
    marginTop: '20px',
    boxShadow: '0px 0px 2px 0 black',
    width: '100%',
  },
};
export const TextFieldStyleA = {
  '& .MuiInputBase-root ': {
    fontSize: '1rem',
    background: `${palette.white}`,
    height: '50px',
    marginTop: '-30px',
    boxShadow: '0px 0px 2px 0 black',
    width: '100%',
  },
};
// export const TextFieldStyleA = {
//   marginTop: '50px',
//   "& .MuiInputBase-root": {
//     fontSize: '1rem',
//     background: `${palette.white}`,
//     height: '45px',
//     marginTop: '5px',
//     maxWidth: '100%',
//     width: '300px',
//   },
//   '@media (min-width: 768px)': {
//     "& .MuiInputBase-root": {
//       width: '500px',
//     },
//   },
//   '@media (min-width: 992px)': {
//     "& .MuiInputBase-root": {
//       width: '400px',
//     },
//   },
//   '@media (min-width: 1200px)': {
//     "& .MuiInputBase-root": {
//       width: '500px',
//     },
//   },
//   '@media (min-width: 1440px)': {
//     "& .MuiInputBase-root": {
//       width: '500px',
//     },
//   },
// };

export const SubmitButton = {
  color: palette.white,
  background: palette.tertiary,
  margin: 'auto',
  fontWeight: 600,
  width: '12rem',
  height: '2.5rem',
  textAlign: 'center',
  marginTop: '1.5rem',
  boxShadow: '0px 0px 5px #000000',
};

export const TextFieldMsg = styled.div`
  ${tw`mt-5`}
`;

export const TextFieldMain = styled.div`
  ${tw` flex flex-col`}
`;

export const ContactDescMain = styled.div`
  ${tw`flex flex-col w-[50%] justify-center items-center mt-20 ml-5
lg:mt-0
`}
`;

export const TextFieldMsgStyle = {
  '& .MuiInputBase-root ': {
    fontSize: '1rem',
    background: `${palette.white}`,
    marginTop: '10px',
    boxShadow: '0px 0px 3px 0px black',
    width: '100%',
  },
  width: '100%',
  '@media (max-width: 600px)': {
    '& .MuiInputBase-root': {
      height: '100px',
    },
  },
};

export const TextFieldMainCont = styled.div`
  ${tw`mt-5`}
`;

export const ContactIconImage = styled.img`
  ${tw`w-[15vh] h-auto `}
`;

export const ContactMainHead = styled.h1`
  ${tw`flex justify-center items-center mt-80 text-4xl`}
  color:${palette.primary}
`;
export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[20%] rounded-lg`}
  background:${palette.primary}
`;
