import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';

const CalendarTabs = () => {
  const location = useLocation();
  const defaultTabValue = [
    '/LearnersEventsNavigator',
    '/LearnersEventsNavigator/Announcements',
  ].includes(location.pathname)
    ? location.pathname
    : '/LearnersEventsNavigator';
    
    const { t } = useTranslation();
  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label={t("calender.calendarLabel")}
        value="/LearnersEventsNavigator"
        component={Link}
        to="/LearnersEventsNavigator"
      />
      <StyledTab
        label={t("calender.announcementsLabel")}
        value="/LearnersEventsNavigator/Announcements"
        component={Link}
        to="/LearnersEventsNavigator/Announcements"
      />
    </StyledTabs>
  );
};

export default CalendarTabs;
