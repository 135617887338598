import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

const PreviewTopicText = (props) => {
  const [readingTime, setReadingTime] = useState(0);

  const stripHTMLTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  useEffect(() => {
    const text = stripHTMLTags(props.item.textDescription);
    const wordCount = text.split(' ').length;
    const time = wordCount / 3; // 3 words per second
    setReadingTime(time);
    props.onReadingTimeCalculated(time);
  }, [props.item.textDescription]);

  const textContent = stripHTMLTags(props.item.textDescription);

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          margin: '20px 20px 10px 0', // Adjust margin as needed
          padding: '10px', // Adjust padding as needed
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add box shadow
          overflow: 'auto', // Enable scrolling if content overflows
          textAlign: 'justify', // Justify text content
        }}
      >
        <h3>{props.item.title}</h3>
      </Grid>
      <div style={{ height: "410px", overflowY: "auto", padding: "10px" }}>
        {textContent}
      </div>
    </div>
  );
};

export default PreviewTopicText;
