import React, { useEffect } from 'react';
import {
  CardContainer,
  EvCard1,
  EvCard2,
  EvCard3,
  EvCard4,
  EvCard5,
  EvCard6,
  RegisterButton1,
  PopupCont,
  EvCardTitle,
  EventTime,
  EventName,
} from '../../styles/Calendar/CalendarStyle';
import { useNavigate } from 'react-router-dom';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEventsByStartDate } from '../../features/calendar/CalendarSlice';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EventPopup = ({ date }) => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar.events);
  const { users, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchEventsByStartDate(date));
  }, [dispatch, date]);

  const navigate = useNavigate();

  const handleRegister = (eventname) => {
    if (users.user.userid?.role === 'SuperAdmin') {
      navigate(`/EventsNavigator/eventdetails/${eventname}`);
    } else {
      navigate(`/LearnersEventsNavigator/eventdetails/${eventname}`);
    }
  };

  const { t } = useTranslation();
  return (
    <PopupCont>
      {events.length > 0 ? (
        <CardContainer>
          {events?.map((event) => (
            <EvCard1 key={event.EventId}>
              <EvCardTitle>
                <EventName>{event.EventName}</EventName>
              </EvCardTitle>
              <EventTime
                style={{
                  marginTop: '-30px',
                  color: '#979DA3',
                  fontSize: '15px',
                }}
              >
                {t('calender.scheduledTime')} {event.STime}
              </EventTime>
              <RegisterButton1 onClick={() => handleRegister(event.EventName)}>
                {t('calender.viewDetailsLabel')}
              </RegisterButton1>
            </EvCard1>
          ))}
        </CardContainer>
      ) : (
        <p> {t('calender.noEventLabel')}</p>
      )}
    </PopupCont>
  );
};

export default EventPopup;
