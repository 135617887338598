
// import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';

// import {
//   Container,
//   Grid,
//   FormControl,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   Typography,
//   TextField,
//   MenuItem,
//   Chip,
//   Button,
//   Box
// } from "@mui/material";
// import { Autocomplete } from "@mui/material";
// import CourseModules from "../../../store/CourseModules.json";
// import ScrollToTop from "../../Utilities/ScrollToTop";
// import { useDispatch, useSelector } from 'react-redux';
// import { addLesson } from '../../../features/courses/addCourseDetailsSlice';
// import JoditReact from "jodit-react";


// const AddChapterDialog = ({ moduleId, onClose }) => {
//   const [selectedValue, setSelectedValue] = useState("");
//   const [copyLesson, setCopyLesson] = useState([]);
//   const [selectedLessons, setSelectedLessons] = useState([]);
//   const [selectedOption, setSelectedOption] = useState('create');

//   const dispatch = useDispatch();
//   const { id , courseStructure } = useParams();
//   console.log(id , courseStructure)
//   const [LessonsDetail, setLessonsDetail] = useState({
//     moduleId: "",
//     id: "",
//     title: "",
//     courseStructure:"",
//     description: "", 
//     number: "", 
//     EstTime: "",
//     items: [],

//   });

//   const handleModuleNameChange = (e) => {
//     // const newTitle = e.target.value;
//     const { name, value } = e.target;
//     if(courseStructure === 'CMLT') {
//     setLessonsDetail({
//       ...LessonsDetail,
//       id: generateRandomNumber(),
//       // title: newTitle,
//       moduleId: moduleId ,
//       courseStructure : courseStructure,
//       [name]: value
//     }) } else {
//       setLessonsDetail({
//         ...LessonsDetail,
//         id: generateRandomNumber(),
//         // title: newTitle,
//         [name]: value,
//         courseStructure :courseStructure
//       })
//     }
//   };

//   const generateRandomNumber = () => {
//     const min = 100000;
//     const max = 999999;
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   };

//   const handleSave = async () => {
//     await dispatch(addLesson(LessonsDetail));
//     onClose();
//   };

//   const handleOptionSelect = (event) => {
//     setSelectedValue(event.target.value);
//     setSelectedLessons([]);
//     setCopyLesson([]);
//   };
//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   const handleCopyLessonChange = (event, value) => {
//     setCopyLesson(value);
//     setSelectedLessons(value);
//   };

//   const handleRemoveLesson = (lesson) => {
//     setSelectedLessons((prevSelectedLessons) =>
//       prevSelectedLessons.filter((item) => item !== lesson)
//     );
//     setCopyLesson((prevCopyLesson) =>
//       prevCopyLesson.filter((item) => item !== lesson)
//     );
//   };

//   return (
//     // <Box sx={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop:"74px" }}>
//     //   <Container>
//     //     <ScrollToTop />
//     //     <Grid container spacing={2} sx={{ mb: 2 }}>
//     //       <Grid item xs={12}>
//     //         <Typography style={{ marginBottom: "8px", marginTop: "22px" }}>
//     //           Lesson Title
//     //         </Typography>
//     //         <TextField
//     //           id="outlined-basic"
//     //           placeholder="Enter Lesson Name"
//     //           variant="outlined"
//     //           fullWidth
//     //           value={LessonsDetail.title}
//     //           onChange={handleModuleNameChange}
//     //           required
//     //         />
//     //       </Grid>
//     //     </Grid>

//     //     <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
//     //       <RadioGroup
//     //         name="selectOption"
//     //         value={selectedValue}
//     //         onChange={handleOptionSelect}
//     //         style={{ flexDirection: "row" }}
//     //       >
//     //         <FormControlLabel
//     //           value="copyLesson"
//     //           control={<Radio />}
//     //           label="Copy Lesson"
//     //         />
//     //       </RadioGroup>
//     //     </FormControl>

//     //     {selectedValue === "copyLesson" && (
//     //       <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
//     //         <Autocomplete
//     //           multiple
//     //           options={CourseModules.module.lessons}
//     //           getOptionLabel={(option) => option.title}
//     //           value={copyLesson}
//     //           onChange={handleCopyLessonChange}
//     //           renderInput={(params) => (
//     //             <TextField {...params} label="Select Lesson" variant="outlined" />
//     //           )}
//     //         />
//     //         <div style={{ marginTop: 16 }}>
//     //           {selectedLessons.map((lesson) => (
//     //             <Chip
//     //               key={lesson.id}
//     //               label={lesson.title}
//     //               onDelete={() => handleRemoveLesson(lesson)}
//     //               style={{ marginRight: 8, marginBottom: 8 }}
//     //             />
//     //           ))}
//     //         </div>
//     //       </FormControl>
//     //     )}
//     //     <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
//     //       <Button onClick={onClose} style={{ marginRight: '10px' }}>Cancel</Button>
//     //       <Button variant="contained" onClick={handleOnSave} style={{ backgroundColor: "#0684FB", color: "white" }}>Submit</Button>
//     //     </Box>
//     //   </Container>
//     // </Box>
//     <div style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "14px", maxHeight: '80vh', overflow: 'auto' }}>
//     <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
//       <RadioGroup
//         aria-label="moduleOption"
//         name="moduleOption"
//         value={selectedOption}
//         onChange={handleOptionChange}
//         style={{ flexDirection: 'row' }}
//       >
//         <FormControlLabel
//           value="create"
//           control={<Radio />}
//           label="Create Lesson"
//         />
//         <FormControlLabel
//           value="copy"
//           control={<Radio />}
//           label="Copy Lesson"
//         />
//       </RadioGroup>
//     </FormControl>
//     <div style={{ borderBottom: '1px solid grey', marginBottom: '16px' }}></div>

//     {selectedOption === 'create' && (
//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         {/* <Grid item xs={12}>
//           <h4>General Information</h4>
//         </Grid> */}
//         <Grid item xs={12}>
//           <Typography style={{ marginBottom: "8px", marginTop: "22px" }}>
//             Lesson Title
//           </Typography>
//           <TextField
//             id="outlined-basic"
//             placeholder="Enter Lesson Name"
//             variant="outlined"
//             fullWidth
//             name="title"
//             value={LessonsDetail.title}
//             onChange={handleModuleNameChange}
//             required
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <Typography>Description</Typography>
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             id="outlined-basic"
//             placeholder="Enter Description"
//             variant="outlined"
//             rows="5"
//             multiline
//             fullWidth
//             name="description"
//             value={LessonsDetail.description}
//             onChange={handleModuleNameChange}
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <Typography>
//             Lesson Number
//           </Typography>
//           <TextField
//             id="outlined-basic"
//             placeholder="Enter Module Number"
//             variant="outlined"
//             fullWidth
//             name="number"
//             type="number"
//             value={LessonsDetail.number}
//             onChange={handleModuleNameChange}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <Typography>Estimated Time</Typography>
//           <TextField
//             id="outlined-basic"
//             placeholder="Enter Estimated Time"
//             variant="outlined"
//             fullWidth
//             name="EstTime"
//             value={LessonsDetail.EstTime}
//             onChange={handleModuleNameChange}
//             required
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <Typography>Learning Objectives</Typography>
//         </Grid>
//         <Grid item xs={12}>
//           <JoditReact
//             value={LessonsDetail.objectives}
//             config={{
//               style: {},
//             }}
//           />
//         </Grid>
//       </Grid>
//     )}

//     {selectedOption === 'copy' && (
//       <>
//         <FormControl fullWidth style={{ marginTop: '16px' }}>
//           <Autocomplete
//             multiple
//             options={CourseModules.module.lessons}
//             getOptionLabel={(option) => option.title}
//             value={copyLesson}
//             onChange={handleCopyLessonChange}
//             renderInput={(params) => (
//               <TextField {...params} label="Select Lesson" variant="outlined" />
//             )}
//           />
//         </FormControl>
//         <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
//           {selectedLessons.map((lesson) => (
//             <Chip
//               key={lesson.id}
//               label={lesson.title}
//               onDelete={() => handleRemoveLesson(lesson)}
//               style={{ marginRight: '8px', marginBottom: '8px' }}
//             />
//           ))}
//         </div>
//       </>
//     )}

//     <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
//       <Button onClick={onClose} style={{ marginRight: '10px' }}>Cancel</Button>
//       <Button variant="contained" onClick={handleSave} style={{ backgroundColor: "#0684FB", color: "white" }}>Submit</Button>
//     </div>
//   </div>
//   );
// };

// export default AddChapterDialog;






import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  MenuItem,
  Chip,
  Button,
  Box
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import ScrollToTop from "../../Utilities/ScrollToTop";
import { useDispatch, useSelector } from 'react-redux';
import { addLesson, fetchCourseInSlice, fetchCourseDetailsInSlice , fetchCourseDetailsForCopyInSlice} from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from "jodit-react";

const AddChapterDialog = ({ moduleId, onClose }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [copyLesson, setCopyLesson] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedOption, setSelectedOption] = useState('create');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);

  const [LessonsDetail, setLessonsDetail] = useState({
    moduleId: "",
    id: "",
    title: "",
    courseStructure:"",
    description: "", 
    number: "", 
    EstTime: "",
    items: [],
  });

  const dispatch = useDispatch();
  const { id , courseStructure } = useParams();
  const courses = useSelector(state => state.addCourseDetails.coursesList);
  const courseDetails = useSelector(state => state.addCourseDetails.courseDetails);
  const courseDetailsForCopy =useSelector(state => state.addCourseDetails.courseDetailsForCopy);
  useEffect(() => {
    dispatch(fetchCourseInSlice());
  }, [dispatch]);

  // useEffect(() => {
  //   if (selectedCourse) {
  //     dispatch(fetchCourseDetailsInSlice(selectedCourse));
  //   }
  // }, [selectedCourse, dispatch]);

  const handleModuleNameChange = (e) => {
    const { name, value } = e.target;
    if(courseStructure == "CMLT") {
    setLessonsDetail(prevState => ({
      ...prevState,
      id: generateRandomNumber(),
      moduleId: moduleId,
      courseStructure: courseStructure,
      [name]: value
    }))} else if (courseStructure == "CLT"){
      setLessonsDetail(prevState => ({
        ...prevState,
        id: generateRandomNumber(),
        // moduleId: moduleId,
        courseStructure: courseStructure,
        [name]: value
      }))
    } else {
      console.log("Please Select Correct Course Structure")
      alert("Please Select Correct Course Structure")
      const closeCreateLessonOrModule = () =>{onClose()}
      
    closeCreateLessonOrModule()

    };
  };

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSave = async () => {
    
    console.log( selectedLessons, "copy model");
    

    if(selectedOption == 'copy'){
      await dispatch(addLesson(selectedLessons));

    }else {

      await dispatch(addLesson(LessonsDetail));
    }
    onClose();
  };

  const handleOptionSelect = (event) => {
    setSelectedValue(event.target.value);
    setSelectedLessons([]);
    setCopyLesson([]);
  };

  const handleCourseSelect = (event, value) => {
    
     console.log(value , "handleCourseSelect")
    setSelectedCourse(value);
    if (value) {
      dispatch(fetchCourseDetailsForCopyInSlice(value.id));
    }
  };

  
  const handleModuleSelect = (value) => {
    console.log(value ,"handleModuleSelect" )

    const modifiedValue = value.map(moduleObj => ({
      ...moduleObj,
      module: {
        ...moduleObj.module,
        id: generateRandomNumber(),// Replace generateNewId with your id generation logic
        title: "Copied " + moduleObj.module.title
      }
    }));
  
    console.log(modifiedValue, "Modified handleModuleSelect");
    setSelectedModules(value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCourseChange = (event, value) => {
    setSelectedCourse(value ? value.id : null);
    setSelectedModule(null);
  };

  const handleModuleChange = (event, value) => {
    setSelectedModule(value ? value.id : null);
  };

  const handleCopyLessonChange = (event, value) => {
    console.log(value , "handleCopyLessonChange")
    setCopyLesson(value);
    const modifiedValue = value.map(lessonObj => ({
      ...lessonObj,
      // Replace generateNewId with your id generation logic
        title: "Copied " + lessonObj.title ,
        id: generateRandomNumber(),
        courseStructure: courseStructure,

        
      
    }))
    setSelectedLessons(modifiedValue);
  };

  const handleRemoveLesson = (lesson) => {
    setSelectedLessons(prevSelectedLessons =>
      prevSelectedLessons.filter(item => item !== lesson)
    );
    setCopyLesson(prevCopyLesson =>
      prevCopyLesson.filter(item => item !== lesson)
    );
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "14px", maxHeight: '80vh', overflow: 'auto' }}>
      <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
        <RadioGroup
          aria-label="moduleOption"
          name="moduleOption"
          value={selectedOption}
          onChange={handleOptionChange}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value="create"
            control={<Radio />}
            label="Create Lesson"
          />
          <FormControlLabel
            value="copy"
            control={<Radio />}
            label="Copy Lesson"
          />
        </RadioGroup>
      </FormControl>
      <div style={{ borderBottom:'1px solid grey', marginBottom: '16px' }}></div>

      {selectedOption === 'create' && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: "8px", marginTop: "22px" }}>
              Lesson Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Lesson Name"
              variant="outlined"
              fullWidth
              name="title"
              value={LessonsDetail.title}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Enter Description"
              variant="outlined"
              rows="5"
              multiline
              fullWidth
              name="description"
              value={LessonsDetail.description}
              onChange={handleModuleNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Lesson Number
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Number"
              variant="outlined"
              fullWidth
              name="number"
              type="number"
              value={LessonsDetail.number}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Estimated Time</Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Estimated Time"
              variant="outlined"
              fullWidth
              name="EstTime"
              value={LessonsDetail.EstTime}
              onChange={handleModuleNameChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Learning Objectives</Typography>
          </Grid>
          <Grid item xs={12}>
            <JoditReact
              value={LessonsDetail.objectives}
              config={{}}
            />
          </Grid>
        </Grid>
      )}

      {selectedOption === 'copy' && (
        <>
          <FormControl fullWidth style={{ marginTop: '16px' }}>
          <Autocomplete
              options={courses.map(course => ({ title: course.generalInformation.title, id: course._id }))}
              value={selectedCourse}
              onChange={handleCourseSelect}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label="Select Course" variant="outlined" />
              )}
            />
          </FormControl>

          {selectedCourse && (
            <>
             <FormControl fullWidth style={{ marginTop: '16px' }}>
  <Autocomplete
    multiple
    options={courseDetailsForCopy?.modules?.map(module => ({ module }))}
    value={selectedModules.module}
    onChange={(event, value) => handleModuleSelect(value)}
    getOptionLabel={(option) => option?.module?.title}
    renderInput={(params) => (
      <TextField {...params} label="Select Module" variant="outlined" />
    )}
  />
</FormControl>
              <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
                {selectedModules?.module?.map((module) => (
                  <Chip
                    key={module?.title}
                    label={module?.title}
                    onDelete={() => handleRemoveModule(module)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                  />
                ))}
              </div>
            </>
          )}

{selectedModules?.length > 0 && (
        <FormControl fullWidth style={{ marginTop: '16px' }}>
          <Autocomplete
            multiple
            options={
              selectedModules
                ?.map((module) => module?.module.lessons || [])
                .flat()
            }
            getOptionLabel={(option) => option.title}
            value={copyLesson}
            onChange={handleCopyLessonChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Lesson" variant="outlined" />
            )}
          />
        </FormControl>
      )}

          <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
            {selectedLessons.map((lesson) => (
              <Chip
                key={lesson.id}
                label={lesson.title}
                onDelete={() => handleRemoveLesson(lesson)}
                style={{ marginRight: '8px', marginBottom: '8px' }}
              />
            ))}
          </div>
        </>
      )}

      <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} style={{ marginRight: '10px' }}>Cancel</Button>
        <Button variant="contained" onClick={handleSave} style={{ backgroundColor: "#0684FB", color: "white" }}>Submit</Button>
      </div>
    </div>
  );
};

export default AddChapterDialog;
