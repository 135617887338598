
import React, { useState } from "react";
import { TextField, Tooltip, IconButton, Grid, Typography, InputAdornment, Button, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
import DeleteIcon from '@material-ui/icons/Delete';
import JoditReact from "jodit-react";
import axios from "axios";
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';

const UploadDownload = ({ isOpen, onClose, selectedItem, onBackButtonClick, lessonId, moduleId }) => {
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    selectedValue: "upload",
    links: selectedItem?.link || [],
    uploadUrls: [],
    moduleId: selectedItem?.moduleId || "",
    lessonId: selectedItem?.lessonId || "",
    title: selectedItem?.title || "",
    textDescription: selectedItem?.textDescription || "",
    id: selectedItem?.id || "",
    courseStructure: selectedItem?.courseStructure || "",
    contentType: selectedItem?.contentType || "",
    fileNames: selectedItem?.fileName || []
  });

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState(selectedItem?.fileNames ? selectedItem.fileNames : []);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: generateRandomNumber(),
      contentType: 'Download',
      courseStructure: courseStructure,
    }));
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleFilesChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = [...files, ...selectedFiles];
    setFiles(newFiles);

    const newFileNames = [...fileNames, ...selectedFiles.map(file => file.name)];
    setFileNames(newFileNames);
    setFormData((prevData) => ({
      ...prevData,
      fileNames: newFileNames
    }));
    await requestUploadUrls(selectedFiles);
  };

  const requestUploadUrls = async (files) => {
    try {
      const urls = await Promise.all(
        files.map(file =>
          axios.post('http://localhost:8000/api/course/uploadVideo', {
            fileName: file.name,
            fileType: file.type,
          })
        )
      );
      const uploadUrls = urls.map(response => response.data.url);
      const links = uploadUrls.map(url => url.split('?')[0]);

      setFormData((prevData) => ({
        ...prevData,
        links: [...prevData.links, ...links],
        uploadUrls: [...prevData.uploadUrls, ...uploadUrls],
      }));
    } catch (error) {
      console.error('Error requesting upload URLs:', error);
    }
  };

  const uploadToS3 = async () => {
    setIsLoading(true);
    try {
      await Promise.all(
        formData.uploadUrls.map((url, index) =>
          axios.put(url, files[index], {
            headers: {
              "Content-Type": files[index].type,
            },
          })
        )
      );
      alert('Files uploaded successfully!');
    } catch (error) {
      console.error('Error uploading files:', error);
    }
    setIsLoading(false);
  };

  const handleFileRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    const newFileNames = [...fileNames];
    newFileNames.splice(index, 1);
    setFileNames(newFileNames);

    const newUploadUrls = [...formData.uploadUrls];
    newUploadUrls.splice(index, 1);

    const newLinks = [...formData.links];
    newLinks.splice(index, 1);

    setFormData((prevData) => ({
      ...prevData,
      uploadUrls: newUploadUrls,
      links: newLinks,
    }));
  };

  const handleUploadAll = async () => {
    if (files.length > 0) {
      await uploadToS3();
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    console.log(formData)
    await dispatch(addItem(formData))
    onClose();
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Download
            </Typography>
          </Grid>
        </Grid>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }}>Discard Changes</Button>
          <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }} onClick={handleSubmit}>Save</Button>
        </div>
      </div>
      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <Typography variant="h6" gutterBottom>
          Description
        </Typography>
        <JoditReact
          value={formData.textDescription}
          config={{
            placeholder: "Enter the description",
          }}
          onChange={(newContent) => setFormData((prevData) => ({ ...prevData, textDescription: newContent }))}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            variant="outlined"
            label="Upload Files"
            helperText="Max size: 200MB each"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ borderRadius: 4 }}
                  >
                    Upload Files
                    <input type="file" multiple style={{ display: "none" }} onChange={handleFilesChange} />
                  </Button>
                </InputAdornment>
              ),
            }}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          {fileNames.map((fileName, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <TextField
                variant="outlined"
                value={fileName}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleFileRemove(index)} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ flexGrow: 1, marginRight: '10px' }}
              />
            </div>
          ))}
          {fileNames.length > 0 && (
            <Button variant="contained" color="primary" onClick={handleUploadAll} style={{ marginTop: '10px' }}>
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Upload All Files'}
            </Button>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default UploadDownload;
