import React from 'react';
import { useLocation } from 'react-router-dom';

const DisplayScrom = () => {
  const location = useLocation();
  const { scormUrl } = location.state || {};

  if (!scormUrl) {
    return <div>Error: SCORM URL not found</div>;
  }

  return (
    <div>
      <h2>Uploaded Scrom Course</h2>
      <div style={{display:'flex', width:'80%', margin:'auto'}}>
      <iframe src={scormUrl} width="100%" height="600px" />
      </div>
    </div>
  );
};

export default DisplayScrom;
