import React, { useEffect } from 'react';


import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  MainServiceCont,
  ServiceDescCont,
  ServiceHeadSub,
  ServiceDesc,
  ServiceCardsCont,
  SpanTag,
  TextContOne,
  TextContTwo,
  TextContThree,
  TextContFour,
  HeadOne,
  HeadTwo,
  HeadThree,
  HeadFour,
  DescOne,
  DescTwo,
  DescThree,
  DescFour,
  SectionA,
  SectionB,
} from '../../styles/Landing/ServicesLandingStyle';

import { TitleB, TitleDash, TitleHeadComCont } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';

const ServicesLanding = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <MainServiceCont>
        <TitleHeadComCont>
          <TitleB
            // data-aos="fade-left"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="500"
            // data-aos-delay="100"
          >
            {t("services.servicesLandingLabel")}
          </TitleB>
          <TitleDash
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          />
        </TitleHeadComCont>
        <ServiceDescCont>
          <ServiceHeadSub
            // data-aos="zoom-in"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="500"
            // data-aos-delay="200"
          >
            {t("services.servicesLandingEmpoweringText")}
          </ServiceHeadSub>
          <ServiceDesc
            // data-aos="zoom-in"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="500"
            // data-aos-delay="300"
          >
            {t("services.personalizedServicesText")}
            <SpanTag>
              {t("services.personalizedServicesText")}
            </SpanTag>{' '}
            {t("services.landingCDSText")}
            <SpanTag>
              {t("services.digitalLearningLabel")}
            </SpanTag>{' '}
            {t("services.digitalLearningText")}
              <SpanTag>  {t("services.landingStaffingServicesLabel")}</SpanTag> 
              {t("services.landingStaffingServicesText")}
          </ServiceDesc> 
        </ServiceDescCont>

        <ServiceCardsCont>
          <SectionA>
            <TextContOne
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <HeadOne>  {t("services.landingCSDLabel")}</HeadOne>
              <DescOne>
                {t("services.landingCSDText")}


              </DescOne>
            </TextContOne>
            <TextContTwo
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <HeadTwo> {t("services.landingDCD")}</HeadTwo>
              <DescTwo>
                {t("services.landingDCDText")}
              </DescTwo>
            </TextContTwo>
          </SectionA>
          <SectionB>
            <TextContThree
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <HeadThree>{t("services.landingEducationalLabel")}</HeadThree>
              <DescThree>
                {t("services.landingEducationalText")}
              </DescThree>
            </TextContThree>
            <TextContFour
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <HeadFour>{t("services.ServicesStaffingServicesLabel")}</HeadFour>
              <DescFour>
                {t("services.ServicesStaffingServicesText")}

              </DescFour>
            </TextContFour>
          </SectionB>
        </ServiceCardsCont>

        <TitleHeadComCont>
          <TitleB
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            {t("services.landingLeapotAcademyLabel")}
          </TitleB>
          <TitleDash
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="500"
            data-aos-delay="100"
          />
        </TitleHeadComCont>
        <ServiceDesc
          // data-aos="fade-left"
          // data-aos-easing="ease-in-out"
          // data-aos-duration="500"
          // data-aos-delay="100"
        >
          {t("services.landingLeapotAcademyText")}

        </ServiceDesc>
      </MainServiceCont>
    </>
  );
};

export default ServicesLanding;
