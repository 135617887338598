import React, { useEffect, useState } from 'react';
import {
  ProfileContainer,
  Heading,
  HeaderContent,
  ProfileImage,
  ProfileImageCont,
  ImageContainerSmall,
  SubmitBtnStyle,
  BoxStyling,
} from '../../styles/Profile/ProfileStyle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { HeaderContainer } from '../../styles/Common/CommonStyles';
import { Tooltip, IconButton } from '@mui/material';
import { FaPlus } from 'react-icons/fa';
import { palette } from '../../styles/palette';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { connect } from 'react-redux';
import AdditionalField from './AdditionalField';
import ProfileContainerComp from './ProfileContainerComp';
import {
  StyledFormContainer,
  StyledForm,
  StyledBackButton,
  StyledInputContainer,
  StyledInputWrapper,
  StyledLabel,
  StyledInput,
  StyledSubmitButton,
  StyledImg,
  StyledInputWrapperRight,
} from '../../styles/Profile/ProfileStyle';
import { useDispatch, useSelector } from 'react-redux';

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state?.auth);
  console.log("users from My Profile Page")
  console.log(users);
  const [formData, setFormData] = useState({
    username: users?.user?.userid?.username,
    email: users?.user?.userid?.email,
    phoneNo: users?.user?.userid?.phoneNo,
    address: users?.user?.address,
    state: users?.user?.state,
    country: users?.user?.country,
    // pincode: user?.user?.pincode,
    city: users?.user?.city,
    companyName: users?.user?.username,
    picture: users?.user?.userid?.picture,
  });

  const [profileErrorState, setProfileErrorState] = useState({
    username: false,
    email: false,
    phoneNo: false,
    address: false,
    state: false,
    country: false,
    city: false,
    userImgUrl: false,
    picture: false,
  });

  return (
    <ProfileContainer>
      <HeaderContainer>
        <HeaderContent>
          <Tooltip title="Go Back">
            <IconButton
              size="small"
              onClick={() => navigate(-1)}
              sx={{ color: `${palette.THEME_THREE}` }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <Heading>My Profile</Heading>
        </HeaderContent>
      </HeaderContainer>
      <ProfileContainerComp userData={users} />
    </ProfileContainer>
  );
};

export default MyProfile;
