import React, { useState } from 'react'
import CreateCourseForm from './CreateCourseForm';
import axios from 'axios';
import ScormPlayer from './ScromPlayer';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ProgressModal = ({ open, progress, completed, onClose }) => (
  <Modal open={open} onClose={completed ? onClose : () => { }}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" component="h2">
        Uploading files, please wait...
      </Typography>
      <Box sx={{ width: '100%', mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography sx={{ mt: 2 }}>{`${Math.round(progress)}%`}</Typography>
    </Box>
  </Modal>
);

export const ScormUploader = ({scormUrl, setScormUrl}) => {
  const [files, setFiles] = useState([]);
  const [uploadUrls, setUploadUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [scormFolderPath, setScormFolderPath] = useState('');
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const onDrop = async (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
    for (const file of newFiles) {
      await requestUploadUrl(file.webkitRelativePath, file.type);
    }
  };

  const requestUploadUrl = async (filePath, fileType) => {
    try {
      const response = await axios.post('http://localhost:8000/api/uploadscrom', {
        fileName: filePath,
        fileType: fileType,
      });
      const uploadUrl = response.data.url;

      setUploadUrls(prevUrls => ({ ...prevUrls, [filePath]: response.data.url }));

      setScormFolderPath(response.data.path); // Save the folder path

    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async (file, url) => {
    try {
      const data = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(prevProgress => {
          // Calculate the increment based on the number of files
          const increment = percentCompleted / files.length;
          // Ensure progress doesn't exceed 100%
          const newProgress = Math.min(prevProgress + increment, 100);
          return newProgress;
        });
      },
    });
      console.log('File uploaded successfully', data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
    setProgress(0);
    setCompleted(false);

    for (const file of files) {
      const url = uploadUrls[file.webkitRelativePath];
      if (url) {
        await uploadToS3(file, url);
      }
    }
    const mainScormFile = files.find(file => file.name === 'story.html');
    if (mainScormFile) {
      // const scormPath = mainScormFile.webkitRelativePath;
      // const scormUrl = `https://leapot-lms.s3.ap-south-1.amazonaws.com/lms/course/scorm/${scormPath}`;
      // setScormUrl(scormUrl);

      const baseUrl = new URL(uploadUrls[mainScormFile.webkitRelativePath]).origin;
      const scormUrl = `${baseUrl}/${scormFolderPath}${mainScormFile.webkitRelativePath}`;
      setScormUrl(scormUrl);

    }

    setCompleted(true);
    setLoading(false);

  };

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{border: '1px solid black', display:'flex', justifyContent:'space-between', alignItems:'center', width:'70%', margin:'auto', padding:'25px', marginBottom:'45px'}} > 
      <input
        type="file"
        webkitdirectory="true"
        directory="true"
        multiple
        onChange={onDrop}
        style={{
          padding: '10px',  
          fontSize: '16px', 
          border: '1px solid #ccc',
          borderRadius: '5px', 
          marginRight: '10px'
        }}
      />
      <Button variant="contained" onClick={handleSendMessage} disabled={files.length === 0 || loading}>
        Upload
      </Button>
      </div>
      <ProgressModal
        open={loading}
        progress={progress}
        completed={completed}
        onClose={() => setLoading(false)}
      />
      <div style={{margin:'20px'}}>
      {scormUrl && <ScormPlayer scormUrl={scormUrl} />}
      </div>
    </div>
  );
};

const ScormImport = () => {
  const [scormUrl, setScormUrl] = useState('');

  return (
    <div style={{ marginTop: '15px' }}>
      <CreateCourseForm isImport={true} scormUrl={scormUrl} setScormUrl={setScormUrl}/>
    </div>
  )
}

export default ScormImport

