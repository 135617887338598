import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserData from '../../store/UsersData.json';
import BackButton from '../Utilities/BackButton';
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { HeadingH2, Text } from '../../styles/Common/CommonStyles';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAnnouncementInfo } from '../../features/calendar/CalendarSlice';
import {
  Head,
  Details,
  ImgSection,
  Info,
  Profile,
  SubDetailDiv,
  Form,
  CheckboxContainer,
} from '../../styles/Calendar/CalendarStyle';
import { useTranslation } from 'react-i18next';

// Function to format date as MM-DD-YYYY
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const AnnounceDetails = () => {
  const { announcementNo } = useParams();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.calendar.info);
  const announcement = useSelector((state) => state.calendar.announcement);

  const filteredAnnouncements = announcement?.filter(
    (announcement) => announcement._id === announcementNo,
  );
  console.log(filteredAnnouncements);

  const announcementDetails = filteredAnnouncements.length
    ? filteredAnnouncements[0]
    : null;

  const { t } = useTranslation();
  return (
    <Details>
      <Head>
        <BackButton />
        <HeadingH2>{announcementDetails?.announcementNo}</HeadingH2>
      </Head>
      <Profile>
        <Info>
          <TextField
            label={t('calender.announcementNumb')}
            variant="outlined"
            value={announcementDetails?.announcementNo}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.eventNameLabel')}
            variant="outlined"
            value={announcementDetails?.eventName}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.startdateLabel')}
            variant="outlined"
            margin="normal"
            value={formatDate(announcementDetails?.eventDate)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.starttimeLabel')}
            variant="outlined"
            margin="normal"
            value={announcementDetails?.startTime}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
          <TextField
            label={t('calender.eventTypeLabel')}
            variant="outlined"
            value={announcementDetails?.type}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '90%' }}
          />
        </Info>
        <ImgSection>
          <img
            src={announcementDetails?.image}
            alt="User Avatar"
            style={{ width: '500px', height: '300px' }}
          />
        </ImgSection>
      </Profile>
    </Details>
  );
};

export default AnnounceDetails;
