import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import {
  NavbarContainer,
  NavbarContainerMain,
  Image,
  ImageContainer,
  Bars,
  NavMenu,
  NavLink,
  NavBtn,
  NavBtnLink,
  ProfileHead,
  Initial,
  ProfileContainer,
  faCustomStyle,
  Container1,
  ProfileLink,
  ProfilePic,
  SmallScreenDiv,
  SmallScreenUl,
  SmallScreenLi,
  SmallNavLink,
  Hamburger,
} from '../../styles/Landing/NavbarElements';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { logInUser } from '../../features/authentication/AuthenticationSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import LanguageSelector from '../LanguageSelector';
import { useTranslation } from 'react-i18next';
import Loader from '../Utilities/Loader'; 

const NavbarMain = ({ flushLoginData, userProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setIsOpen(false); // Close the menu when resizing to a larger screen
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((userCred) => {
  //     if (userCred) {
  //       console.log(userCred);
  //       userCred.getIdToken().then((token) => {
  //         dispatch(logInUser(token))
  //         navigate("/Dashboard")
  //       });
  //     }
  //   });
  // }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOnLogoClick = () => {
    navigate('/');
  };

  const [displayProfile, setDisplayProfile] = useState(false);

  if (loading) {
    return  < Loader/>;
  }
  const { t } = useTranslation();

  return (
    <>
      <NavbarContainerMain>
        <ImageContainer>
          <Image
            src={require('../../images/NoBackground.png')}
            onClick={handleOnLogoClick}
          />
        </ImageContainer>

        {isSmallScreen ? (
          isOpen ? (
            <Hamburger>
              <FaBars
                fontSize="large"
                style={{ color: 'white' }}
                className="text-white text-2xl cursor-pointer"
                onClick={toggleMenu}
              />
            </Hamburger>
          ) : (
            <Hamburger>
              <FaBars
                fontSize="large"
                style={{ color: 'white' }}
                className="text-white text-2xl cursor-pointer"
                onClick={toggleMenu}
              />
            </Hamburger>
          )
        ) : (
          <NavMenu className="flex items-center">
            <NavLink to="/aboutus">{t("navbar.navbarAboutLabel")}</NavLink>
            <NavLink to="/services">{t("navbar.navbarServicesLabel")}</NavLink>
            <NavLink to="/contactus">{t("navbar.navbarContactLabel")}</NavLink>
            <LanguageSelector />
            <NavBtn>
              <NavBtnLink to="/login">{t("navbar.navbarLoginLabel")}</NavBtnLink>
            </NavBtn>
          </NavMenu>
        )}
      </NavbarContainerMain>

      {isSmallScreen && isOpen && (
        <SmallScreenDiv isOpen={isOpen}>
          <SmallScreenUl>
            <SmallScreenLi>
              <SmallNavLink to="/home" onClick={toggleMenu}>
                {t("navbar.navbarHomeLabel")}
              </SmallNavLink>
            </SmallScreenLi>
            <SmallScreenLi>
              <SmallNavLink to="/aboutus" onClick={toggleMenu}>
              {t("navbar.navbarAboutLabel")}
              </SmallNavLink>
            </SmallScreenLi>

            <SmallScreenLi>
              <SmallNavLink to="/services" onClick={toggleMenu}>
              {t("navbar.navbarServicesLabel")}
              </SmallNavLink>
            </SmallScreenLi>

            <SmallScreenLi>
              <SmallNavLink to="/contactus" onClick={toggleMenu}>
              {t("navbar.navbarContactLabel")}
              </SmallNavLink>
            </SmallScreenLi>

            <SmallScreenLi>
              <SmallNavLink to="/login" onClick={toggleMenu}>
              {t("navbar.navbarLoginLabel")}
              </SmallNavLink>
            </SmallScreenLi>

            <SmallScreenLi>
                <LanguageSelector />
            </SmallScreenLi>

          </SmallScreenUl>
        </SmallScreenDiv>
      )}
    </>
  );
};

export default NavbarMain;
