// import React, { useState, useEffect } from 'react';
// import { Box,Typography, Grid, TextField, Button } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import { updateLesson } from '../../../features/courses/addCourseDetailsSlice';
// import JoditReact from "jodit-react";
// import { useParams } from 'react-router-dom';

// const CreateLesson = ({ lesson, onClose }) => {
//   const dispatch = useDispatch();
//   const [lessonDetails, setLessonDetails] = useState({ ...lesson });

//   useEffect(() => {
//     setLessonDetails({ ...lesson });
//   }, [lesson]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setLessonDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = () => {
//     dispatch(updateLesson(lessonDetails));
//     onClose(); // Close the CreateLesson component
//   };

//   const handleCancel = () => {
//     onClose(); // Close the CreateLesson component
//   };

//   return (
//     <Box style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "14px", maxHeight: '80vh', overflow: 'auto' }}>
//                 <Grid container spacing={2} sx={{ mb: 2 }}>
//           <Grid item xs={12}>
//             <Typography style={{ marginBottom: "8px", marginTop: "22px" }}>
//               Lesson Title
//             </Typography>
//             <TextField
//               id="outlined-basic"
//               placeholder="Enter Lesson Name"
//               variant="outlined"
//               fullWidth
//               name="title"
//               value={lessonDetails.title}
//               onChange={handleChange}
//               required
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography>Description</Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               id="outlined-basic"
//               placeholder="Enter Description"
//               variant="outlined"
//               rows="5"
//               multiline
//               fullWidth
//               name="description"
//               value={lessonDetails.description}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>
//               Lesson Number
//             </Typography>
//             <TextField
//               id="outlined-basic"
//               placeholder="Enter Lesson Number"
//               variant="outlined"
//               fullWidth
//               name="number"
//               type="number"
//               value={lessonDetails.number}
//               onChange={handleChange}
//               required
//             />
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>Estimated Time</Typography>
//             <TextField
//               id="outlined-basic"
//               placeholder="Enter Estimated Time"
//               variant="outlined"
//               fullWidth
//               name="EstTime"
//               value={lessonDetails.EstTime}
//               onChange={handleChange}
//               required
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography>Learning Objectives</Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <JoditReact
//               value={lessonDetails.objectives}
//               config={{
//                 style: {},
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//           <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginRight: '8px' }}>
//             Save
//           </Button>
//           <Button variant="contained" onClick={handleCancel}>
//             Cancel
//           </Button>
//         </Grid>
//         </Grid>
//     </Box>
//   );
// };

// export default CreateLesson;
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Button, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateLesson, deleteLesson } from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from 'jodit-react';

const CreateLesson = ({ lesson, onClose }) => {
  const dispatch = useDispatch();
  const [lessonDetails, setLessonDetails] = useState({ ...lesson });

  useEffect(() => {
    setLessonDetails({ ...lesson });
  }, [lesson]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLessonDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(updateLesson(lessonDetails));
    onClose(); // Close the CreateLesson component
  };

  const handleCancel = () => {
    onClose(); // Close the CreateLesson component
  };

  const handleDelete = () => {
    dispatch(deleteLesson({ moduleId: lesson.moduleId, lessonId: lesson.id }));
    onClose(); // Close the CreateLesson component
  };

  return (
    <Box style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '14px', maxHeight: '80vh', overflow: 'auto' }}>
      <div style={{ display: "flex", justifyContent: "right", alignItems: "center"}}>
          <Grid item xs={12}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px" }} onClick={handleCancel}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginRight: '8px' }}>
            Save
          </Button>
        </Grid>
      </div>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography style={{ marginBottom: '8px', marginTop: '22px' }}>Lesson Title</Typography>
          <TextField
            id="outlined-basic"
            placeholder="Enter Lesson Name"
            variant="outlined"
            fullWidth
            name="title"
            value={lessonDetails.title}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Description</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            placeholder="Enter Description"
            variant="outlined"
            rows="5"
            multiline
            fullWidth
            name="description"
            value={lessonDetails.description}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>Lesson Number</Typography>
          <TextField
            id="outlined-basic"
            placeholder="Enter Lesson Number"
            variant="outlined"
            fullWidth
            name="number"
            type="number"
            value={lessonDetails.number}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>Estimated Time</Typography>
          <TextField
            id="outlined-basic"
            placeholder="Enter Estimated Time"
            variant="outlined"
            fullWidth
            name="EstTime"
            value={lessonDetails.EstTime}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Learning Objectives</Typography>
        </Grid>
        <Grid item xs={12}>
          <JoditReact value={lessonDetails.objectives} config={{ style: {} }} />
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="Delete Lesson">
          <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateLesson;

