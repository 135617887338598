import React, { useState ,useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { palette } from '../../../styles/palette';
import { useDispatch , useSelector} from "react-redux";
import { saveDetails ,fetchCourseDetailsInSlice} from '../../../features/courses/addCourseDetailsSlice';
import PreviewModuleOrLesson from './PreviewModuleOrLesson';
import ContentPlayer from '../ContentPlayer';
import CreateTopicText from './LearnerPreviewTopic/CreateTopicText';



const LearnerCoursePreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseTitle } = location.state || {};
  const dispatch = useDispatch()
  const [expandedModule, setExpandedModule] = useState(null);
  const [expandedLessons, setExpandedLessons] = useState({});
  const [selectedItem, setSelectedItem] = useState(null); 
  const [selectedPreviewItem, setSelectedPreviewItem] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null); 
  const [openTopicDialog, setOpenTopicDialog] = useState(false);
  const [moduleId, setModuleId] = useState(null); 
  const [lessonId, setLessonId] = useState(null); 

  const { modules ,lessons} = useSelector((state) => state.addCourseDetails.courseDetails) || {};
  const { id , courseStructure } = useParams();

  useEffect(() => {
    const fetchCourseDetails = async(id) =>{
     await dispatch(fetchCourseDetailsInSlice(id))
    }
    fetchCourseDetails(id)
  }, [id]);

  const getItemTitle = (item) => {
    return item.content || item.title || ''; // Update this based on your JSON structure
  };
  
  const getItemContent = (item) => {
    return item.text || item.content || ''; // Update this based on your JSON structure
  };
  const handleCreateTopicsClick = () => {
    // Close AddTopicDialog if it's open
    setOpenTopicDialog(false);
  };
  const handleCloseCreateTopic = () => {
    setSelectedItem(false);
  };
  const handleModuleOnClick = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };
  
  const handleDeleteTopic = async (moduleId , lessonId , id) =>{
    await  dispatch(deleteTopic({moduleId , lessonId , id}))
  }


  const handleModuleTextClick = (module) => {
    setSelectedPreviewItem(module);
    setSelectedItemType('module');
  };

  const handleLessonClick = (lessonId) => {
    setExpandedLessons({
      ...expandedLessons,
      [lessonId]: !expandedLessons[lessonId],
    });
  };

  const handleLessonTextClick = (lesson) => {
    setSelectedPreviewItem(lesson);
    setSelectedItemType('lesson');
  };

  const isModuleExpanded = (moduleId) => {
    return expandedModule === moduleId;
  };

  const isLessonExpanded = (lessonId) => {
    return expandedLessons[lessonId];
  };

  const handleSaveCourse = async () =>{
    const result = await  dispatch(saveDetails({modules:modules , courseStructure :courseStructure , courseId:id , lessons:lessons }))
    if(result.payload?.data?.success){
      alert(result.payload?.data?.message)
    }else {
      alert(result.payload?.data?.message)
    }
  }

  const handleCloseDetailsView = () => {
    setSelectedPreviewItem(null);
    setSelectedItemType(null);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: 385,
          height: '100vh',
          backgroundColor: '#F1F1F1',
          padding: '20px',
        }}
      >
        <div style={{ display: "flex" }}>
        <Tooltip title="Go Back">
          <IconButton
            size="small"
            onClick={() => navigate('/CourseBuilder')}
            sx={{ color: `${palette.THEME_THREE}` }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <h4 style={{color:"#555454"}}>{courseTitle}</h4>
        </div>
        <div style={{ width: '100%', borderTop: '1px solid #ccc' }} />

        <List style={{ height: '70%', overflowY: 'auto'}}>
        {courseStructure === 'CMLT' ? (
          modules?.map((module) => (
            <div key={module?.id}>
              <ListItem style={{ background: '#fff', marginTop: '10px' }}>
                <ListItemText 
                  sx={{ color: `${palette.primary}` }}
                  primary={module?.title}
                  onClick={() => handleModuleTextClick(module)}
                />
                <Box sx={{ marginRight: '8px' }} />
                {isModuleExpanded(module?.id) ? (
                  <ExpandLessIcon onClick={() => handleModuleOnClick(module.id)}/>
                ) : (
                  <ExpandMoreIcon onClick={() => handleModuleOnClick(module.id)} />
                )}
              </ListItem>
              <Collapse in={isModuleExpanded(module?.id)} timeout="auto" unmountOnExit>
                <List component={Paper} style={{ padding: '20px', marginBottom: '10px' }}>
                  {module?.lessons?.map((lesson) => (
                    <div key={lesson.id}>
                      <ListItem style={{ background: '#fff', marginBottom: '10px', border: '1px solid #CACACA', borderRadius: '5px' }}>
                        <ListItemText 
                          sx={{ color: `${palette.secondary}` }} 
                          primary={lesson.title}
                          onClick={() => handleLessonTextClick(lesson)}
                        />
                        <Box sx={{ marginRight: '8px' }} />
                        {isLessonExpanded(lesson.id) ? (
                          <ExpandLessIcon onClick={() => handleLessonClick(lesson.id)}/>
                        ) : (
                          <ExpandMoreIcon onClick={() => handleLessonClick(lesson.id)}/>
                        )}
                      </ListItem>
                      <Collapse in={isLessonExpanded(lesson.id)} timeout="auto" unmountOnExit>
                        <List>
                          {lesson?.items?.map((item, index) => (
                            <ListItem
                              key={index}
                              style={{
                                background: selectedItem === item ? '#d0eaff' : '#f9f9f9', // Highlight if selected
                                marginBottom: '5px',
                                paddingLeft: '20px',
                                cursor: 'pointer'
                              }}
                              onClick={() => setSelectedItem(item)}
                            >
                              <ListItemText primary={getItemTitle(item)} />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Collapse>
            </div>
          ))
        ) : (
          // modules?.map((module) => (
          //   <div key={module?.id}>
          //     <ListItem
          //       style={{
          //         background: '#fff',
          //         marginBottom: '10px',
          //         border: '1px solid #CACACA',
          //         borderRadius: '5px',
          //       }}
          //       onClick={() => handleModuleTextClick(module)}
          //     >
          //       <ListItemText
          //         sx={{ color: `${palette.secondary}` }}
          //         primary={module?.title}
          //       />
          //     </ListItem>
          //   </div>
          // ))
          <List  
          component={Paper}
          style={{
            padding: '20px',
            marginBottom: '10px'
          }}
        >
                  {lessons?.map((lesson) => (
                    <div key={lesson.id}>
                      <ListItem  
                        // onClick={() => handleLessonClick(lesson.id)}
                        style={{
                          background: '#fff',
                          marginBottom: '10px',
                          border: '1px solid #CACACA',
                          borderRadius: '5px'
                        }}
                      >
                        <ListItemText 
                        sx={{ color: `${palette.secondary}` }} 
                        primary={lesson.title}
                        onClick={() => handleLessonTextClick(lesson)}
                         />
                        <Tooltip title="Add Topic">
                          <AddIcon onClick={() => handleAddTopic(lesson.moduleId , lesson.id)} />
                        </Tooltip>
                        {/* <Tooltip title="Delete Lesson">
                          <DeleteIcon onClick={() => handleDeleteLesson(lesson?.moduleId, lesson.id)} style={{ cursor: 'pointer', fontSize: '20px' }} />
                        </Tooltip> */}
                        <Box sx={{ marginRight: '8px' }} />
                        {isLessonExpanded(lesson.id) ? (
                          <ExpandLessIcon onClick={() => handleLessonClick(lesson.id)}/>
                        ) : (
                          <ExpandMoreIcon onClick={() => handleLessonClick(lesson.id)} />
                        )}
                      </ListItem>
                      <Collapse
                        in={isLessonExpanded(lesson.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {lesson?.items?.map((item, index) => (
                            <ListItem
                            key={index}
                            style={{
                              background: selectedItem === item ? '#d0eaff' : '#f9f9f9', // Highlight if selected
                              marginBottom: '5px',
                              paddingLeft: '20px',
                              cursor: 'pointer'
                            }}
                            onClick={() => setSelectedItem(item)}
                            >
                              <ListItemText primary={getItemTitle(item)} />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
        )}
        </List>
      </div>
      <div style={{ flex: 1, padding: '20px', marginTop:"-30px" }}>
        {selectedPreviewItem && selectedItemType && (
          <PreviewModuleOrLesson
            item={selectedPreviewItem}
            type={selectedItemType}
            onClose={handleCloseDetailsView}
          />
        )}

{/* {selectedItem && (
          <CreateTopicText
            itemTitle={getItemTitle(selectedItem)}
            itemContent={getItemContent(selectedItem)}
            onClick={handleCreateTopicsClick} // Add event handler to close CreateTopics
            // lessonId= {lessonId}
            // moduleId = {moduleId}
            // selectedItem = {selectedItem}
            lessonId={selectedItem.lessonId}
            moduleId={selectedItem.moduleId}
            selectedItem={selectedItem}
            handleDeleteTopic={handleDeleteTopic}
            onClose={handleCloseCreateTopic}  
          />
      )} */}
              {selectedItem && (
          <ContentPlayer
            item={selectedItem}
            onClose={handleCloseCreateTopic}
          />
        )}

      </div>
    </div>
  );
};

export default LearnerCoursePreview;
