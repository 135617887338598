// code 3
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import tw from 'twin.macro';

import {
  MainContainer,
  LangSelect,
  Option,
  OptionsContainer,
  SelectButton,
} from '../styles/languageSelectorStyle';

const languages = [
  { code: 'en', lang: 'English' },
  { code: 'sp', lang: 'Spanish' },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const handleOptionClick = (code, lang) => {
    i18n.changeLanguage(code);
    setSelectedLanguage(lang);
    setIsOpen(false);
  };

  return (
    <MainContainer>
      <LangSelect>
        <SelectButton onClick={() => setIsOpen(!isOpen)}>
          {selectedLanguage || 'Language'}
        </SelectButton>
        <OptionsContainer isOpen={isOpen}>
          {languages.map((lang) => (
            <Option
              key={lang.code}
              onClick={() => handleOptionClick(lang.code, lang.lang)}
            >
              {lang.lang}
            </Option>
          ))}
        </OptionsContainer>
      </LangSelect>
    </MainContainer>
  );
};

export default LanguageSelector;
