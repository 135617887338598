import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../palette";
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';

export const CourseBuilderContainer = styled.div`
  ${tw`w-full`};
  background: ${palette.bgColor1};
`;
export const ContainerCourse = styled.div`
  ${tw`w-full flex p-8 items-center justify-center`}
`;

export const HeaderContainer = styled.div`
  ${tw`w-full shadow-md justify-between flex items-center lg:h-24 `}
  color: ${palette.black};
  background: ${palette.bgColor2};
`;

export const Content = styled.div`
  ${tw`pl-5`}
`;

export const Title = styled.p`
  ${tw`font-semibold pl-2.5`}
`;

export const Heading = styled.p`
  ${tw`2xl:text-xl lg:text-base font-semibold text-lg`}
`;
export const Description = styled.p`
  ${tw` font-bold	2xl:text-lg lg:text-xs lg:mt-12`}
  white-space: nowrap; // Ensure the text appears in a single line
  margin-top: 24px;
  // overflow: hidden; // Hide overflow text
  // text-overflow: ellipsis; // Show ellipsis (...) for overflow text

`;

export const ButtonCont = styled.div`
  ${tw`mr-8 flex justify-end`}
`;
export const ButtonText = styled.p`
  ${tw`lg:text-sm`}
`;

export const CreateCourseContainer = styled.div`
  ${tw`w-full`};
`;

export const CreateContent = styled.div`
  ${tw`flex pl-5 items-center`}
`;

export const BodyContainer = styled.div`
  ${tw`m-5`}
`;

export const ToggleCont = {
  display: "flex",
  alignItems: "center",
};

export const PriceDesc = styled.div`
  ${tw`p-4 mt-6`}
  background: ${palette.light_gray}
`;

export const ArrowBackCont = styled.div`
  ${tw`mr-2.5 cursor-pointer`}
`;

export const ContainerStyle = {
  marginTop: "20px",
  marginBottom: "40px",
  // background: `${palette.bgColor3}`,
};

export const StepperContainer = styled.div`
  margin: 50px;
  width: 80%;
`;

export const ButtonContainer = styled.div`
  margin: 50px;
  width: 80%;
`;

export const HeadingClass = {
  fontWeight: 600,
};

export const DescriptionClass = {
  marginBottom: "36px",
  overflowWrap: "normal",
  wordBreak: "normal",
  // width: "80%",
};

export const Step3ContainerStyle = {
  marginTop: "50px",
  marginBottom: "50px",
  background: `${palette.bgColor3}`,
};

export const ToggleStyle = {
  disply: "flex",
};

export const courseBuilder = {
  "& .MuiGrid-root ": {
    background: `${palette.bgColor3}`,
  },
};

export const ContentHeading = {
  fontSize: "18px",
  marginTop: "10px",
  marginBottom: "10px",
  fontWeight: "600",
};

export const TextFieldStyle = {
  "& .MuiInputBase-root ": {
    background: `${palette.white}`,
    // width: "500px",
    marginRight: "10px",
    border: "1px solid ",
    borderColor: `${palette.primary}`,
  },
};

export const Caption = styled.p`
  font-size: 12px;
  padding: 0;
  margin: 0;
`;

export const FormStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "30px",
  borderBottom: "1px solid",
  borderColor: `${palette.primary}`,
};

export const ManualStyle = {
  display: "flex",
  justifyContent: "flex-start",
  
};


export const StyledCard = styled(Card)`
  width: 100%;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); /* Example of hover effect */
  }
`;

////////////////////////////////////

export const ChatContainer = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
width: 100%;
`;

export const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
background: #fff;
border-bottom: 1px solid #ddd;
`;

export const UserInfo = styled.div`
${tw ` flex items-center`}
`;

export const UserName = styled.div`
  font-weight: bold;
  font-size: 1em;
  color: ${palette.primary};
`;

export const CourseTitle = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  color: ${palette.primary};
`;

export const UserStatus = styled.div`
  color: gray;
  font-size: 0.9em;
`;

export const HeaderIcons = styled.div`
  display: flex;
  gap: 15px;
`;

export const Messages = styled.div`
flex: 1;
overflow-y: auto;
padding-left: 30px;
padding-top: 20px;
`;

export const MessageContainer = styled.div`
display: flex;
align-items: flex-start;
margin-bottom: 10px;
`;

export const BubbleHead = styled.div`
${tw `flex justify-between items-center gap-5`}
`;

export const Icons = styled.div`
${tw`flex gap-2.5`}
`;

export const Bubble = styled.div`
${tw `rounded-lg p-4 relative m-1`}
border: 2px solid #DFEEF6;
max-width: 85%;
`;

export const TimeStamp = styled.div`
position: absolute;
right: 10px;
bottom: 1px;
font-size: 10px;
color: #aaa;
`;

export const ReplyContainer = styled.div`
  font-size: 12px;
  color: #777;
  margin-top: 5px;
`

export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const InputContainer = styled.div`
  padding: 10px 20px;
  background: #fff;
  border-top: 1px solid #ddd;
`;

export const ImageContainer = styled.div`
max-width: 100%;
max-height: 300px;
border-radius: 5px;
margin-top: 10px;
`;

export const ReplyMessage = styled.div`
  background-color: #e0e0ff;
  padding: 15px;
  border-radius: 15px;
  margin-left: 15px;
`;

