import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CertificateTabs from './CertificateTabs';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../Utilities/CommonButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Grid,
  Card,
  Typography,
  TextField,
  CardActionArea,
  CardContent,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { palette } from '../../styles/palette';
import {
  fetchCertificateTemplate,
  deleteCertificateTemplate,
  addCertificate,
} from '../../features/certificate/CertificateSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchEmail,
  ButtonDiv,
  WholePage
} from '../../styles/Certificate/CertificateStyle';
import { Text } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
function CertificatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [certificateName, setCertificateName] = useState('');
  const [certificateTemplates, setCertificateTemplates] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const { data } = useSelector((state) => state.certificate) || {};
  const certRefs = useRef([]);
  const displayDiv = false;
  const { t } = useTranslation();

  const filteredTemplates = data?.templates
    ? data.templates.filter((template) =>
        template.certificateName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      )
    : [];

  const handleOpenModal = (template) => {
    setSelectedTemplate(template);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectClick = (template) => {
    setSelectedTemplate(template);
    setIsNameDialogOpen(true);
  };

  const handleCloseNameDialog = () => {
    setIsNameDialogOpen(false);
  };

  const handleCertificateNameChange = (event) => {
    setCertificateName(event.target.value);
  };

  const handleSubmitName = async () => {
    if (certificateName.trim() === '') {
      alert('Please enter a certificate name.');
      return;
    }
    try {
      const response = await dispatch(
        addCertificate({
          certificateName: certificateName,
          certificateBody: selectedTemplate.certificateBody,
        }),
      );
      setIsNameDialogOpen(false);
      toast.success('Certificate created successfully!');
      navigate('/Certificate/SeparateCertificate');
    } catch (error) {
      console.error('Error creating certificate:', error);
    }
  };

  const handleDeleteDialogOpen = (template) => {
    setTemplateToDelete(template);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handleDeleteTemplate = () => {
    dispatch(deleteCertificateTemplate(templateToDelete._id))
      .then(() => {
        const updatedTemplates = certificateTemplates.filter(
          (template) => template._id !== templateToDelete._id,
        );
        setCertificateTemplates(updatedTemplates);
        setDeleteDialogOpen(false);
        setTemplateToDelete(null);
      })
      .catch((error) => {
        console.error('Error deleting template:', error);
        setDeleteDialogOpen(false);
        setTemplateToDelete(null);
      });
  };

  useEffect(() => {
    dispatch(fetchCertificateTemplate());
  }, []);

  return (
    <>
      <CertificateTabs />
      <WholePage>
      <div style={{width:'95%', margin:'auto'}}>
        <Text>{t('certificate.certificatePgText')}</Text>
        <SearchEmail>
          <TextField
            label={t('certificate.searchText')}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Create New Template">
          <ButtonDiv style={{marginRight:'30px'}}>            
            <CommonButton
              onClick={() => navigate('/Certificate/AddTemplate')}
              label={t('certificate.certificateCreateLabel')}
            />
          </ButtonDiv>
          </Tooltip>

        </SearchEmail>

        <Grid container spacing={2}>
          {Array.from(filteredTemplates).map((template, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 325 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    style={{ textAlign: 'center', marginLeft: '10px' }}
                  >
                    {template.certificateName}
                  </Typography>
                  <div style={{ marginLeft: 'auto' }}>
                    <Tooltip title={t('certificate.certificateSelectLabel')}>
                      <IconButton onClick={() => handleSelectClick(template)}>
                        <CheckCircleIcon style={{ color: palette.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('certificate.certificateViewLabel')}>
                      <IconButton onClick={() => handleOpenModal(template)}>
                        <VisibilityIcon style={{ color: palette.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('certificate.deleteLabel')}>
                      <IconButton
                        onClick={() => handleDeleteDialogOpen(template)}
                      >
                        <DeleteIcon style={{ color: palette.primary }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <div
                  style={{
                    overflow: 'hidden', // Ensure no scrollbars
                    display: 'flex', // Flexbox to center the image
                    alignItems: 'center',
                    border: '1px solid red', // Add border for debugging
                  }}
                >
                  <img
                    style={{
                      maxWidth: '100%', // Ensure image doesn't exceed container width
                      maxHeight: '100%', // Ensure image doesn't exceed container height
                      objectFit: 'cover', // Ensures the image covers the entire div
                    }}
                    src={template?.certificateImage}
                    alt="certificate image"
                  />
                </div>

                <CardActionArea onClick={() => handleOpenModal(template)}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow:
                        'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',

                      '&:hover': {
                        boxShadow:
                          'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                      },
                    }}
                  >
                    {/* <img src={certificateImages[index]} /> */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="certificate-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="certificate-dialog-title">
            {selectedTemplate?.Name}
            <IconButton
              aria-label={t('certificate.closeLabel')}
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <br />
          </DialogTitle>
          <DialogContent>
            {selectedTemplate && (
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedTemplate.certificateBody,
                }}
                alt="Certificate"
                style={{ width: '100%', height: 'auto' }}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={isNameDialogOpen} onClose={handleCloseNameDialog}>
          <DialogTitle>{t('certificate.enterNameLabel')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={t('certificate.certificateName')}
              type="text"
              fullWidth
              variant="standard"
              value={certificateName}
              onChange={handleCertificateNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNameDialog}>
              {t('certificate.cancelLabel')}
            </Button>
            <Button onClick={handleSubmitName}>
              {t('certificate.submitLable')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
          <DialogTitle>{t('certificate.ConfirmDeleteLabel')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('certificate.deleteTemQue')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>
              {t('certificate.cancelLabel')}
            </Button>
            <Button onClick={handleDeleteTemplate} color="error">
              {t('certificate.deleteLabel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      </WholePage>
    </>
  );
}

export default CertificatePage;
