import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateUserApi } from '../../api/MyProfileApi';

export const updateUserProfile = createAsyncThunk(
  'userProfile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUserApi(data);
      console.log('upadte in sli8ce', response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState = {
  users: [],
  status: 'idle',
  error: null,
};

const userProfileSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //update
      .addCase(updateUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userProfileSlice.reducer;
