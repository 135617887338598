import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';

export const ButtonStyle = styled.button`
  ${tw`text-lg md:text-base py-2 px-4 rounded border-none transition-all duration-300 cursor-pointer`}
  color: ${palette.white};
  background-color: ${palette.tertiary};
  &:hover {
    background-color: ${palette.buttonhover};
  }
`;
