import React, { useState } from "react";
import { TextField, Tooltip, IconButton, Grid, Typography, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
import JoditReact from "jodit-react";
import { useDispatch, useSelector } from 'react-redux';
import {addItem} from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';

const InsertText = ({ onClose , lessonId , selectedItem ,moduleId }) => {
  const { id , courseStructure } = useParams();
  console.log(id , courseStructure)

  const dispatch = useDispatch();

  // if(courseStructure === 'CMLT') {
  //   setLessonsDetail({
  //     ...LessonsDetail,
  //     id: generateRandomNumber(),
  //     title: newTitle,
  //     moduleId: moduleId ,
  //     courseStructure : courseStructure
  //   }) } else {
  //     setLessonsDetail({
  //       ...LessonsDetail,
  //       id: generateRandomNumber(),
  //       title: newTitle,
  //       courseStructure :courseStructure
  //     })
  //   }

  const [formData, setFormData] = useState({
    moduleId:"",
    lessonId:"",
    title: "",
    textDescription: "",
    id: "",
    courseStructure:"",
    contentType: selectedItem?.contentType || "",

  });

  const handleOnClose = () => {
    onClose();
  };

  const handleChange = (event) => {
    console.log("handleChange lessonId", lessonId)
    const { name, value } = event.target;
    console.log(name ,value  )
    setFormData({
      ...formData,
      [name]: value ,
      lessonId : lessonId,
      moduleId : moduleId,
      id : generateRandomNumber() ,
      courseStructure :courseStructure,
      contentType: 'Text',
    });
  };
  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };


  const handleSave = async() => {
    console.log(formData)
    console.log(lessonId)
    await  dispatch(addItem(formData))
    onClose();
  };

  return (
    <div>
      <h1>{lessonId}</h1>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
        {/* Grid container aligned to the left */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Text
            </Typography>
          </Grid>
        </Grid>

        {/* Buttons div aligned to the right */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }} onClick={onClose}>Discard Changes</Button>
          <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }} onClick={handleSave}>Save</Button>
        </div>
      </div>
      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"
          style={{ width: "100%", marginBottom: "10px" }}
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
        <JoditReact
          value={formData.textDescription}
          onChange={(value) => setFormData({ ...formData, textDescription: value })}
          config={{
            placeholder: "Enter the text description" // Specify your desired placeholder text
          }}
        />
      </div>
    </div>
  );
};

export default InsertText;
