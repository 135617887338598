import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../palette';

export const PageContainer = styled.div`
  ${tw`w-full relative`}
  margin-top:20px;
  margin-bottom: 10px;
  background: ${palette.white};
`;
export const SidebarCont = styled.div`
  display: 'flex';
  width: '100%';
  height: '100%';
  flex-direction: 'column';
`;

export const HeaderContainer = styled.div`
  ${tw`w-full justify-between flex items-center lg:h-24  `}
  color: ${palette.white};
`;

export const Content = styled.div`
  ${tw`ml-6 mb-6`}
`;

export const Title = styled.p`
  ${tw`font-semibold text-3xl`}
`;

export const Heading = styled.p`
  ${tw`2xl:text-xl lg:text-base font-semibold text-lg`}
`;

export const Description = styled.p`
  ${tw`text-xs md:text-base shadow-none hidden lg:block`}
`;

export const HeaderContent = styled.div`
  ${tw`flex flex-col items-start`}
`;

export const ModuleContainerStyle = {
  marginTop: '20px',
  paddingBottom: '50px',
  paddingTop: '50px',
};
export const Container = styled.div`
  ${tw`flex p-8 items-center justify-center`}
`;

export const LoginButton = styled.div`
  ${tw`w-full`}
  background : ${palette.bgColor1};
`;

export const Text = styled.p`
  ${tw`text-sm sm:text-base tracking-normal sm:tracking-wider text-justify md:text-left`}
`;

export const HeadingH1 = styled.h1`
  ${tw`text-3xl sm:text-4xl tracking-normal sm:tracking-wider leading-relaxed sm:leading-loose text-justify md:text-left`}
`;

export const HeadingH2 = styled.h2`
  ${tw`text-center md:text-left text-xl md:text-2xl`}
`;

export const HeadingH3 = styled.h3`
  ${tw`text-base md:text-xl`}
`;

export const TitleB = styled.h1`
  ${tw` font-bold text-lg 2xl:text-3xl xl:text-3xl lg:text-3xl md:text-xl sm:text-lg leading-4 `}
  color: ${palette.primary};
`;

export const TitleHeadComCont = styled.div`
  ${tw`flex justify-center items-center flex-col `}
`;

export const TitleDash = styled.hr`
  ${tw`h-[3px] w-[100%] rounded-lg mb-5`}
  background:${palette.primary}
`;
