import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import CalendarMain from './CalendarMain';
import EventDetails from './EventDetails';
import Announcements from './Announcements';
import ArchivedAnnouncements from './ArchivedAnnouncements';

import AnnounceDetails from './AnnounceDetails';
import UserInfo from './UserInfo';
import EnrolledUsers from './EnrolledUsers';
import AddAnnouncements from './AddAnnouncements';
import { PageContainer } from '../../styles/Common/CommonStyles';

const Calendar = () => {
  return (
    <PageContainer style={{minHeight:'73vh'}}>
      <>
        <Routes>
          <Route index path="/*" element={<CalendarMain />} />
          <Route path="/eventdetails/:eventname" element={<EventDetails />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route
            path="/archivedAnnouncements"
            element={<ArchivedAnnouncements />}
          />

          <Route
            path="/announcedetails/:announcementNo"
            element={<AnnounceDetails />}
          />
          <Route path="/userinfo/:eventname" element={<UserInfo />} />
          <Route path="/enrolledusers/:eventname" element={<EnrolledUsers />} />
          <Route path="/addannouncements" element={<AddAnnouncements />} />
        </Routes>
      </>
    </PageContainer>
  );
};

export default Calendar;
