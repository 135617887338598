import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { signInWithEmailAndPassword } from 'firebase/auth';
import "firebase/compat/auth";
import axios from 'axios';

import {
  LoginContainerMain,
  LoginContainer,
  Header,
  TextFieldStyle,
  LoginButton,
  Text,
  SignupContainer,
  SignUpText,
  LoginForm,
  LoginImg,
  LoginImgSub,
  LoginButtonGoogle,
  ImgLogin,
  Sepbtn,
} from "../../styles/Landing/LoginStyles";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { palette } from "../../styles/palette";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
// import { ValidateLogin, saveProfileData } from "../../state";
import { useDispatch , useSelector} from "react-redux";


import googleA from "../../images/googleA.png";
// import loginbgD from "../../images/loginbgD.jpg";
import loginbgD from "../../images/loginbg.jpg";

import { loginUserApi } from "../../api/AuthenticationApi";
// import Demologinfile1 from './Demologinfile'
import {logInUser , logInUserWithEmil} from "../../features/authentication/AuthenticationSlice"
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from '../Utilities/Loader'; 

const Login = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    userName: '',
    password: '',
  });

  const [errorState, setErrorState] = useState({
    userName: false,
    password: false,
  });

  const handleBlur = (e) => {
    if (e.target.value === '' || null || undefined)
      setErrorState({ ...errorState, [e.target.name]: true });
  };

  const handleChange = (event) => {
    console.log('In login handle change ', event.target.value);
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    if (event.target.value === '' || null || undefined) {
      setErrorState({
        ...errorState,
        [event.target.name]: true,
      });
    } else {
      setErrorState({
        ...errorState,
        [event.target.name]: false,
      });
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  };

  // const handleSubmit = () => {
  //   let loginData = {};
  //   if (state.userName === '' || errorState.userName === true)
  //     alert('Please enter Email Id');
  //   else if (state.password === '' || errorState.password === true)
  //     alert('Please enter password');
  //   else {
  //     loginData.userName = state.userName;
  //     loginData.password = state.password;
  //     // ValidateLogin(loginData, LoginSuccess, LoginFailure);
  //   }
  // };

  const handleSignup = () =>{
    navigate('/Signup')
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    console.log("dhfksjfb")
  
    try {
      const authData = await signInWithEmailAndPassword(firebase.auth(),state.userName, state.password);
      const token = await authData.user.getIdToken();
      localStorage.setItem("userToken", token);

      console.log("user Token" , token)
      // setEmailToken(token);
      // Save token to local storage or cookies for future requests
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token; // Set token in header for subsequent requests
    const userData = await dispatch(logInUserWithEmil(token));
    console.log("userData in dummylogin" , userData)
    if (userData?.payload?.statusCode == 200) {
              console.log("undr")
              toast(userData.payload.message)
              localStorage.setItem('auth', true);
              navigate("/Dashboard");
            } else {
              toast(userData?.payload?.message)
            }
   
    } catch (error) {
      // setMessage(error.message);
      console.log("error" , error)
    }
  };


  // For Google login
  const [auth, setAuth] = useState(
    false || window.localStorage.getItem('auth') === 'true',
  );
  const [token, setToken] = useState('');

  // to call login user after token change
  useEffect(() => {
    if (token) {
      dispatch(logInUser(token));
      // alert('you are login successful');
      navigate('/Dashboard');
    }
  }, [token]);

  // to handle Google login
  const loginWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        const user = result.user;
        if (user) {
          user.getIdToken().then((token) => {
            setToken(token);
            localStorage.setItem("userToken", token);

          });
        }
      })
      .catch((error) => {
        console.error('Error signing in with Google: ', error);
      });
  };
  if (loading) {
    return < Loader/>;
  }
  const { t } = useTranslation();
  return (
    <>
    {state.userName}
      <LoginContainerMain>
        <LoginContainer>
          <Header> {t("login.loginWelcomeLabel")}</Header>
          <LoginForm>
            <TextField
              required
              label= {t("login.loginEmailLabel")}
              name="userName"
              id="userName"
              variant="outlined"
              defaultValue="User Name"
              sx={TextFieldStyle}
              error={errorState.userName}
              value={state.userName}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{ minLength: 0 }}
            />
            <TextField
              required
              label= {t("login.loginPasswordLabel")}
              name="password"
              id="password"
              type="password"
              variant="outlined"
              sx={TextFieldStyle}
              error={errorState.password}
              value={state.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Button
              variant="contained"
              size="large"
              onClick={handleSignIn}
              style={LoginButton}
              sx={{
                '& .MuiButtonBase-root': {
                  backgroundColor: `${palette.tertiary}}`,
                },
              }}
            >
               {t("login.loginText")}
            </Button>

            <Button style={LoginButtonGoogle} onClick={loginWithGoogle}>
              <ImgLogin src={googleA} alt="" />
              {t("login.loginGoogleLabel")}
            </Button>
            <SignupContainer>
            <Button
            variant="contained"
              size="large"
              onClick={handleSignup}
              style={LoginButton}
              sx={{
                '& .MuiButtonBase-root': {
                  backgroundColor: `${palette.tertiary}}`,
                  color:  `${palette.white}}`,
                },
              }}
            >
              SIGN UP
            </Button>
            </SignupContainer>
            {/* <div className="style" style={{display:"flex" , justifyContent:"center" , padding:"3px"}}>
        <button className="" style={{display:"flex" ,marginRight:"10px" , paddingRight:"3px", background:"white"  , justifyContent:"center" , alignItems:"center", padding:"3px" , borderRadius:"4px", border:"0px", cursor:"pointer" , marginBottom:"12px" , boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}} onClick={loginWithGoogle}>  <img className="" style={{width:"35px" , height:"35px", marginRight:"2px"}} src={GoogleImg} alt="" /> <span className="text-center" style={{marginLeft:"2px" , fontWeight:"bolder" ,padding:"3px", marginRight:"6px"}}>Google Sign In</span></button>
        <button className="" style={{display:"flex" , background:"white"  ,  paddingRight:"3px" ,justifyContent:"center" , alignItems:"center", padding:"3px" , borderRadius:"4px", border:"0px", cursor:"pointer" , marginBottom:"12px" , boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}} onClick={loginWithGoogle}>  <img className="" style={{width:"32px" , height:"32px", marginRight:"2px", marginLeft:"3px"}} src={FaceBook} alt="" /> <span className="text-center" style={{marginLeft:"2px" , fontWeight:"bolder",padding:"3px" , marginRight:"6px"}}> Facebook Sign In</span></button>
        </div> 
        
        <div className="style" style={{display:"flex" , justifyContent:"center" , padding:"3px"}}>

        <button className="" style={{display:"flex" ,marginRight:"10px" , background:"white"  , justifyContent:"center" , alignItems:"center", padding:"5px" ,borderRadius:"100%", border:"0px", cursor:"pointer" , marginBottom:"12px" , boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"}} onClick={loginWithGoogle}>  <img className="" style={{width:"35px" , height:"35px"}} src={GoogleImg} alt="" /> </button>
        <button className="" style={{display:"flex" , background:"white"  ,justifyContent:"center" , alignItems:"center", padding:"5px" , borderRadius:"100%", border:"0px", cursor:"pointer" , marginBottom:"12px" , boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" , }} onClick={loginWithGoogle}>  <img className="" style={{width:"35px" , height:"35px"}} src={Facebook_Logo} alt="" /> </button>
        </div> 
        */}
          </LoginForm>
        </LoginContainer>
        <LoginImg>
          <LoginImgSub src={loginbgD} alt="error" />
          {/* <LoginImgSub src="https://calendar.tamparealtors.org/backgrounds/tampa/login.jpg" alt="error" /> */}
          {/* <LoginImgSub src="https://www.splashlearn.com/math-vocabulary/wp-content/uploads/2022/05/image5-888x1024.jpg" alt="error" /> */}
        </LoginImg>
      </LoginContainerMain>
      {/* <Demologinfile1/> */}
    </>
  );
};

export default Login;