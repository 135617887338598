import React from "react";
import { Routes, Route } from "react-router-dom";
import LearnerEligibleCertificate from "../Certificates/LearnerEligibleCertificate";
import LearnerAllCertificate from "../Certificates/LearnerAllCertificate";

import {
  PageContainer,
} from "../../styles/Common/CommonStyles";

const LearnerCertificate = () => {
  return (
    <PageContainer>
      <Routes>
        <Route path="/*" element={<LearnerAllCertificate />} />                  
        <Route path="/LearnerEligibleCertificate" element={<LearnerEligibleCertificate />} />
      </Routes>
    </PageContainer>
  );
};

export default LearnerCertificate;
