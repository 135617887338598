import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Box,
} from '@mui/material';
import { ButtonCont } from '../../../styles/Courses/CourseBuilderStyles';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { palette } from '../../../styles/palette';
import courseModule from '../../../store/CourseModules.json';
import CommonButton from '../../Utilities/CommonButton';
import AddModuleDialog from './AddModuleDialog';
import AddChapterDialog from './AddChapterDialog';
import AddTopicDialog from './AddTopicDialog';
import CreateTopics from './CreateTopics';
import LessonTopics from './LessonTopics';
import AddOrCopyTopic from './AddOrCopyTopic';
import CourseSettings from '../CourseSettings/CourseSettings'; // Import the CourseSettings component
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteModule,
  deleteLesson,
  deleteTopic,
  saveDetails,
  fetchCourseDetailsInSlice,
} from '../../../features/courses/addCourseDetailsSlice';
import CreateModule from './CreateModule';
import CreateLesson from './CreateLesson';

const CourseModules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expandedModule, setExpandedModule] = useState(null);
  const [expandedLessons, setExpandedLessons] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openChapterDialog, setOpenChapterDialog] = useState(false);
  const [openTopicDialog, setOpenTopicDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of selected item
  const [displayContent, setDisplayContent] = useState(false);
  const [displayContentData, setDisplayContentData] = useState({});
  const [showCourseSettings, setShowCourseSettings] = useState(false);
  const [showAddModuleForm, setShowAddModuleForm] = useState(false);
  const [showAddChapterForm, setShowAddChapterForm] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [showCreateModule, setShowCreateModule] = useState(false);
  const [showCreateLesson, setShowCreateLesson] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [ModuleData, setModuleData] = useState({});
  const { modules, lessons } =
    useSelector((state) => state.addCourseDetails.courseDetails) || {};

  const { id, courseStructure } = useParams();
  console.log(id, courseStructure);

  useEffect(() => {
    const fetchCourseDetails = async (id) => {
      await dispatch(fetchCourseDetailsInSlice(id));
    };

    fetchCourseDetails(id);
  }, [id]);

  const handleAddTopicSubmit = (topicData) => {
    // Handle the submitted topic data here
    console.log('Submitted topic data:', topicData);
    setOpenTopicDialog(false);
  };
  const handleCreateTopicsClick = () => {
    // Close AddTopicDialog if it's open
    setOpenTopicDialog(false);
  };

  const handleModuleOnClick = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };
  const handleModuleTextClick = (module) => {
    setSelectedModule(module);
    setShowCreateModule(true);
  };

  const handleLessonClick = (lessonId) => {
    setExpandedLessons({
      ...expandedLessons,
      [lessonId]: !expandedLessons[lessonId],
    });
  };

  const handleLessonTextClick = (lesson) => {
    setSelectedLesson(lesson);
    setShowCreateLesson(true);
  };

  const isModuleExpanded = (moduleId) => {
    return expandedModule === moduleId;
  };

  const isLessonExpanded = (lessonId) => {
    return expandedLessons[lessonId];
  };

  const handleAddModule = () => {
    setOpenDialog(true);
  };

  const handleAddModuleClick = () => {
    setShowAddModuleForm(true);
  };

  const handleCloseAddModuleForm = () => {
    setShowAddModuleForm(false);
  };

  const handleCloseAddChapterForm = () => {
    setShowAddChapterForm(false);
    setSelectedModuleId(null);
  };

  const [courseModule, setCourseModule] = useState({
    module: [],
  });

  // const [lessonModule, setlessonModule] = useState({
  //         module: []
  //       });

  const [moduleId, setModuleId] = useState(null);
  const [lessonId, setLessonId] = useState(null);

  const handleCloseDialog = (courseModule) => {
    setCourseModule((prevCourseModule) => ({
      module: [...prevCourseModule?.module, courseModule],
    }));
    // setModuleData(courseModule)
    setOpenDialog(false);
  };

  // const handleAddChapter = (id) => {
  //   setModuleId(id)
  //   setOpenChapterDialog(true);
  // };
  const handleAddChapter = (moduleId) => {
    setSelectedModuleId(moduleId);
    setShowAddChapterForm(true);
  };

  const handleCloseChapDialog = (courseLesson) => {
    setCourseModule((prevModuleName) => ({
      module: prevModuleName?.module?.map((mod) => {
        if (mod?.id === moduleId) {
          // If the module ID matches the specified module ID, update its lessons array
          return {
            ...mod,
            lessons: [...mod?.lessons, courseLesson], // Append the new lesson to the lessons array of this module
          };
        }
        return mod; // Return unchanged module if the ID doesn't match
      }),
    }));
    setOpenChapterDialog(false);
  };

  const handleAddTopic = (moduleId, id) => {
    console.log('handleAddTopic', id);
    setLessonId(id);
    setModuleId(moduleId);
    console.log('HAndle add topic');
    setOpenTopicDialog(true);
    // Close CreateTopics if it's open
    setSelectedItem(null);
  };

  // const handleDeleteModule = async (id) =>{
  //   await  dispatch(deleteModule(id))
  // }

  // const handleDeleteLesson = async (moduleId , lessonId) =>{
  //   console.log(lessonId , "handleDeleteLesson")
  //   await  dispatch(deleteLesson({ moduleId, lessonId }))
  // }

  const handleDeleteTopic = async (moduleId, lessonId, id) => {
    await dispatch(deleteTopic({ moduleId, lessonId, id }));
  };

  const handleSaveCourse = async () => {
    console.log({ modules: modules, courseId: id });
    const result = await dispatch(
      saveDetails({
        modules: modules,
        courseStructure: courseStructure,
        courseId: id,
        lessons: lessons,
      }),
    );
    console.log(result, 'result handleSaveCourse');
    if (result.payload?.data?.success) {
      alert(result.payload?.data?.message);
    } else {
      alert(result.payload?.data?.message);
    }
  };

  const handleCloseTopicDialog = (data) => {
    console.log('fgj');
    console.log(data);
    setOpenTopicDialog(false);
    setSelectedItem(null);
  };

  const handleOnContentClick = (item) => {
    setDisplayContent(true);
    setDisplayContentData(item);
    setOpenTopicDialog(false);
  };

  const handleCloseLessonTopic = () => {
    setOpenTopicDialog(true);
    setSelectedItem(null);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item); // Update the selected item when clicked
  };

  const handleLessonTopicsClose = () => {
    setOpenTopicDialog(true);
    setDisplayContent(false);
    setDisplayContentData('');
  };

  const toggleCourseSettings = () => {
    setShowCourseSettings(!showCourseSettings);
  };

  const getItemTitle = (item) => {
    return item.content || item.title || ''; // Update this based on your JSON structure
  };

  const getItemContent = (item) => {
    return item.text || item.content || ''; // Update this based on your JSON structure
  };

  const handleCloseCreateModule = () => {
    setShowCreateModule(false);
  };

  const handleModuleUpdate = (updatedModule) => {
    dispatch(updateModule(updatedModule));
    setShowCreateModule(false);
  };

  const handleCloseCreateLesson = () => {
    setShowCreateLesson(false);
  };

  const handleLessonUpdate = (updatedLesson) => {
    dispatch(updateLesson(updatedLesson));
    setShowCreateLesson(false);
  };
  const handleCloseCreateTopic = () => {
    setSelectedItem(false);
  };

  return (
    <>
      {/* <ButtonCont>
          <CommonButton onClick={handleSaveCourse}   label=" Save Course" />
        </ButtonCont> */}
      {/* <div style={{ display: 'flex', justifyContent: 'space-between'}}> */}
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: 385,
            height: '100vh',
            backgroundColor: '#F1F1F1',
            padding: '20px',
            // overflowY: 'auto'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={() => navigate('/CourseBuilder')}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
            <h5>React Course 1 </h5>
            <Tooltip title="Course Setting">
              <IconButton
                size="small"
                // onClick={() => navigate('/CourseSettings')}
                onClick={toggleCourseSettings}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <BuildOutlinedIcon />
              </IconButton>
            </Tooltip>

            {/* <Box ml={2} width="calc(100% - 500px)">
        {showCourseSettings && <CourseSettings />}
    </Box> */}
          </div>
          <List style={{ height: '100%', overflowY: 'auto' }}>
            {courseStructure === 'CMLT' ? (
              modules?.map((module) => (
                <div key={module?.id}>
                  <ListItem style={{ background: '#fff', marginTop: '10px' }}>
                    <ListItemText
                      sx={{ color: `${palette.primary}` }}
                      primary={module?.title}
                      onClick={() => handleModuleTextClick(module)}
                    />
                    <Tooltip title="Add Lesson">
                      <AddIcon
                        onClick={() => handleAddChapter(module?.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                    {/* <Tooltip title="Delete Module">
          <DeleteIcon onClick={() => handleDeleteModule(module?.id)} style={{ cursor: 'pointer', fontSize: '20px' }} />
        </Tooltip> */}
                    <Box sx={{ marginRight: '8px' }} />
                    {isModuleExpanded(module?.id) ? (
                      <ExpandLessIcon
                        onClick={() => handleModuleOnClick(module.id)}
                      />
                    ) : (
                      <ExpandMoreIcon
                        onClick={() => handleModuleOnClick(module.id)}
                      />
                    )}
                  </ListItem>
                  <Collapse
                    in={isModuleExpanded(module?.id)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component={Paper}
                      style={{
                        padding: '20px',
                        marginBottom: '10px',
                      }}
                    >
                      {module?.lessons?.map((lesson) => (
                        <div key={lesson.id}>
                          <ListItem
                            // onClick={() => handleLessonClick(lesson.id)}
                            style={{
                              background: '#fff',
                              marginBottom: '10px',
                              border: '1px solid #CACACA',
                              borderRadius: '5px',
                            }}
                          >
                            <ListItemText
                              sx={{ color: `${palette.secondary}` }}
                              primary={lesson.title}
                              onClick={() => handleLessonTextClick(lesson)}
                            />

                            <Tooltip title="Add Topic">
                              <AddIcon
                                onClick={() =>
                                  handleAddTopic(lesson.moduleId, lesson.id)
                                }
                              />
                            </Tooltip>
                            {/* <Tooltip title="Delete Lesson">
                  <DeleteIcon onClick={() => handleDeleteLesson(lesson?.moduleId, lesson.id)} style={{ cursor: 'pointer', fontSize: '20px' }} />
                </Tooltip> */}
                            <Box sx={{ marginRight: '8px' }} />
                            {isLessonExpanded(lesson.id) ? (
                              <ExpandLessIcon
                                onClick={() => handleLessonClick(lesson.id)}
                              />
                            ) : (
                              <ExpandMoreIcon
                                onClick={() => handleLessonClick(lesson.id)}
                              />
                            )}
                          </ListItem>
                          <Collapse
                            in={isLessonExpanded(lesson.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List>
                              {lesson?.items?.map((item, index) => (
                                <ListItem
                                  key={index}
                                  style={{
                                    background:
                                      selectedItem === item
                                        ? '#d0eaff'
                                        : '#f9f9f9', // Highlight if selected
                                    marginBottom: '5px',
                                    paddingLeft: '20px',
                                    cursor: 'pointer', // Add cursor to indicate clickable item
                                  }}
                                  onClick={() => handleItemClick(item)}
                                >
                                  <ListItemText primary={getItemTitle(item)} />
                                  {/* <Tooltip title="Delete Topic">
                        <DeleteIcon 
                          onClick={() => handleDeleteTopic(item.moduleId, item.lessonId, item.id)} 
                          style={{ cursor: "pointer", fontSize: "20px" }} 
                        />
                      </Tooltip> */}
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </div>
                      ))}
                    </List>
                  </Collapse>
                </div>
              ))
            ) : (
              <List
                component={Paper}
                style={{
                  padding: '20px',
                  marginBottom: '10px',
                }}
              >
                {lessons?.map((lesson) => (
                  <div key={lesson.id}>
                    <ListItem
                      // onClick={() => handleLessonClick(lesson.id)}
                      style={{
                        background: '#fff',
                        marginBottom: '10px',
                        border: '1px solid #CACACA',
                        borderRadius: '5px',
                      }}
                    >
                      <ListItemText
                        sx={{ color: `${palette.secondary}` }}
                        primary={lesson.title}
                        onClick={() => handleLessonTextClick(lesson)}
                      />
                      <Tooltip title="Add Topic">
                        <AddIcon
                          onClick={() =>
                            handleAddTopic(lesson.moduleId, lesson.id)
                          }
                        />
                      </Tooltip>
                      {/* <Tooltip title="Delete Lesson">
                  <DeleteIcon onClick={() => handleDeleteLesson(lesson?.moduleId, lesson.id)} style={{ cursor: 'pointer', fontSize: '20px' }} />
                </Tooltip> */}
                      <Box sx={{ marginRight: '8px' }} />
                      {isLessonExpanded(lesson.id) ? (
                        <ExpandLessIcon
                          onClick={() => handleLessonClick(lesson.id)}
                        />
                      ) : (
                        <ExpandMoreIcon
                          onClick={() => handleLessonClick(lesson.id)}
                        />
                      )}
                    </ListItem>
                    <Collapse
                      in={isLessonExpanded(lesson.id)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        {lesson?.items?.map((item, index) => (
                          <ListItem
                            key={index}
                            style={{
                              background:
                                selectedItem === item ? '#d0eaff' : '#f9f9f9', // Highlight if selected
                              marginBottom: '5px',
                              paddingLeft: '20px',
                              cursor: 'pointer', // Add cursor to indicate clickable item
                            }}
                            onClick={() => handleItemClick(item)}
                          >
                            <ListItemText primary={getItemTitle(item)} />
                            {/* <Tooltip title="Delete Topic">
                        <DeleteIcon 
                          onClick={() => handleDeleteTopic(item.moduleId, item.lessonId, item.id)} 
                          style={{ cursor: "pointer", fontSize: "20px" }} 
                        />
                      </Tooltip> */}
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ))}
              </List>
            )}
            <div
              style={{
                textAlign: 'center',
                position: 'fixed',
                bottom: 0,
                marginLeft: '-24px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
              }}
            >
              <div
                style={{
                  padding: '12px',
                  backgroundColor: '#F1F1F1',
                  color: 'white',
                  border: 'none',
                  width: 405,
                  fontSize: '16px',
                }}
              >
                {/* <div style={{ width: '100%' }}>
          <CommonButton onClick={handleAddModule} label="Add Module"/>
          </div> */}
                {courseStructure === 'CMLT' ? (
                  <button
                    style={{
                      padding: '15px',
                      backgroundColor: '#0684FB',
                      color: 'white',
                      border: 'none',
                      width: 380,
                      fontSize: '16px',
                      borderRadius: '8px',
                    }}
                    // onClick={handleAddModule}
                    onClick={handleAddModuleClick}
                  >
                    Add Module
                  </button>
                ) : (
                  <button
                    style={{
                      padding: '15px',
                      backgroundColor: '#0684FB',
                      color: 'white',
                      border: 'none',
                      width: 380,
                      fontSize: '16px',
                      borderRadius: '8px',
                    }}
                    // onClick={handleAddModule}
                    onClick={() => handleAddChapter(module?.id)}
                  >
                    Add Lesson
                  </button>
                )}
              </div>
            </div>
            {/* Add Module Dialog */}
            {/* <AddModuleDialog open={openDialog} onClose={handleCloseDialog} /> */}
          </List>
        </div>

        {/* Render CourseSettings component */}
        {showCourseSettings && (
          <Box ml={2} width="calc(100% - 500px)">
            <CourseSettings />
          </Box>
        )}

        {/* rendering Display of selected Topic */}
        {selectedItem && !openTopicDialog && (
          <LessonTopics
            // data = {selectedItem.contentType}
            selectedItem={selectedItem}
            onClose={handleCloseLessonTopic}
            lessonId={lessonId}
            moduleId={moduleId}
          />
        )}
        {openTopicDialog && !selectedItem && (
          <Box ml={2} width="calc(100% - 500px)">
            <AddOrCopyTopic
              open={openTopicDialog}
              onClose={handleCloseTopicDialog}
              onCancel={{ handleCloseTopicDialog }}
              onContentClick={handleOnContentClick}
            />
          </Box>
        )}
        {displayContent && (
          <Box ml={2} width="calc(100% - 500px)">
            <LessonTopics
              open={displayContent}
              data={displayContentData}
              onClose={handleLessonTopicsClose}
              lessonId={lessonId}
              moduleId={moduleId}
            />
          </Box>
        )}

        {/* Render JoditEditor component if selectedItem is not null */}
        {selectedItem && (
          <Box ml={2} width="calc(100% - 500px)">
            {/* <CreateTopics
            itemTitle={getItemTitle(selectedItem)}
            itemContent={getItemContent(selectedItem)}
            onClick={handleCreateTopicsClick} // Add event handler to close CreateTopics
            // lessonId= {lessonId}
            // moduleId = {moduleId}
            // selectedItem = {selectedItem}
            lessonId={selectedItem.lessonId}
            moduleId={selectedItem.moduleId}
            selectedItem={selectedItem}
            handleDeleteTopic={handleDeleteTopic}
            onClose={handleCloseCreateTopic}
            
          /> */}
            <LessonTopics
              data={selectedItem.contentType}
              selectedItem={selectedItem}
              onClose={handleCloseLessonTopic}
              lessonId={lessonId}
              moduleId={moduleId}
            />
          </Box>
        )}

        {showAddModuleForm && (
          <Box ml={2} width="calc(100% - 500px)">
            <AddModuleDialog onClose={handleCloseAddModuleForm} />
          </Box>
        )}

        {showAddChapterForm && (
          <Box ml={2} width="calc(100% - 500px)">
            <AddChapterDialog
              onClose={handleCloseAddChapterForm}
              moduleId={selectedModuleId}
            />
          </Box>
        )}

        {showCreateModule && (
          <Box ml={2} width="calc(100% - 500px)">
            <CreateModule
              open={showCreateModule}
              module={selectedModule}
              onSave={handleModuleUpdate}
              onClose={handleCloseCreateModule}
            />
          </Box>
        )}

        {showCreateLesson && (
          <Box ml={2} width="calc(100% - 500px)">
            <CreateLesson
              open={showCreateLesson}
              lesson={selectedLesson}
              onSave={handleLessonUpdate}
              onClose={handleCloseCreateLesson}
            />
          </Box>
        )}
      </div>
      <div
        style={{
          display: 'flex',

          // flexDirection:"column",
          // marginLeft:"1320px",
          // marginTop:"90px",
          // position: 'fixed',
        }}
      >
        <CommonButton onClick={handleSaveCourse} label=" Save Course" />
      </div>
      {/* </div> */}

      <div style={{ position: 'relative', bottom: '930px' }}>
        <div
          style={{
            position: 'absolute',
            top: '10px', // Adjust as needed
            right: '10px', // Adjust as needed
          }}
        >
          {/* <CommonButton onClick={handleSaveCourse} label="Save Course" /> */}
        </div>
      </div>
    </>
  );
};

export default CourseModules;
