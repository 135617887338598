import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Switch,
  Tooltip,
  IconButton,
  TextField
} from "@mui/material";
import {
  Step3ContainerStyle,
  HeadingClass,
  DescriptionClass,
  ToggleStyle,
} from "../../../styles/Courses/CourseBuilderStyles";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../../styles/Common/CommonStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CourseSettingTabs from "./CourseSettingTabs";
import CommonButton from "../../Utilities/CommonButton";

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { basicSetting } from '../../../features/courses/CourseUtilitySlice';

const BasicSettings = () => {
  const { courseId } = useParams();
 const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    courseId : courseId ,
    contentScheduling: false,
    sequentialLearningPath: false,
    enforceCompleteVideoViewing: false,
    security: {
      disableTextCopying: false,
      showLearnerCount: false,
      showValidity: false,
      showCourseInfo: false,
      allowBookmarking: false,
    },
    completionPercentage: 100, // Assuming it's a static value
    name: "", // You need to collect this value from a form field
    langCode: "en"
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name in formData) {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    } else if (name in formData.security) {
      setFormData({
        ...formData,
        security: {
          ...formData.security,
          [name]: checked,
        },
      });
    }
  };

  const handleNameChange = (event) => {
    setFormData({
      ...formData,
      name: [event.target.value],
    });
  };

  const handleSaveCourseSetting = async () => {
    console.log(formData , "handleSaveCourse")
    try {
      const response = dispatch(basicSetting(formData))
      alert("setting save successfully")
    } catch (error) {
      console.error("Error saving course settings:", error);
    }
  };

  return (
    <PageContainer>
      <CourseSettingTabs />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '95%', margin: 'auto' }}>
        <Tooltip title="Go Back">
          <IconButton
            size="small"
            onClick={() => navigate("/CourseBuilder")}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <div>
          <CommonButton label="Save setting" onClick={handleSaveCourseSetting} />
        </div>
      </div>
      <Container fluid sx={Step3ContainerStyle}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {/* <Grid item xs={8}>
            <Typography sx={HeadingClass}>Course Name</Typography>
            <TextField
              name="name"
              value={formData.name}
              onChange={handleNameChange}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Enable Content Scheduling</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={formData.contentScheduling}
              onChange={handleChange}
              name="contentScheduling"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Course Items will be released gradually over time (e.g. every few days or once a week)
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Enforce sequential learning path</Typography>
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.sequentialLearningPath}
              onChange={handleChange}
              name="sequentialLearningPath"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Course items can only be accessed in the sequential order. Learners need to mark course items as completed to move to next item. In an already published course, all previously completed items will remain accessible.
            </Typography>
          </Grid>
          <Grid item xs={8} sx={HeadingClass}>
            Enforce complete video viewing
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.enforceCompleteVideoViewing}
              onChange={handleChange}
              name="enforceCompleteVideoViewing"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>
              Learners need to watch at least 90% of the video lesson to move to next course item. It only works if sequential learning path is enabled. This feature is compatible only with videos uploaded on the platform.
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={HeadingClass}>Completion Percentage </Typography>
            <TextField
                         name="completionPercentage"
                         value={formData.completionPercentage}
                         onChange={handleChange}
           
              fullWidth
            />
          </Grid>
          <Grid item xs={8} sx={HeadingClass}>
            Security
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.security.disableTextCopying}
              onChange={handleChange}
              name="disableTextCopying"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography sx={DescriptionClass}>Disable Text Copying</Typography>
          </Grid>
          <Grid item xs={8} sx={HeadingClass}>
            Show Learners Count on Landing Page
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.security.showLearnerCount}
              onChange={handleChange}
              name="showLearnerCount"
            />
          </Grid>

          <Grid item xs={8} sx={HeadingClass}>
            Show validity to learners (my courses)
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.security.showValidity}
              onChange={handleChange}
              name="showValidity"
            />
          </Grid>

          <Grid item xs={8} sx={HeadingClass}>
            Show additional info (like duration, pages, etc) in Course Curriculum
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.security.showCourseInfo}
              onChange={handleChange}
              name="showCourseInfo"
            />
          </Grid>

          <Grid item xs={8} sx={HeadingClass}>
            Allow Bookmark Course Items
          </Grid>
          <Grid item xs={2} sx={ToggleStyle}>
            <Switch
              checked={formData.security.allowBookmarking}
              onChange={handleChange}
              name="allowBookmarking"
            />
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default BasicSettings;
