import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeMute from '@material-ui/icons/VolumeOff';
import Slider from '@material-ui/core/Slider';
import PlayIcon from '../../images/icons/play.png';
import PauseIcon from '../../images/icons/pause.png';
import VolumeIcon from '../../images/icons/volume.png';
import CCIcon from '../../images/icons/cc.png';
import MaximizeIcon from '../../images/icons/computer.png';
import PreviousIcon from '../../images/icons/previous.png';
import NextIcon from '../../images/icons/next.png';
import PlayBackSpeed from '../../images/icons/playBackSpeed.png';
import replay_button from '../../images/icons/replay_button.png';
import { palette } from '../../styles/palette';
import { formatTime } from './LearnerCourseBuilder/LearnerPreviewTopic/TimeUtils';

const ControlSectionWrapper = styled.div`
  display: flex;
  border: 0.5px solid ${palette.light_gray};
  width: 98%;
  background-color: ${palette.light_gray};
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const ControlButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? '#ccc' : palette.light_gray};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 10px;
  margin: 0;
  border: none;
  &:hover {
    background-color: ${(props) =>
      props.disabled ? '#ccc' : palette.buttonhover};
    padding: 10px;
  }
`;

const ButtonSection = styled.button`
  // border: 0.5px solid
  //   ${(props) => (props.disabled ? 'none' : palette.playerButtonBorder)};
    border: none;
  background-color: ${(props) =>
    props.disabled ? "none" : palette.light_gray};
  color: ${(props) => props.disabled ? '#666' : palette.primary};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  padding: 4px;
`;


const ControlIcon = styled.img`
height: 20px;
width: 20px;
margin: 2px;
filter: ${(props) => (props.disabled ? 'grayscale(100%)' : 'none')};
cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};


`;

const ProgressBar = styled.input`
  width: 60%;
  cursor: pointer;
  height: 2px;
`;

const VerticalSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  margin-top: 10px; 
`;

const VerticalSlider = styled(Slider)`
height: 100%; 
.MuiSlider-rail {
  background-color: ${palette.primary}; 
}
.MuiSlider-track {
  background-color: ${palette.primary}; 
}
.MuiSlider-thumb {
  margin-top: -5px; 
}
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 35px;
  font-size: 12px;
  margin-left:  -405px;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const AbsoluteDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 0;
  z-index: 1000;
`

const ControlSection = ({
  contentType, // Add contentType prop
  data,
  volume,
  onStart,
  onPlay,
  onPause,
  onBuffer,
  onSeek,
  onEnded,
  onError,
  played,
  onProgress,
  onDuration,
  onReady,
  onPlaybackRateChange,
  playbackRate,
  dropdownOpen,
  onNext,
  onPrevious,
  onToggleCC,
  onToggleFitToScreen,
  isPlaying,
  duration,
  currentTime,
  textScrollPercentage,
  toggleDropdown,
  handleSetPlaybackRate,
  handleSeekMouseDown,
  handleSeekChange,
  handleSeekMouseUp,
  enableNext,
  onReplay,
  onMute,
  muted,
  onVolumeChange,
  onVolumeSeekDown,
  onSeekMouseDown,
}) => {
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  // Define the conditions for enabling/disabling buttons
  const isVideoOrAudio = contentType === 'video' || contentType === 'audio';
  const isText = contentType === 'text';
  const isPPTOrPDF = contentType === 'ppt' || contentType === 'pdf';
  const isVideoOrAudioOrText = contentType === 'video' || contentType === 'audio' || contentType === 'text';

  return (
    <ControlSectionWrapper>
      <Tooltip title="Previous Topic">
        <ControlButton onClick={onPrevious}>
          <ButtonSection>
            <img src={PreviousIcon} style={{ height: '20px', width: '20px' }} />
          </ButtonSection>
        </ControlButton>
      </Tooltip>
      
      <Tooltip title={isPlaying ? 'Pause' : 'Play'}>
        <ControlButton onClick={isPlaying ? onPause : onPlay} >
          <ButtonSection>
            {isPlaying ? (
              <ControlIcon
                src={PauseIcon}
                disabled={!isVideoOrAudio}

              />
            ) : (
              <ControlIcon
                src={PlayIcon}
                disabled={!isVideoOrAudio}

              />
            )}
          </ButtonSection>
        </ControlButton>
      </Tooltip>

      <Tooltip title="Seek Bar">
        <ProgressBar
          type="range"
          min={0}
          max={100}
          value={played * 100}
          onMouseDown={handleSeekMouseDown}
          onChange={(e) => handleSeekChange(e)}
          onMouseUp={(e) => handleSeekMouseUp(e)}
          onDuration={onDuration}
          disabled={!isVideoOrAudioOrText}
        />
      </Tooltip>

      <TimeDisplay  >
        <span>{formatTime(currentTime)}</span>
        <span>{formatTime(duration)}</span>
      </TimeDisplay>

      <ControlButton>
        <ButtonSection onClick={onReplay} >
          <Tooltip title="Replay">
            <ControlIcon
              src={replay_button}
              disabled={!isVideoOrAudio}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton>

      <VerticalSliderWrapper>
        {showVolumeSlider && (
          <VerticalSlider
            min={0}
            max={100}
            value={muted ? 0 : volume * 100}
            onChange={onVolumeChange}
            onMouseDown={onSeekMouseDown}
            onChangeCommitted={onVolumeSeekDown}
            disabled={!isVideoOrAudio}
          />
        )}
        <ControlButton onClick={() => setShowVolumeSlider(!showVolumeSlider)} >
          <ButtonSection disabled={!isVideoOrAudio} >
            <Tooltip title="Volume">
              <div style={{marginTop:"-12px"}}>
              {muted ? (
                <VolumeMute />
              ) : volume > 0.5 ? (
                <VolumeUp  />
              ) : (
                <VolumeDown />
              )}
              </div>
            </Tooltip>
          </ButtonSection>
        </ControlButton>
      </VerticalSliderWrapper>

      <Tooltip title="Playback Speed">
          <ControlButton >
            <Dropdown
              group
              isOpen={dropdownOpen}
              size="sm"
              direction="up"
              toggle={toggleDropdown}
              disabled={!isVideoOrAudio}
            >

              <DropdownToggle
                caret
                style={{
                  backgroundColor: `${palette.light_gray}`,
                  color: `${palette.primary}`,
                   border:"none"
                }}
                disabled={!isVideoOrAudio}
              >
                <ButtonSection disabled={!isVideoOrAudio}>
                  
                {`${playbackRate}x`}
                <ControlIcon
                  src={PlayBackSpeed}
                  alt="Playback Speed"
                  disabled={!isVideoOrAudio}
                  style={{ height: '25px', width: '25px' }}
                />
                </ButtonSection>
              </DropdownToggle>

              <AbsoluteDropdownMenu>
                {[0.5, 1, 1.5, 2].map((rate) => (
                  <button
                    key={rate}
                    value={rate}
                    onClick={(e) =>
                      onPlaybackRateChange(parseFloat(e.currentTarget.value))
                    }
                    style={{
                      border: 'none',
                      color: `${palette.primary}`,
                      backgroundColor: `${palette.white}`,
                      width: "100%",
                      padding: "5px 0"
                    }}
                    disabled={!isVideoOrAudio}
                  >
                    {rate} x
                  </button>
                ))}
              </AbsoluteDropdownMenu>
              
            </Dropdown>
          </ControlButton>
          </Tooltip>

      <ControlButton >
        <ButtonSection disabled={!isVideoOrAudio}>
          <Tooltip title={isText ? 'Disable CC' : 'Enable CC'}>
            <ControlIcon
              src={CCIcon}
              style={{ height: '20px', width: '20px', margin: '2px' }}
              onClick={onToggleCC}
              disabled={!isVideoOrAudio}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton>

      <ControlButton onClick={onToggleFitToScreen} >
        <ButtonSection >
          <Tooltip title={isText ? 'Minimize' : 'Maximize'}>
            <img
              src={MaximizeIcon}
              style={{ height: '20px', width: '20px', margin: '2px' }}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton>

      <ControlButton onClick={onNext}>
        <ButtonSection>
          <Tooltip title="Next Topic">
            <img
              src={NextIcon}
              style={{ height: '20px', width: '20px', margin: '2px' }}
            />
          </Tooltip>
        </ButtonSection>
      </ControlButton>
    </ControlSectionWrapper>
  );
};

export default ControlSection;
