import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Box,
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  helperText,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment-timezone';

import { AddAnnoucementCont } from '../../styles/Calendar/CalendarStyle';
import {
  Head,
  Details,
  ImgSection,
  Info,
  Profile,
  SubDetailDiv,
  Form,
  CheckboxContainer,
} from '../../styles/Calendar/CalendarStyle';
import BackButton from '../Utilities/BackButton';
import { useDispatch, useSelector } from 'react-redux';

import { addAnnouncementdetails } from '../../features/calendar/CalendarSlice';
import { HeadingH2, Text } from '../../styles/Common/CommonStyles';
import CommonButton from '../Utilities/CommonButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const AddAnnouncements = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [fieldErrors, setFieldErrors] = useState({});
  const [formData, setFormData] = useState({
    announcementNo: '',
    eventName: '',
    eventDate: '',
    eventEndDate: '',
    type: '',
    image: '',
    startTime: '',
    endTime: '',
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [file, setFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState('');
  const [encoadUrl, setencodingUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      console.log(selectedFile);
      setFile(selectedFile);

      await requestUploadUrl(selectedFile);
    } else {
      console.error('No file selected');
      setFile(null);
    }
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/calendar/addAnnouncementImage',
        {
          fileName: file.name,
          fileType: file.type,
        },
      );
      console.log(response.data.url);
      setUploadUrl(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      const data = await axios.put(uploadUrl, file, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          //   'Content-Type': file.type,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully');

      return uploadUrl.split('?')[0]; // Just return the URL without encoding it

    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const validateRequiredFields = (data) => {
    const errors = {};
    const requiredFields = [
      'announcementNo',
      'eventName',
      'eventDate',
      'eventEndDate',
      'type',
      'startTime',
      'endTime',
    ];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors[field] = `The ${field} field is required.`;
      }
    });
    return errors;

  };
  const { t } = useTranslation();
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setFieldErrors((prev) => ({
        ...prev,
        [name]: `The ${name} field is required.`,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit called');


    const errors = validateRequiredFields(formData);
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      const firstEmptyField = document.querySelector(
        `[name="${Object.keys(errors)[0]}"]`,
      );
      if (firstEmptyField) {
        firstEmptyField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    try {
      setLoading(true);
      console.log('Uploading file...');
      let imageUrl = '';
      if (file) {
        imageUrl = await uploadToS3();
      }

      console.log('Submitting form data:', {
        ...formData,
        image: imageUrl,
      });

      const response = await axios.post(
        'http://localhost:8000/api/calendar/addannouncements',
        {
          ...formData,
          image: imageUrl,
        },
      );


      if (response.status === 200) {
        console.log('Announcement added successfully');
        setSnackbarMessage('Announcement added successfully!');
        setOpenSnackbar(true);
        setFormData({
          announcementNo: '',
          eventName: '',
          eventDate: '',
          eventEndDate: '',
          type: '',
          image: '',
          startTime: '',
          endTime: '',
        });
        navigate(`/EventsNavigator/Announcements`);
      } else {
        console.error('Failed to add announcement', response);
        setSnackbarMessage('Failed to add announcement. Please try again.');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(
        'Failed to add announcement. Please try again.',
        error.message,
      );
      setSnackbarMessage('Failed to add announcement. Please try again.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);

    }
  };
  return (
    <>
      <AddAnnoucementCont>
        <Head>
          <BackButton />
          <HeadingH2>{t('calender.announcementLabel')}</HeadingH2>
        </Head>
        <form onSubmit={handleSubmit}>
          <TextField
            name="announcementNo"
            label={t('calender.announcementNumb')}
            value={formData.announcementNo}
            onChange={handleInputChange}
            fullWidth
            required
            onBlur={handleBlur}
            margin="normal"
            error={Boolean(fieldErrors.announcementNo)}
            helperText={fieldErrors.announcementNo}
          />
          <TextField
            name="eventName"
            label={t('calender.eventNameLabel')}
            value={formData.eventName}
            onChange={handleInputChange}
            fullWidth
            required
            onBlur={handleBlur}
            margin="normal"
            error={Boolean(fieldErrors.eventName)}
            helperText={fieldErrors.eventName}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="eventDate"
                label={t('calender.startdateLabel')}
                type="date"
                value={formData.eventDate}
                onChange={handleInputChange}
                fullWidth
                required
                onBlur={handleBlur}
                error={Boolean(fieldErrors.eventDate)}
                helperText={fieldErrors.eventDate}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="eventEndDate"
                label={t('calender.enddateLabel')}
                type="date"
                value={formData.eventEndDate}
                onChange={handleInputChange}
                fullWidth
                onBlur={handleBlur}
                required
                error={Boolean(fieldErrors.eventEndDate)}
                helperText={fieldErrors.eventEndDate}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="startTime"
                label={t('calender.starttimeLabel')}
                type="time"
                value={formData.startTime}
                onChange={handleInputChange}
                onBlur={handleBlur}
                fullWidth
                required
                error={Boolean(fieldErrors.startTime)}
                helperText={fieldErrors.startTime}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="endTime"
                label={t('calender.endtimeLabel')}
                type="time"
                value={formData.endTime}
                onChange={handleInputChange}
                fullWidth
                onBlur={handleBlur}
                required
                error={Boolean(fieldErrors.endTime)}
                helperText={fieldErrors.endTime}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <TextField
            name="type"
            select
            label={t('calender.eventTypeLabel')}
            value={formData.type}
            onChange={handleInputChange}
            fullWidth
            onBlur={handleBlur}
            placeholder="select"
            displayEmpty
            required
            margin="normal"
            error={Boolean(fieldErrors.CourseType)}
            helperText={fieldErrors.CourseType}
          >
            <MenuItem value="none" disabled>
              Select Event Type
            </MenuItem>
            <MenuItem value="Workshop">Workshop</MenuItem>
            <MenuItem value="Seminar">Seminar</MenuItem>
            <MenuItem value="Lecture">Lecture</MenuItem>
            <MenuItem value="Hands-on Lab">Hands-on Lab</MenuItem>
            <MenuItem value="Webinar">Webinar</MenuItem>
            <MenuItem value="Bootcamp">Bootcamp</MenuItem>
            <MenuItem value="Training Program">Training Program</MenuItem>
          </TextField>
          <Typography variant="body2" color="#2563EB" gutterBottom>
            <Typography variant="body2" color="#2563EB" gutterBottom>
              {t('calender.imagedescriptionLabel1')}
            </Typography>
            {t('calender.imagedescriptionLabel2')}
          </Typography>
          <Grid
            container
            sx={{
              border: '1px solid #c7c7c7',
              padding: '20px',
              borderRadius: '3px',
            }}
          >
            <Grid item>
              <input type="file" name="image" onChange={handleFileChange} />
              <input directory="" webkitdirectory="" type="file" />
            </Grid>
            <Grid item>
              {formData.image && (
                <Box ml={8} width={500} height={300}>
                  <img
                    src={formData.image}
                    alt="Uploaded"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <br />
          <Grid>
            <Grid>
              <CommonButton
                label={
                  loading ? t('calender.addingLabel') : t('calender.addLabel')
                }
                type="submit"
                disabled={loading}
              ></CommonButton>
            </Grid>
          </Grid>
        </form>
      </AddAnnoucementCont>

    </>
  );
};

export default AddAnnouncements;
