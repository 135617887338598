import React, { useState, useEffect } from 'react';
import { Box,Typography, Grid, TextField, Button, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateModule,  deleteModule} from '../../../features/courses/addCourseDetailsSlice';
import JoditReact from "jodit-react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';

const CreateModule = ({ module, onClose }) => {
  const dispatch = useDispatch();
  const [moduleDetails, setModuleDetails] = useState({ ...module });

  useEffect(() => {
    setModuleDetails({ ...module });
  }, [module]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModuleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(updateModule(moduleDetails));
    onClose(); // Close the CreateModule component
  };

  const handleCancel = () => {
    onClose(); // Close the CreateModule component
  };

  const handleDelete = () => {
    dispatch(deleteModule(module.id));
    onClose(); // Close the CreateModule component
  };

  return (
    <Box style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: "14px", maxHeight: '80vh', overflow: 'auto' }}>
      <div style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
          <Grid item xs={12}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px" }} onClick={handleCancel}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginRight: '8px' }}>
            Save
          </Button>
        </Grid>
        </div>
                <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: "8px", marginTop: "22px" }}>
              Module Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Name"
              variant="outlined"
              fullWidth
              name="title"
              value={moduleDetails.title}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Enter Description"
              variant="outlined"
              rows="5"
              multiline
              fullWidth
              name="description"
              value={moduleDetails.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Module Number
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Module Number"
              variant="outlined"
              fullWidth
              name="number"
              type="number"
              value={moduleDetails.number}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Estimated Time</Typography>
            <TextField
              id="outlined-basic"
              placeholder="Enter Estimated Time"
              variant="outlined"
              fullWidth
              name="EstTime"
              value={moduleDetails.EstTime}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Learning Objectives</Typography>
          </Grid>
          <Grid item xs={12}>
            <JoditReact
              value={moduleDetails.objectives}
              config={{
                style: {},
              }}
            />
          </Grid>
          <Grid item xs={12}>
          <Tooltip title="Delete Module">
            {/* <IconButton onClick={handleDelete} style={{ color: 'red', marginLeft: '8px' }}>
              <DeleteIcon />
            </IconButton> */}
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Tooltip>
        </Grid>
        </Grid>
    </Box>
  );
};

export default CreateModule;
