import react from 'react';
import { Routes, Route } from 'react-router-dom';
import Admins from './Admins';
import HistoryofImports from './HistoryofImports';
import Instructors from './Instructors';
import Learners from './Learners';
import UserDetails from './UserDetails';

const Users = () => {
  return (
    <Routes>
      <Route index path="/*" element={<Admins />} />
      <Route path="/HistoryofImports" element={<HistoryofImports />} />
      <Route path="/Instructors" element={<Instructors />} />
      <Route path="/Learners" element={<Learners />} />
      <Route path="/UserDetails/:userId" element={<UserDetails />} />
    </Routes>
  );
};

export default Users;
