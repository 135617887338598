import axios from 'axios';

// const URL = 'https://leapot-lms-backend.onrender.com/api';
// const URL = 'http://localhost:8000/api';

const URL = process.env.REACT_APP_URL;

export const loginUserApi = async (token) => {
  try {
    console.log(token);
    //    return await axios.post(`${URL}/login`,data);
    return await axios.get(`${URL}/api/eventManager/signIn`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  } catch (err) {
    console.log('Error occurs while running loginUser function', err);
  }
};

export const logInUserWithEmilApi = async (token) => {
  try {
    console.log('logInUserWithEmilApi', token);
    return await axios.post(`${process.env.REACT_APP_URL}/login`, data);

    return await axios.post(`${URL}/loginWithEmail`, {
      // return await axios.post(`${URL}/api/loginWithEmail`, {

      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  } catch (err) {
    console.log('Error occurs while running loginUser function', err);
  }
};

export const logoutApi = async (token, email) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_URL}/api/logout`,
      { email: email },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  } catch (err) {
    console.log('Error occurs while running logout function', err);
    throw err;
  }
};
