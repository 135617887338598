import React, { useState, useEffect } from 'react';
import LearnerNotificationTabs from './LearnerNotificationTabs';
import { Delete, Archive, CheckCircle, Star } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router-dom';

import { WholePage } from '../../styles/Certificate/CertificateStyle';

import {
  NotificationContainer,
  NotificationContent,
  NotificationSender,
  NotificationActions,
  ActionIcon,
  ActionButton,
} from '../../styles/Notification/NotificationStyles';
import { Text } from '../../styles/Common/CommonStyles';
import { useTranslation } from 'react-i18next';

const Notification = ({
  id,
  subject,
  sender,
  timestamp,
  onArchive,
  onDelete,
  onMarkAsRead,
  isOpen,
}) => {
  const [isRead, setIsRead] = useState(false);
  const [isImportant, setIsImportant] = useState(false);
  const navigate = useNavigate();

  const handleArchive = () => {
    onArchive();
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleMarkAsRead = () => {
    setIsRead(true);
    onMarkAsRead(id);
  };

  const handleToggleImportant = (event) => {
    event.stopPropagation();
    setIsImportant(!isImportant);
  };

  const handleClick = (event, message) => {
    event.preventDefault();
    navigate(`/LearnerNotification/DetailedNotification/${id}`, {
      state: { message },
    });
  };

  const { t } = useTranslation();
  return (
    <NotificationContainer
      className={`${isRead ? 'read' : ''} ${isImportant ? 'important' : ''}`}
      onClick={handleClick}
    >
      <NotificationContent>
        <NotificationSender>
          <Tooltip
            title={isImportant ? 'Remove from Important' : 'Mark as Important'}
            arrow
          >
            <ActionIcon>
              <Star
                onClick={handleToggleImportant}
                style={{
                  cursor: 'pointer',
                  color: isImportant ? 'gold' : 'grey',
                  marginTop: '4px',
                }}
              />
            </ActionIcon>
          </Tooltip>
          <Text>{sender}</Text>
        </NotificationSender>
        <Text>{subject}</Text>
        <Text>
          {t('notification.ReceivedAt')}{' '}
          {new Date(timestamp).toLocaleTimeString()}
        </Text>
      </NotificationContent>
      <NotificationActions>
        {!isRead && (
          <Tooltip title={t('notification.markLabel')} arrow>
            <ActionButton>
              <CheckCircle
                onClick={handleMarkAsRead}
                style={{ cursor: 'pointer' }}
              />
            </ActionButton>
          </Tooltip>
        )}
        <Tooltip title={t('notification.archiveLabel')} arrow>
          <ActionButton>
            <Archive onClick={handleArchive} style={{ cursor: 'pointer' }} />
          </ActionButton>
        </Tooltip>
        <Tooltip title={t('notification.deleteLabel')} arrow>
          <ActionButton>
            <Delete onClick={handleDelete} style={{ cursor: 'pointer' }} />
          </ActionButton>
        </Tooltip>
      </NotificationActions>
    </NotificationContainer>
  );
};

const LearnerNotification = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [openedNotification, setOpenedNotification] = useState(null);

  const notifications = [
    {
      id: 1,
      subject: 'Important Notification',
      message: 'This is an important notification message.',
      sender: 'Admin',
      timestamp: Date.now(),
    },
    {
      id: 2,
      subject: 'Another notification',
      message: 'This is another notification message.',
      sender: 'John Doe',
      timestamp: Date.now() - 1000 * 60 * 5, // 5 minutes ago
    },
    {
      id: 3,
      subject: 'Yet another notification',
      message: 'This is yet another notification message.',
      sender: 'Jane Doe',
      timestamp: Date.now() - 1000 * 60 * 10, // 10 minutes ago
    },
  ];

  const handleNotificationArchive = () => {
    console.log('Notification archived');
  };

  const handleNotificationDelete = () => {
    console.log('Notification deleted');
  };

  const handleNotificationMarkAsRead = (id) => {
    console.log(`Notification ${id} marked as read`);
  };

  const toggleNotification = (id) => {
    if (openedNotification === id) {
      setOpenedNotification(null);
    } else {
      setOpenedNotification(id);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = notifications.filter(
      (notification) =>
        notification.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
        notification.sender.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredNotifications(filtered);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);
  const { t } = useTranslation();
  return (
    <>
      <LearnerNotificationTabs />
      <WholePage style={{ marginTop: '35px' }}>
        <br />
        <TextField
          label={t('notification.searchNotificationLabel')}
          size="small"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '300px', marginBottom: '16px', marginLeft: '44px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {filteredNotifications.map((notification) => (
          <Notification
            key={notification.id}
            {...notification}
            onArchive={handleNotificationArchive}
            onDelete={handleNotificationDelete}
            onMarkAsRead={handleNotificationMarkAsRead}
            isOpen={openedNotification === notification.id}
            toggleOpen={toggleNotification}
            handleClick={(event) => handleClick(event, notification.message)}
          />
        ))}
      </WholePage>
    </>
  );
};

export default LearnerNotification;
