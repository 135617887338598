import { blue } from '@mui/material/colors';
import React from 'react';
import DataTable from 'react-data-table-component'; // Import your DataTable library
import { palette } from '../../styles/palette';

const TableUtilityLib = (props) => {
  const handleRowClick = (row) => {
    if (props.onRowClicked) {
      props.onRowClicked(row);
    }
  };
  const handleRowSelected = (state) => {
    if (props.onSelectedRowsChange) {
      props.onSelectedRowsChange(state);
    }
  };

  const customStyles = {
    table: {
      style: {
        border: '1px solid #ddd',
        borderRadius: '5px', // Add border radius
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f2f2f2', // Change header row background color
        color: palette.primary,
        fontWeight: 'bold',
      },
    },
    highlightOnHover: {
      style: {
        backgroundColor: palette.primary, // Change background color on hover
      },
    },
  };

  return (
    <DataTable
      title={props.title}
      columns={props.columns}
      data={props.data}
      highlightOnHover
      pointerOnHover
      selectableRows={props.selectableRows}
      customStyles={customStyles}
      selectableRowsHighlight={props.selectableRowsHighlight}
      contextActions={props.contextActions}
      onRowClicked={(row) => handleRowClick(row)}
      onSelectedRowsChange={handleRowSelected}
      pagination
    />
  );
};

export default TableUtilityLib;
