// import UploadModal from "../../Utilities/UploadModal";
// import { TextField } from "@mui/material";

// const AudioUpload = ({ isOpen, onClose }) => {
//   return (
//     <UploadModal
//       isOpen={isOpen}
//       onClose={onClose}
//       onCancel={onClose}
//       title="Audio (Max audio size:200MB)"
//     >
//       <div style={{ marginRight: "10px", marginLeft: "10px" }}>
//         <TextField variant="outlined" label="Audio Title" style={{ width: "100%" }} />
//       </div>

//     </UploadModal>
//   );
// };
// export default AudioUpload;

import React ,  {useState} from "react";
import { TextField,Tooltip,IconButton, Grid, Typography, InputAdornment, Button} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
import JoditReact from "jodit-react";

import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {addItem} from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';
import axios from "axios";

const AudioUpload = ({ isOpen, onClose, selectedItem ,onBackButtonClick, lessonId , moduleId  }) => {
  const handleOnClose = () => {
     onClose();
  };
  const { id , courseStructure } = useParams();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    selectedValue: "upload",
    link: selectedItem?.link || "",
    uploadUrl: '',
    moduleId:selectedItem?.moduleId || "",
    lessonId:selectedItem?.lessonId || "",
    title: selectedItem?.title ||"",
    textDescription:selectedItem?.textDescription || "",
    id: selectedItem?.id || "",
    courseStructure: selectedItem?.courseStructure || "",
    contentType : selectedItem?.contentType || ""

  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId : lessonId,
      moduleId : moduleId,
      id : generateRandomNumber() ,
      contentType: 'Audio',
      courseStructure :courseStructure,
      link: formData?.uploadUrl?.split('?')[0],

    }));
    console.log(formData)
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    await requestUploadUrl(selectedFile);
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post('http://localhost:8000/api/course/uploadVideo', {
        fileName: file.name,
        fileType: file.type,
      });
      setFormData((prevData) => ({
        ...prevData,
        link : response.data.url?.split('?')[0] ,
        uploadUrl: response.data.url,
      }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      await axios.put(formData.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      setVideoShow(true);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileName('');
    setFormData((prevData) => ({
      ...prevData,
      uploadUrl: '',
    }));
  };

  const handleUpload = async () => {
    if (file) {
      await uploadToS3();
      
    }
  };

  const handleSubmit = async () => {
    console.log(formData)
    await  dispatch(addItem(formData))
    onClose();
   
  };


  return (
    <div>
     

<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
  {/* Grid container aligned to the left */}
  <Grid container alignItems="center" spacing={1} sx={{mt:1, mb:2}}>
    <Grid item>
      <Tooltip title="Go Back">
        <IconButton
          size="small"
          onClick={handleOnClose}
          sx={{ color: `${palette.THEME_THREE}` }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item sx={{mt:1}}>
      <Typography variant="h5" gutterBottom>
        Audio
      </Typography>
    </Grid>
  </Grid>

  {/* Buttons div aligned to the right */}
  <div style={{ display: "flex", alignItems: "center" }}>
    <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }}>Discard Changes</Button>
    <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }} onClick={handleSubmit}>Save</Button>
  </div>
</div>
      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"
          style={{ width: "100%",  marginBottom: "10px" }}
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
      <JoditReact value={formData.textDescription}
          config={{ placeholder: "Enter the video description" }}
          onChange={newContent => setFormData((prevData) => ({
            ...prevData,
            textDescription: newContent,
          }))} />

<Grid container spacing={2} alignItems="center" >
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              label="Upload Video File"
              value={fileName}
              helperText="Max video size 2GB"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {fileName && (
                      <IconButton onClick={handleFileRemove} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {!fileName && (
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        style={{ borderRadius: 4 }}
                      >
                        Choose File
                        <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', padding: '4px' }}>
            {fileName && (
              <Button variant="contained" color="primary" onClick={handleUpload} fullWidth>
                Upload
              </Button>
            )}
          </Grid>
        </Grid>

        {((formData?.link && formData?.link.trim() !== "") || (selectedItem?.link && selectedItem?.link.trim() !== "")) && (
  <audio style={{ width:" 100%" }}
    key={formData?.link && formData?.link.trim() !== "" ? selectedItem?.link : formData?.link} 
    // width="900" 
    controls
  >
    <source 
      src={formData?.link && formData?.link.trim() !== "" ? selectedItem?.link : formData?.link } 
      type="video/mp4" 
    />
    Your browser does not support the video tag.
  </ audio >
)}
      </div>
    </div>
  );
};

export default AudioUpload;

