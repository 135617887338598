import React, { useState } from 'react';
import {
  PageContainer,
  Container,
} from '../../styles/Dashboard/DashboardStyles';
import LineGraph from '../Utilities/LineGraph';
import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DisplayTableLib from '../Utilities/TableUtilityLib';
import Chart from 'react-apexcharts';
import CommonButton from '../Utilities/CommonButton';
import { Description } from '../../styles/Common/CommonStyles';
import { ProfileHead, ProfilePic } from '../../styles/Landing/NavbarElements';
import { ImageTag } from '../../styles/Calendar/CalendarStyle';
import TopEvents from '../../store/TopEvents.json';
import memberDetails from '../../store/DashboardMemberDetails.json';
import sellingDetails from '../../store/DashboardSellingCourseData.json';
import instructorDetails from '../../store/DashboardInstructorDetials.json';
import notification from '../../store/DashboardNotification.json';
import { useTranslation } from 'react-i18next';

const AdminDashboard = () => {
  const [notifications, setNotifications] = useState([...notification]);
  const [selectedValue, setSelectedValue] = useState('daily');
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const teachersDetailsColumn = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: 'Qualification',
      selector: (row) => row.qualification,
      sortable: true,
    },
    {
      name: 'Fees',
      selector: (row) => row.fees,
      sortable: true,
    },
    {
      name: 'Performance',
      selector: (row) => row.performance,
      sortable: true,
    },
  ];

  const memberDetailsColumns = [
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Courses',
      selector: (row) => row.courses,
      sortable: true,
    },
    {
      name: 'Enrolled',
      selector: (row) => row.enrolled,
      sortable: true,
    },
    {
      name: 'Progress',
      selector: (row) => row.progress,
      sortable: true,
    },
    {
      name: 'Rating',
      selector: (row) => row.rating,
      sortable: true,
    },
  ];

  const topCoursesColumns = [
    {
      name: 'Event Name',
      selector: (row) => row.courseName,
      sortable: true,
    },
    {
      name: 'Enrolled Students',
      selector: (row) => row.enrollStudents,
      sortable: true,
    },
  ];
  const [state, setState] = useState({
    options: {
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: ['Sun', 'Mon', 'Tue', 'Thu', 'Fri', 'Sat'],
      },
    },
    series: [
      {
        name: 'Visitors',
        data: [100, 100, 100, 200, 100, 150],
      },
    ],
  });
  const [queryState, setQueryState] = useState({
    options: {
      labels: ['Resolved', 'Pending'],
    },
    series: [68, 32],
  });

  const [activeState, setActiveState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    series: [
      {
        name: 'Active Users',
        data: [120, 155, 180, 200, 100, 150, 200, 160, 150, 140, 250, 230],
      },
    ],
  });
  const [revenueState, setARevenueState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    series: [
      {
        name: 'New Member',
        data: [120, 155, 180, 200, 100, 150, 200, 160, 150, 140, 250, 230],
      },
      {
        name: 'Total Order',
        data: [110, 125, 165, 222, 130, 140, 190, 170, 140, 150, 260, 240],
      },
      {
        name: 'Total Profit',
        data: [140, 190, 150, 140, 165, 146, 245, 164, 172, 183, 142, 137],
      },
    ],
  });

  const sellingCourseColumn = [
    {
      name: 'CourseTitle',
      selector: (row) => row.courseTitle,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: 'Enrolled Students',
      selector: (row) => row.enrollStudents,
      sortable: true,
    },
    {
      name: 'Stock',
      selector: (row) => row.stock,
      sortable: true,
    },
  ];

  const { t } = useTranslation();
  return (
    <PageContainer style={{ border: '0px solid blue' }}>
      <Grid container sx={{ border: '0px solid red', mt: 2 }}>
        {/*First Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {t("dashboard.totalLearners")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                70
              </Typography>
              {/* <Link>See all Learners</Link> */}
              <Link>{t("dashboard.seeAllLearners")}</Link>
            </CardContent>
          </Card>
        </Grid>
        {/* Second Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {/* ACTIVE LEARNERS */}
                    {t("dashboard.activeLearner")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                12
              </Typography>
              {/* <Link>View all Learners</Link> */}
              <Link>{t("dashboard.viewAllLearners")}</Link>
            </CardContent>
          </Card>
        </Grid>
        {/* Third Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {/* NO. OF COURSES */}
                    {t("dashboard.nubOfCources")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                10
              </Typography>
              {/* <Link>See Details</Link> */}
              <Link>{t("dashboard.seeDetails")}s</Link>
            </CardContent>
          </Card>
        </Grid>
        {/* Fourth Card Grid */}
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          style={{ border: '0px solid green' }}
        >
          <Card sx={{ minWidth: 290 }}>
            <CardContent>
              <Grid container>
                <Grid xs={9} style={{ border: '0px solid red' }}>
                  <Typography color="text.secondary" variant="body1">
                    {/* SITE VIEW */}
                    {t("dashboard.siteView")}
                  </Typography>
                </Grid>
                <Grid xs={3} style={{ border: '0px solid red' }}>
                  <Typography color="green">^ 20%</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                100
              </Typography>
              {/* <Link>View Details</Link> */}
              <Link>{t("dashboard.viewDetails")}</Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Notification list and Announcement Grid */}
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mb: 1, ml: 2, mr: 1, mt: 1, minHeight: 440 }}>
            <CardContent>
              <Grid container style={{ border: '0px solid red' }}>
                <Grid item xs={10}>
                  {/* <Typography variant="h6">Notification</Typography> */}
                  <Typography variant="h6">{t("dashboard.Notification")}</Typography>
                </Grid>

                <Grid item xs={2}>
                  {/* <Link style={{ cursor: 'pointer' }}>View All</Link> */}
                  <Link style={{ cursor: 'pointer' }}>{t("dashboard.viewAll")}</Link>
                </Grid>
              </Grid>
              <hr />

              <Grid container>
                <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                  <ul style={{ listStyleType: 'disc' }}>
                    {notifications.map((allNotification) => {
                      return (
                        <li style={{ marginBottom: '15px', cursor: 'pointer' }}>
                          {allNotification.notification}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid green' }}>
          <Card
            sx={{
              mr: 2,
              ml: 1,
              mb: 1,
              mt: 1,
              border: '0px solid green',
            }}
          >
            <CardContent>
              <Grid xs={12}>
                {/* <Typography variant="h6">Upcoming Courses</Typography> */}
                <Typography variant="h6">{t("dashboard.upComingCources")}</Typography>
              </Grid>
              <hr />
              <Grid display="flex" justifyContent="center" alignItems="center">
                <ImageTag
                  style={{ border: '0px solid red' }}
                  src="https://i.pinimg.com/736x/73/a4/5e/73a45ea3b5548b23b44148ec3fd0a72c.jpg"
                ></ImageTag>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* User Activity Grid */}
      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ ml: 2, mb: 1 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={10} style={{ border: '0px solid red' }}>
                  {/* <Typography variant="h6">Visitors</Typography> */}
                  <Typography variant="h6">{t("dashboard.visitor")}</Typography>
                </Grid>
                <Grid item xs={2} style={{ border: '0px solid red' }}>
                  <Select
                    fullWidth
                    size="small"
                    value={selectedValue}
                    onChange={handleSelectChange}
                    defaultValue="daily"
                  >
                    {/* <MenuItem value="daily"> Daily</MenuItem> */}
                    <MenuItem value="daily">{t("dashboard.daily")}</MenuItem>
                    {/* <MenuItem value="monthly"> Monthly</MenuItem> */}
                    <MenuItem value="monthly"> {t("dashboard.monthly")}</MenuItem>
                    {/* <MenuItem value="yearly"> Yearly</MenuItem> */}
                    <MenuItem value="yearly"> {t("dashboard.yearly")}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Chart
                options={state.options}
                series={state.series}
                type="bar"
                // width="700"
                height="300"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mr: 2, mb: 1 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={10}>
                  {/* <Typography variant="h6">Student Queries</Typography> */}
                  <Typography variant="h6">{t("dashboard.studentQueries")}</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              {/* Place the Percentage Circular graph */}
              <Chart
                options={queryState.options}
                series={queryState.series}
                type="donut"
                width="460"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2, mb: 1, border: '0px solid red' }}>
        <Grid item xs={5} style={{ border: '0px solid red' }}>
          <Card sx={{ mr: 1, ml: 2, mt: 1, mb: 1 }}>
            <CardContent>
              {/* <Typography variant="h6"> Active Users</Typography> */}
              <Typography variant="h6"> {t("dashboard.activeUsers")}</Typography>
              <Chart
                options={activeState.options}
                series={activeState.series}
                type="bar"
                // width='500'
                height="310"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7} style={{ border: '0px solid red' }}>
          <Card sx={{ mt: 1, mb: 1, ml: 1, mr: 2 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4} style={{ border: '0px solid red' }}>
                  {/* <Typography variant="h6">Member Details</Typography> */}
                  <Typography variant="h6">{t("dashboard.memberDetails")}</Typography>
                </Grid>
                <Grid item xs={8} sx={{ border: '0px solid green', mb: 1 }}>
                  <TextField
                    fullWidth
                    size="small"
                    // placeholder="Search by name"
                    placeholder={t("dashboard.searchbyname")}
                  />
                </Grid>
              </Grid>
              <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                <DisplayTableLib
                  columns={memberDetailsColumns}
                  data={memberDetails}
                ></DisplayTableLib>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Best Selling Course and Revinue Report Grid  */}
      <Grid container>
        <Grid item xs={6} style={{ border: '0px solid red' }}>
          <Card sx={{ mt: 2, mr: 1, ml: 2, mb: 1 }}>
            <CardContent>
              {/* <Typography variant="h6">Revenue Report</Typography> */}
              <Typography variant="h6">{t("dashboard.revenueReport")}</Typography>
              <Grid container style={{ border: '0px solid green' }}>
                <Grid container xs={4} style={{ border: '0px solid red' }}>
                  <Grid
                    item
                    xs={5}
                    style={{ border: '0px solid yellow' }}
                  ></Grid>
                  <Grid item xs={7} style={{ border: '0px solid green' }}>
                    {/* <Typography>New Student</Typography> */}
                    <Typography>{t("dashboard.newStudent")}</Typography>
                    <Typography>550</Typography>
                  </Grid>
                </Grid>
                <Grid container xs={4} style={{ border: '0px solid blue' }}>
                  <Grid
                    item
                    xs={5}
                    style={{ border: '0px solid yellow' }}
                  ></Grid>
                  <Grid item xs={7} style={{ border: '0px solid green' }}>
                     {/* <Typography>Total Order</Typography> */}
                   <Typography>{t("dashboard.totalOrder")}r</Typography> 
                    <Typography>550</Typography>
                  </Grid>
                </Grid>
                <Grid container xs={4} style={{ border: '0px solid magenta' }}>
                  <Grid
                    item
                    xs={5}
                    style={{ border: '0px solid yellow' }}
                  ></Grid>
                  <Grid item xs={7} style={{ border: '0px solid green' }}>
                    {/* <Typography>Total Profit</Typography> */}
                    <Typography>{t("dashboard.totalProfit")}</Typography>
                    <Typography>$5263.55</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Chart
                options={revenueState.options}
                series={revenueState.series}
                type="bar"
                height="280"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ border: '0px solid green' }}>
          <Card sx={{ mt: 2, mr: 2, ml: 1, mb: 1 }}>
            <CardContent>
              {/* <Typography variant="h6">Best Selling Courses</Typography> */}
              <Typography variant="h6">{t("dashboard.bestSellingCourses")}</Typography>
              <div style={{ maxHeight: '345px', overflowY: 'auto' }}>
                <DisplayTableLib
                  columns={sellingCourseColumn}
                  data={sellingDetails}
                ></DisplayTableLib>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Instructor Details and Top Courses Grid */}
      <Grid container>
        <Grid item xs={7} style={{ border: '0px solid red' }}>
          <Card sx={{ ml: 2, mb: 1, mt: 1, mr: 1 }}>
            <CardContent>
              <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                <DisplayTableLib
                  // title="Instructors Details"
                  title={t("dashboard.instructorsDetails")}
                  columns={teachersDetailsColumn}
                  data={instructorDetails}
                ></DisplayTableLib>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5} style={{ border: '0px solid green' }}>
          <Card sx={{ mt: 1, ml: 1, mr: 2, mb: 1 }}>
            <CardContent>
              <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                <DisplayTableLib
                  // title="Top Events"
                  title={t("dashboard.topEvents")}
                  columns={topCoursesColumns}
                  data={TopEvents}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default AdminDashboard;
