// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Grid,
//   TextField,
//   Typography,
//   InputAdornment,
//   Divider,
//   Switch,
//   IconButton,
//   Tooltip,
//   List,
//   ListItem,
//   ListItemText,
//   Paper,
//   Collapse,
//   ListItemIcon,
//   Box, // Import Box component
//   Button, // Import Button component
//   Link
// } from "@mui/material";
// import {
//   HeaderContainer,
//   CreateContent,
//   Heading,
// } from "../../../styles/Courses/CourseBuilderStyles";
// import { useNavigate } from "react-router-dom";
// import { PageContainer } from "../../../styles/Common/CommonStyles";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { palette } from "../../../styles/palette";
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
// import MenuIcon from '@mui/icons-material/Menu'; // Import Menu icon
// import courseModule from "../../../store/CourseModules.json";
// import ChapterContent from "./ChapterContent";
// const CoursePreview = () => {
//   const navigate = useNavigate();
//   const [expandedModule, setExpandedModule] = useState(null);
//   const [expandedLessons, setExpandedLessons] = useState({});
//   const [sidebarOpen, setSidebarOpen] = useState(true); // State to track sidebar open/close
//   const [selectedItem, setSelectedItem] = useState("hello"); // State to track selected item
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleModuleClick = (moduleId) => {
//     setExpandedModule(expandedModule === moduleId ? null : moduleId);
//   };

//   const handleLessonClick = (lessonId) => {
//       setExpandedLessons({
//         ...expandedLessons,
//         [lessonId]: !expandedLessons[lessonId],
//       });
//     };

//   const isModuleExpanded = (moduleId) => {
//     return expandedModule === moduleId;
//   };

//   const isLessonExpanded = (lessonId) => {
//       return expandedLessons[lessonId];
//     };

//     const toggleSidebar = () => {
//       setSidebarOpen(!sidebarOpen);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//   };

//   return (
//     <PageContainer>
//     <div style={{ display: 'flex' }}>
//       {/* Sidebar */}
//       <div style={{ width: sidebarOpen ? 350 : 0, height: '100vh', backgroundColor: sidebarOpen ? '#F1F1F1' : '#FFF', padding: '20px', overflow: 'hidden' }}>
//         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
//           {/* <Tooltip title="Go Back">
//             <IconButton
//               size="small"
//               onClick={() => navigate("/CourseBuilder")}
//               sx={{ color: `${palette.THEME_THREE}` }}
//             >
//               <ArrowBackIosIcon />
//             </IconButton>
//           </Tooltip> */}
//           {/* Hamburger Icon to toggle sidebar */}
//           <IconButton
//             size="small"
//             onClick={toggleSidebar}
//             sx={{ color: `${palette.THEME_THREE}` }}
//             style={{ marginLeft: 'auto' }}
//           >
//             <MenuIcon />
//           </IconButton>
//         </div>
//         {/* Sidebar content */}
//         <List style={{ height: '100%', overflowY: 'auto' }}>
//           {courseModule.module && (
//             <div>
//               <ListItem
//                 onClick={() => handleModuleClick(courseModule.module.id)}
//                 style={{ background: '#fff', marginBottom: '10px' }}
//               >
//                 <ListItemText primary={courseModule.module.title} />
//                 {isModuleExpanded(courseModule.module.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//               </ListItem>
//               <Collapse in={isModuleExpanded(courseModule.module.id)} timeout="auto" unmountOnExit>
//                 <List component={Paper} style={{ padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
//                   {courseModule.module.lessons.map((lesson) => (
//                     <div key={lesson.id}>
//                       <ListItem
//                         onClick={() => handleLessonClick(lesson.id)}
//                         style={{ background: '#fff', marginBottom: '10px', border: "1px solid #CACACA", borderRadius: "5px" }}
//                       >
//                       <ListItemIcon >
//                         <DoubleArrowIcon /> {/* Add LibraryBooksIcon here */}
//                       </ListItemIcon>
//                         <ListItemText primary={lesson.title} />
//                         {isLessonExpanded(lesson.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                       </ListItem>
//                       <Collapse in={isLessonExpanded(lesson.id)} timeout="auto" unmountOnExit>
//                         <List>
//                           {lesson.items.map((item, index) => (
//                             <ListItem
//                               key={index}
//                               // style={{marginBottom: '5px', paddingLeft: '20px', borderBottom:"1px solid #f1f1f1" }}
//                               style={{
//                                 marginBottom: '5px',
//                                 paddingLeft: '50px',
//                                 borderBottom: "1px solid #f1f1f1",
//                                 whiteSpace: 'nowrap', // Prevent text from wrapping
//                                 overflow: 'hidden', // Hide overflow text
//                                 textOverflow: 'ellipsis', // Display ellipsis (...) for overflow text
//                             }}
//                             sx={{
//                               "&:hover": {
//                                 backgroundColor: '#F1F1F1', 
//                               },
//                             }}
//                               onClick={() => handleItemClick(item)}
//                             >
//                               <ListItemIcon >
//                                 <LibraryBooksOutlinedIcon /> {/* Add LibraryBooksIcon here */}
//                               </ListItemIcon>
//                               <ListItemText primary={getItemContent(item)}/>
                             
//                             </ListItem>
//                           ))}
//                         </List>
//                       </Collapse>
//                     </div>
//                   ))}
//                 </List>
//               </Collapse>
//             </div>
//           )}
//         </List>
//       </div>
//       {/* Chapter content */}
//       {selectedItem && (
//         <ChapterContent selectedItem={selectedItem}
//         items={courseModule.module.lessons[currentIndex].items} 
//             currentIndex={currentIndex}  // Pass currentIndex as a prop
//             setCurrentIndex={setCurrentIndex} // Pass setCurrentIndex function as a prop
//             />
//       )}
//     </div>
//   </PageContainer>

//   );
// };

// const getItemContent = (item) => {
//   switch (item.type) {
//     case "heading":
//       return item.content;
//     case "text":
//       return item.content;
//     case "video":
//       return item.content;
//     case "audio":
//       return item.content;  
//     case "quiz":
//       return item.title;
//     case "link":
//       return item.title + ": " + item.url;
//     case "assignment":
//       return item.title + ": " + item.description;
//     case "live_test":
//       return item.title + " (Duration: " + item.time + " minutes)";
//     default:
//       return "";
//   }
// };


// export default CoursePreview;


import React, { useState ,useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Box,
} from '@mui/material';
import { ButtonCont} from '../../../styles/Courses/CourseBuilderStyles';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { palette } from '../../../styles/palette';
import courseModule from '../../../store/CourseModules.json';
import CommonButton from '../../Utilities/CommonButton';
import AddModuleDialog from '../CourseModule/AddModuleDialog';
import AddChapterDialog from '../CourseModule/AddChapterDialog';
import AddTopicDialog from '../CourseModule/AddTopicDialog';
import CreateTopics from '../CourseModule/CreateTopics';
import LessonTopics from '../CourseModule/LessonTopics';
import AddOrCopyTopic from '../CourseModule/AddOrCopyTopic';
import  CourseSettings  from "../CourseSettings/CourseSettings"; // Import the CourseSettings component
import { useDispatch , useSelector} from "react-redux";
import {deleteModule ,deleteLesson ,deleteTopic , saveDetails ,fetchCourseDetailsInSlice} from '../../../features/courses/addCourseDetailsSlice';

const CoursePreview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [expandedModule, setExpandedModule] = useState(null);
  const [expandedLessons, setExpandedLessons] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openChapterDialog, setOpenChapterDialog] = useState(false);
  const [openTopicDialog, setOpenTopicDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of selected item
  const [displayContent, setDisplayContent] = useState(false);
  const [displayContentData, setDisplayContentData] = useState({});
  const [showCourseSettings, setShowCourseSettings] = useState(false);
  const [showAddModuleForm, setShowAddModuleForm] = useState(false);
  const [showAddChapterForm, setShowAddChapterForm] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);

  const [selectedModule, setSelectedModule] = useState(null);
  const [ModuleData, setModuleData] = useState({});
  const { modules ,lessons} = useSelector((state) => state.addCourseDetails.courseDetails) || {};

  const { id , courseStructure } = useParams();
  console.log(id , courseStructure)

  useEffect(() => {
   
    const fetchCourseDetails = async(id) =>{
     await dispatch(fetchCourseDetailsInSlice(id))
    }
 
    fetchCourseDetails(id)
  }, [id]);
  

  const handleAddTopicSubmit = (topicData) => {
    // Handle the submitted topic data here
    console.log('Submitted topic data:', topicData);
    setOpenTopicDialog(false);
  };
  const handleCreateTopicsClick = () => {
    // Close AddTopicDialog if it's open
    setOpenTopicDialog(false);
  };

  const handleOtherComponentClick = () => {
    // Close AddTopicDialog if it's open
    setOpenTopicDialog(false);
    // Close CreateTopics if it's open
    setSelectedItem(null);
  };

  const handleModuleClick = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
    // setSelectedModule(module);

  };

  const handleLessonClick = (lessonId) => {
    setExpandedLessons({
      ...expandedLessons,
      [lessonId]: !expandedLessons[lessonId],
    });
  };

  const isModuleExpanded = (moduleId) => {
    return expandedModule === moduleId;
  };

  const isLessonExpanded = (lessonId) => {
    return expandedLessons[lessonId];
  };

  const handleAddModule = () => {
    setOpenDialog(true);
  };

  const handleAddModuleClick = () => {
    setShowAddModuleForm(true);
  };

  const handleCloseAddModuleForm = () => {
    setShowAddModuleForm(false);
  };

  
  const handleCloseAddChapterForm = () => {
    setShowAddChapterForm(false);
    setSelectedModuleId(null);
  };


const [courseModule, setCourseModule] = useState({
        module: []
      });

// const [lessonModule, setlessonModule] = useState({
//         module: []
//       });     

      const [moduleId, setModuleId] = useState(null); 
      const [lessonId, setLessonId] = useState(null);   


  const handleCloseDialog = (courseModule) => {
setCourseModule((prevCourseModule) => ({
            module: [...prevCourseModule?.module, courseModule],
          }));
    // setModuleData(courseModule)
    setOpenDialog(false);
  };

  // const handleAddChapter = (id) => {
  //   setModuleId(id)
  //   setOpenChapterDialog(true);
  // };


  const handleCloseChapDialog = (courseLesson) => {
    setCourseModule((prevModuleName) => ({
      module: prevModuleName?.module?.map((mod) => {
        if (mod?.id === moduleId) {
          // If the module ID matches the specified module ID, update its lessons array
          return {
            ...mod,
            lessons: [...mod?.lessons, courseLesson] // Append the new lesson to the lessons array of this module
          };
        }
        return mod; // Return unchanged module if the ID doesn't match
      })
    }));
    setOpenChapterDialog(false);
  };

  const handleAddTopic = (moduleId, id) => {
    console.log("handleAddTopic", id)
    setLessonId(id)
    setModuleId(moduleId)
    console.log('HAndle add topic');
    setOpenTopicDialog(true);
    // Close CreateTopics if it's open
    setSelectedItem(null);
  };

  const handleDeleteModule = async (id) =>{
    await  dispatch(deleteModule(id))
  }

  const handleDeleteLesson = async (moduleId , lessonId) =>{
    console.log(lessonId , "handleDeleteLesson")
    await  dispatch(deleteLesson({ moduleId, lessonId }))
  }

  const handleDeleteTopic = async (moduleId , lessonId , id) =>{
    await  dispatch(deleteTopic({moduleId , lessonId , id}))
  }


  const handleSaveCourse = async () =>{
    console.log({modules:modules , courseId:id })
    const result = await  dispatch(saveDetails({modules:modules , courseId:id ,courseStructure : courseStructure, lessons:lessons }))
   console.log(result ,"result handleSaveCourse");
   if(result.payload.data.success){
    alert(result.payload?.data?.message)
   }else {
    alert(result.payload?.data?.message)
   }
  }

  const handleCloseTopicDialog = (data) => {
    console.log("fgj")
    console.log(data)
    setOpenTopicDialog(true);
    setSelectedItem(null);
  };

  const handleOnContentClick = (item) => {
    setDisplayContent(true);
    setDisplayContentData(item);
    setOpenTopicDialog(false);
  };

  const handleCloseLessonTopic = () => {
    setOpenTopicDialog(true);
    setSelectedItem(null);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item); // Update the selected item when clicked
  };

  const handleLessonTopicsClose = () => {
    
    setOpenTopicDialog(true);
    setDisplayContent(false);
    setDisplayContentData('');
  };


  const toggleCourseSettings = () => {
    setShowCourseSettings(!showCourseSettings);
  };

  const getItemTitle = (item) => {
    return item.content || item.title || ''; // Update this based on your JSON structure
  };

  const getItemContent = (item) => {
    return item.text || item.content || ''; // Update this based on your JSON structure
  };

  return (
    <>
     
    {/* <div style={{ display: 'flex', justifyContent: 'space-between'}}> */}
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: 400,
          height: '620px',
          backgroundColor: '#F1F1F1',
          padding: '20px',
          
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Tooltip title="Go Back">
          <IconButton
            size="small"
            onClick={() => navigate('/CourseBuilder')}
            sx={{ color: `${palette.THEME_THREE}` }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
       <h5>React Course 1 </h5>
        <Tooltip title="Course Setting">
          <IconButton
            size="small"
            // onClick={() => navigate('/CourseSettings')}
            onClick={toggleCourseSettings}
            sx={{ color: `${palette.THEME_THREE}`}}
      
          >
            <BuildOutlinedIcon />
          </IconButton>
        </Tooltip>

        {/* <Box ml={2} width="calc(100% - 500px)">
        {showCourseSettings && <CourseSettings />}
    </Box> */}
        </div>

        <List style={{ height: '100%', overflowY: 'auto' }}>
        
        {courseStructure === 'CMLT' ? (
  modules?.map((module) => (
    <div key={module?.id}>
      <ListItem 
        onClick={() => handleModuleClick(module?.id)}
        style={{ background: '#fff', marginTop: '10px' }}
      >
        <ListItemText sx={{ color: `${palette.primary}` }} primary={module?.title}  />
        <Box sx={{ marginRight: '8px' }} />
        {isModuleExpanded(module?.id) ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </ListItem>
      <Collapse
        in={isModuleExpanded(module?.id)}
        timeout="auto"
        unmountOnExit
      >
        <List  
          component={Paper}
          style={{
            padding: '20px',
            marginBottom: '10px'
          }}
        >
          {module?.lessons?.map((lesson) => (
            <div key={lesson.id}>
              <ListItem  
                onClick={() => handleLessonClick(lesson.id)}
                style={{
                  background: '#fff',
                  marginBottom: '10px',
                  border: '1px solid #CACACA',
                  borderRadius: '5px'
                }}
              >
                <ListItemText sx={{ color: `${palette.secondary}` }} primary={lesson.title} />
                
                
                <Box sx={{ marginRight: '8px' }} />
                {isLessonExpanded(lesson.id) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={isLessonExpanded(lesson.id)}
                timeout="auto"
                unmountOnExit
              >
                <List>
                  {lesson?.items?.map((item, index) => (
                    <ListItem
                      key={index}
                      style={{
                        background: selectedItem === item ? '#d0eaff' : '#f9f9f9', // Highlight if selected
                        marginBottom: '5px',
                        paddingLeft: '20px',
                        cursor: 'pointer' // Add cursor to indicate clickable item
                      }}
                      onClick={() => handleItemClick(item)}
                    >
                      <ListItemText primary={getItemTitle(item)} />
                      {/* <Tooltip title="Delete Topic">
                        <DeleteIcon 
                          onClick={() => handleDeleteTopic(item.moduleId, item.lessonId, item.id)} 
                          style={{ cursor: "pointer", fontSize: "20px" }} 
                        />
                      </Tooltip> */}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Collapse>
    </div>
  ))
) : (
  
  <List  
  component={Paper}
  style={{
    padding: '20px',
    marginBottom: '10px'
  }}
>
          {lessons?.map((lesson) => (
            <div key={lesson.id}>
              <ListItem  
                onClick={() => handleLessonClick(lesson.id)}
                style={{
                  background: '#fff',
                  marginBottom: '10px',
                  border: '1px solid #CACACA',
                  borderRadius: '5px'
                }}
              >
                <ListItemText sx={{ color: `${palette.secondary}` }} primary={lesson.title} />
                <Tooltip title="Add Topic">
                  <AddIcon onClick={() => handleAddTopic(lesson.moduleId , lesson.id)} />
                </Tooltip>
                <Tooltip title="Delete Lesson">
                  <DeleteIcon onClick={() => handleDeleteLesson(lesson?.moduleId, lesson.id)} style={{ cursor: 'pointer', fontSize: '20px' }} />
                </Tooltip>
                <Box sx={{ marginRight: '8px' }} />
                {isLessonExpanded(lesson.id) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={isLessonExpanded(lesson.id)}
                timeout="auto"
                unmountOnExit
              >
                <List>
                  {lesson?.items?.map((item, index) => (
                    <ListItem
                      key={index}
                      style={{
                        background: selectedItem === item ? '#d0eaff' : '#f9f9f9', // Highlight if selected
                        marginBottom: '5px',
                        paddingLeft: '20px',
                        cursor: 'pointer' // Add cursor to indicate clickable item
                      }}
                      onClick={() => handleItemClick(item)}
                    >
                      <ListItemText primary={getItemTitle(item)} />
                      {/* <Tooltip title="Delete Topic">
                        <DeleteIcon 
                          onClick={() => handleDeleteTopic(item.moduleId, item.lessonId, item.id)} 
                          style={{ cursor: "pointer", fontSize: "20px" }} 
                        />
                      </Tooltip> */}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
     

) }
          <div
            style={{
              textAlign: 'center',
              position: 'fixed',
              bottom: 0,
              marginLeft: '-24px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
            }}
          >
         
          </div>
          {/* Add Module Dialog */}
          {/* <AddModuleDialog open={openDialog} onClose={handleCloseDialog} /> */}
        </List>
      </div>

        
      {/* Render CourseSettings component */}
      {showCourseSettings && (
        <Box ml={2} width="calc(100% - 500px)">
          <CourseSettings />
        </Box>
      )}

      {/* rendering Display of selected Topic */}
      {selectedItem && !openTopicDialog && (
        <LessonTopics
          selectedItem={selectedItem}
          onClose={handleCloseLessonTopic}
          lessonId= {lessonId}
          moduleId = {moduleId}
        />
      )}
      {openTopicDialog && !selectedItem && (
        <Box ml={2} width="calc(100% - 500px)">
          <AddOrCopyTopic
            open={openTopicDialog}
            onClose={handleCloseTopicDialog}
            onContentClick={handleOnContentClick}
          />
        </Box>
      )}
      {displayContent && (
        <Box ml={2} width="calc(100% - 500px)">
          <LessonTopics
            open={displayContent}
            data={displayContentData}
            onClose={handleLessonTopicsClose}
            lessonId= {lessonId}
            moduleId = {moduleId}
          />
        </Box>
      )}


      {/* Render JoditEditor component if selectedItem is not null */}
      {selectedItem && (
        <Box ml={2} width="calc(100% - 500px)">
          <CreateTopics
            itemTitle={getItemTitle(selectedItem)}
            itemContent={getItemContent(selectedItem)}
            onClick={handleCreateTopicsClick} // Add event handler to close CreateTopics
            // lessonId= {lessonId}
            // moduleId = {moduleId}
            // selectedItem = {selectedItem}
            lessonId={selectedItem.lessonId}
            moduleId={selectedItem.moduleId}
            selectedItem={selectedItem}
            handleDeleteTopic={handleDeleteTopic}
            

          />
        </Box>
      )}

{showAddModuleForm && (
        <Box ml={2} width="calc(100% - 500px)">
          <AddModuleDialog onClose={handleCloseAddModuleForm} />
        </Box>
      )}

{showAddChapterForm && (
        <Box ml={2} width="calc(100% - 500px)">
          <AddChapterDialog onClose={handleCloseAddChapterForm} moduleId={selectedModuleId} />
        </Box>
      )}

{/* {selectedModule && (
        <Box ml={2} width="calc(100% - 500px)">
          <CreateTopics
            itemTitle={selectedModule.title}
            itemContent={selectedModule.content}
            lessonId={selectedModule.lessonId}
            moduleId={selectedModule.moduleId}
            selectedItem={selectedModule}
            handleDeleteTopic={handleDeleteTopic}
          />
        </Box>
      )} */}
    </div>
    {/* <div style={{
  // display: 'flex',
  // flexDirection:"column",
  marginRight:"14px",
  marginTop:"14px",
  // position: 'fixed',
}}>
          <CommonButton onClick={handleSaveCourse}   label=" Save Course" />
        </div> */}
        {/* </div> */}

  <div style={{ position: 'relative', bottom:"930px" }}>
  <div
    style={{
      position: 'absolute',
      top: '10px', // Adjust as needed
      right: '10px' // Adjust as needed
    }}
  >
    {/* <CommonButton onClick={handleSaveCourse} label="Save Course" /> */}
  </div>
</div>
    </>
  );
};

export default CoursePreview;

