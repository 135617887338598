import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../palette";

export const PageContainer = styled.div`
  ${tw`w-full flex flex-col items-center`}
  background : ${palette.bgColor1};
  // overflow-x: auto;
`;

export const GraphContent = styled.div``;

export const ChartSection = styled.div`
  ${tw`flex`};
`;

export const Container = styled.div`
  ${tw`flex pl-8 items-center justify-center`}
`;
