import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../../styles/palette';
// import ContactBgImage from '../images/Contactus.jpg';

export const ContactContMain = styled.div`
  ${tw`flex justify-center items-center flex-col m-10 rounded-lg mt-[98px]
sm:m-20 sm:mt-[98px]
`}// border:2px solid ${palette.primary}
// overflow: hidden;

// background-size: cover;
// background-position: center;
`;

export const Desc = styled.p`
  ${tw`flex justify-center items-center text-center 2xl:text-xl xl:text-lg lg:text-base mt-5 leading-8 `}
`;

export const ContactFormMain = styled.div`
  ${tw`flex justify-center items-center flex-col  h-auto w-[100%] `}
`;

export const ContactFormCont = styled.div`
  ${tw`flex justify-center items-center flex-col w-[40%] h-[600px] rounded-xl `}
`;
export const ContactFormMainsub = styled.div`
  ${tw`flex justify-center items-center h-auto w-[100%]`}
`;

export const ContactFormCont2 = styled.div`
  ${tw`flex justify-center items-center flex-col h-auto  w-[100%]`}
`;

export const ContactImg = styled.img`
  ${tw`hidden w-[50%] h-auto
lg:block
`}
`;

export const SubmitButton = styled.div`
  ${tw`p-3 w-[40%] flex justify-center text-white text-xl items-center mt-10 rounded-lg`}
  background:${palette.primary}
`;

export const TextFieldStyle = {
  marginTop: '50px',
  '& .MuiInputBase-root': {
    fontSize: '1rem',
    background: `${palette.white}`,
    height: '45px',
    marginTop: '5px',
    maxWidth: '100%',
    width: '300px',
  },
  '@media (min-width: 768px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
  '@media (min-width: 992px)': {
    '& .MuiInputBase-root': {
      width: '400px',
    },
  },
  '@media (min-width: 1200px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
  '@media (min-width: 1440px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
};
export const TextFieldStyle2 = {
  marginTop: '50px',
  '& .MuiInputBase-root': {
    fontSize: '1rem',
    background: `${palette.white}`,
    marginTop: '5px',
    maxWidth: '100%',
    width: '300px',
  },
  '@media (min-width: 768px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
  '@media (min-width: 992px)': {
    '& .MuiInputBase-root': {
      width: '400px',
    },
  },
  '@media (min-width: 1200px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
  '@media (min-width: 1440px)': {
    '& .MuiInputBase-root': {
      width: '500px',
    },
  },
};

export const Title2 = styled.h2`
  ${tw` text-lg 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-xl sm:text-lg leading-4 `}
  color:${palette.primary}
`;

export const Title3 = styled.h2`
  ${tw`text-xl m-2
  md:text-2xl
  `}
  color:${palette.primary}
`;

export const Desc2 = styled.p`
  ${tw`text-sm text-lg mt-2
  md:text-xl
  `}
`;

export const Desc3 = styled.p`
  ${tw`flex`}
`;

export const TitleCont = styled.div`
  ${tw`flex justify-center items-center`}
`;

export const TitleContB = styled.div`
  ${tw`flex justify-center items-center flex-col`}
`;

export const ContSub2 = styled.div`
  ${tw`flex justify-around items-center flex-col w-[35%] m-10 `}
`;

export const ContSub2B = styled.div`
  ${tw`flex justify-around items-center flex-col w-[40%] m-10 mt-40 `}
`;

export const ContSub2Main = styled.div`
  ${tw`flex justify-center items-center w-[100%] `}
  `;

export const ContSub2MainB = styled.div`
  ${tw`flex justify-center items-center w-[100%] mt-[-120px]
  lg:mt-[0px]
  `}
`;

export const ContactFormCont3 = styled.div`
  ${tw`flex justify-center items-center flex-col w-[100%]
  lg:mt-[-100px]
  lg:flex-row
  `}
`;

export const SocialMediaIcon = styled.img`
  ${tw`w-8 h-auto mx-3 cursor-pointer`}
`;

export const TitleLogo = styled.img`
  ${tw`w-8 h-auto mx-3`}
`;

export const TitleDash2 = styled.hr`
  ${tw`h-[2px] w-[90%] rounded-lg mb-5`}
  background:${palette.primary}
`;
