import React from "react";

const Modal = ({ isOpen, onClose, children, modalWidth, modalHeight }) => {
  if (!isOpen) return null;
  return (
    <div
      // onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "white",
          height: modalHeight ? modalHeight : "40vh",
          width: modalWidth ? modalWidth : "40vw",
          margin: "auto",
          padding: "2%",
          border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: "2px solid ",
          justifyContent: "center",
          // display: "flex",
          overflowY: "auto", // Only vertical scrollbar
          // scrollbarWidth: "revert-layer",
          // scrollbarColor: "black white",

          scrollPadding: "12px",
          overscrollbehavior: "contain",
        }}
      >
        {children}
        <style>
          {`
            ::-webkit-scrollbar {
              width: 15px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #6082B6;
              border-radius: 10px; // Set the border radius for the scrollbar thumb
            }

            // ::-webkit-scrollbar-track {
            //   background-color: #a0a0a0;
            //   border-radius: 10px; // Set the border radius for the scrollbar track
            // }
          `}
        </style>
      </div>
    </div>
  );
};

export default Modal;
