import React from 'react'
import { Grid } from '@mui/material';

const PreviewTopicPDF = ( (props) => {
  
    return (
      <div style={{ width: "100%" }}>
        <Grid container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                      margin: '20px 20px 10px 0', // Adjust margin as needed
                      padding: '10px', // Adjust padding as needed
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add box shadow
                      overflow: 'auto', // Enable scrolling if content overflows
                      textAlign: 'justify', // Justify text content
                  }}
              >
                 <h3> {props.item.title}</h3>
              </Grid> 
              <iframe
        src={props.item.link}
        width="100%"
          height="425px"
          style={{ border: 'none' }}
        />
         </div>
    );
  });

export default PreviewTopicPDF