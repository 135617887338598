import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationTabs from './NotificationTabs';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Tooltip, TextField } from '@mui/material';
import { palette } from '../../styles/palette';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from '@mui/material';
import { WholePage } from '../../styles/Certificate/CertificateStyle';

import {
  List,
  ListItem,
  Content,
  Label,
  Subject,
  Page,
  View,
} from '../../styles/Notification/NotificationListStyles';

import {
  fetchNotificationTemplate,
  deleteNotification,
} from '../../features/notification/NotificationSlice';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const NotificationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [notificationToView, setNotificationToView] = useState(null);
  const [NotificationToDelete, setNotificationToDelete] = useState('notDelete');
  const [searchQuery, setSearchQuery] = useState('');
  const { template, loading } =
    useSelector((state) => state.notification) || {};

  useEffect(() => {
    if (!template || template.length === 0) {
      dispatch(fetchNotificationTemplate());
    }
  }, [dispatch, template]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (notificationId) => {
    navigate(`/Notification/editnotification/${notificationId}`);
  };

  const filteredNotifications = template?.filter(
    (notification) =>
      !notification.isDeleted &&
      notification.notificationType
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
  );

  const handleDeleteDialogOpen = (notification) => {
    setNotificationToDelete(notification);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setNotificationToDelete(null);
  };

  const handleDelete = () => {
    if (!NotificationToDelete) return;
    dispatch(deleteNotification(NotificationToDelete._id))
      .then(() => {
        setDeleteDialogOpen(false);
        setNotificationToDelete('delete');
      })
      .catch((error) => {
        console.error('Error deleting certificate:', error);
        setDeleteDialogOpen(false);
        setNotificationToDelete(null);
      });
  };

  const handleOpenModal = (notification) => {
    setNotificationToView(notification);
    setViewDialogOpen(true);
  };

  const handleCloseModal = () => {
    setViewDialogOpen(false);
    setNotificationToView(null);
  };

  const { t } = useTranslation();
  return (
    <>
      <NotificationTabs />
      <WholePage style={{ marginTop: '35px' }}>
        <Page>
          <TextField
            label={t('notification.searchText')}
            size="small"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginTop: '15px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <List>
              {filteredNotifications?.map((notification) => (
                <ListItem key={notification.id}>
                  <Content>
                    <Label>{notification.notificationType}</Label>
                    <Subject>{notification.subject}</Subject>
                  </Content>
                  <div>
                    <Tooltip title={t('notification.certificateViewLabel')}>
                      <IconButton onClick={() => handleOpenModal(notification)}>
                        <VisibilityIcon style={{ color: palette.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('notification.edit')}>
                      <IconButton onClick={() => handleEdit(notification._id)}>
                        <EditIcon style={{ color: palette.primary }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('notification.deleteLabel')}>
                      <IconButton
                        onClick={() => handleDeleteDialogOpen(notification)}
                      >
                        <DeleteIcon style={{ color: palette.primary }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </ListItem>
              ))}
            </List>
          )}
        </Page>
      </WholePage>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>{t('notification.confirmDeleteLabel')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('notification.confirmQueText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>
            {t('notification.cancelLabel')}
          </Button>
          <Button onClick={handleDelete} color="error">
            {t('notification.deleteLabel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={viewDialogOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('notification.viewLabel')}</DialogTitle>

        <DialogContent>
          {notificationToView && (
            <>
              <View>
                <h4>{notificationToView.notificationType}</h4>
              </View>
              <br />
              <View>
                <strong>Subject:</strong> {notificationToView.subject}
              </View>
              <br />
              <View>
                <div
                  dangerouslySetInnerHTML={{
                    __html: notificationToView.notificationBody,
                  }}
                />
              </View>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>
            {t('notification.deleteLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationList;
