// NonBlockingLoading.js
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NonBlockingLoading = ({ open, progress }) => {
  if (!open) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        zIndex: 9999,
        backgroundColor: '#eee',
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(30px)',
      }}
    >
      <Typography variant="body1" align="center" sx={{ marginBottom: '8px' }}>
        Please wait a few moment till the users are added in database, you can
        navigate to other modules till then........
      </Typography>
      <LinearProgress
        style={{ marginTop: '15px' }}
        color="primary"
        // variant="determinate"
        // value={progress}
      />
      {/* <Typography variant="body2" align="center" sx={{ marginTop: '8px' }}>
        {`${Math.round(progress)}%`}
      </Typography> */}
    </Box>
  );
};

export default NonBlockingLoading;
