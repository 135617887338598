// // store/registrationsSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { fetchRegistrationsApi } from '../../api/CoursesApi';

// export const fetchRegistrations = createAsyncThunk(
//   'registrations/fetchRegistrations',
//   async (courseid, { rejectWithValue }) => {
//     try {
//       const response = await fetchRegistrationsApi(courseid);
//       return response.data.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// const registrationsSlice = createSlice({
//   name: 'registrations',
//   initialState: {
//     data: [],
//     status: 'idle',
//     error: null
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchRegistrations.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(fetchRegistrations.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.data = action.payload;
//       })
//       .addCase(fetchRegistrations.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       });
//   }
// });

// export default registrationsSlice.reducer;

// features/learners/learnersSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchLearnersByCourse = createAsyncThunk(
  'learners/fetchLearnersByCourse',
  async (courseId, thunkAPI) => {
    try {
      const response = await axios.get(`http://localhost:8000/api/course/${courseId}/learners`);
      console.log("fetchlearnerbycourse",response.data.data);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const learnersSlice = createSlice({
  name: 'learners',
  initialState: {
    learners: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLearnersByCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLearnersByCourse.fulfilled, (state, action) => {
        state.learners = action.payload;
        state.loading = false;
      })
      .addCase(fetchLearnersByCourse.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default learnersSlice.reducer;

