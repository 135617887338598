import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  StyledTabs,
  StyledTab,
} from "../../styles/Certificate/CertificateStyle"
import { useTranslation } from 'react-i18next';


const NotificationTabs = () => {
  const location = useLocation();
  const getBasePath = (path) => {
    const segments = path.split('/');
    return segments.length > 2 ? `/${segments[1]}/${segments[2]}` : path;
  };
  const baseLocation = getBasePath(location.pathname);
  const validPaths = [
    "/Notification",
    "/Notification/listnotification",
    "/Notification/displaynotification",
    "/Notification/sendnotification",
    "/Notification/notificationsettings"
  ];
  const defaultTabValue = validPaths.includes(baseLocation) ? baseLocation : "/Notification";

  const { t } = useTranslation();
  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label={t("notification.createLabel")}
        value="/Notification"
        component={Link}
        to="/Notification"
      />
      <StyledTab
        label={t("notification.listLabel")}
        value="/Notification/listnotification"
        component={Link}
        to="/Notification/listnotification"
      />
      <StyledTab
        label={t("notification.sentLabel")}
        value="/Notification/displaynotification"
        component={Link}
        to="/Notification/displaynotification"
      />
      <StyledTab
        label={t("notification.sendLabel")}
        value="/Notification/sendnotification"
        component={Link}
        to="/Notification/sendnotification"
      />
      <StyledTab
        label={t("notification.settingsLabel")}
        value="/Notification/notificationsettings"
        component={Link}
        to="/Notification/notificationsettings"
      />
    </StyledTabs>
  );
};

export default NotificationTabs;
