import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@mui/icons-material/Share';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { CloudDownload, Visibility } from '@mui/icons-material';
import LearnerTabs from './LearnerTabs';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import CertificateData from '../../store/CertificateData.json';
import {
  WholePage,
  SearchEmail,
  TableDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
const LearnerCertificate = () => {
  const columns = [
    { name: 'Name', selector: (row) => row.ename, sortable: true },
    { name: 'Enrolled Date', selector: (row) => row.edate, sortable: true },
    { name: 'Completion Date', selector: (row) => row.cdate, sortable: true },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Tooltip title="Download">
            <CloudDownload fontSize="small" style={{ marginRight: 14 }} />
          </Tooltip>
          <Tooltip title="View">
            <Visibility fontSize="small" style={{ marginRight: 14 }} />
          </Tooltip>
          <Tooltip title="Share">
            <ShareIcon fontSize="small" style={{ marginRight: 14 }} />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <>
      <LearnerTabs />
      <br />
      <WholePage>
        <SearchEmail>
          <TextField
            id="outlined-basic"
            label={t("certificate.searchText")}
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchEmail>
        <TableDiv>
          <TableUtilityLib
            columns={columns}
            data={CertificateData}
          />
        </TableDiv>
      </WholePage>
    </>
  );
};

export default LearnerCertificate;
