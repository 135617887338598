import React, { useEffect, useState } from 'react';
import {
  ProfileContainer,
  ProfileImage,
  ProfileImageCont,
  ImageContainerSmall,
  SubmitBtnStyle,
  BoxStyling,
} from '../../styles/Profile/ProfileStyle';
import { HeaderContainer } from '../../styles/Common/CommonStyles';
import { palette } from '../../styles/palette';
import { Link, useLocation } from 'react-router-dom';
import {
  Tooltip,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from '@mui/material';
import { AddFieldContainer } from '../../styles/Profile/ProfileStyle';
import { useNavigate } from 'react-router-dom';
import {
  TextFieldStyle,
  TypographyStyle,
} from '../../styles/Profile/ProfileStyle';
import AdditionalField from './AdditionalField';
import CommonButton from '../Utilities/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile } from '../../features/profile/UserProfileSlice';

const ProfileContainerComp = ({ userData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);

  // console.log(user, 'user in profule');
  console.log(userData, 'User Data from Parent');
  console.log(userData.user.userid._id);

  const [addedFields, setAddedFields] = useState([]);
  const [displayAddFields, setDisplayAddFields] = useState(false);
  const [open, setOpen] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [profileState, setProfileState] = useState({
    // username: user?.user?.userid?.username,
    firstname: userData?.user?.userid?.firstname,
    lastname: userData?.user.useerid?.lastname,
    email: userData?.user?.userid?.email,
    phoneNo: userData?.user?.userid?.phoneNo,
    address: userData?.user?.address,
    state: userData?.user?.state,
    //pincode: user?.user?.pincode,
    city: userData?.user?.city,
    companyName: userData?.user?.username,
    picture: userData?.user?.userid?.picture, // Default country code
  });
  const [additionalFieldsData, setAdditionalFieldsData] = useState([]);
  const [additionalFieldsErrorState, setAdditionalFieldsErrorState] = useState(
    {},
  );

  const [profileErrorState, setProfileErrorState] = useState({
    // userName: false,
    firstname: false,
    lastname: false,
    userEmailId: false,
    userPhoneNo: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    state: false,
    stateLicenseNo: false,
    countryName: false,
    userImgUrl: false,
    file: false,
  });

  const [displayModal, setDisplayModal] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();
  // const handleLogout = () => {
  //   flushLoginData();
  //   navigate('/');
  // };

  useEffect(() => {
    console.log('USer profile', userProfile);
    setProfileState(userProfile);
    if (
      userProfile?.hasOwnProperty('additionalFields') &&
      userProfile?.additionalFields.length > 0
    ) {
      setDisplayAddFields(true);
    }
  }, [userProfile]);

  const handleBlur = (e) => {
    if (e.target.value === '' || null || undefined)
      setProfileErrorState({ ...profileErrorState, [e.target.name]: true });
  };

  // Below 3 functions are for adding additional fields in profile page
  const handleAddTextField = () => {
    console.log('handle display text');
    setDisplayModal(true);
  };

  const handleAddFields = (fieldProps) => {
    console.log('Field to be added is ', fieldProps);
    const newFields = [...addedFields, fieldProps];
    setAddedFields(newFields);
    setProfileState({
      ...profileState,
      additionalFields: newFields,
    });
    setDisplayAddFields(true);
  };

  useEffect(() => {
    console.log('Extra fields added are ', addedFields);
    console.log('Length of the added fields is ', addedFields.length);
    if (addedFields.length > 0) {
      // AddExtraFields([...addedFields]);
      setProfileState({
        ...profileState,
        additionalFields: [...addedFields],
      });
    }
  }, [addedFields]);
  // }, [AddExtraFields, profileState, addedFields]);

  useEffect(() => {
    // Initialize error state for additional fields
    const additionalFieldsErrors = {};
    userProfile?.additionalFields?.forEach((field) => {
      additionalFieldsErrors[field.fieldName.replace(' ', '-')] = false;
    });
    setAdditionalFieldsErrorState(additionalFieldsErrors);
  }, [userProfile]);

  const handleAddFieldBlur = (fieldName) => {
    if (!profileState.additionalFields[fieldName]) {
      setAdditionalFieldsErrorState((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    }
  };

  //Changing data to the additional fields

  const handleAddFieldChange = (event, fieldName) => {
    const fieldValue = event.target.value;

    // Find the index of the field in additionalFieldsData
    const fieldIndex = additionalFieldsData.findIndex(
      (field) => field.fieldName === fieldName,
    );

    // If the field is found, update its data
    if (fieldIndex !== -1) {
      const updatedAdditionalFieldsData = [...additionalFieldsData];
      updatedAdditionalFieldsData[fieldIndex] = {
        ...updatedAdditionalFieldsData[fieldIndex],
        data: fieldValue,
      };
      setAdditionalFieldsData(updatedAdditionalFieldsData);
    }
  };

  // To update the profile image
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileState({ ...profileState, picture: e.target.files[0] });
      console.log('Set image : ', e.target.files[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target.result);
        console.log('image  ', event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    handleClose();
  };

  //On submit after change in the data int he profile page.

  const handleSubmit = () => {
    // Check for empty fields
    // const emptyFields = Object.entries(profileState).filter(
    //   ([fieldName, value]) => {
    //     if (typeof value === 'string' && !value.trim()) {
    //       setProfileErrorState((prevState) => ({
    //         ...prevState,
    //         [fieldName]: true,
    //       }));
    //       return true;
    //     }
    //     return false;
    //   },
    // );
    // console.log('The empty fields', emptyFields);

    // Check for individual field errors
    // if (
    //   profileErrorState.firstname ||
    //   profileState.firstname === '' ||
    //   profileState.firstname === null ||
    //   profileState.firstname === undefined
    // ) {
    //   alert('Enter Firstname');
    //   return;
    // } else if (
    //   profileErrorState.lastname ||
    //   profileState.lastname === '' ||
    //   profileState.lastname === null ||
    //   profileState.lastname === undefined
    // ) {
    //   alert('Enter Lastname');
    // }
    // //  else if (
    // //   profileErrorState.userEmailId ||
    // //   profileState.email === ('' || null || undefined)
    // // ) {
    // //   alert('Enter userEmailId');
    // //   return;
    // // }
    // // else if (
    // //   profileErrorState.userPhoneNo ||
    // //   profileState.userPhoneNo === ('' || null || undefined)
    // // ) {
    // //   alert('Enter Phone Number');
    // //   return;
    // // }
    // else if (
    //   profileErrorState.userAddressL1 ||
    //   profileState.userAddressL1 === ('' || null || undefined)
    // ) {
    //   alert('Enter Address Line 1');
    //   return;
    // } else if (
    //   profileErrorState.userAddressL2 ||
    //   profileState.userAddressL2 === ('' || null || undefined)
    // ) {
    //   alert('Enter Address Line 2');
    //   return;
    // }
    // //  else if (
    // //   profileErrorState.userAddressL3 ||
    // //   profileState.userAddressL3 === ('' || null || undefined)
    // // ) {
    // //   alert('Enter Address Line 3');
    // //   return;
    // // }
    // else if (
    //   profileErrorState.userState ||
    //   profileState.userState === ('' || null || undefined)
    // ) {
    //   alert('Enter State');
    //   return;
    // } else if (
    //   profileErrorState.userStateLNo ||
    //   profileState.userStateLNo === ('' || null || undefined)
    // ) {
    //   alert('Enter State License No.');
    //   return;
    // } else if (
    //   profileErrorState.userCountry ||
    //   profileState.userCountry === ('' || null || undefined)
    // ) {
    //   alert('Enter Country');
    //   return;
    // }
    // // Check for phone number format errors
    // if (profileErrorState.userPhoneNo) {
    //   alert('Please enter a valid phone number.');
    //   return;
    // }

    console.log('In submit profile ', profileState);
    dispatch(updateUserProfile(profileState));
    console.log(profileState);
    // AddExtraFields(additionalFieldsData);
    alert('User details updated successfully');
    // navigate('/MyProfile');

    // Refresh the page
    // window.location.reload();
  };

  // Validation functions for USA and India phone numbers
  const handleChange = (event, fieldName) => {
    const { name, value } = event.target;
    console.log(name, value);
    setProfileState({ ...profileState, [name]: value });

    // Handle country code separately
    if (name === 'countryCode') {
      setProfileState({
        ...profileState,
        [name]: value,
        userPhoneNo: '', // Reset phone number when country code changes
      });
    } else {
      // For other fields, update profile state and error state as before
      setProfileState({
        ...profileState,
        [name]: value,
      });

      setProfileErrorState({
        ...profileErrorState,
        [name]: value === '' || value === null || value === undefined,
      });
    }

    // Validate phone number based on the country code
    if (fieldName === 'userPhoneNo') {
      const countryCode = profileState.countryCode;
      let isValid = true;

      if (countryCode === '+1') {
        // USA phone number validation
        isValid = validateUsaPhoneNo(value);
      } else if (countryCode === '+91') {
        // India phone number validation
        isValid = validateIndiaPhoneNo(value);
      }

      setProfileErrorState({
        ...profileErrorState,
        [name]: !isValid,
      });
    }
  };

  const validateUsaPhoneNo = (phoneNumber) => {
    const usaPhoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return usaPhoneRegex.test(phoneNumber);
  };

  const validateIndiaPhoneNo = (phoneNumber) => {
    const indiaPhoneRegex = /^\+?91?\d{9}$/;
    return indiaPhoneRegex.test(phoneNumber);
  };

  return (
    <ProfileContainer style={{ marginBlockStart: '40px', marginLeft: '40px' }}>
      <Box sx={BoxStyling}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} sx={ImageContainerSmall}>
            <Tooltip title="Upload Image">
              <ProfileImageCont onClick={handleOpen}>
                {/* <ProfileImage /> */}
                {previewImage ? (
                  <div
                    style={{
                      backgroundImage: `url(${previewImage})`,
                    }}
                  ></div>
                ) : (
                  <ProfileImage src={require('../../images/user.png')} />
                )}

                {/* Logic to display dialogue box for image selection */}
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Change Profile Picture</DialogTitle>
                  <DialogContent>
                    {/* Add your picture selection/upload component here */}
                    {/* For simplicity, let's just use a button for demonstration */}
                    <Button variant="contained" component="label">
                      Upload Picture
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handlePictureChange}
                      />
                    </Button>
                  </DialogContent>
                </Dialog>
              </ProfileImageCont>
            </Tooltip>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}> Email Id </Typography>
            <TextField
              fullWidth
              // required
              type="email"
              name="userEmailId"
              id="userEmailId"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userEmailId}
              value={profileState.email}
              onBlur={handleBlur}
              onChange={handleChange}
              // InputProps={{ minLength: 0 }}
            />
            <FormHelperText
              error={profileErrorState.userEmailId}
              sx={{
                color: profileErrorState.userEmailId ? 'inherit' : 'red', // Change color to red when there is an error
              }}
            >
              {/* Combine regular expression and "@" symbol check */}
              {profileState.email && // Check if any value is entered
                (!/^[^@]+@[^@]+\.[^@]+$/.test(profileState.email) ||
                !profileState.email.includes('@')
                  ? 'Please enter a valid email format. (e.g., leapot@example.com)'
                  : '')}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}> Firstname </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="firstname"
              id="firstname"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.firstname}
              value={profileState.firstname}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{ minLength: 0 }}
            ></TextField>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}> Lastname </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="lastname"
              id="lastname"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.lastname}
              value={profileState.lastname}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{ minLength: 0 }}
            ></TextField>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Phone No</Typography>
            <FormControl>
              <TextField
                sx={{
                  width: '134%',
                  border: '1px solid',
                  borderColor: '#0684FB',
                  borderRadius: '5px',
                }}
                required
                type="phoneno"
                name="userPhoneNo"
                id="userPhoneNo"
                variant="outlined"
                value={profileState.userPhoneNo}
                onChange={(e) => handleChange(e, 'userPhoneNo')}
                error={profileErrorState.userPhoneNo}
                InputProps={{
                  startAdornment: (
                    <Select
                      value={profileState.countryCode}
                      onChange={(e) => handleChange(e, 'countryCode')}
                      variant="outlined"
                      style={{ width: 140, marginLeft: '-15px' }}
                      name="countryCode"
                    >
                      <MenuItem value="+1">+1 (USA)</MenuItem>
                      <MenuItem value="+91">+91 (India)</MenuItem>
                    </Select>
                  ),
                  inputProps: {
                    style: { paddingLeft: '20px' }, // Add space between dropdown and input area
                  },
                }}
              />
              <FormHelperText error={profileErrorState.userPhoneNo}>
                {profileErrorState.userPhoneNo && (
                  <>
                    {/* Error messages based on selected country code */}
                    {profileState.countryCode === '+1' ? (
                      <span>Please enter a valid USA phone number.</span>
                    ) : (
                      <span>Please enter a valid India phone number.</span>
                    )}
                  </>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Address Line 1 </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userAddressL1"
              id="userAddressL1"
              variant="outlined"
              sx={TextFieldStyle}
              // error={profileErrorState.userAddressL1}
              value={profileState.address}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userAddressL1}>
              {profileErrorState.userAddressL1
                ? 'Please enter a valid Address'
                : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Address Line 2 </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userAddressL2"
              id="userAddressL2"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userAddressL2}
              value={profileState.userAddressL2}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userAddressL2}>
              {profileErrorState.userAddressL2
                ? 'Please enter a valid Address'
                : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>State </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userState"
              id="userState"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userState}
              value={profileState.userState}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userState}>
              {profileErrorState.userState ? 'Please enter a valid State' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>State License No </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userStateLNo"
              id="userStateLNo"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userStateLNo}
              value={profileState.userStateLNo}
              onBlur={handleBlur}
              onChange={handleChange}
            ></TextField>
            <FormHelperText error={profileErrorState.userStateLNo}>
              {profileErrorState.userStateLNo
                ? 'Please enter a valid State License No'
                : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography style={TypographyStyle}>Country </Typography>
            <TextField
              fullWidth
              required
              type="text"
              name="userCountry"
              id="userCountry"
              variant="outlined"
              sx={TextFieldStyle}
              error={profileErrorState.userCountry}
              value={profileState.userCountry}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <FormHelperText error={profileErrorState.userCountry}>
              {profileErrorState.userCountry
                ? 'Please enter a valid country name'
                : ''}
            </FormHelperText>
          </Grid>
          {displayAddFields &&
            profileState?.hasOwnProperty('additionalFields') &&
            profileState?.additionalFields.length > 0 &&
            addedFields?.map((item, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <Typography style={TypographyStyle}>
                  {item.fieldName}
                </Typography>
                <TextField
                  required
                  type="text"
                  name={item.fieldName.replace(' ', '-')}
                  id={item.fieldName.replace(' ', '-')}
                  value={profileState.additionalFields[index].data || ''}
                  variant="outlined"
                  sx={TextFieldStyle}
                  onBlur={() =>
                    handleAddFieldBlur(item.fieldName.replace(' ', '-'))
                  }
                  onChange={(event) =>
                    handleAddFieldChange(event, item.fieldName)
                  }
                  error={
                    additionalFieldsErrorState[item.fieldName.replace(' ', '-')]
                  }
                />
                <FormHelperText
                  error={
                    additionalFieldsErrorState[item.fieldName.replace(' ', '-')]
                  }
                >
                  {additionalFieldsErrorState[
                    item.fieldName.replace(' ', '-')
                  ] && `Please enter ${item.fieldName}`}
                </FormHelperText>
              </Grid>
            ))}
          {profileState?.additionalFields?.length === 0 ||
          profileState?.additionalFields?.length % 2 === 0 ? (
            <>
              <Grid item xs={0} md={6}></Grid>
              <AddFieldContainer>
                <Grid item xs={12} sm={12} lg={6} onClick={handleAddTextField}>
                  Add Field
                </Grid>
              </AddFieldContainer>
            </>
          ) : (
            <AddFieldContainer>
              <Grid item xs={12} sm={12} lg={6} onClick={handleAddTextField}>
                Add Field
              </Grid>
            </AddFieldContainer>
          )}
        </Grid>
      </Box>
      {displayModal && (
        <AdditionalField
          isOpen={displayModal}
          onClose={() => {
            console.log('Before close function');
            setDisplayModal(false);
            console.log('After close function');
          }}
          AddFields={(fieldProps) => handleAddFields(fieldProps)}
        />
      )}
      {!displayModal && (
        <SubmitBtnStyle>
          <CommonButton onClick={handleSubmit} label="Submit" />
        </SubmitBtnStyle>
      )}
    </ProfileContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
  };
};

export default ProfileContainerComp;
