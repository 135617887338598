import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  UpdatesImg,
  ImageSlidebtn,
  AnchorTag,
  ImageTag,
} from '../../styles/Calendar/CalendarStyle';
import { fetchAnnouncement } from '../../features/calendar/CalendarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ImageSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const announcement = useSelector((state) => state.calendar.announcement);
  const { users, loading } = useSelector((state) => state.auth);

  // Filtering to extract only image URLs from the announcements
  const images = announcement
    .filter((announcement) => announcement.image)
    .map((announcement) => announcement.image)
    .slice(0, 3);

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle next slide
  const nextSlide = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  // Function to handle previous slide
  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    // Automatically advance to the next slide every 3 seconds
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, images.length]);

  const handleAnnDetails = (id) => {
    if (users.user.userid?.role === 'SuperAdmin') {
      navigate(`/EventsNavigator/announcedetails/${id}`);
    } else {
      navigate(`/LearnersEventsNavigator/announcedetails/${id}`);
    }
  };

  return (
    <>
      <UpdatesImg>
        <ImageSlidebtn onClick={prevSlide}>
          <ArrowBackIosIcon />
        </ImageSlidebtn>
        <AnchorTag>
          <ImageTag
            src={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleAnnDetails(announcement[currentIndex]._id)}
          />
        </AnchorTag>
        <ImageSlidebtn onClick={nextSlide}>
          <ArrowForwardIosIcon />
        </ImageSlidebtn>
      </UpdatesImg>
    </>
  );
};

export default ImageSlider;
