import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CircularProgress, TextField, MenuItem } from '@mui/material';
import CertificateTabs from './CertificateTabs';
import SearchIcon from '@material-ui/icons/Search';
import CommonButton from '../Utilities/CommonButton';
import TableUtilityLib from '../Utilities/TableUtilityLib';
import { Text } from '../../styles/Common/CommonStyles';
import { Tooltip } from '@mui/material';
 
import {
  fetchIssuedCertificate,
  issueCertificate,
  issueBulkCertificate,
  fetchEvent,
} from '../../features/certificate/CertificateSlice';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import {
  SearchEmail,
  WholePage,
  CommonButtonCancle,
  TableButtonDiv,
  SeparateTableButton,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


const CertificateIssued = () => {
  const dispatch = useDispatch();
  const [completionDate, setcompletionDate] = useState('');
  const [email, setEmail] = useState('');
  const [event, setEvent] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);
  const [downloadSeparateDialog, setDownloadSeparateDialog] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [bulkIssueResult, setBulkIssueResult] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const { events, issued, loading } =
    useSelector((state) => state.certificate) || {};

    useEffect(() => {
      if (!events || events.length === 0) {
        dispatch(fetchEvent());
      }
    }, [dispatch, events]);
    

    useEffect(() => {
      if (!issued || issued.length === 0) {
        dispatch(fetchIssuedCertificate());
      }
    }, [issued]);
    

  const columns = [
    { name: 'Event Name', selector: (row) => row.eventName, sortable: true },
    { name: 'Event Date', selector: (row) => row.issueDate, sortable: true },
    {
      name: 'Serial Number',
      selector: (row) => row.serialNumber,
      sortable: true,
    },
    { name: 'Email', selector: (row) => row.email, sortable: true }
  ];

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const filteredData = issued?.data?.filter((row) =>
    row.eventName.toLowerCase().includes(filterValue.toLowerCase()),
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFieldErrors({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFieldErrors({});
  };

  const handleDownloadDialog = () => {
    setDownloadDialog(true);
    setFieldErrors({});
  };

  const handleDownloadCloseDialog = () => {
    setDownloadDialog(false);
    setDownloadSeparateDialog(false);
    setSelectedEmail('');
    setFieldErrors({});
  };

  const handleCompletionDate = (event) => {
    setcompletionDate(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };


  const handleSubmit = () => {
    const errors = {};

    if (completionDate.trim() === "") {
      errors.completionDate = "Issued Date is required.";
    }

    if (event.trim() === "") {
      errors.event = "Event is required.";
    }

    if (email.trim() === "") {
      errors.email = "Email is required.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors); 
      return; 
    }


    const issuedData = {
      email,
      eventid: event,
      issueDate: completionDate,
    };

    dispatch(issueCertificate(issuedData))
      .then((response) => {
        alert(`${JSON.stringify(response.payload["message"])}`);
        setEmail('');
        setcompletionDate('');
        setEvent('');
        setOpenDialog(false);
      })
      .catch((error) => {
        toast.error(`Error issuing certificate: ${error.message}`);
        setOpenDialog(false);
      });
  };

  const handleBulkSubmit = async () => {
    const errors = {};

    if (completionDate.trim() === "") {
      errors.completionDate = "Issued Date is required.";
    }

    if (event.trim() === "") {
      errors.event = "Event is required.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors); 
      return; 
    }

    const issuedData = {
      email,
      eventid: event,
      issueDate: completionDate,
    };

    try {
      const response = await dispatch(issueBulkCertificate(issuedData)).unwrap();
      setBulkIssueResult(response);

      if (
        response.successfulIssues.length === 0 &&
        response.failedIssues.length === 0
      ) {
        toast.warning("No users are registered for this event.");
        setDownloadDialog(false);
      } else {
        const message = `Bulk Issue Result:\n` +
          `Success: ${response.successfulIssues.length}\n` +
          `Failed: ${response.failedIssues.length}`;
        alert(message);
        setDownloadDialog(false);
      }
    } catch (error) {
      toast.error("Error issuing certificates: " + (error.message || "Unknown error"));
      setDownloadDialog(false);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };
  const { t } = useTranslation();

  return (
    <>
      <CertificateTabs />
      <div style={{width:'95%', margin:'auto'}}>

      <WholePage>
        <Text>
          {t("certificate.issueListText")}
        </Text>
        <SearchEmail onSubmit={handleFormSubmit}>
          <TextField
            id="outlined-basic"
            label={t("certificate.searchText")}
            size="small"
            variant="outlined"
            value={filterValue}
            onChange={handleFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableButtonDiv>
            <Tooltip title='Issue Single Certificate'>
            <SeparateTableButton>
              <CommonButton label={t("certificate.issueLabel")} onClick={handleOpenDialog} />
            </SeparateTableButton>
            </Tooltip>
            <Tooltip title='Issue Bulk Certificate'>
            <SeparateTableButton>
              <CommonButton label={t("certificate.bulkIssueLabel")} onClick={handleDownloadDialog} />
            </SeparateTableButton>
            </Tooltip>
          </TableButtonDiv>
        </SearchEmail>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{t("certificate.issueCertificateLabel")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                label={t("certificate.issueDateLabel")}
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                value={completionDate}
                onChange={handleCompletionDate}
                required
                error={Boolean(fieldErrors.completionDate)} 
                helperText={fieldErrors.completionDate} 
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                label={t("certificate.selectEventLabel")}
                variant="outlined"
                margin="normal"
                sx={{ width: 555 }}
                value={event}
                required
                onChange={(e) => setEvent(e.target.value)}
                error={Boolean(fieldErrors.event)} 
                helperText={fieldErrors.event} 
              >
                {Array.from(events).map((event) => (
                  <MenuItem key={event._id} value={event._id}>
                    {event.EventName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={t("certificate.cerificateEmail")}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                value={email}
                type='email'
                onChange={handleEmailChange}
                error={Boolean(fieldErrors.email)} 
                helperText={fieldErrors.email} 
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton label={t("certificate.issueLabel")} onClick={handleSubmit} />
            <CommonButton label={t("certificate.cancelLabel")} onClick={handleCloseDialog} />
          </DialogActions>
        </Dialog>

        <Dialog open={downloadDialog} onClose={handleDownloadCloseDialog}>
          <DialogTitle>{t("certificate.bulkIssueLabel")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                label={t("certificate.issueDateLabel")}
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={completionDate}
                onChange={handleCompletionDate}
                error={Boolean(fieldErrors.completionDate)} 
                helperText={fieldErrors.completionDate} 
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                label={t("certificate.selectEventLabel")}
                variant="outlined"
                margin="normal"
                sx={{ width: 555 }}
                value={event}
                required
                onChange={(e) => setEvent(e.target.value)}
                error={Boolean(fieldErrors.event)} 
                helperText={fieldErrors.event} 
              >
                {Array.from(events).map((event) => (
                  <MenuItem key={event._id} value={event._id}>
                    {event.EventName}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton label={t("certificate.issueLabel")} onClick={handleBulkSubmit} />
            <CommonButtonCancle
              label={t("certificate.cancelLabel")}
              onClick={handleDownloadCloseDialog}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          open={bulkIssueResult !== null && (bulkIssueResult.successfulIssues.length > 0 || bulkIssueResult.failedIssues.length > 0)}
          onClose={() => setBulkIssueResult(null)}
        >
          <DialogTitle>{t("certificate.bulkIssueResultLabel")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {bulkIssueResult?.message}
              <h4>{t("certificate.successfulIssueLabel")}</h4>
              {bulkIssueResult?.successfulIssues.length > 0 ? (
                bulkIssueResult.successfulIssues.map((issue, index) => (
                  <p key={index}>{issue.message}</p>
                ))
              ) : (
                <p>No successful issues.</p>
              )}
              <h4>{t("certificate.failedIssueLabel")}</h4>
              {bulkIssueResult?.failedIssues.length > 0 ? (
                bulkIssueResult.failedIssues.map((issue, index) => (
                  <p key={index}>{issue.message}</p>
                ))
              ) : (
                <p>No failed issues.</p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton
              label={t("certificate.closeLabel")}
              onClick={() => setBulkIssueResult(null)}
            />
          </DialogActions>
        </Dialog>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableUtilityLib columns={columns} data={filteredData} />
        )}
      </WholePage>
      </div>
    </>
  );
};
export default CertificateIssued;
