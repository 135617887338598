import React, { useState } from "react";
import { TextField, Tooltip, IconButton, Grid, Typography, Button, FormControlLabel, Checkbox, Paper } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon
import { palette } from "../../../styles/palette";
import QuestionUtility from '../CourseModule/QuestionUtility'; // Import the QuestionUtility component

const InsertQuiz = ({ onClose }) => {
  const [questionUtilities, setQuestionUtilities] = useState([]); // Array to store QuestionUtility components
  const [isMandatory, setIsMandatory] = useState(false);
  const [passingPercentage, setPassingPercentage] = useState(0);
  const [isPassingPercentageRequired, setIsPassingPercentageRequired] = useState(false);
  const [isTimerEnabled, setIsTimerEnabled] = useState(false);
  const [questionTiming, setQuestionTiming] = useState(0);
  const [maxAttempts, setMaxAttempts] = useState(1);
  const [showScore, setShowScore] = useState(false);
  const [areAllQuestionsRequired, setAreAllQuestionsRequired] = useState(false); // Define state variable
  const [allowReview, setAllowReview] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  
  const initialValues = {
    questionType: 'oneAnswer',
    questionText: '',
    choices: [],
    // isOptional: false,
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleAddQuestionClick = () => {
    const newQuestionUtility = (
      <div key={questionUtilities.length}>
        <QuestionUtility initialValues={initialValues} showOptional={false} onClose={() => handleRemoveQuestionUtility(newQuestionUtility)} />
        <Grid item xs={12} container justifyContent="flex-end">
        <IconButton color="error" onClick={() => handleRemoveQuestionUtility(newQuestionUtility)}>
          <DeleteIcon />
        </IconButton>
        </Grid>
      </div>
    );
    setQuestionUtilities([...questionUtilities, newQuestionUtility]); // Add new QuestionUtility component to the array
  };

  const handleRemoveQuestionUtility = (utilityToRemove) => {
    const updatedUtilities = questionUtilities.filter(utility => utility !== utilityToRemove); // Remove the clicked QuestionUtility
    setQuestionUtilities(updatedUtilities);
  };

  const handleAllQuestionsRequiredToggle = (event) => {
    setAreAllQuestionsRequired(event.target.checked);
  };

  const handlePassingPercentageRequiredToggle = () => {
    setIsPassingPercentageRequired(!isPassingPercentageRequired);
    if (!isPassingPercentageRequired) setPassingPercentage('');
  };

  const handleTimerToggle = () => {
    setIsTimerEnabled(!isTimerEnabled);
    if (!isTimerEnabled) setQuestionTiming('');
  };


  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
        {/* Grid container aligned to the left */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Quiz
            </Typography>
          </Grid>
        </Grid>
        {/* Buttons div aligned to the right */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }}>Discard Changes</Button>
          <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }}>Save</Button>
        </div>
      </div>

      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"

          style={{ width: "100%", marginBottom: "24px" }}
        />
        {questionUtilities.map((utility, index) => (
          <div key={index}>{utility}</div>
        ))}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ borderRadius: 4 }}
            onClick={handleAddQuestionClick}
          >
            Add Question
          </Button>
        </Grid>
      </div>
      <Paper elevation={3} sx={{mt:4}}>
        <Grid container spacing={2} sx={{ml:2}}>
        <Typography variant="h6" sx={{ml:2, mt:2}} >
          Quiz Settings
      </Typography>
      <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isMandatory} onChange={() => setIsMandatory(!isMandatory)} />}
            label="Is Quiz Mandatory"
          />
        </Grid>
        <Grid item xs={12}>
         <FormControlLabel
          control={<Checkbox checked={areAllQuestionsRequired} onChange={handleAllQuestionsRequiredToggle} />}
          label="Are all questions required"
          />
       </Grid>
       <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isPassingPercentageRequired} onChange={handlePassingPercentageRequiredToggle} />}
            label="Is Passing Percentage Required"
          />
          {isPassingPercentageRequired && (
            <TextField
              type="number"
              value={passingPercentage}
              onChange={(e) => setPassingPercentage(e.target.value)}
              placeholder="Passing Percentage"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isTimerEnabled} onChange={handleTimerToggle} />}
            label="Enable Quiz Timer"
          />
          {isTimerEnabled && (
            <TextField
              type="number"
              value={questionTiming}
              onChange={(e) => setQuestionTiming(e.target.value)}
              placeholder="Question Timing (seconds)"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={showScore} onChange={() => setShowScore(!showScore)} />}
            label="Show Score on Completion"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={allowReview} onChange={() => setAllowReview(!allowReview)} />}
            label="Allow Review of Answers"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={showAnswers} onChange={() => setShowAnswers(!showAnswers)} />}
            label="Show Correct Answers Post Completion"
          />
        </Grid>
        <Grid item xs={12} sx={{mb:2}}>
          <TextField
            type="number"
            value={maxAttempts}
            onChange={(e) => setMaxAttempts(e.target.value)}
            placeholder="Maximum Attempts"
            label="Maximum Attempts"
          />
        </Grid>
      </Grid>
      </Paper>

    </div>
  );
};

export default InsertQuiz;




