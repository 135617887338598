import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton, Tooltip
  
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddTopicContainer from './AddTopicContainer';
import CopyTopicContainer from './CopyTopicContainer';

const AddOrCopyTopic = ({ open, onClose, onContentClick }) => {
  const [optionSelected, setOptionSelected] = useState('add');
  const [displayLibrary, setDisplayLibrary] = useState({
    video: false,
    audio: false,
    text: false,
    pdf: false,
    download: false,
    presentation: false,
    multimedia: false,
    live: false,
    codingTest: false,
    scorm: false,
    quiz: false,
    assignment: false,
    exam: false,
    survey: false,
  });
  

  const handleOptionSelect = (event) => {
    console.log('Handle option select', event.target.value);
    setOptionSelected(event.target.value);
  };

  const handleAddContainerClose = (item) => {
    setOptionSelected('');
    onClose(item);
  };

  const handleOnContentClick = (item) => {
    onContentClick(item);
  };

  const handleCopyContainerClose = () => {
    setOptionSelected('');
    onClose();
  };

  const handleSelectTopic = (data) => {
    console.log('handleSelectTopic ', data);
  };
  const handleclickCancel = () => {
    onClose(); // Close the CreateModule component
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FormControl
          sx={{
            margin: '20px',
          }}
        >
          <RadioGroup
            name="selectOption"
            value={optionSelected}
            onChange={handleOptionSelect}
            style={{ flexDirection: 'row', margin: '10px' }} // Align radio buttons horizontally
          >
            <Grid container spacing={12} alignItems="center">
              <Grid item xs={6}>
                <FormControlLabel
                  value="add"
                  control={<Radio />}
                  label="Add Topic"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="copy"
                  control={<Radio />}
                  label="Copy Topic"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
       
        <Tooltip title="Close">
          <IconButton onClick={handleclickCancel} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>

      {optionSelected === 'add' && (
        <AddTopicContainer
          open={optionSelected === 'add'}
          onClose={handleAddContainerClose}
          onContentClick={handleOnContentClick}
        />
      )}
      {optionSelected === 'copy' && (
        <CopyTopicContainer
          open={optionSelected === 'copy'}
          onClose={handleCopyContainerClose}
        />
      )}    
    </div>
  );
};
export default AddOrCopyTopic;
