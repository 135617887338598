import React, { useState } from 'react';

import {
  ContactContMain,
  ContactFormCont,
  TextFieldStyle,
  Desc,
  ContactFormMain,
  ContactImg,
  SubmitButton,
  ContactFormCont2,
  ContactFormMainsub,
  Title2,
  Title3,
  Desc2,
  ContSub2,
  ContSub2Main,
  ContactFormCont3,
  SocialMediaIcon,
  Desc3,
  TitleCont,
  TitleLogo,
  TitleDash2,
  TitleContB,
  TextFieldStyle2,
  ContSub2B,
  ContSub2MainB,
} from '../../styles/Landing/ContactusStyle';

import MinHeightTextarea from './MinHeightTextarea'; 

import { TitleB, TitleDash } from '../../styles/Common/CommonStyles';

import TextField from '@mui/material/TextField';

import ContactUsImg from '../../images/Contactus.jpg';

import linkedin from '../../images/linkedin.png';


import ph from '../../images/telephone.png';
import Em from '../../images/mail.png';
import Ad from '../../images/location.png';
import social from '../../images/socialMedia.png';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Contactus = () => {
  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });
  const [state, setState] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === '') {
      setErrorState({ ...errorState, [name]: true });
    } else if (name === 'email' && !validateEmail(value)) {
      alert('Please enter a valid email address.');
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: false });
    }
  };
  
  // Function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async () => {
    if (state.name === '' || errorState.name === true) {
      alert('Please enter Name');
    } else if (state.email === '' || errorState.email === true) {
      alert('Please enter email');
    } else if (state.mobile === '' || errorState.mobile === true) {
      alert('Please enter mobile');
    } else if (state.message === '' || errorState.message === true) {
      alert('Please enter message');
    } 
    // else if (state.subject === '' || errorState.subject === true) {
    //   alert('Please enter subject');
    // } 
    else {
      try {
        const response = await fetch(
          'https://leapot-lms-backend.onrender.com/api/addContactUs',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: state.name,
              email: state.email,
              mobile: state.mobile,
              subject: state.subject,
              message: state.message,
            }),
          },
        );

        if (response.ok) {
          toast.info('Your details have been forwarded to the concerned team.');
          setErrorState({
            name: false,
            email: false,
            mobile: false,
            subject: false,
            message: false,
          });
          setState({
            name: '',
            email: '',
            mobile: '',
            subject: '',
            message: '',
          });
        } else {
          toast.error('Failed to submit the form. Please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to submit the form. Please try again later.');
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let isError = false;
  
    if (name === 'mobile') {
      console.log("Mobile value:", value);
      if (value.length !== 10 || isNaN(value)) {
        console.log("Mobile number error");
        isError = true;
      }
    }
  
    if (value === '') {
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: isError });
    }
  
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const { t } = useTranslation();
  return (
    <ContactContMain>
      <TitleContB>
        <TitleB> {t("landing.ContactUsLabel")}</TitleB>
        <TitleDash />
      </TitleContB>
      <Desc>
        {t("landing.questionText")}

      </Desc>
      <ContactFormMain>
        <ContactFormMainsub>
          <ContactImg src={ContactUsImg} />
          <ContactFormCont>
            <TextField
              required
              label={t("landing.nameTextLabel")}
              type="text"
              name="name"
              id="name"
              variant="outlined"
              placeholder={t("landing.nameTextLabel")}
              sx={TextFieldStyle}
              error={errorState.name}
              value={state.name}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
            <TextField
              required
              label={t("landing.emailTextLabel")}
              type="text"
              name="email"
              id="email"
              variant="outlined"
              placeholder={t("landing.emailTextLabel")}
              sx={TextFieldStyle}
              error={errorState.email}
              value={state.email}
              onBlur={handleBlur}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
            <TextField
              required
              label={t("landing.mobileLable")}
              type="number"
              name="mobile"
              id="mobile"
              variant="outlined"
              placeholder={t("landing.mobileLable")}
              sx={TextFieldStyle}
              error={errorState.mobile}
              value={state.mobile}
              onBlur={handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                let isError = false;
            
                if (value.length <= 10 || isNaN(value)) {
                  isError = true;
                }
            
                setErrorState({ ...errorState, mobile: isError });
                setState({ ...state, mobile: value });
              }}
            />
            <MinHeightTextarea
              error={errorState.message}
              value={state.message}
              onChange={(newValue) => setState({ ...state, message: newValue })}
            />

            <SubmitButton onClick={handleSubmit}>{t("landing.submitLabel")}</SubmitButton>
          </ContactFormCont>
        </ContactFormMainsub>
      </ContactFormMain>
      <ContactFormCont2>
        <TitleB>{t("landing.ConnectLabel")}</TitleB>
        <TitleDash2 />
        <ContactFormCont3>
          <ContSub2Main>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={ph} />
                <Title3>{t("landing.phoneTextLabel")}</Title3>
              </TitleCont>
              <Desc2>+917038585222</Desc2>
            </ContSub2>
            <ContSub2>
              <TitleCont>
                <TitleLogo src={Em} />
                <Title3>{t("landing.emailTextLabel")}</Title3>
              </TitleCont>
              <Desc2>contact@leapot.in</Desc2>
            </ContSub2>
          </ContSub2Main>
          <ContSub2MainB >
            <ContSub2>
              <TitleCont>
                <TitleLogo src={social} />
                <Title3>{t("landing.socialMedia")}</Title3>
              </TitleCont>
              <Desc3>
                <a href="https://in.linkedin.com/company/leapot-technologies">
                  <SocialMediaIcon src={linkedin} />
                </a>
              </Desc3>
            </ContSub2>
            <ContSub2B>
              <TitleCont>
                <TitleLogo src={Ad} />
                <Title3>{t("landing.addressTextLabel")}</Title3>
              </TitleCont>
              <Desc2>
                {t("landing.leapotOfficeAddress")}
              </Desc2>
            </ContSub2B>
          </ContSub2MainB>
        </ContactFormCont3>
      </ContactFormCont2>
    </ContactContMain>
  );
};

export default Contactus;
