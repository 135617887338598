import React from 'react';
import { useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import TextField from '@mui/material/TextField';
import CommonButton from '../Utilities/CommonButton';
import CertificateTemplateData from '../../store/CertificateTemplate.json';
import {
  CertificateForm,
  JoditEditorDiv,
  ButtonDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CertificateTemplate = () => {
  const { id } = useParams();
  
  const selectedTemplate = CertificateTemplateData.find(
    (template) => template.id === parseInt(id),
  );
  const config = {
    placeholder: 'Start typing....',
    height: 400,
    readonly: false,
    autofocus: true,
    observer: false,
    shouldWork: true,
  };

  const { t } = useTranslation();
  return (
    <>
      <CertificateForm>
        {selectedTemplate && (
          <div>
            <h3>{selectedTemplate.Name}</h3>
            <TextField
              label={t("certificate.subjectLabel")}
              variant="outlined"
              margin="normal"
              fullWidth
              value={selectedTemplate.description}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <JoditEditorDiv>
              <JoditEditor
                value={selectedTemplate.htmlTemplate}
                config={config}
              />
            </JoditEditorDiv>
            <ButtonDiv>
              <CommonButton
                label={t("certificate.saveChangeLabel")}
                onClick={() => toast.success('Changes Saved Successfully')}
              />
            </ButtonDiv>
          </div>
        )}
      </CertificateForm>
    </>
  );
};

export default CertificateTemplate;
