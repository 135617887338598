import React from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../Utilities/BackButton';
import {
  Back,
  NotificationDetailed,
  ActionButton,
  FullHeight,
  NotificationHead,
  DateText,
} from '../../styles/Notification/NotificationStyles';
import { Text, HeadingH2 } from '../../styles/Common/CommonStyles';

import { Archive, Delete, Star } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const DetailedArchivedNotification = () => {
  const notifications = [
    {
      id: 1,
      subject: 'Notification 1',
      message: 'Archived notification 1 ',
      sender: 'Admin',
      timestamp: Date.now() - 1000 * 60 * 15,
      isRead: true,
    },
    {
      id: 2,
      subject: 'Notification 2',
      message: 'Archived notification 2',
      sender: 'John Doe',
      timestamp: Date.now() - 1000 * 60 * 30,
      isRead: false,
    },
    {
      id: 3,
      subject: 'Notification 3',
      message: 'Archived notification 3',
      sender: 'Jane Doe',
      timestamp: Date.now() - 1000 * 60 * 45,
      isRead: true,
    },
  ];

  const { id } = useParams();

  const notification = notifications.find(
    (notification) => notification.id === parseInt(id),
  );

  if (!notification) {
    return <p>Notification not found</p>;
  }

  const handleArchive = () => {
    console.log(`Notification ${id} archived`);
  };

  const handleDelete = () => {
    console.log(`Notification ${id} deleted`);
  };

  const handleMarkImportant = () => {
    console.log(`Notification ${id} marked as important`);
  };

  const { t } = useTranslation();

  return (
    <>
      <Back>
        <BackButton />
      </Back>
      <FullHeight>
        <NotificationDetailed>
          <NotificationHead>
            <div>
              <HeadingH2>{notification.subject}</HeadingH2>
              <DateText>
                {new Date(notification.timestamp).toLocaleTimeString()}
              </DateText>
            </div>
            <div>
              <ActionButton onClick={handleArchive}>
                <Archive />
              </ActionButton>
              <ActionButton onClick={handleDelete}>
                <Delete />
              </ActionButton>
              <ActionButton onClick={handleMarkImportant}>
                <Star />
              </ActionButton>
            </div>
          </NotificationHead>
          <Text>{notification.message}</Text>
        </NotificationDetailed>
      </FullHeight>
    </>
  );
};

export default DetailedArchivedNotification;
