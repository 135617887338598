import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from '../../Utilities/BackButton';
import io from 'socket.io-client';
import axios from 'axios';
import { palette } from '../../../styles/palette';
import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Search,
  AttachFile,
  Cancel,
  ThumbUp,
  ThumbDown,
} from '@mui/icons-material';
import ReplyIcon from '@mui/icons-material/Reply';
import Avatar from '@mui/material/Avatar';
import CommonButton from '../../Utilities/CommonButton';
import {
  postDiscussion,
  fetchDiscussion,
} from '../../../features/courses/courseDiscussionSlice';
import {
  ChatContainer,
  InputContainer,
  Header,
  UserInfo,
  UserName,
  HeaderIcons,
  Messages,
  MessageContainer,
  ImageContainer,
  Bubble,
  TimeStamp,
  ReplyContainer,
  IconGroup,
  CourseTitle,
  BubbleHead,
  Icons,
} from '../../../styles/Courses/CourseBuilderStyles';
const socket = io('http://localhost:8000');

const CourseDiscussion = () => {
  const { courseId } = useParams();
  const [message, setMessage] = useState('');
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [replyingToMessage, setReplyingToMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadUrl, setUploadUrl] = useState('');
  const [activeThumbUp, setActiveThumbUp] = useState({});
  const [activeThumbDown, setActiveThumbDown] = useState({});
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const { users } = useSelector((state) => state.auth);
  const { messages } = useSelector((state) => state.chat) || [];
  const { coursesList } = useSelector((state) => state.addCourseDetails) || {};

useEffect(() => {
  dispatch(fetchDiscussion(courseId));

  const socket = io('http://localhost:8000');

  socket.on('connect', () => {
    console.log('Connected to socket server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from socket server');
  });

  socket.on('message', (newMessage) => {
    dispatch(fetchDiscussion(courseId));
  });

  return () => {
    socket.disconnect();
    socket.close();
  };
}, [dispatch, courseId]);


  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
    files.forEach(async (file) => {
      await requestUploadUrl(file);
    });
  };

  const handleCancelFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== fileIndex,
    );
    setSelectedFiles(updatedFiles);
  };

  const requestUploadUrl = async (selectedFiles) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/uploadAttachments',
        {
          fileName: selectedFiles.name,
          fileType: selectedFiles.type,
        },
      );
      console.log(response.data.url);
      setUploadUrl(response.data.url);
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    try {
      const data = await axios.put(uploadUrl, selectedFiles, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully', data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async () => {
    await uploadToS3();

    const newMessage = {
      course: courseId,
      sender: users.user._id,
      text: message,
      replyTo: replyingToMessage ? replyingToMessage._id : null,
      attachments: uploadUrl?.split('?')[0],
    };
    socket.emit('message', newMessage);
    dispatch(postDiscussion(newMessage)).then(() => {
    
      setMessage('');
      setSelectedFiles([]);
      setReplyingToMessage(null);
      scrollToBottom();
    });
    dispatch(fetchDiscussion(courseId));
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMessages = messages.data?.filter((message) =>
    message.text?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const course = coursesList.find((course) => course._id === courseId);
  const courseTitle = course ? course.title : 'Course Discussion';

  const messageMap = {};
  messages.data?.forEach((message) => {
    messageMap[message._id] = message;
  });

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleThumbUp = async (messageId) => {
    try {
      await axios.put(
        `http://localhost:8000/api/course/messages/${messageId}/thumbUp`,
        { userId: users.user._id },
      );
      dispatch(fetchDiscussion(courseId)); // Refresh messages
    } catch (error) {
      console.error('Error updating thumb up:', error);
    }
  };

  const handleThumbDown = async (messageId) => {
    try {
      await axios.put(
        `http://localhost:8000/api/course/messages/${messageId}/thumbDown`,
        { userId: users.user._id },
      );
      dispatch(fetchDiscussion(courseId)); // Refresh messages
    } catch (error) {
      console.error('Error updating thumb down:', error);
    }
  };

  useEffect(() => {
    if (messages && users.user) {
      const thumbUpStates = {};
      const thumbDownStates = {};

      messages.data?.forEach((message) => {
        if (
          Array.isArray(message.usersVoted.thumbUps) &&
          message.usersVoted.thumbUps.includes(users.user._id)
        ) {
          thumbUpStates[message._id] = true;
        }
        if (
          Array.isArray(message.usersVoted.thumbDowns) &&
          message.usersVoted.thumbDowns.includes(users.user._id)
        ) {
          thumbDownStates[message._id] = true;
        }
      });

      setActiveThumbUp(thumbUpStates);
      setActiveThumbDown(thumbDownStates);
    }
  }, [messages, users.user]);

  return (
    <>
      <ChatContainer>
        <Header>
          <UserInfo>
            <div>
              <CourseTitle>{courseTitle}</CourseTitle>
            </div>
          </UserInfo>
          <HeaderIcons>
            <Tooltip title="Search messages">
              <TextField
                placeholder="Search messages"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <IconButton size="small">
                      <Search />
                    </IconButton>
                  ),
                }}
              />
            </Tooltip>
          </HeaderIcons>
        </Header>

        <Messages>
          {filteredMessages?.map((message, idx) => (
            <MessageContainer
              key={message._id}
              onMouseEnter={() => setHoveredMessageId(idx)}
              onMouseLeave={() => setHoveredMessageId(null)}
            >
              <Bubble>
                <BubbleHead>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Avatar
                      src={
                        message.sender?.userid?.picture || '/default-avatar.png'
                      }
                    />
                    <UserName style={{ marginRight: '5px' }}>
                      {message.sender.userid.username
                        ? message.sender.userid.username
                        : message.sender.userid.email}
                    </UserName>
                  </div>
                  <Icons>
                    <Tooltip title="Reply">
                      <IconButton
                        size="small"
                        onClick={() => setReplyingToMessage(message)}
                      >
                        <ReplyIcon
                          fontSize="small"
                          sx={{
                            '&:hover': {
                              color: `${palette.primary}`,
                            },
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {/* {hoveredMessageId === message._id && ( */}
                    <>
                      <Tooltip title="Helpful">
                        <IconButton
                          size="small"
                          onClick={() => handleThumbUp(message._id)}
                        >
                          <ThumbUp
                            fontSize="small"
                            style={{
                              color: activeThumbUp[message._id]
                                ? palette.primary
                                : 'inherit',
                            }}
                          />
                        </IconButton>
                        {message.thumbUps}
                      </Tooltip>
                      <Tooltip title="Not Helpful">
                        <IconButton
                          size="small"
                          onClick={() => handleThumbDown(message._id)}
                        >
                          <ThumbDown
                            fontSize="small"
                            style={{
                              color: activeThumbDown[message._id]
                                ? palette.primary
                                : 'inherit',
                            }}
                          />
                        </IconButton>
                        {message.thumbDowns}
                      </Tooltip>
                    </>
                    {/* )} */}
                  </Icons>
                </BubbleHead>
                <br />
                <Typography>{message.text}</Typography>
                {message.replyTo && messageMap[message.replyTo] && (
                  <ReplyContainer>
                    Replying to: {messageMap[message.replyTo].text}
                  </ReplyContainer>
                )}
                {message.attachments && (
                  <ImageContainer>
                    {message.attachments.map((attachment, index) => {
                      const isImage = attachment.match(
                        /\.(jpeg|jpg|gif|png)$/i,
                      );
                      return (
                        <div key={index}>
                          {isImage ? (
                            <img
                              src={attachment}
                              alt="attachment"
                              style={{ maxWidth: '100%', maxHeight: '300px' }}
                            />
                          ) : (
                            <a
                              href={attachment}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {attachment.split('/').pop()}
                            </a>
                          )}
                        </div>
                      );
                    })}
                  </ImageContainer>
                )}
                <IconGroup>
                  <TimeStamp>{formatTime(message.timestamp)}</TimeStamp>
                </IconGroup>
              </Bubble>
            </MessageContainer>
          ))}
          <div ref={messagesEndRef} />
        </Messages>
        <InputContainer container spacing={1}>
          <Grid item xs={12}>
            {replyingToMessage && (
              <Typography variant="body2" color="textSecondary">
                Replying to: {replyingToMessage.text}
              </Typography>
            )}
            {selectedFiles?.length > 0 && (
              <div>
                Selected Files:
                {selectedFiles?.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '5px',
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {file.name} ({(file.size / 1024).toFixed(2)} KB)
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleCancelFile(index)}
                      style={{ marginLeft: '5px' }}
                    >
                      <Cancel fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '5px',
              }}
            >
              <TextField
                placeholder="Enter Message..... "
                fullWidth
                value={message}
                onChange={handleMessage}
                variant="outlined"
                // InputProps={{
                //   style: {
                //     border: 'none',
                //     outline: 'none',
                //   },
                // }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <IconButton component="label">
                <AttachFile />
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </IconButton>
              <CommonButton label="Send" onClick={handleSendMessage} />
            </div>
          </Grid>
        </InputContainer>
      </ChatContainer>
    </>
  );
};

export default CourseDiscussion;
