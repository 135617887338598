import axios from 'axios';
import { getToken, getLangCode } from './localStorageUtils';
import { postData , getData } from './CustomApiCalling';
const URL = process.env.REACT_APP_URL;

export const fetchCertificateTemplateApi = async () => {
  try {
    const queryParams = { };
    const customHeaders = {};
    // return await getData('/api/certificate/fetchTemplate', customHeaders, queryParams);
    return await axios.get(`${URL}/api/certificate/fetchTemplate`);
  } catch (err) {
    console.log('Error occurs while fetching certificate template', err);
    throw err; 
  }
};

export const deleteCertificateTemplateApi = async (templateId) => {
    try {
      return await axios.put(`${URL}/api/certificate/deleteTemplate/${templateId}`);
    } catch (err) {
      console.log('Error occurs while deleting certificate template', err);
      throw err; 
    }
  };

  export const deleteCertificateApi = async (certificateId) => {
    try {
      return await axios.put(`${URL}/api/certificate/deleteCertificate/${certificateId}`);
    } catch (err) {
      console.log('Error occurs while deleting certificate template', err);
      throw err; 
    }
  };

  export const createCertificateTemplateApi = async (templateData) => {
    try {
      const customHeaders = {};
    return await postData('/api/certificate/addTemplate', templateData , customHeaders );
    } catch (err) {;
      console.log('Error occurs while creating certificate template', err);
      throw err; 
    }
  };

  export const createCertificateApi = async (templateData) => {
    try {
      return await axios.post(`${URL}/api/certificate/useTemplate`, templateData);
    } catch (err) {
      console.log('Error occurs while creating certificate', err);
      throw err; 
    }
  };

  export const createCertificateSettingsApi = async (templateData) => {
    try {
      return await axios.post(`${URL}/api/certificate/certificateSettings`, templateData);
    } catch (err) {
      console.log('Error occurs while creating certificate settings', err);
      throw err; 
    }
  };

  export const updateCertificateSettingsApi = async (templateData) => {
    try {
      return await axios.put(`${URL}/api/certificate/updateCertificateSetting/${templateData.settingId}`, templateData);
    } catch (err) {
      console.log('Error occurs while creating certificate settings', err);
      throw err; 
    }
  };

  export const blacklistApi = async (data) => {
    try {
      return await axios.post(`${URL}/api/certificate/blacklist`, data);
    } catch (err) {
      console.log('Error occurs while creating certificate', err);
      throw err; 
    }
  };

  export const issueSingleCertificateApi = async (data) => {
    try {
      return await axios.post(`${URL}/api/certificate/singleIssue`, data);
    } catch (err) {
      console.log('Error occurs while creating certificate', err);
      throw err; 
    }
  };

  export const issueBulkCertificateApi = async (data) => {
    try {
      return await axios.post(`${URL}/api/certificate/bulkIssue`, data);
    } catch (err) {
      console.log('Error occurs while creating certificate', err);
      throw err; 
    }
  };

  export const fetchAllCertificates = async () => {
    try{
        return await axios.get(`${URL}/api/certificate/fetchCertificates`);
    } catch (err){
        console.log('Error occurs while fetching certificates', err);
        throw err; 
    }
  };

  export const fetchIssueCertificate = async () => {
    try{
        return await axios.get(`${URL}/api/certificate/fetchIssueCertificate`);
    } catch (err){
        console.log('Error occurs while fetching issued certificates', err);
        throw err; 
    }
  };

  export const fetchBlacklistedUsers = async () => {
    try{
        return await axios.get(`${URL}/api/certificate/getBlacklistedUsers`);
    } catch (err){
        console.log('Error occurs while fetching blacklisted users', err);
        throw err; 
    }
  };

  export const fetchCertificateSettings = async () => {
    try{
        return await axios.get(`${URL}/api/certificate/fetchSetting`);
    } catch (err){
        console.log('Error occurs while fetching blacklisted users', err);
        throw err; 
    }
  };

  export const editCertificateApi = async (certificateId, updatedData) => {
    try {
      return await axios.put(`${URL}/api/certificate/editCertificate/${certificateId}`, updatedData);
    } catch (err) {
      console.log('Error occurs while editing certificate', err);
      throw err; 
    }
  };

  export const fetchSingleSettingsApi = async (settingId) => {
    try {
      return await axios.get(`${URL}/api/certificate/fetchSingleSetting/${settingId}`);
    } catch (err) {
      console.log('Error occurs while editing settings', err);
      throw err; 
    }
  };

  export const fetchSingleCertificateApi = async (certificateId) => {
    try {
      return await axios.get(`${URL}/api/certificate/fetchSingleCertificate/${certificateId}`);
    } catch (err) {
      console.log('Error occurs while fetching certificate', err);
      throw err; 
    }
  };

  export const fetchEventApi = async () => {
    try {
      const response = await axios.get(`${URL}/api/eventManager/getEvents`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  export const deleteSettingsApi = async (settingId) => {
    try {
      return await axios.put(`${URL}/api/certificate/DeleteEventSetting/${settingId}`);
    } catch (err) {
      console.log('Error occurs while deleting settings', err);
      throw err; 
    }
  };