import React from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import SearchBar from '../Utilities/SearchBar';

import InputAdornment from "@mui/material/InputAdornment";
const Payment = () => {
  return (
    <Box style={{ margin: '50px' }}>
      {/* <Card style={{ maxWidth: 500, padding: "20px 5px", margin: "0 auto" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" style={{ color: "#FFA722", fontWeight: "bold" }}>
          Event Details
        </Typography>

          <form>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ fontWeight: "bold" }}>Event Name</label>
              <TextField
                id="eventName"
                placeholder="Name of the event"
                fullWidth
                required
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ fontWeight: "bold" }}>Event Type</label>
              <TextField
                id="eventType"
                placeholder="Event Type"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ fontWeight: "bold" }}>Event Date</label>
              <TextField
                type="date"
                id="eventDate"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ fontWeight: "bold" }}>Event Fees</label>
              <TextField
                id="eventFees"
                variant="outlined"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ fontWeight: "bold" }}>Tax</label>
              <TextField
                id="tax"
                variant="outlined"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ fontWeight: "bold" }}>Total Amount</label>
              <TextField
                id="totalAmount"
                variant="outlined"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <input type="checkbox" id="terms" name="terms" />
              <label htmlFor="terms">Accept Terms and Conditions</label>
              <p>
                I agree to the Terms and Service that my data will be taken and
                used.
              </p>
            </div>

        </form>
      </CardContent>
    </Card> */}
      <SearchBar
        Typography1="Search by"
        Typography2="and order results by"
        ButtonText1="Search"
        ButtonText2="Clear"
      />
    </Box>
  );
};

export default Payment;
