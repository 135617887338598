import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCertificateTemplateApi,
  deleteCertificateTemplateApi,
  createCertificateTemplateApi,
  fetchIssueCertificate,
  fetchBlacklistedUsers,
  createCertificateApi,
  fetchAllCertificates,
  editCertificateApi,
  deleteCertificateApi,
  blacklistApi,
  fetchCertificateSettings,
  fetchEventApi,
  fetchSingleCertificateApi,
  createCertificateSettingsApi,
  issueSingleCertificateApi,
  fetchSingleSettingsApi,
  updateCertificateSettingsApi,
  issueBulkCertificateApi,
  deleteSettingsApi
} from '../../api/CertificateApi';

export const fetchCertificateTemplate = createAsyncThunk(
  'certificate/fetchTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCertificateTemplateApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCertificateTemplate = createAsyncThunk(
  'certificate/deleteTemplate',
  async (templateId, { rejectWithValue, dispatch }) => {
    try {
      await deleteCertificateTemplateApi(templateId);
      dispatch(fetchCertificateTemplate());
      return templateId; // Return the deleted template ID
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteCertificate = createAsyncThunk(
  'certificate/deleteTemplate',
  async (certificateId, { rejectWithValue }) => {
    try {
      await deleteCertificateApi(certificateId);
      return certificateId; // Return the deleted template ID
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addCertificateTemplate = createAsyncThunk(
  'certificate/addTemplate',
  async (templateData, { rejectWithValue }) => {
    try {
      const response = await createCertificateTemplateApi(templateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addCertificate = createAsyncThunk(
  'certificate/addCertificate',
  async (templateData, { rejectWithValue }) => {
    try {
      const response = await createCertificateApi(templateData);
      return response.data; // Assuming your API returns the newly created template
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addCertificateSettings = createAsyncThunk(
  'certificate/addCertificate',
  async (Data, { rejectWithValue }) => {
    try {
      const response = await createCertificateSettingsApi(Data);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAllCertificateApi = createAsyncThunk(
  'certificate/fetchAllCertificateApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAllCertificates();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSingleCertificate = createAsyncThunk(
  'certificate/fetchSingleCertificateApi',
  async (certificateId, { rejectWithValue }) => {
    try {
      const response = await fetchSingleCertificateApi(certificateId); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);


export const updateCertificateSetting = createAsyncThunk(
  'certificate/updateCertificateSetting',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCertificateSettingsApi(data);
      console.log(response , "updateCertificateSetting") 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);


export const fetchAllSettingsApi = createAsyncThunk(
  'certificate/fetchAllSettingsApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCertificateSettings();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchIssuedCertificate = createAsyncThunk(
  'certificate/fetchIssueCertificate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchIssueCertificate();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const blacklistUser = createAsyncThunk(
  'certificate/blacklistUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await blacklistApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const issueCertificate = createAsyncThunk(
  'certificate/issueCertificate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await issueSingleCertificateApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const issueBulkCertificate = createAsyncThunk(
  '/certificate/bulkIssue',
  async (data, { rejectWithValue }) => {
    try {
      const response = await issueBulkCertificateApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBlacklistedUsersApi = createAsyncThunk(
  '/certificate/getBlacklistedUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchBlacklistedUsers();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editCertificate = createAsyncThunk(
  'certificate/editCertificate',
  async ({ certificateId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await editCertificateApi(certificateId, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSingleSetting = createAsyncThunk(
  'certificate/fetchSingleSetting',
  async ( settingsId, { rejectWithValue }) => {
    try {
      const response = await fetchSingleSettingsApi(settingsId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchEvent = createAsyncThunk(
  'fetchEvent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchEventApi();
      console.log("Fetch event slice",response);
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSetting = createAsyncThunk(
  'certificate/deleteTemplate',
  async (settingId, { rejectWithValue, dispatch }) => {
    try {
      await deleteSettingsApi(settingId);
      dispatch(fetchAllSettingsApi());
      return settingId; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState = {
  data: [],
  events:[],
  allcertificate:[],
  settings:[],
  issued: [],
  blacklist: [],
  allsettings:[],
  templatecertificate:[],
  loading: false,
  error: null,
};

const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificateTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCertificateTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCertificateTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      })
      .addCase(addCertificateTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCertificateTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.templatecertificate.push(action.payload);
        state.error = null;
      })
      .addCase(addCertificateTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
        state.error = null;
      })
      .addCase(fetchEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.events = null;
      })
      .addCase(fetchAllCertificateApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCertificateApi.fulfilled, (state, action) => {
        state.loading = false;
        state.allcertificate = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCertificateApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allcertificate = null;
      })
      .addCase(fetchSingleSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.settings = null;
      })
      .addCase(fetchIssuedCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIssuedCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.issued = action.payload;
        state.error = null;
      })
      .addCase(fetchIssuedCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.issued = null;
      })
      .addCase(fetchBlacklistedUsersApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlacklistedUsersApi.fulfilled, (state, action) => {
        state.loading = false;
        state.blacklist = action.payload;
        state.error = null;
      })
      .addCase(fetchBlacklistedUsersApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.blacklist = null;
      })
      .addCase(fetchAllSettingsApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllSettingsApi.fulfilled, (state, action) => {
        state.loading = false;
        state.allsettings = action.payload;
        state.error = null;
      })
      .addCase(fetchAllSettingsApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allsettings = null;
      })
  },
});

export default certificateSlice.reducer;
