// import React, { useState } from "react";
// import {
//   Container,
//   Grid,
//   Typography,
//   Switch,
//   Tooltip,
//   IconButton,
//   FormControlLabel,
//   Checkbox,
// } from "@mui/material";
// import {
//   Step3ContainerStyle,
//   HeadingClass,
//   DescriptionClass,
//   ToggleStyle,
//   HeaderContainer,
//   CreateContent,
//   Heading,
//   ButtonCont,
//   ButtonText,
// } from "../../../styles/Courses/CourseBuilderStyles";
// import { useNavigate } from "react-router-dom";
// import { PageContainer } from "../../../styles/Common/CommonStyles";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { palette } from "../../../styles/palette";
// import coursesList from "../../../store/CoursesData.json";
// import CourseSettingTabs from "./CourseSettingTabs";
// import CommonButton from "../../Utilities/CommonButton";

// const CourseSettings = () => {
//   const [content, enableContent] = useState(false);
//   const [complete, enableComplete] = useState(false);
//   const [sequential, enableSequential] = useState(false);
//   const [security, enableSecurity] = useState(false);
//   const [learnerCount, enableLearnerCount] = useState(false);
//   const [validity, enableValidity] = useState(false);
//   const [additionalInfo, enableAdditionalInfo] = useState(false);
//   const [bookmark, enableBookmark] = useState(false);

//   const navigate = useNavigate();

//   const contentToggle = (event) => {
//     enableContent(!content);
//     console.log(content);
//   };

//   const completeToggle = () => {
//     enableComplete(!complete);
//   };

//   const sequentialToggle = () => {
//     enableSequential(!sequential);
//   };
//   const securityToggle = () => {
//     enableSecurity(!security);
//   };

//   const learnerCountToggle = () => {
//     enableLearnerCount(!learnerCount);
//   };

//   const validityToggle = () => {
//     enableValidity(!validity);
//   };

//   const additionalInfoToggle = () => {
//     enableAdditionalInfo(!additionalInfo);
//   };

//   const bookmarkToggle = () => {
//     enableBookmark(!bookmark);
//   };

//   const handleSaveCourse = () => {
//     console.log("handle saveee");
//     navigate("/CourseBuilder");
//   };
//   return (
//     <PageContainer>
//       <Grid container spacing={0}>
//         <Grid item xs={1} sx={{background:`${palette.light_gray}` , pt:1 , pl:2}}>
//           <Tooltip title="Go Back">
//               <IconButton
//                 size="small"
//                 onClick={() => navigate("/CourseBuilder")}
//                 sx={{ color: `${palette.THEME_THREE}` }}
//               >
//                 <ArrowBackIosIcon />
//               </IconButton>
//             </Tooltip>
//         </Grid>
//         <Grid item xs={10}>
//           <CourseSettingTabs />
//         </Grid>
//         <Grid item xs={1} sx={{background:`${palette.light_gray}` , pt:0.5}} >
//           <CommonButton label="Save" />
//         </Grid>
//       </Grid>

//       <Container fluid sx={Step3ContainerStyle}>
//         <Grid container spacing={2} sx={{ mb: 2 }}>
//           <Grid item xs={8}>
//             <Typography sx={HeadingClass}>
//               Enable Content Scheduling{" "}
//             </Typography>
//           </Grid>
//           <Grid item xs={2}>
//             <Switch checked={content} onChange={contentToggle} />
//           </Grid>
//           <Grid item xs={8}>
//             <Typography sx={DescriptionClass}>
//               Course Items will be released gradually over time (e.g. every few
//               days or once a week)
//             </Typography>
//           </Grid>
//           <Grid item xs={8}>
//             <Typography sx={HeadingClass}>
//               Enforce sequential learning path
//             </Typography>
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={sequential} onChange={sequentialToggle} />
//           </Grid>
//           <Grid item xs={8}>
//             <Typography sx={DescriptionClass}>
//               Course items can only be accessed in the sequential order.
//               Learners need to mark course items as completed to move to next
//               item. In an already published course, all previously completed
//               items will remain accessible.
//             </Typography>
//           </Grid>
//           <Grid item xs={8} sx={HeadingClass}>
//             Enforce complete video viewing
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={complete} onChange={completeToggle} />
//           </Grid>
//           <Grid item xs={8}>
//             <Typography sx={DescriptionClass}>
//               Learners need to watch at-least 90% of the video lesson to move to
//               next course item. It only works if sequential learning path is
//               enabled. This feature is compatible only with videos uploaded on
//               the platform.
//             </Typography>
//           </Grid>
//           <Grid item xs={8} sx={HeadingClass}>
//             Security
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={security} onChange={securityToggle} />
//           </Grid>
//           <Grid item xs={8}>
//             <Typography sx={DescriptionClass}>Disable Text Copying</Typography>
//           </Grid>
//           <Grid item xs={8} sx={HeadingClass}>
//             Show Learners Count on Landing Page
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={learnerCount} onChange={learnerCountToggle} />
//           </Grid>

//           <Grid item xs={8} sx={HeadingClass}>
//             Show validity to learners (my courses)
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={validity} onChange={validityToggle} />
//           </Grid>

//           <Grid item xs={8} sx={HeadingClass}>
//             Show additional info (like duration, pages, etc) in Course
//             Curriculum
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={additionalInfo} onChange={additionalInfoToggle} />
//           </Grid>

//           <Grid item xs={8} sx={HeadingClass}>
//             Allow Bookmark Course Items
//           </Grid>
//           <Grid item xs={2} sx={ToggleStyle}>
//             <Switch checked={bookmark} onChange={bookmarkToggle} />
//           </Grid>
//         </Grid>
//       </Container>
//     </PageContainer>
//   );
// };

// export default CourseSettings;

import React, { useState } from "react";
import { Grid,  Tooltip,  IconButton,} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { palette } from "../../../styles/palette";
 import { useNavigate } from "react-router-dom";
import CourseSettingTabs from "../CourseSettings/CourseSettingTabs";
import BasicSettings from "./BasicSettings";
import PlayerAppearanceSettings from "./CoursePlayerAppearanceSetting";
import ProgressCompletionSettings from "./CourseProgressCompletionSetting";
import CommonButton from "../../Utilities/CommonButton";
import { useParams } from 'react-router-dom';

const CourseSettings = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();

  const [selectedTab, setSelectedTab] = useState(`/CourseSettings/${courseId}`);

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };
    const handleSaveCourse = () => {
    console.log("handle saveee");
    navigate("/CourseBuilder");
  };

  return (
    <div>
      <Grid container alignItems="center" sx={{ml: '5px'}}>
    {/* Grid for the back button */}
    <Grid item>
        <Tooltip title="Go Back">
            <IconButton
                size="small"
                onClick={() => navigate("/CourseBuilder")}
                // sx={{ color: `${palette.THEME_THREE}` }}
            >
                <ArrowBackIosIcon />
            </IconButton>
        </Tooltip>
    </Grid>

    <Grid item sx={{ marginLeft: 'auto', pt: 0.5, marginRight:"12px" }}>
        <CommonButton label="Save" onClick={handleSaveCourse} />
    </Grid>
</Grid>
  <div >
      <CourseSettingTabs onChange={handleTabChange} />
      {selectedTab === `/CourseSettings/${courseId}` && <BasicSettings />}
      {selectedTab === `/CoursePlayerAppearenceSetting/${courseId}` && <PlayerAppearanceSettings />}
      {/* {selectedTab === "/CourseProgressCompletionSetting" && <ProgressCompletionSettings />} */}
      
    </div>
    </div>
  );
};

export default CourseSettings;
