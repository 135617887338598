import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  InputAdornment,
  Button
} from "@mui/material";
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JoditReact from "jodit-react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { useParams } from 'react-router-dom';

const VideoUpload = ({ isOpen, onClose, selectedItem, onBackButtonClick, lessonId, moduleId }) => {
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [videoKey, setVideoKey] = useState(Date.now()); 

  const [formData, setFormData] = useState({
    selectedValue: "upload",
    link: selectedItem?.link || "",
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || "",
    lessonId: selectedItem?.lessonId || "",
    title: selectedItem?.title || "",
    textDescription: selectedItem?.textDescription || "",
    id: selectedItem?.id || "",
    courseStructure: selectedItem?.courseStructure || "",
    contentType: selectedItem?.contentType || "",
    fileName: selectedItem?.fileName || ""  
  });

  const [file, setFile] = useState(null);
  const [videoShow, setVideoShow] = useState(false);

  useEffect(() => {
    setFormData({
      selectedValue: "upload",
      link: selectedItem?.link || "",
      uploadUrl: '',
      moduleId: selectedItem?.moduleId || "",
      lessonId: selectedItem?.lessonId || "",
      title: selectedItem?.title || "",
      textDescription: selectedItem?.textDescription || "",
      id: selectedItem?.id || "",
      courseStructure: selectedItem?.courseStructure || "",
      contentType: selectedItem?.contentType || "",
      fileName: selectedItem?.fileName || ""
    });
  }, [selectedItem]);

  useEffect(() => {
    setVideoKey(Date.now()); // Update video key whenever formData.link changes
  }, [formData.link]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: generateRandomNumber(),
      contentType: 'Video',
      courseStructure: courseStructure,
      link: formData.selectedValue !== "upload" ? formData.link : formData.uploadUrl?.split('?')[0],
    }));
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      selectedValue: value,
      link: "", // Reset the link field when changing video type
    }));
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFormData((prevData) => ({
      ...prevData,
      fileName: selectedFile?.name
    }));
    await requestUploadUrl(selectedFile);
  };

  const requestUploadUrl = async (file) => {
    try {
      const response = await axios.post('http://localhost:8000/api/course/uploadVideo', {
        fileName: file?.name,
        fileType: file?.type,
      });
      setFormData((prevData) => ({
        ...prevData,
        link: response.data.url?.split('?')[0],
        uploadUrl: response.data.url,
      }));
    } catch (error) {
      console.error('Error requesting upload URL:', error);
    }
  };

  const uploadToS3 = async () => {
    console.log(uploadToS3)
    try {
      await axios.put(formData.uploadUrl, file, {
        headers: {
          "Content-Type": file?.type,
        },
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFormData((prevData) => ({
      ...prevData,
      fileName: '',
      uploadUrl: '',
      link: ''
    }));
  };

  const handleUpload = async () => {
    console.log("handleUpload")
    if (file) {
      await uploadToS3();
      alert("File Upload successfully")

      setVideoShow(true);
    }
    alert("File Upload unsuccessfully")

  };

  const handleSubmit = async () => {
    await dispatch(addItem(formData));
    onClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: "#0684FB" }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
              Video Upload
            </Typography>
          </Grid>
        </Grid>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px", width: "180px" }}>Discard Changes</Button>
          <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }} onClick={handleSubmit}>Save</Button>
        </div>
      </div>

      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <TextField
          variant="outlined"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <JoditReact
          value={formData.textDescription}
          config={{ placeholder: "Enter the video description" }}
          onChange={newContent => setFormData((prevData) => ({
            ...prevData,
            textDescription: newContent,
          }))}
        />
      </div>

      <RadioGroup
        row
        style={{ marginLeft: "20px", marginBottom: "2%", marginTop: "12px" }}
        value={formData.selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel value="upload" control={<Radio />} label="Upload" />
        <FormControlLabel value="youtube" control={<Radio />} label="YouTube" />
        <FormControlLabel value="vimeo" control={<Radio />} label="Vimeo" />
        <FormControlLabel value="link" control={<Radio />} label="Link" />
        <FormControlLabel value="embedcode" control={<Radio />} label="Embed Code" />
      </RadioGroup>

      {formData.selectedValue === "upload" && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              label="Upload Video File"
              value={formData.fileName}
              helperText="Max video size 2GB"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {formData.fileName && (
                      <IconButton onClick={handleFileRemove} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {!formData.fileName && (
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        style={{ borderRadius: 4 }}
                      >
                        Choose File
                        <input type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={handleUpload}
              style={{ backgroundColor: '#0684FB', color: 'white' }}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      )}

      {formData.selectedValue === "youtube" && (
        <TextField
          variant="outlined"
          label="YouTube Link"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}

      {formData.selectedValue === "vimeo" && (
        <TextField
          variant="outlined"
          label="Vimeo Link"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}

      {formData.selectedValue === "link" && (
        <TextField
          variant="outlined"
          label="Link"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}

      {formData.selectedValue === "embedcode" && (
        <TextField
          variant="outlined"
          label="Embed Code"
          name="link"
          value={formData.link}
          onChange={handleInputChange}
          multiline
          rows={3}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}

{(formData.fileName !== "" || videoShow) && (
  <video 
  key={videoKey} // Use unique key
    width="600" 
    controls
  >
    <source 
      src={formData?.link} 
      type="video/mp4" 
    />
    Your browser does not support the video tag.
  </video>
)}

    </Container>
  );
};

export default VideoUpload;
