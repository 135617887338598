import React from 'react'
import { Grid } from '@mui/material';

const PreviewTopicPresentation = ( (props) => {
  
    return (
      <div style={{ width: "100%" }}>
        <Grid container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                      margin: '20px 20px 10px 0', // Adjust margin as needed
                      padding: '10px', // Adjust padding as needed
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add box shadow
                      overflow: 'auto', // Enable scrolling if content overflows
                      textAlign: 'justify', // Justify text content
                  }}
              >
                 <h3> {props.item.title}</h3>
              </Grid> 
              <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=https://leapot-lms.s3.ap-south-1.amazonaws.com/course/videos/047fbf9f-9bdf-434f-93e0-b1e2be194282-8aec0e3b-ee1a-4459-b433-738c51e6bbef-ppt+template+(2).pptx`}
          width="100%"
          height="425px"
          style={{ border: 'none' }}
        />
         </div>
    );
  });

export default PreviewTopicPresentation