import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../../styles/palette";


export const MainServiceCont = styled.div`
  ${tw`m-0 flex justify-center items-center flex-col mt-10
  lg:m-20
  `}
`;

export const TitleDash = styled.hr`
${tw`h-[3px] w-[20%] rounded-lg`}
background:${palette.primary}
`

export const ServiceDescCont = styled.div`
${tw`flex justify-center items-center flex-col`}
`
export const ServiceHead = styled.h1`
${tw`text-4xl font-bold`}
color : ${palette.primary}
`
export const ServiceHeadSub = styled.h2`
${tw`text-xl
lg:text-2xl
xl:text-3xl
`}
`

export const ServiceDesc = styled.p`
  ${tw`2xl:text-xl xl:text-lg xl:leading-8 text-justify leading-8  w-[80vw]
  lg:text-base lg:leading-8 md:text-lg  md:leading-7 sm:text-lg   tracking-wide`}
  color: ${palette.black};
`

export const ServiceDesc2 = styled.p`
${tw`text-base text-justify
md:text-center md:text-xl
`}
`


export const TitleHead = styled.p`
${tw`flex justify-center items-center flex-col`}
`

export const SpanTag = styled.span`
${tw`font-bold`}
Color:${palette.primary}
`

export const ServiceCardsCont = styled.div`
${tw`mb-20 flex justify-center items-center flex-col

`}
`

export const TextContOne = styled.div`
${tw`m-4 p-4 h-[100%] w-auto  shadow-xl rounded-[15px]`}
background:${palette.primary}
// background:linear-gradient(180deg, ${palette.primary}, ${palette.buttonhover})
`
export const TextContTwo = styled.div`
${tw`m-4 p-4 h-[100%] w-auto  shadow-xl rounded-[15px]`}
background:${palette.primary}
// background:linear-gradient(180deg, white, ${palette.tertiary})
`
export const TextContThree = styled.div`
${tw`m-4 h-[100%] w-[90%] p-4  shadow-xl rounded-[15px] 
md:w-[40%]
`}
background:${palette.primary}
// background:linear-gradient(180deg, white, ${palette.tertiary})
`
export const TextContFour = styled.div`
${tw`h-[100%] w-[90%] m-4 p-4  shadow-xl rounded-[15px]
md:w-[60%]
`}
background:${palette.primary}
// background:linear-gradient(180deg, white, ${palette.tertiary})
`


export const HeadOne = styled.h2`
${tw`text-white font-medium`}
// color:${palette.primary}
`
export const HeadTwo = styled.h2`
${tw`text-white font-medium`}
// color:${palette.primary}
`
export const HeadThree = styled.h2`
${tw`text-white font-medium`}
// color:${palette.primary}
`
export const HeadFour = styled.h2`
${tw`text-white font-medium`}
// color:${palette.primary}
`

export const DescOne = styled.p`
${tw`text-justify tracking-wider text-base leading-8 text-white 
lg:text-base
`}
`
export const DescTwo = styled.p`
${tw`text-justify text-base tracking-wider leading-8 text-white 
lg:text-base
`}
`
export const DescThree = styled.p`
${tw`text-justify text-base tracking-wider leading-8 text-white 
lg:text-base
`}
`
export const DescFour = styled.p`
${tw`text-justify text-base tracking-wider leading-8 text-white 
lg:text-base
`}
`

export const SectionA = styled.p`
${tw`flex justify-center flex-col m-[-5px] h-[100%] w-[100%] items-center 
md:flex-row
`}

`

export const SectionB = styled.p`
${tw`flex justify-center items-center flex-col h-[100%] w-[100%]  
md:flex-row
`}
`


export const ServiceCardsImg = styled.img`
${tw`w-[100%] h-[200px]`}
`

export const ServiceCardsContB = styled.div`
${tw`flex justify-center items-center flex-col w-[50%] p-5  shadow-2xl`}
`

export const ServiceCardsContBMain = styled.div`
${tw`flex justify-center items-center flex-col`}
`

