import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';
import { TextField, Tooltip } from '@mui/material';
import CommonButton from '../Utilities/CommonButton';
import BackButton from '../Utilities/BackButton';
import { addCertificateTemplate } from '../../features/certificate/CertificateSlice';

import {
  CertificateForm,
  JoditEditorDiv,
  CertificateHeading,
  ButtonDiv,
  ArrowDiv,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function AddTemplate() {
  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
    }),
    [],
  );

  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({});

  const handleSubmit = () => {
    const errors = {};

    if (templateName.trim() === '') {
      errors.templateName = 'Template Name is required.';
    }

    if (content.trim() === '') {
      alert('Template is required.');
      return;
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const templateData = {
      certificateName: templateName,
      certificateBody: content,
    };

    dispatch(addCertificateTemplate(templateData))
      .then((response) => {
        setTemplateName('');
        setContent('');
        toast.success(`Template created successfully!`);
        navigate('/Certificate/CertificatePage');
      })
      .catch((error) => {
        toast.error('Error in creating');
      });
  };

  const { t } = useTranslation();
  return (
    <>
      <ArrowDiv>
        <BackButton />
        <CertificateHeading>
          {t('certificate.designTemplate')}
        </CertificateHeading>
      </ArrowDiv>
      <CertificateForm>
        <TextField
          label={t('certificate.TempleteNameLabel')}
          variant="outlined"
          fullWidth
          value={templateName}
          error={Boolean(fieldErrors.templateName)}
          helperText={fieldErrors.templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          style={{ marginBottom: '16px', marginTop: '8px' }}
        />

        <JoditEditorDiv>
          <JoditEditor
            value={content}
            config={config}
            onChange={(newContent) => setContent(newContent)}
          />
        </JoditEditorDiv>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Tooltip title="Click here to save" placement="bottom">
            <span>
              {' '}
              <CommonButton
                onClick={handleSubmit}
                label={t('certificate.certificateCreateLabel')}
              />
            </span>
          </Tooltip>
        </div>
      </CertificateForm>
    </>
  );
}

export default AddTemplate;
