import { palette, theme } from '../palette';
import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  Content,
  Text,
  HeadingH3
} from "../../styles/Common/CommonStyles";

export const NavText = styled(Text)`
${tw`mt-2 hidden lg:block text-sm`}
`;

export const NavHeading = styled(HeadingH3)`
${tw`m-0 mt-4 lg:mt-4 text-base`}
`;

export const NavbarContainer = styled.div`
  ${tw``}
  background: ${palette.primary};
  top: 0;
  height: 70px;
  position: sticky;
  z-index: 100;
`;

export const NavbarContainerMain = styled.div`
  ${tw`  w-full flex justify-between h-20 items-center  `}
  background: ${palette.primary};
  top: 0;
  right: 0;
  z-index: 2;
  height: 100px;
`;


export const HeaderContainer = styled.div`
  ${tw`w-full justify-between flex items-center lg:h-24  `}
  color: ${palette.white};
`;

export const ImageContainer = styled.div`
  ${tw`py-1 pl-4 `}
`;
export const Image = styled.img`
  ${tw`w-auto h-14  cursor-pointer`}
`;

// export const Bars = styled(FaBars)`
//   ${tw`absolute   cursor-pointer`}
//   display: none;
//   color: ${palette.black};
//   @media screen and (max-width: 768px) {
//     display: block;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//   }
// `;

export const NavMenu = styled.div`
  ${tw` 2xl:text-2xl lg:text-sm relative flex items-center w-1/2 justify-end`}
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  right: 150px;
`;

export const NavLink = styled(Link)`
  ${tw`flex  items-center h-full cursor-pointer text-base`}
  color: ${palette.white};
  text-decoration: none;
  padding: 0 1rem;
  &:hover {
    font-weight: bold;
  }

  &.active {
    font-weight: bold;
    border-bottom-style: solid;
    border-bottom-width: 3px;
  }
`;

export const NavBtn = styled.div`
  ${tw`cursor-pointer`}
  // border-radius: 4px;
  width: 80px;
`;

export const NavBtnLink = styled(Link)`
  ${tw`cursor-pointer `}
  border-radius: ${theme.themeBorderRadius};
  background-color: #0684fb;
  padding: 10px 22px;
  color: ${palette.white};
  outline: none;
  border: 2px solid #fff;
  // transition: all 0.2s ease-in-out;
  text-decoration: none;
  min-width: 150px;
  padding: 8px 40px;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  letter-spacing: 0.007em;
  margin-left: 30px;
  /* line-height: 14px; */
  &:hover {
    // transition: all 0.2s ease-in-out;
    background: #fff;
    color: #0684fb;
  }
`;

export const SmallScreenDiv = styled.div`
  // ${tw`shadow-2xl`}
  // z-index: 100;
  // background: ${palette.primary};
  // ${tw`shadow-2xl`}
  // z-index: 100;
  // background: ${palette.primary};

  ${tw`absolute px-2.5 shadow-2xl shadow-black shadow-slate-600`};
  background: ${palette.primary};
  width: 150px;
  right: 0;
  z-index: 100;
  opacity: ${({ isOpen }) =>
    isOpen ? '1' : '0'}; // Adjust opacity based on isOpen state
  transform: translateY(
    ${({ isOpen }) => (isOpen ? '0' : '-100%')}
  ); // Slide down when isOpen, otherwise slide up
`;

export const SmallScreenUl = styled.div`
  ${tw`flex flex-col gap-4 p-8 items-center`}
  ${tw`flex flex-col gap-4 p-8 items-center`}
`;

export const SmallScreenLi = styled.div`
  ${tw``}
  ${tw``}
`;

export const SmallNavLink = styled(Link)`
  ${tw`flex items-center cursor-pointer text-lg`}
  text-decoration: none;
  color: ${palette.white};
  ${tw`flex items-center cursor-pointer text-lg`}
  text-decoration: none;
  color: ${palette.white};
  padding: 0 1rem;
  &:hover {
    font-weight: bold;
  }
`;

export const Hamburger = styled.div`
  ${tw`mr-4`}
  ${tw`mr-4`}
`;

export const Nav = styled.nav`
  ${tw`flex content-between `}
  background: ${palette.primary};
  height: 85px;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
`;

export const LogoLink = styled(Link)`
  ${tw`flex items-center h-full cursor-pointer`}
  width : 200px;
  text-decoration: none;
  padding: 0 1rem;
`;

// export const faCustomStyle = {
//   // position: "absolute",
//   // right: "70px",
// };

// export const ProfileContainer = styled.div`
//   ${tw``}
// `;

export const Initial = styled.h1`
  ${tw`text-center items-center justify-center`}
  color: ${palette.white};
  font-size: 16px;
  margin-top: 5px;
`;

export const ProfileContainer = styled.div`
  ${tw``}
  right: 0px;
`;

export const faCustomStyle = {
  position: "absolute",
  right: "70px",
};

export const Container1 = styled.div`
  ${tw`shadow-2xl shadow-black shadow-slate-600 mb-2 fixed bottom-0 left-12 md:static`};
  background: ${palette.white};
`;

export const ProfileLink = styled(Link)`
  ${tw`flex p-3 items-center cursor-pointer font-normal text-base`}
  color: ${palette.primary};
  text-decoration: none;
  &.active {
    color: ${palette.secondary};
    text-decoration: underline;
  }
  &.hover {
    color: ${palette.secondary};
    text-decoration: underline;
  }
`;

export const ProfilePic = styled.img`
  ${tw``}
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left:6px
`;

export const ProfileHead = styled.div`
  ${tw`flex justify-around items-center mb-2`}
`;

export const SettingIcon = styled.div`
  ${tw``}
`;
