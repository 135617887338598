import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
const NotificationTabs = () => {
  const location = useLocation();
  const defaultTabValue = [
    '/LearnerNotification',
    '/LearnerNotification/ArchivedNotification',
  ].includes(location.pathname)
    ? location.pathname
    : '/LearnerNotification';

    const { t } = useTranslation();

  return (
    <StyledTabs value={defaultTabValue}>
      <StyledTab
        label={t("notification.inboxLabel")}
        value="/LearnerNotification"
        component={Link}
        to="/LearnerNotification"
      />
      <StyledTab
        label={t("notification.archiveLabel")}
        value="/LearnerNotification/ArchivedNotification"
        component={Link}
        to="/LearnerNotification/ArchivedNotification"
      />
    </StyledTabs>
  );
};

export default NotificationTabs;
