import React, { useEffect, useState } from 'react';
import {
  EventTitle,
  Title,
  EventCon1,
  InstructorPic,
  RightSide,
  LeftSide,
  DescriptionCont,
  DescriptionTitle,
  RightDown,
  EventCon2,
  LeftDown,
  EventTag,
  Social,
  SocialCont,
  ShareCont,
  EventDetailsDescription,
  TagsCont,
  EventHeading,
  Para,
  CalendarButton,
  CalendarImg,
  LeftCont,
  InstructorCont,
  LeftContSub,
  InstructorName,
  BackArrow,
  InstName,
  LearningOutcomesGrid,
} from '../../styles/Calendar/CalendarStyle';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../styles/palette';
import { Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventsTabs from './EventsTabs';
import BackButton from '../Utilities/BackButton';
import CommonButton from '../Utilities/CommonButton';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventDetails } from '../../features/calendar/CalendarSlice';
import { Tags, ListItem, List } from '../../styles/Calendar/CalendarStyle';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { useTranslation } from 'react-i18next';

const EventDetails = () => {
  const navigate = useNavigate();
  const { eventname } = useParams();
  const dispatch = useDispatch();
  const { eventDetails } = useSelector((state) => state.calendar);
  const { users, loading } = useSelector((state) => state.auth);
  console.log('Event Details:', eventDetails);
  useEffect(() => {
    dispatch(fetchEventDetails(eventname));
  }, [dispatch, eventname]);

  const handleUsers = (eventname) => {
    navigate(`/EventsNavigator/enrolledusers/${eventname}`);
  };
  const handleBack = () => {
    navigate('/EventsNavigator');
  };
  const handlePaymentButtonClick = () => {
    navigate('/payment');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };
  const { t } = useTranslation();
  return (
    <>
      {users.user.userid?.role === 'SuperAdmin' ? <EventsTabs /> : null}

      <PageContainer>
        <EventTitle>
          <BackButton onClick={handleBack} style={{ marginTop: '10px' }} />
          <Title>{eventDetails?.EventName}</Title>
        </EventTitle>
        <EventCon1>
          <LeftSide>
            <LeftCont>
              <LeftContSub>
                <InstName className="text-xl font-bold mr-4">
                  {t('calender.instructorNameLabel')}
                </InstName>
                <InstructorCont>
                  <InstructorPic
                    src={require('../../images/user.png')}
                    alt="Instructor"
                  />
                  <InstructorName>{eventDetails?.InstName}</InstructorName>
                </InstructorCont>
              </LeftContSub>
            </LeftCont>
          </LeftSide>
          <RightSide>
            {eventDetails?.OutlookCal === 'Yes' && (
              <CalendarButton>
                <AddToCalendarButton
                  name={eventDetails?.EventName}
                  options={['Apple', 'Google', 'Yahoo']}
                  startDate={eventDetails?.SDate}
                ></AddToCalendarButton>
              </CalendarButton>
            )}
          </RightSide>
        </EventCon1>
        <DescriptionCont>
          <DescriptionTitle>
            {t('calender.eventdescriptionLabel')}
          </DescriptionTitle>
          <p style={{ marginLeft: '49px' }}>{eventDetails?.EventDesp}</p>
        </DescriptionCont>
        <EventCon2>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} lg={4}>
              <LeftDown>
                <EventDetailsDescription>
                  <EventHeading>{t('calender.eventdetailsLabel')}</EventHeading>
                  <Para>
                    Event Id: <span>{eventDetails?.EventId}</span>
                  </Para>
                  <Para>
                    Hours: <span>{eventDetails?.Duration}</span>
                  </Para>
                  <Para>
                    Event Name: <span>{eventDetails?.EventName}</span>
                  </Para>
                  <Para>
                    Event Type: <span>{eventDetails?.CourseType}</span>
                  </Para>
                  <Para>
                    Event Date: <span>{formatDate(eventDetails?.SDate)}</span>
                  </Para>
                  <Para>
                    Event Fees: <span>{eventDetails?.CourseFees}</span>
                  </Para>
                  <CommonButton
                    label={
                      users.user.userid?.role === 'SuperAdmin'
                        ? 'Manually Enroll Users'
                        : 'Payment'
                    }
                    onClick={() => handleUsers(eventDetails.EventName)}
                  ></CommonButton>
                </EventDetailsDescription>
              </LeftDown>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <RightDown>
                <EventHeading style={{ marginTop: '20px' }}>
                  {t('calender.tagsLabel')}
                </EventHeading>
                <TagsCont className="">
                  {eventDetails?.tags &&
                    eventDetails.tags.map((tag, index) => (
                      <Tags key={index}>{tag}</Tags>
                    ))}
                </TagsCont>
              </RightDown>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <LearningOutcomesGrid>
                <EventHeading>{t('calender.learningLabel')}</EventHeading>
                <List>
                  {eventDetails?.EventSummary &&
                    eventDetails.EventSummary.split(',').map(
                      (outcome, index) => (
                        <ListItem key={index}>{outcome.trim()}</ListItem>
                      ),
                    )}
                </List>
              </LearningOutcomesGrid>
            </Grid>
          </Grid>

        </EventCon2>
        <ShareCont>
          <EventHeading>{t('calender.shareText')}</EventHeading>
          <SocialCont>
            <Social
              src={require('../../images/instagram.jpg')}
              alt="Instagram"
            />
            <Social src={require('../../images/facebook.jpg')} alt="Facebook" />
            <Social src={require('../../images/linkedin.jpg')} alt="LinkedIn" />
            <Social src={require('../../images/twitter.jpg')} alt="Twitter" />
          </SocialCont>
        </ShareCont>
      </PageContainer>
    </>
  );
};

export default EventDetails;
