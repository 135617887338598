import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllTags,
  fetchEventsByTagAsync,
} from '../../features/calendar/CalendarSlice';
import {
  TagHeader,
  TagBody,
  TagBodySub,
  Tag,
  TagBtn,
} from '../../styles/Calendar/CalendarStyle';

import moment from 'moment';

import { useTranslation } from 'react-i18next';

const TagsCard = ({ displayCount }) => {
  const dispatch = useDispatch();
  const [allTags, setAllTags] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const { tags, loading, error } = useSelector((state) => state.calendar);
  const eventTags = useSelector((state) => state.calendar.data);

  // Filter out tags associated with deleted events
  const filteredTags = tags.filter((tag) => !tag.isDeleted);

  useEffect(() => {
    dispatch(fetchAllTags());
  }, [dispatch]);

  const currentDate = moment().startOf('day'); // Get current date without time

  const handleClick = (tag) => {
    console.log('Clicked tag:', tag);
    dispatch(fetchEventsByTagAsync(tag));
  };

  const handleOnClick = () => {
    setShowMore(!showMore);
  };

  const filteredTagsByDate = filteredTags.filter((tag) =>
    eventTags.some(
      (event) =>
        event && // Check if event is not null or undefined
        event.tags && // Check if event.Tags is not null or undefined
        moment(event.SDate).startOf('day').isSameOrAfter(currentDate) &&
        event.tags.includes(tag),
    ),
  );

  const { t } = useTranslation();

  return (
    <>
      <TagHeader>{t('calender.topTagLabel')}</TagHeader>
      <TagBody>
        <TagBodySub>
          <Tag onClick={() => handleClick('All')}>All</Tag>
          {filteredTagsByDate
            .slice(0, showMore ? filteredTagsByDate.length : 6)
            .map((tag, index) => (
              <Tag key={index} onClick={() => handleClick(tag)}>
                {tag}
              </Tag>
            ))}
        </TagBodySub>
        <TagBtn onClick={handleOnClick} style={{ cursor: 'pointer' }}>

          {showMore ? t('calender.showlessLabel') : t('calender.showmoreLabel')}

        </TagBtn>
      </TagBody>
    </>
  );
};

export default TagsCard;
