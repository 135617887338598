// import React , {useState ,useEffect} from 'react';
// import { TextField, Typography, Button } from '@mui/material';
// import JoditReact from "jodit-react";
// import { useDispatch, useSelector } from 'react-redux';
// import {addItem} from '../../../features/courses/addCourseDetailsSlice';

// const CreateTopics = ({ itemTitle, itemContent , lessonId , moduleId  , selectedItem}) => {
//   const dispatch = useDispatch();
//   console.log(selectedItem , "CreateTopics selectedItem")
//   const [formData, setFormData] = useState({
//     moduleId: null,
//     lessonId: null,
//     title: null,
//     textDescription: null,
//     id: null
//   });

//   useEffect(() => {
//     if (selectedItem) {
//       setFormData({
//         moduleId: selectedItem.moduleId,
//         lessonId: selectedItem.lessonId,
//         title: selectedItem.title,
//         textDescription: selectedItem.textDescription,
//         id: selectedItem.id
//       });
//     }
//   }, [selectedItem]);

//   const handleChange = (event) => {
  
//     const { name, value } = event.target;
//     console.log(name ,value  )
//     setFormData({
//       ...formData,
//       [name]: value ,
      
//     });
//   };

//   const handleSave = async() => {
//     console.log(formData)
//     console.log(lessonId)
//     await  dispatch(addItem(formData))

//   };



//   return (
//     <div style={{marginTop:"63px", width:"100%"}}>
//         <div style={{ display: "flex", justifyContent: "right", alignItems: "center", marginBottom: '10px' }}>
//         <div>
//           <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px" }}>Discard Changes</Button>
//           <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" } } onClick={handleSave}>Save</Button>
//         </div>
//       </div>
//       <TextField
//         label="Topic Title"
//         value={formData.title}
//         onChange={handleChange}
//         variant="outlined"
//         fullWidth
//         name="title"
//         style={{ marginBottom: '10px' }}
//       />
//       <Typography variant="h6" gutterBottom>
//         Content
//       </Typography>
//       <JoditReact 
//       value={formData.textDescription}
//        onChange={(value) => setFormData({ ...formData, textDescription: value })} 
//        name="textDescription"
//        />
//     </div>
//   );
// };

// export default CreateTopics;

import React, { useState, useEffect } from 'react';
import { Grid,TextField, Typography, Button, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import JoditReact from "jodit-react";
import { useDispatch } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CreateTopics = ({ itemTitle, itemContent, lessonId, moduleId, selectedItem, handleDeleteTopic, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    moduleId: null,
    lessonId: null,
    title: null,
    textDescription: null,
    id: null
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        moduleId: selectedItem.moduleId,
        lessonId: selectedItem.lessonId,
        title: selectedItem.title,
        textDescription: selectedItem.textDescription,
        id: selectedItem.id
      });
    }
  }, [selectedItem]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    await dispatch(addItem(formData));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    console.log(action);
    handleMenuClose();
  };
  const handleClose = () => {
    onClose(); // Close the CreateModule component
  };

  return (
    <div style={{ marginTop: "63px", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "right", alignItems: "center", marginBottom: '10px' }}>
        <div>
          <Button variant="outlined" style={{ color: "#0684FB", borderColor: "#ccc", marginRight: "10px" }}onClick={handleClose}>Close</Button>
          <Button variant="contained" style={{ backgroundColor: "#0684FB", color: "white", borderColor: "#ccc" }} onClick={handleSave}>Save</Button>
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuItemClick('Preview current topic')}>Preview current topic</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Preview current  module')}>Preview current module</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Preview complete course')}>Preview complete course</MenuItem>
          </Menu>
        </div>
      </div>
      <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                    margin: '20px 20px 20px 0', // Adjust margin as needed
                    padding: '20px', // Adjust padding as needed
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow
                    overflow: 'auto', // Enable scrolling if content overflows
                    maxHeight: 'calc(100vh - 180px)', // Limit the maximum height to allow vertical scrolling
                    textAlign: 'justify', // Justify text content
                }}
            >
                {formData.title}
            </Grid> 

      <TextField
        label="Topic Title"
        value={formData.title}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        name="title"
        style={{ marginBottom: '10px' }}
      />
      <Typography variant="h6" gutterBottom>
        Content
      </Typography>
      <JoditReact
        value={formData.textDescription}
        onChange={(value) => setFormData({ ...formData, textDescription: value })}
        name="textDescription"
      />
          <Button variant="contained" color="error" style={{ marginTop: '10px' }}
            onClick={() => handleDeleteTopic(moduleId, lessonId, selectedItem.id)}>
            Delete</Button>
            <div style={{marginTop:"155px"}}></div>
    </div>
  );
};

export default CreateTopics;
