import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CommonButton from '../Utilities/CommonButton';
import BackButton from '../Utilities/BackButton';
import { createNotificationSettings } from '../../features/notification/NotificationSlice';
import { fetchRole } from '../../features/userModule/userModuleSlice';
import {
  CreateForm,
  BackButtonWrapper,
} from '../../styles/Notification/CreateNotificationStyles';

import { HeadingH2 } from '../../styles/Common/CommonStyles';

import { ButtonDiv } from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const allUserRoles = [
  'Admin',
  'Instructor',
  'Learner',
  'Course Reviewer',
  'Course Developer',
];

const CreateSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState('');
  const [settingsName, setSettingsName] = useState('');
  const [description, setDescription] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const { roles } = useSelector((state) => state.users) || {};

  useEffect(() => {
    if (!roles.data || roles.data.length === 0) {
      dispatch(fetchRole());
    }
  }, [dispatch, roles.data]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleCreateClick = () => {
    const errors = {};

    if (settingsName.trim() === '') {
      errors.settingsName = 'Settings Name is required.';
    }

    if (description.trim() === '') {
      errors.description = 'Description is required.';
    }

    if (selectedRole.trim() === '') {
      errors.selectedRole = 'Select Role.';
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const rolesToSave =
      selectedRole === 'Select All'
        ? [{ role: 'Select All', isEnabled: true }]
        : [{ role: selectedRole, isEnabled: false }];

    const newSetting = {
      settingsName,
      description,
      roles: rolesToSave,
    };

    dispatch(createNotificationSettings(newSetting))
      .then((data) => {
        toast.success('Notification setting created successfully');
        navigate('/Notification/notificationsettings');
      })
      .catch((error) => {
        console.error('Error creating notification setting:', error);
      });
  };

  const { t } = useTranslation();
  return (
    <>
      <BackButtonWrapper>
        <BackButton />
        <HeadingH2>Create Settings</HeadingH2>
      </BackButtonWrapper>
      <CreateForm>
        <TextField
          label={t('notification.role')}
          select
          value={selectedRole}
          onChange={handleRoleChange}
          error={Boolean(fieldErrors.selectedRole)}
          helperText={fieldErrors.selectedRole}
          fullWidth
        >
          <MenuItem value="" disabled>
            {t('notification.selectRole')}
          </MenuItem>
          <MenuItem value="Select All">{t('notification.selectRole')}</MenuItem>
          {Array.isArray(roles.data) &&
            roles.data.map((role) => (
              <MenuItem key={role._id} value={role.name}>
                {role.name}
              </MenuItem>
            ))}
        </TextField>

        <TextField
          label={t('notification.settingNameLabel')}
          variant="outlined"
          margin="normal"
          value={settingsName}
          error={Boolean(fieldErrors.settingsName)}
          helperText={fieldErrors.settingsName}
          onChange={(e) => setSettingsName(e.target.value)}
        />
        <TextField
          label={t('notification.descriptionLabel')}
          variant="outlined"
          margin="normal"
          value={description}
          error={Boolean(fieldErrors.description)}
          helperText={fieldErrors.description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <ButtonDiv>
          <CommonButton
            label={t('notification.createLabel')}
            onClick={handleCreateClick}
          />
        </ButtonDiv>
      </CreateForm>
    </>
  );
};

export default CreateSettings;
