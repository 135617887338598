// import React, { useState } from 'react';
// import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Grid , Typography, IconButton, Checkbox, FormControlLabel} from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import JoditEditor from "jodit-react";

// const QuestionUtility = () => {
//   const [questionType, setQuestionType] = useState('');
//   const [questionText, setQuestionText] = useState('');
//   const [choices, setChoices] = useState([]);
//   const [choiceCount, setChoiceCount] = useState(0); // State to keep track of choice count
//   const [isOptional, setIsOptional] = useState(false); // State to track if the question is optional

//   const handleQuestionTypeChange = (event) => {
//     setQuestionType(event.target.value);
//     setQuestionText(''); // Reset question text when question type changes
//     setChoices([]); // Reset choices when question type changes
//   };

//   const handleAddChoice = () => {
//     const newChoiceCount = choiceCount + 1;
//     setChoiceCount(newChoiceCount);
//     const choiceNumber = choices.length + 1;
//     setChoices([...choices, { id: choiceNumber, text: '' }]);
//   };

//   const handleChoiceTextChange = (id, text) => {
//     const updatedChoices = choices.map(choice =>
//       choice.id === id ? { ...choice, text } : choice
//     );
//     setChoices(updatedChoices);
//   };

//   const handleDeleteChoice = (id) => {
//     const updatedChoices = choices.filter(choice => choice.id !== id);
//     setChoices(updatedChoices);
//   };

//   const handleOptionalChange = (event) => {
//     setIsOptional(event.target.checked);
//   };

//   return (
//     <div>
//       <FormControl fullWidth sx={{ marginBottom: 2 }}>
//         <TextField
//           select
//           label="Question Type"
//           value={questionType}
//           onChange={handleQuestionTypeChange}
//         >
//           <MenuItem value="oneAnswer">One Answer</MenuItem>
//           <MenuItem value="oneOrMoreAnswers">One or More Answers</MenuItem>
//           <MenuItem value="rating">Rating</MenuItem>
//           <MenuItem value="scale">Scale</MenuItem>
//           <MenuItem value="freeText">Free Text</MenuItem>
//         </TextField>
//       </FormControl>

//       <FormControl fullWidth sx={{ marginBottom: 2 }}>
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={isOptional}
//               onChange={handleOptionalChange}
//               color="primary"
//             />
//           }
//           label="This question is optional"
//         />
//       </FormControl>

//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Typography variant="h6" gutterBottom>
//             Question
//           </Typography>
//           <JoditEditor
//             value={questionText}
//             onChange={(newContent) => setQuestionText(newContent)}
//             config={{
//               placeholder: "Type your question here" // Specify your desired placeholder text
//             }}
//           />
//         </Grid>

//         {choices.map(choice => (
//           <Grid item xs={12} key={choice.id}>
//             <Typography variant="subtitle1" gutterBottom>{`Choice ${choice.id}`}</Typography>
//             <JoditEditor
//               value={choice.text}
//               onChange={(newContent) => handleChoiceTextChange(choice.id, newContent)}
//               config={{
//                 placeholder: "Type your content here" // Display dynamic choice number in placeholder
//               }}
//             />
//             <Grid item xs={12} container justifyContent="flex-end"> {/* Align delete button to the right */}
//               <IconButton color="error" onClick={() => handleDeleteChoice(choice.id)}>
//                 <DeleteIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         ))}

//         <Grid item xs={12}>
//           <Button
//             variant="contained"
//             onClick={handleAddChoice}
//           >
//             Add Choice
//           </Button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default QuestionUtility;


import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Grid , Typography, IconButton, Checkbox, FormControlLabel} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import JoditEditor from "jodit-react";

const QuestionUtility = ({ initialValues }) => {
  const [questionType, setQuestionType] = useState(initialValues.questionType || '');
  const [questionText, setQuestionText] = useState(initialValues.questionText || '');
  const [choices, setChoices] = useState(initialValues.choices || []);
  const [choiceCount, setChoiceCount] = useState(0); // State to keep track of choice count
  const [isOptional, setIsOptional] = useState(initialValues.isOptional || false); // State to track if the question is optional

  const handleQuestionTypeChange = (event) => {
    setQuestionType(event.target.value);
    setQuestionText(''); // Reset question text when question type changes
    setChoices([]); // Reset choices when question type changes
  };

  const handleAddChoice = () => {
    const newChoiceCount = choiceCount + 1;
    setChoiceCount(newChoiceCount);
    const choiceNumber = choices.length + 1;
    setChoices([...choices, { id: choiceNumber, text: '' }]);
  };

  const handleChoiceTextChange = (id, text) => {
    const updatedChoices = choices.map(choice =>
      choice.id === id ? { ...choice, text } : choice
    );
    setChoices(updatedChoices);
  };

  const handleDeleteChoice = (id) => {
    const updatedChoices = choices.filter(choice => choice.id !== id);
    setChoices(updatedChoices);
  };

  const handleOptionalChange = (event) => {
    setIsOptional(event.target.checked);
  };

  return (
    <div>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          select
          label="Question Type"
          value={questionType}
          onChange={handleQuestionTypeChange}
        >
          <MenuItem value="oneAnswer">One Answer</MenuItem>
          <MenuItem value="oneOrMoreAnswers">One or More Answers</MenuItem>
          <MenuItem value="rating">Rating</MenuItem>
          <MenuItem value="scale">Scale</MenuItem>
          <MenuItem value="freeText">Free Text</MenuItem>
        </TextField>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOptional}
              onChange={handleOptionalChange}
              color="primary"
            />
          }
          label="This question is optional"
        />
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Question
          </Typography>
          <JoditEditor
            value={questionText}
            onChange={(newContent) => setQuestionText(newContent)}
            config={{
              placeholder: "Type your question here", // Specify your desired placeholder text
              autofocus: true,
            }}
          />
        </Grid>

        {choices.map(choice => (
          <Grid item xs={12} key={choice.id}>
            <Typography variant="subtitle1" gutterBottom>{`Choice ${choice.id}`}</Typography>
            <JoditEditor
              value={choice.text}
              onChange={(newContent) => handleChoiceTextChange(choice.id, newContent)}
              config={{
                placeholder: "Type your content here", // Display dynamic choice number in placeholder
                autofocus: true,
              }}
            />
            <Grid item xs={12} container justifyContent="flex-end"> {/* Align delete button to the right */}
              <IconButton color="error" onClick={() => handleDeleteChoice(choice.id)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleAddChoice}
          >
            Add Choice
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuestionUtility;

