import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateNotification from "./CreateNotification";
import DisplayNotification from "./DisplayNotification";
import EditNotification from "./EditNotification";
import NotificationList from "./NotificationList";
import SendNotification from "./SendNotification";
import NotificationSettings from "./NotificationSettings";
import LearnerNotification from "./AllNotification";
import ArchivedNotification from "./ArchivedNotification";
import DetailedArchivedNotification from "./DetailedArchivedNotification";
import DetailedNotification from "./DetailedNotification";
import CreateSettings from "./CreateSettings";
import {
  PageContainer,
} from "../../styles/Common/CommonStyles";

const Notifications = () => {
  return (
    <PageContainer  style={{minHeight:'71vh'}}>
      <Routes>
        <Route index path="/*" element={<CreateNotification />} />
        <Route path="/displaynotification" element={<DisplayNotification />} />
        <Route path="/createsettings" element={<CreateSettings />} />
        <Route path="/editnotification/:id" element={<EditNotification />} />
        <Route path="/listnotification" element={<NotificationList />} />
        <Route path="/sendnotification" element={<SendNotification />} />
        <Route path="/sendnotification/:id" element={<SendNotification />} />
        <Route path="/notificationsettings" element={<NotificationSettings />} />
        <Route path="/LearnerNotification" element={<LearnerNotification />} />                  
        <Route path="/ArchivedNotification" element={<ArchivedNotification />}/>
        <Route path="/DetailedArchivedNotification/:id" element={<DetailedArchivedNotification />} />
        <Route path="/DetailedNotification/:id" element={<DetailedNotification />} /> 
      </Routes>
    </PageContainer>
  );
};

export default Notifications;
