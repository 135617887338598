import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDiscussionApi, postDiscussionApi } from '../../api/CoursesApi'

export const fetchDiscussion = createAsyncThunk(
  'messages/fetchMessages',
   async (courseId, { rejectWithValue }) => {
    try{
  const response = await fetchDiscussionApi(courseId);
  return response.data;
    }
    catch(error){
      return rejectWithValue(error.response.data);
    }
});

export const postDiscussion = createAsyncThunk(
  'messages/addMessage', 
  async (messageData, { rejectWithValue , dispatch}) => {
  try {
    const response = await postDiscussionApi(messageData);
    dispatch(fetchDiscussion(courseId));
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
  dispatch(fetchDiscussion(courseId));
});

const courseDiscussionSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: [],
    loading: false,
    error: null,
  },
  reducers: {
    postDiscussionSuccess: (state, action) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchDiscussion.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchDiscussion.fulfilled, (state, action) => {
      state.messages = action.payload;
      state.loading = false;
    })
    .addCase(fetchDiscussion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(postDiscussion.fulfilled, (state, action) => {
      state.messages.push(action.payload);
    });
  },
});

export const { postDiscussionSuccess } = courseDiscussionSlice.actions;
export default courseDiscussionSlice.reducer;
