import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonButton from '../Utilities/CommonButton';
import NotificationTabs from './NotificationTabs';
import { useDispatch, useSelector } from 'react-redux';
import JoditEditor from 'jodit-react';

import {
  fetchSingleSentNotification,
  fetchNotificationTemplate,
  sendNotification,
} from '../../features/notification/NotificationSlice';

import { Page } from '../../styles/Notification/NotificationListStyles';

import {
  Tab,
  Tabs,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';

import { WholePage } from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const SendNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [notificationType, setNotificationType] = useState('');
  const [recipient, setRecipient] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [cc, setCc] = useState('');
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const initialLoad = useRef(true);
  const notificationTemplates = useSelector(
    (state) => state.notification.template,
  );

  const config = useMemo(
    () => ({
      height: 400,
      readonly: false,
      placeholder: '',
    }),
    [],
  );

  useEffect(() => {
    dispatch(fetchNotificationTemplate());
  }, [dispatch]);

  useEffect(() => {
    const fetchTemplateContent = async () => {
      try {
        const response = await dispatch(fetchSingleSentNotification(id));
        const notificationData = response.payload[0];
        const { user_recipients, cc, email_Type, email_Subject, email_Body } =
          notificationData;

        // if (user_recipients.length > 1) {
        //   setTabValue(1);
        // } else {
        //   setTabValue(0);
        // }
        setTabValue(user_recipients.length > 1 ? 1 : 0);

        setRecipient(user_recipients.join(', '));
        setNotificationType(email_Type);
        setCc(cc);
        setSubject(email_Subject);
        setEmailBody(email_Body);

        handleRecipientChange({
          target: { value: user_recipients.join(', ') },
        });
      } catch (error) {
        console.error('Error fetching notification content:', error);
      }
    };
    if (initialLoad.current) {
      fetchTemplateContent();
      initialLoad.current = false;
    }
    // fetchTemplateContent();
  }, [dispatch, id]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleRecipientChange = (e) => {
    const input = e.target.value;
    setRecipient(input);

    const recipientList = input
      .split(/[,;]+/)
      .map((email) => email.trim())
      .filter((email) => email.includes('@'));
    setRecipients(recipientList);
  };

  const handleNotificationTypeChange = (event) => {
    const selectedType = event.target.value;
    const template = notificationTemplates.find(
      (type) => type.notificationType === selectedType,
    );

    setNotificationType(selectedType);
    setSubject(template?.subject || '');
    setEmailBody(template?.notificationBody || '');
  };

  const handleSendNotification = () => {
    if (!recipient || !subject || !emailBody || !notificationType) {
      alert('Please fill in all required fields');
      return;
    }
    const notificationData = {
      user_recipients: recipients,
      cc,
      email_Subject: subject,
      email_Body: emailBody,
      email_Type: notificationType,
    };

    dispatch(sendNotification(notificationData))
      .then(() => {
        toast.success('Notification sent successfully!');
        setNotificationType('');
        setRecipient('');
        setRecipients([]);
        setCc('');
        setSubject('');
        setEmailBody('');
        navigate('/Notification/displaynotification');
      })
      .catch((error) => {
        console.error('Error sending notification:', error);
        toast.error('Failed to send notification. Please try again.');
      });
  };

  const { t } = useTranslation();
  return (
    <>
      <NotificationTabs />
      <WholePage style={{ marginTop: '35px' }}>
        <Page>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={t('notification.IndividualSendLabel')} />
            <Tab label={t('notification.bulkLabel')} />
          </Tabs>
          {tabValue === 0 && (
            <div>
              <TextField
                select
                label={t('notification.selectNotificationLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={notificationType}
                onChange={handleNotificationTypeChange}
              >
                {notificationTemplates?.map((type) => (
                  <MenuItem
                    key={type.notificationType}
                    value={type.notificationType}
                  >
                    {type.notificationType}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={t('notification.RecipientLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={recipient}
                onChange={handleRecipientChange}
              />
              <TextField
                label="CC"
                type="email"
                variant="outlined"
                margin="normal"
                fullWidth
                value={cc}
                onChange={(e) => setCc(e.target.value)}
              />
              <TextField
                label={t('subjectLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <JoditEditor
                value={emailBody}
                onChange={(newContent) => setEmailBody(newContent)}
                config={config}
              />
              <Button onClick={handlePreview}>
                {t('notification.previewLabel')}
              </Button>
            </div>
          )}

          {tabValue === 1 && (
            <div>
              <TextField
                select
                label={t('notification.typeNotificationLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={notificationType}
                onChange={handleNotificationTypeChange}
              >
                {notificationTemplates?.map((type) => (
                  <MenuItem
                    key={type.notificationType}
                    value={type.notificationType}
                  >
                    {type.notificationType}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label={t('notification.Recipientscomma')}
                variant="outlined"
                margin="normal"
                multiline
                rows={3}
                fullWidth
                value={recipient}
                onChange={handleRecipientChange}
              />
              <TextField
                label="CC"
                variant="outlined"
                margin="normal"
                fullWidth
                value={cc}
                onChange={(e) => setCc(e.target.value)}
              />
              <TextField
                label={t('notification.subjectLabel')}
                variant="outlined"
                margin="normal"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <JoditEditor
                value={emailBody}
                onChange={(newContent) => setEmailBody(newContent)}
                config={config}
              />
              <Button onClick={handlePreview}>
                {t('notification.previewLabel')}
              </Button>
            </div>
          )}
          <div>
            <CommonButton
              label={t('notification.sendLabel')}
              onClick={handleSendNotification}
            />{' '}
            &nbsp;
            <CommonButton
              label={t('notification.cancelLabel')}
              onClick={() => toast.warning('notification.Cancelled')}
            />
          </div>
          <Dialog open={openPreview} onClose={handleClosePreview}>
            <DialogTitle>{subject}</DialogTitle>{' '}
            <DialogContent>
              <Typography dangerouslySetInnerHTML={{ __html: emailBody }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePreview}>
                {t('notification.closeNotifiction')}
              </Button>
            </DialogActions>
          </Dialog>
        </Page>
      </WholePage>
    </>
  );
};

export default SendNotification;
