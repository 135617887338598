import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-multi-date-picker';
import { DateObject } from 'react-multi-date-picker';
import CloseIcon from '@mui/icons-material/Close';
import EventPopup from './EventPopup';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import {
  AddDiv,
  CloseIconStyle,
  DialogBoxTitle,
  CalendarContainer,
} from '../../styles/Calendar/CalendarStyle';
import '../../styles/Calendar/Calendar.css';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { palette } from '../../styles/palette';
import { green, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

const CalendarCompSub = ({ eventsData, props }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dateValue1, setDateValue1] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState(
    calculateMonthsToShow(window.innerWidth),
  );
  const { data } = useSelector((state) => state.calendar);

  useEffect(() => {
    const handleResize = () => {
      setMonthsToShow(calculateMonthsToShow(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function calculateMonthsToShow(width) {
    if (width < 450) {
      return 1;
    } else if (width >= 450 && width < 640) {
      return 1;
    } else if (width >= 640 && width < 790) {
      return 1;
    } else if (width >= 790 && width < 1024) {
      return 2;
    } else {
      return 2;
    }
  }

  // Function to handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date.format('YYYY-MM-DD'));
    setOpenDialog(true);
  };

  const handleClose = () => {
    console.log('in handle close function handle close');
    setOpenDialog(false);
  };


  const highlightedDates = data
    ? data.map((ele, index) => {
        return new DateObject(new Date(ele.SDate)).format('YYYY-MM-DD');
        console.log('inside date ', ele.SDate);
      })
    : [];

  const mapDays = ({ date }) => {
    console.log('inside mapdev');
    let dateString = date.format('YYYY-MM-DD'); // Convert the current date to string
    let isHighlighted = highlightedDates.includes(dateString);
    console.log('inside mapdev next'); // Check if this date string is in highlightedDates


    if (isHighlighted) {
      return {
        style: {
          backgroundColor: palette.Calendar_highlight,
          color: palette.Calendar_bgdate,
        },
      };
    }
  };

  const { t } = useTranslation();
  return (
    <CalendarContainer>
      <Calendar
        numberOfMonths={monthsToShow}
        onChange={handleDateChange}
        format="YYYY-MM-DD"
        mapDays={mapDays}
        className="calendar-class"
        weekDays={['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']}
      />

      <Dialog open={openDialog} maxWidth="md">
        <AddDiv>
          <DialogBoxTitle>
            <DialogTitle style={{ fontWeight: '700' }}>
              {t('calender.eventScheduledText')}
            </DialogTitle>
          </DialogBoxTitle>

          <Tooltip title={t('calender.closeLabel')}>
            <CloseIcon onClick={handleClose} sx={CloseIconStyle} />
          </Tooltip>
        </AddDiv>
        <DialogContent>
          <EventPopup date={selectedDate} />
        </DialogContent>
      </Dialog>
    </CalendarContainer>
  );
};

CalendarCompSub.defaultProps = {
  eventsData: [],
};
export default CalendarCompSub;
