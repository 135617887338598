import React from "react";
import { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";

const SearchBar = ({
  handleSearch,
  handleClear,
  searchQuery,
  numberValue,
  Typography1,
  Typography2,
  ButtonText1,
  ButtonText2,
}) => {
  const [numberInput, setNumberInput] = useState(numberValue);

  const handleNumberChange = (event) => {
    setNumberInput(event.target.value);
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between" // Distribute elements with space
      style={{ width: "100%", padding: "20px", border: "2px solid" }} // Set width to 100%
    >
      <Grid item>
        <Typography variant="h6">{Typography1}</Typography>
      </Grid>
      <Grid item>
        <TextField
          // label="First/Last name"
          variant="outlined"
          value={searchQuery}
          onChange={(event) => handleSearch(event.target.value)}
          sx={{ width: "155%", marginLeft: "-45px" }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6">{Typography2}</Typography>
      </Grid>
      <Grid item>
        <TextField
          // label="Number"
          variant="outlined"
          type="number"
          value={numberInput}
          onChange={handleNumberChange}
          sx={{ width: "150%", marginLeft: "-45px" }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSearch}
          sx={{
            backgroundColor: "#fff",
            color: "#000",
            border: "1px solid #000",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          {ButtonText1}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          sx={{
            background: "none",
            color: "#000",
            border: "none",
            "&:hover": {
              backgroundColor: "white", // Change background color to gray on hover
            },
          }}
        >
          {ButtonText2}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
