import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  StyledTabs,
  StyledTab,
} from '../../styles/Certificate/CertificateStyle';
import { useTranslation } from 'react-i18next';


const CertificateTabs = () => {
  const location = useLocation();
  const defaultTabValue = ["/Certificate/CertificatePage","/Certificate/SeparateCertificate","/Certificate/CertificateList", "/Certificate/certificateissued", "/Certificate/certificateblacklisted"].includes(location.pathname) ? location.pathname : "/Certificate/CertificatePage";

  const { t } = useTranslation();

  return (
    <StyledTabs value={defaultTabValue}>
       <StyledTab
        label= {t("certificate.templatesLabel")}
        value="/Certificate/CertificatePage"
        component={Link}
        to="/Certificate/CertificatePage"
      />
      <StyledTab
        label= {t("certificate.certificate")}
        value="/Certificate/SeparateCertificate"
        component={Link}
        to="/Certificate/SeparateCertificate"
      />
      <StyledTab
        label= {t("certificate.eventCertificate")}
        value="/Certificate/CertificateList"
        component={Link}
        to="/Certificate/CertificateList"
      />
      <StyledTab
        label= {t("certificate.issuedLabel")}
        value="/Certificate/certificateissued"
        component={Link}
        to="/Certificate/certificateissued"
      />
      <StyledTab
        label= {t("certificate.blackListedLabel")}
        value="/Certificate/certificateblacklisted"
        component={Link}
        to="/Certificate/certificateblacklisted"
      />
    
    </StyledTabs>
  );
};

export default CertificateTabs;
