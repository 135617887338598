import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  InputAdornment,
  Button,
} from '@mui/material';
import PDFViewer from 'pdf-viewer-reactjs';

// import PDFViewer from '../../Utilities/PDFViewer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { palette } from '../../../styles/palette';
import JoditReact from 'jodit-react';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../../features/courses/addCourseDetailsSlice';
import { setPdf } from '../../../features/courses/pdfSlice';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/Sample.css';

const InsertLiveLesson = ({
  isOpen,
  onClose,
  selectedItem,
  onBackButtonClick,
  lessonId,
  moduleId,
}) => {
  const { id, courseStructure } = useParams();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState('upload');
  const [key, setKey] = useState(0); // State to manage key prop
  const [videoShow, setVideoShow] = useState('');
  const [s3link , sets3Link] = useState('');
  const reloadViewer = () => {
    // Increment key to force re-rendering of PDFViewer with a new instance
    setKey((prevKey) => prevKey + 1);
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOnClose = () => {
    onClose();
  };
  

  const [formData, setFormData] = useState({
    selectedValue: 'upload',
    link: selectedItem?.link || '',
    uploadUrl: '',
    moduleId: selectedItem?.moduleId || '',
    lessonId: selectedItem?.lessonId || '',
    title: selectedItem?.title || '',
    textDescription: selectedItem?.textDescription || '',
    id: selectedItem?.id || '',
    courseStructure: selectedItem?.courseStructure || '',
    contentType: selectedItem?.contentType || '',
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      lessonId: lessonId,
      moduleId: moduleId,
      id: generateRandomNumber(),
      contentType: 'Live',
      courseStructure: courseStructure,
    }));
    console.log(formData);
  };

  const generateRandomNumber = () => {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };


  const handleSubmit = async () => {
    console.log("handleSubmit")
    await dispatch(addItem(formData));
    onClose();
  };

  console.log("multimidia link",formData.link);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        {/* Grid container aligned to the left */}
        <Grid container alignItems="center" spacing={1} sx={{ mt: 1, mb: 2 }}>
          <Grid item>
            <Tooltip title="Go Back">
              <IconButton
                size="small"
                onClick={handleOnClose}
                sx={{ color: `${palette.THEME_THREE}` }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Typography variant="h5" gutterBottom>
            Live 
            </Typography>
          </Grid>
        </Grid>

        {/* Buttons div aligned to the right */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            style={{
              color: '#0684FB',
              borderColor: '#ccc',
              marginRight: '10px',
              width: '180px',
            }}
          >
            Discard Changes
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#0684FB',
              color: 'white',
              borderColor: '#ccc',
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>

      <div style={{ marginRight: '10px', marginLeft: '10px' }}>
        <TextField
          variant="outlined"
          label="Title"
          style={{ width: '100%', marginBottom: '10px' }}
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
        <JoditReact
          value={formData.textDescription}
          config={{ placeholder: 'Enter the live topic description' }}
          onChange={(newContent) =>
            setFormData((prevData) => ({
              ...prevData,
              textDescription: newContent,
            }))
          }
        />
      </div>

      <RadioGroup
        row
        style={{ marginLeft: '20px', marginBottom: '2%', marginTop: '12px' }}
        defaultValue="upload"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="publicUrl"
          control={<Radio />}
          label="Public Url"
        />
      </RadioGroup>

      {/* Render different content based on selected radio button */}
    
      {selectedValue === 'publicUrl' && (
        <Grid item xs={12}>
          <TextField 
          label="Link" 
          name="link"
          value={formData.link}
          onChange={handleInputChange}
          sx={{ width: '100%' }} />
        </Grid>
      )}

{formData.link && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Live Link</Typography>
          <iframe
            src={formData.link}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      )}


    </Container>
  );
};

export default InsertLiveLesson;
