import styled from 'styled-components';
import { palette } from '../palette';
import tw from 'twin.macro';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CommonButton from '../../components/Utilities/CommonButton';
import { Link } from 'react-router-dom';

export const WholePage = styled.div`
  ${tw`m-2 mt-2 rounded-lg  `}
  background: ${palette.white};
`;

export const ArrowDiv = styled.div`
  ${tw`flex flex-row items-center ml-4`}
`;

export const CertificateForm = styled.div`
  ${tw`flex flex-col ml-6 mr-6 gap-0 md:ml-14 overflow-hidden`}
`;

export const CertificateHeading = styled.h2`
  ${tw`text-center md:text-left text-xl md:text-2xl`}
`;

export const DisplayCertificate = styled.div`
  ${tw`overflow-auto`}
`;

export const ButtonDiv = styled.div`
  ${tw`flex justify-around sm:justify-end `}
`;

export const JoditEditorDiv = styled.div`
  ${tw`w-full mt-4`}
`;

export const DetailsForm = styled.form`
  ${tw`flex flex-col gap-6`}
`;

export const MainHeading = styled.h3`
  ${tw`text-base md:text-xl px-3 text-stone-700`}
`;

export const MainDiv = styled.div`
  ${tw`py-1 px-5 mx-2 my-1`}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;

export const SubDiv = styled.div`
  ${tw`py-1 px-5 text-slate-700 rounded`}
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

export const SearchEmail = styled.div`
  ${tw`flex justify-between items-center gap-2 mb-4`}
`;

export const OptionText = styled.p`
  ${tw`text-sm sm:text-base tracking-normal sm:tracking-wider leading-relaxed sm:leading-loose text-justify md:text-left`}
`;

export const StrongText = styled.span`
  ${tw`font-bold ml-3 mr-2`}
`;

export const CheckboxContainer = styled.div`
  ${tw`flex items-center`}
`;

export const CheckboxLabel = styled.label`
  ${tw`mr-2.5`}
`;

export const CommonButtonCancle = styled(CommonButton)`
  background-color: ${palette.failure};
`;

export const TableButtonDiv = styled.div`

  ${tw`flex flex-row mt-6 mb-6 `}
  margin-right:49px;

`;

export const SeparateTableButton = styled.div`
  ${tw`ml-2`}/* background-color: ${palette.primary} */
`;

export const EditButton = styled.div`
  ${tw`flex flex-row gap-2 justify-end`}
`;

export const EventDropdown = styled.div`
  ${tw`ml-4`}
`;

export const TableDiv = styled.div`
  ${tw`m-auto w-11/12`}
`;

export const EditLink = styled(Link)`
  color: black; // Set the color to black
  text-decoration: none; // Remove default underline
  &:hover {
    color: black; // Keep the color black on hover
  }
`;

export const StyledTabs = styled(Tabs)`
  background-color: ${palette.light_gray};
  ${tw`fixed z-50 w-full`}
  top: 70px;

  .MuiButtonBase-root.MuiTab-root {
    background: ${palette.light_gray};
    border-radius: 8px 8px 0 0;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    background-color: ${palette.white};
    z-index: 10;
  }

  .MuiTabs-indicator {
    display: none;
  }

  @media (max-width: 768px) {
    .MuiButtonBase-root.MuiTab-root {
      --tab-width: 100%; /* Full width on smaller screens */
    }
  }

  @media (min-width: 769px) {
    .MuiButtonBase-root.MuiTab-root {
      --tab-width: calc(
        100% / 3
      ); /* Divide equally into 3 parts on larger screens */
    }
  }
`;

export const StyledTab = styled(Tab)`
${tw`mb-4`}
`;
