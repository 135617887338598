import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AllNotification from './AllNotification';
import ArchivedNotification from './ArchivedNotification';
import DetailedArchivedNotification from './DetailedArchivedNotification';
import DetailedNotification from './DetailedNotification';
import { PageContainer } from '../../styles/Common/CommonStyles';

const LearnerNotification = () => {
  return (
    <PageContainer>
      <Routes>
        <Route path="/*" element={<AllNotification />} />
        <Route
          path="/ArchivedNotification"
          element={<ArchivedNotification />}
        />
        <Route
          path="/DetailedArchivedNotification/:id"
          element={<DetailedArchivedNotification />}
        />
        <Route
          path="/DetailedNotification/:id"
          element={<DetailedNotification />}
        />
      </Routes>
    </PageContainer>
  );
};

export default LearnerNotification;
