import React, { useState, useEffect } from 'react';
import { ButtonCont } from '../../styles/Courses/CourseBuilderStyles';
import { PageContainer } from '../../styles/Common/CommonStyles';
import { useNavigate } from 'react-router-dom';
// import { navigate } from 'gatsby';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { palette } from '../../styles/palette';
import '../../assets/coursebuilder.css';
import coursesList from '../../store/CoursesData.json';
import CommonButton from '../Utilities/CommonButton';
import { connect } from 'react-redux';

import styled from 'styled-components';
import ContentPlayer from './ContentPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseInSlice } from '../../features/courses/addCourseDetailsSlice';
import ScromPLayer from './CreateCourse/ScromPlayer';
import Loader from '../Utilities/Loader'

const StyledCard = styled(Card)`
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`;

const CourseBuilder = ({ userProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { coursesList, loading } =
  useSelector((state) => state.addCourseDetails) || {};
  const handleSelectCourse = () => {
    navigate('/SelectCourseCard');
  };
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log('inside useEffect');
    const fetchCourse = async () => {
      await dispatch(fetchCourseInSlice());
    };
    if(coursesList.length === 0) {
      fetchCourse();
    }

  }, [dispatch , coursesList]);

 

  // console.log(coursesList , "shvdsuavdkgshaf")
  //   const [searchResultList, setSearchResultList] = useState([...coursesList]);
  // const [backupCoursesList] = useState([...coursesList]); // Keep a backup of the original list
  // console.log(searchResultList , "shvdsuavdkgshaf")

  // // Local state for search results
  const [searchResultList, setSearchResultList] = useState([]);
  const [backupCoursesList, setBackupCoursesList] = useState([]);

  // Update local state when coursesList changes
  useEffect(() => {
    setSearchResultList([...coursesList]);
    setBackupCoursesList([...coursesList]);
  }, [coursesList]); // Dependency on coursesList

  const handleSearchTextChange = (e) => {
    let searchText = e.target?.value?.toLowerCase();

    if (searchText === '') {
      setSearchResultList([...backupCoursesList]);
    } else {
      const filteredCourses = backupCoursesList.filter((course) =>
        course?.generalInformation?.title?.toLowerCase().includes(searchText),
      );
      setSearchResultList(filteredCourses);
    }
  };

  const calculateDaysAgo = (date) => {
    const currentDate = new Date();
    const createdDate = new Date(date);
    console.log(createdDate);
    const timeDifference = currentDate - createdDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const handleNavigate = (course) => {
    if (course?.generalInformation?.courseStructure === 'SCORM') {
      navigate(`/DisplayScrom/${course._id}`, {
        state: { scormUrl: course.generalInformation.scormUrl },
      });
    } else {
      navigate(
        `/CourseModules/${course?._id}/${course?.generalInformation?.courseStructure}`,{
          state: course,
        }
      );
    }
  };

  if (loading) {
    return< Loader/>;
  }
  // const { users, loading } = useSelector((state) => state.auth);

  const filteredCoursesList = users?.user?.userid?.role === 'Learner'
  ? searchResultList.filter((course) => course.generalInformation.isPublished)
  : searchResultList;

  return (
    <PageContainer>
      {!loading ? <section className="course-builder">
        <Container fluid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                placeholder="Search by Course Title"
                variant="outlined"
                fullWidth
                onChange={(e) => handleSearchTextChange(e)}
              />
            </Grid>
            {/* </Grid>
          <Grid container spacing={2}> */}
            {/* <Grid
              item
              xs={12}
              sx={{
                backgroundColor: `${palette.light_gray}`,
              }}
            >
              <ContentPlayer />
            </Grid> */}
            {/* <Grid item xs={5}></Grid> */}

            {filteredCoursesList?.map((course) => {
              const daysAgo = calculateDaysAgo(course?.createdAt);
              return (
                <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
                  {users?.user?.userid?.role === 'SuperAdmin' && (
                    <StyledCard className="course-card">
                      <Tooltip title="Click to add Chapter">
                         <Link onClick={() => handleNavigate(course)}>
                          <div className="image-container">
                            <div
                              class="blurbg"
                              style={{
                                background:
                                  'url(' +
                                  course.generalInformation.bannerImage +
                                  ')',
                              }}
                            ></div>
                          </div>
                        </Link>
                      </Tooltip>
                      <CardContent sx={{ pb: 0 }}>
                        {/* <Link
                          href="#"
                          underline="hover"
                          onClick={() =>
                            navigate('/CourseDetails', {
                              state: course,
                            })
                          }
                        >
                          <Typography variant="body1">
                            {course.title}
                          </Typography>
                        </Link> */}
                        <Link
                          // href="#"
                          underline="hover"
                          onClick={() => handleNavigate(course)}
                        >
                          <Typography variant="body1" fontWeight="bold">
                            {course.generalInformation.title}
                          </Typography>
                        </Link>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {/* {course.author} */}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              mb={2}
                              fontWeight="bold"
                            >
                              {course.generalInformation.isPublished
                                ? 'Published'
                                : 'Unpublished'}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="textSecondary">
                              {/* Enrolled Learners: {course.enrolledLearners} */}
                              Enrolled Learners:
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              mb={2}
                            >
                              {daysAgo === 0
                                ? 'Today'
                                : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider></Divider>
                      <CardActions>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Tooltip title="Details">
                              <IconButton
                                sx={{ color: `${palette.THEME_THREE}` }}
                                size="small"
                              >
                                <InfoOutlinedIcon
                                  onClick={() =>
                                    navigate('/CourseDetails', {
                                      state: course,
                                    })
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Divider orientation="vertical" />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Course Settings">
                              <IconButton
                                size="small"
                                sx={{ color: `${palette.THEME_THREE}` }}
                              >
                                <BuildOutlinedIcon
                                  // onClick={() => navigate('/CourseSettings')}
                                  onClick={() => navigate(`/CourseSettings/${course._id}`)}

                                />
                              </IconButton>
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Landing Page Design">
                              <IconButton
                                size="small"
                                sx={{ color: `${palette.THEME_THREE}` }}
                              >
                                <BrushOutlinedIcon
                                  onClick={() =>
                                    navigate('/LandingPageDetails')
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Learners">
                              <IconButton
                                size="small"
                                sx={{ color: `${palette.THEME_THREE}` }}
                              >
                                <PeopleOutlineOutlinedIcon
                                  onClick={() => navigate(`/LearnersList/${course._id}`)}
                                />
                              </IconButton>
                            </Tooltip>
                            {/* {coursesList?.map((course) => (
        <StyledCard key={course._id}>
          <Tooltip title="Learners">
            <IconButton
              size="small"
              onClick={() => navigate(`/LearnersList/${course._id}`)}
            >
              <PeopleOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </StyledCard>
      ))} */}
                            <Divider orientation="vertical" flexItem />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Course Preview">
                              <IconButton
                                size="small"
                                sx={{ color: `${palette.THEME_THREE}` }}
                              >
                                <VisibilityOutlinedIcon
                                  onClick={() => navigate(`/CoursePreview/${course._id}/${course?.generalInformation?.courseStructure}`)}
                                />
                              </IconButton>
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Course Discussions">
                              <IconButton
                                size="small"
                                sx={{ color: `${palette.THEME_THREE}` }}
                              >
                                <ChatBubbleOutlineOutlinedIcon
                                  onClick={() =>
                                    navigate(`/CourseDiscussion/${course._id}`)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </StyledCard>
                  )}
                  {/* Learners View  */}

                  {users?.user?.userid?.role === 'Learner' && (
                    <StyledCard className="course-card">
                      <Tooltip title="Click to add Chapter">
                        <Link
                          onClick={() =>
                            navigate(
                              `/LearnerCoursePreview/${course?._id}/${course?.generalInformation?.courseStructure}`,
                              {
                                state: {
                                  courseTitle: course.generalInformation.title,
                                },
                              }
                            )
                          }
                        >
                          <div className="image-container">
                            <div
                              class="blurbg"
                              style={{
                                background:
                                  'url(' +
                                  course.generalInformation.bannerImage +
                                  ')',
                              }}
                            ></div>
                          </div>
                        </Link>
                      </Tooltip>
                      <CardContent sx={{ pb: 0 }}>
                        <Link
                          // href="#"
                          underline="hover"
                          // onClick={() =>
                          //   navigate('/LearnerCoursePreview', {
                          //     state: course,
                          //   })
                          // }
                          onClick={() =>
                            navigate(
                              `/LearnerCoursePreview/${course._id}/${course?.generalInformation?.courseStructure}`,
                              {
                                state: {
                                  courseTitle: course.generalInformation.title,
                                },
                              }
                            )
                          }
                        >
                          <Typography variant="body1" fontWeight="bold">
                          {course.generalInformation.title}
                          </Typography>
                        </Link>

                        {/* <Typography variant="subtitle1" color="textSecondary">
                          {course.author}
                        </Typography> */}
                        <Grid container spacing={2}>
                          <Grid item xs={6}></Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              mb={2}
                            >
                              {daysAgo === 0
                                ? 'Today'
                                : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Tooltip title="Details">
                              <IconButton
                                sx={{ color: `${palette.THEME_THREE}` }}
                                size="small"
                              >
                                <InfoOutlinedIcon
                                  onClick={() =>
                                    navigate('/CourseDetails', {
                                      state: course,
                                    })
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Divider orientation="vertical" />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Course Discussions">
                              <IconButton
                                size="small"
                                sx={{ color: `${palette.THEME_THREE}` }}
                              >
                                <ChatBubbleOutlineOutlinedIcon
                                  onClick={() =>
                                    navigate(`/CourseDiscussion/${course._id}`)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </StyledCard>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </section> :  <div>Loading...</div>}
      {users?.user?.userid?.role === 'SuperAdmin' && (
        <div style={{ position: 'fixed', bottom: '30px', right: '20px', zIndex: 1000 }}>
        <CommonButton onClick={handleSelectCourse} label="Create Course" />
      </div>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
  };
};

export default connect(mapStateToProps)(CourseBuilder);
