import styled from "styled-components";
import tw from "twin.macro";
import { palette } from "../palette";

export const FooterContainer = styled.div`
  ${tw`relative w-full h-24 flex justify-center items-center `};
  background-color: ${palette.footer};
  letter-spacing: 0.015em;
  column-gap: 28px;
  bottom: 0px;
  // top: 90px;
`;

export const Container = styled.div`
  ${tw`flex justify-center items-center`}
  letter-spacing: 0.015em;
  column-gap: 4px;
`;
export const Text = styled.p`
  ${`2xl:text-xl xl:text-base lg:text-xs md:text-xs`}
`;

export const FooterText = styled.p`
  ${tw`2xl:text-xl xl:text-base lg:text-xs  md:text-xs cursor-pointer underline `}
`;
